import React, { useState } from 'react'
import { deleteFile, saveFile } from '../../helpers/firebase'


export const Accesorios=({equnico, Eqselect, setEqselect, setEstadomodal})=> {
 
  console.log(equnico)
  const [images,setImg] = useState(null)

  const handleChange= (e)=>{
    equnico[e.target.name] = e.target.value
    console.log(equnico)
  }
   
  const deleteImage = async(img) =>{
    await deleteFile(img)
    const idx = equnico.fotos.indexOf(img)
    console.log(idx)
    const newArray = equnico.fotos.splice(idx,1)
    setImg(newArray)
  
  }    
    const ingresar=()=>{
        setEqselect([
            ...Eqselect,
            equnico
        ])
        setEstadomodal(false)
    }
   const onUpload = async( event )=>{
    if(event.target?.files[0]){
      console.log(event.target?.files)
      try{
        const link = await saveFile(event.target,'images')
        console.log(link)
        equnico.fotos = Array.isArray( equnico.fotos)?[...equnico.fotos,link]:[link]
        setImg( equnico.fotos)
        //console.log(equnico)
      }catch(error){
        //console.log(error)
      }
    }
   } 

  return (
    <div>
      <div className='accesoriosbody'>
        <label className='bold'>Accesorios</label>
        <textarea name="accesorios" defaultValue={'NINGUNO'} onChange={handleChange}></textarea>
        <label  className='bold'>Observación</label>
        <textarea name="observacion" placeholder='Observaciónes' onChange={handleChange}></textarea>
        <br/>
        <label className='bold'>Fotos del equipo</label>
        <input type={'file'} name="img" onChange={onUpload} />
        <div className='flex wrap fix-600 justify-flex-center'  >
        {equnico.fotos && equnico.fotos.map((img)=>
        <div className='mt-5 pos-rel mr-3'>
        <i onClick={()=>deleteImage(img)} class="bi bi-x-circle pos-abs right-0  close-x"></i>
        <img className="img_form" src={img} alt="img" />
  
        </div>)}
        </div>
      </div>
      <br/>
      <button className='btn-btn-secundary w-100' onClick={ingresar}>Seleccionar</button>
    </div>
  )
}
