import React, { useEffect, useState } from 'react'
import { actualizarDatos, crearDatos, filterData, getDataList } from '../../api/common'
import SelectMult from './SelectMult'
import Spinner from "../commons/Spinner";
import { Modalcarga } from '../Modales/Modalcarga'

export const SettingCreate = ({setVentana, setRefresh, refresh, dataEdit=null }) => {

    const [dataSet,setDataSet]=useState(dataEdit)
    const [estadomodal, setEstadomodal]=useState(false)
    
    
    const handleData=(e)=>{
        setDataSet({...dataSet,[e.target.name]: e.target.value})
    }
    const handleDataText=(e)=>{
        setDataSet({...dataSet,[e.target.name]: e.target.value.toLowerCase()})
    }


    useEffect (()=>{
        const container = document.getElementById('myContainer_1');
        const invalidFeedback = document.querySelector('.invalid-selecction');
        if(dataSet?.users || dataSet?.areas || dataSet?.roles ){
            console.log("si seleccionado")
            container.classList.remove('invalidSeleccion');
            invalidFeedback.classList.add('transparent')
        }else{
            container.classList.add('invalidSeleccion');
            invalidFeedback.classList.remove('transparent')
            //invalidFeedback.style.display = 'block';
            console.log("no seleccionado")
            setTimeout(() => {
                container.classList.remove('invalidSeleccion');
                // invalidFeedback.style.display = 'none';
              }, 3000);
        }
        },[dataSet])

    const validarInputs = (e) => {
        e.preventDefault();
        const container = document.getElementById('myContainer_1');
        const invalidFeedback = document.querySelector('.invalid-selecction');

        if(dataSet?.users || dataSet?.areas || dataSet?.roles ){
            container.classList.remove('invalidSeleccion');
            setEstadomodal(true)
            crearSet(dataSet)
        }else{
            container.classList.add('invalidSeleccion');
            console.log("no seleccionado")
            setTimeout(() => {
                container.classList.remove('invalidSeleccion');
                //invalidFeedback.style.display = 'none';
              }, 1500);
        }
      };

    async function crearSet(data){
        try {
            const result= dataEdit?
            await actualizarDatos('NotificacionSet',data,dataEdit._id):
            await crearDatos('NotificacionSet',data)
            console.log("resultado de la peticion",result)
            setRefresh(!refresh)
            setEstadomodal(false)
            setVentana(false)
        } catch (error) {
        }

    }

  return (
    <div>
        <Modalcarga
                  estadoIn={estadomodal} 
                  cambiarestadoIn={setEstadomodal}
        >
            <Spinner/>

        </Modalcarga>
        <form  className="mb-4" onSubmit={validarInputs} novalidate>
            <div className="mb-3">
                <label for="validationCustom01" className="form-label">Nombre</label>
                <input type="text" className="form-control" id="validationCustom01" value={dataSet?.nameNotificacion} required placeholder="Nombre del set" onChange={handleDataText} name='nameNotificacion'  disabled={dataEdit? true:false}/>
            </div>
            <p>
              Las siguientes opciones dirigen las notificaciones  que se enviaran, por lo tanto es obligatorio elegir al menos una.
            </p>
            <div className="sinEfecto"> 
                <label className="invalid-selecction ">
                    Por favor selecciona al menos una de las siguientes opciones
                </label>
            </div>

            <div id="myContainer_1" className="sinEfecto"> 
            <div class="mb-3">
                <label  for="resposanble">Usuarios</label>
                <SelectMult
                dataDefault={dataSet?.users}
                handledata={handleData}
                name='users'
                collection={"Usuarios"}
                field={"nombre"}
                baseFilter={{ 
                    "role.nombre": "~Cliente",
                    "type":"foreign",
                    "populate":[] }}
                />
            </div>
            <div class="mb-3">
                <label  for="resposanble">Areas</label>    
                <SelectMult
                    dataDefault={dataSet?.areas}
                    handledata={handleData}
                    placeholder='Seleciona las areas (opcional)'
                    name='areas'
                    collection={"Areas"}
                    field={"nombre"}
                />
            </div>
            <div class="mb-3">
                <label  for="resposanble">Roles</label>    
                <SelectMult
                    dataDefault={dataSet?.roles}
                    handledata={handleData}
                    placeholder='Seleciona un rol (opcional)'
                    name='roles'
                    collection={"Roles"}
                    field={"nombre"}
                />
            </div>
            </div>
            <hr/>
            <button type="submit" className={dataEdit?"btn-btn-secundary w-100":"btn-btn-primary w-100"}>{dataEdit? "Editar Set":"Crear Set"}</button>
            
        </form> 
    </div>
  )
}


