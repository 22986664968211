import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { consultarLoteEq, getDataList } from "../../api/common";
import SelectSearch from "../commons/SelectSearch";
import Swal from "sweetalert2";
import { VerifiedSerialList } from "../Ot/VerifiedSerialList";
import EquiposRep from "../Ot/EquiposRep";
import { Modales } from "../Modales";

let errors=[]
const FormEquipments = ({ selectitem, setEquipo, cliente,role, equipo, equipos, setEquipos}) => {
  const [items, setItems] = useState(null);
  const [modalEqExist, setModalEqExist]=useState(false);

      //evento para capturar el formulario del equipo
      const handledata = (event) =>{
        setEquipo({
          ...equipo,
          [event.target.name]:event.target.value
        })
        //console.log(equipo)
      }  

  const add = async()=>{
    if(equipo.cliente && equipo.item && equipo.marca && equipo.modelo ){
      let verificar=VerifiedSerialList(equipos,equipo)
      if(verificar.length===0){
      try {
        const verficarEq=await consultarLoteEq('equipos',[equipo])
        setEquipos([
          ...equipos, 
          equipo
        ])
      } catch (error) {
        if(error.response.data.error.message==='EQUIPOS EXISTENTES'){
          errors=error.response.data.detail.split('|')
          console.log(errors)
          setModalEqExist(true) 
        }
      }
    }
  }else{Swal.fire({
    title:"Error Al agregar",
    text: "Se requiere informacion completa del equipo y/o Cliente",
    icon:"warning",
    button:"Aceptar"
  }) }
} 

  useEffect(() => {
    async function getItems() {
      const itemDB = await getDataList("Items");
      ////console.log(clientesDB.data.data)
      const itemlist = itemDB.data.data.map((listitems) => ({
        value: listitems._id,
        label: listitems.nombre,
      }));

      setItems(itemlist);
    }

    getItems();
  }, []);

  return (
    <>
      <Modales 
       estado={modalEqExist} 
        cambiarestado={setModalEqExist}
        titulo={'Equipos Repetidos'}
      >
        <EquiposRep Equipos={errors} />
      </Modales>
      {items && (
        <form className={` formitem ${cliente?' formitem-11':' formitem-9'}`}>
          <Select
            onChange={selectitem}
            searchable="true"
            className="item1"
            options={items}
            required
          ></Select>
          {cliente ? (
            <SelectSearch
             className="h-100"
              handledata={handledata}
              name="cliente"
              collection={"Usuarios"}
              field={"nombre"}
              baseFilter={{ "role.nombre":"Cliente"}}
              disabled={role === 'Cliente'}
             
            />
          ) : null}
          <input
            onChange={handledata}
            name="marca"
            className="titulo"
            type={"string"}
            placeholder="Marca"
            required
          />
          <input
            onChange={handledata}
            name="modelo"
            className="titulo"
            type={"string"}
            placeholder="Modelo"
            required
          />
          <input
            onChange={handledata}
            name="serie"
            className="titulo"
            type={"string"}
            placeholder="Serie"
            required
          />
          <input
            onChange={handledata}
            name="inventario"
            className="titulo"
            type={"string"}
            placeholder="Inv"
          />
          <input
            onChange={handledata}
            name="ubicacion"
            className="titulo"
            type={"string"}
            placeholder="Ubicación"
          />
          <input
            onChange={handledata}
            name="resolucion"
            className="titulo"
            type={"string"}
            placeholder="Resolucion"
          />
          <textarea
            onChange={handledata}
            name="puntos"
            className="titulo"
            type={"string"}
            placeholder="Puntos"
          ></textarea>
          {/*<textarea
            onChange={handledata}
            name="observacion"
            className="titulo"
            type={"string"}
            placeholder="Observacion"
          ></textarea>*/}
          <a onClick={add}>
            <i className="bi bi-plus-square cursor-p"></i>
          </a>
        </form>  
      )}
    </>
  );
};
export default FormEquipments;
