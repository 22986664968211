




const TabForm = ({tabs,setTab,tab,titles}) =>{
   

    return(
    <div className="tab">
    {Array.from(Array(tabs).keys()).map((val,i)=>(
        <div style={{width:'100%'}}>
        {titles?.length>0?<div>{titles[i]['title']}</div>:null}
        <div style={{position:'relative',padding:'15px 0',width:'100%'}}>
        <span key={i} className= {` ${val+1 <= tab?'selected-tab':'tab-component'}`}
        onClick={(e)=>{ setTab(parseInt(val+1)) }} ><i class={`bi ${titles[i]['icon'] || 'bi-info-lg'}`}></i>
        </span>
        <div className={`tab-line ${val+1 <= tab?'check-tab':''}`}> </div>
        </div>
       
        </div>
     ))}
    </div>);
}

export default TabForm;