import React, { useEffect, useState } from "react";

export const Modales = ({
  children,
  estado,
  cambiarestado,
  titulo,
  validation = false,
}) => {
  const [openValidationWindow, setOpenValiationWindow] = useState(false);

  const closeModal = () => {
    if (!validation) return cambiarestado(false);
    setOpenValiationWindow(true);
  };

  return (
    <>
      {estado && (
        <div className="modalbg">
          {openValidationWindow && (
            <div className="modalbg">
              <div
                className="contentmodal"
                style={{ position: "fixed", zIndex: "800" }}
              >
                ¿Estas seguro deseas Cerrar la ventana?
                <div className="flex space-around">
                  {" "}
                  <button
                    className="btn-btn-primary"
                    onClick={() => {
                      setOpenValiationWindow(false);
                      cambiarestado(false);
                    }}
                  >
                    Si
                  </button>{" "}
                  <button
                    className="btn-btn-primary"
                    onClick={() => {
                      setOpenValiationWindow(false);
                    }}
                  >
                    No
                  </button>{" "}
                </div>
              </div>
            </div>
          )}

          <div className={`contentmodal`}>
            <div className="encabezadomodal">
              <h3>{titulo}</h3>
            </div>
            <button className="closebutton" onClick={() => closeModal()}>
              <i className="bi bi-x close closex"></i>
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
};
