import moment from 'moment'
import React from 'react'

export const Listaot=({estado, cambiarestado, setOrdendata, listadoot, setPestaña, setBodyingreso, bodyingreso})=> {
    //console.log(listadoot)

    const selectot=(ot)=>{
        setBodyingreso({
            ...bodyingreso,
            numeroOrden:ot.numeroOrden,
            nameClient:ot.cliente.nombre,
            orden:ot._id,
            consecutivo:ot.ingresos? ot.ingresos+1 : 1
        })
        setOrdendata(ot)
        setPestaña(1)
        cambiarestado(!estado)
    }
  return (
    <div>
        <div>
        <ul clas className='listadoot listaot'>
            <li>No Orden</li>
            <li>Fecha de creación</li>
            <li>Estado</li>
            <li>Seleccionar</li>
        </ul>

        {listadoot.slice().reverse().map(ot =>(
            <div key={ot._id}>
                <ul clas className='listadoot sombra-button'>
                    <li>{ot.numeroOrden}</li>
                    <li>{moment(ot.updatedAt).format('L')}</li>
                    <li>{ot.estado}</li>
                    <li><button  className='btn-btn-secundary' onClick={()=>selectot(ot)}>Seleccionar</button></li>
                </ul>
            </div>
        ))}        
        </div>
    </div>
  )
}
