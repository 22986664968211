import React, { useEffect, useState } from 'react'

const Form_3EqPatron = ({setPageForm, setEqBody, eqBody, setTitulo}) => {
  const [dataCal, setDataCal]=useState()

  console.log(eqBody)
  useEffect(()=>{
    if(eqBody?.calibraciones) setDataCal(eqBody?.calibraciones[0])
    setTitulo('Información de Calibración')
  },[])

  useEffect(()=>{
    if(dataCal)
    setEqBody({
      ...eqBody,
      calibraciones:[
        dataCal
      ]})
   },[dataCal])

  const handleSubmit=async(e)=>{
    e.preventDefault();
    setPageForm(3)
}
  const handlePerCal=(event)=>{
    setEqBody({
        ...eqBody,
        [event.target.name]:event.target.value.toLowerCase()
    })
   }
  
   const handledata=(event)=>{
    setDataCal({
      ...dataCal,
      [event.target.name]:event.target.value.toLowerCase()
  })
}
  return (
    <div>
      <div>
        <form  className='form_container' onSubmit={handleSubmit}>
        <div className='form_group '>
                <label htmlFor={'perCal'} className='form_group_label'>PERIODO DE CALIBRACION EN MESES</label>
                <input name='perCal' className='titulo w-100' type={'number'} onChange={handlePerCal} defaultValue={eqBody?.perCal} required={eqBody?.calibraciones? true: false}  />
            </div>
            <div className='form_group '>
                <label htmlFor={'fecha'} className='form_group_label'>FECHA DEL CALIBRACION</label>
                <input name={'fecha'} className='titulo w-100' type={'date' } onChange={handledata}value={eqBody?.calibraciones && eqBody?.calibraciones[0]?.fecha? eqBody?.calibraciones[0]?.fecha:'' }  />
            </div>
            <div className='form_group ' >
                <label htmlFor={'evidencia'} className='form_group_label'>EVIDENCIA</label>
                <input name={'evidencia'} className='titulo w-100' onChange={handledata}defaultValue={eqBody?.calibraciones && eqBody?.calibraciones[0]?.evidencia? eqBody?.calibraciones[0]?.evidencia:'' }  />
            </div>
            <div className='form_group ' >
                <label htmlFor={'responsable'} className='form_group_label'>RESPONSABLE</label>
                <input name={'responsable'} className='titulo w-100' onChange={handledata}defaultValue={eqBody?.calibraciones && eqBody?.calibraciones[0]?.responsable? eqBody?.calibraciones[0]?.responsable:''  }  />
            </div>

            <div className='w-100'>
            <button className='btn-btn-tertiary ' onClick={()=>setPageForm(2)}>Anterior</button>
            <button className='btn-btn-secundary' onClick={()=>setPageForm(4)}>Siguiente</button>
            </div>
        </form>
    </div>
      
    </div>
  )
}

export default Form_3EqPatron