export const obtenerSede=(equipos)=>{
    // Set para almacenar las sedes únicas
  const sedesUnicas = new Set();
  // Recorrer cada equipo
  equipos.forEach(equipo => {
    // Agregar la sede del equipo al set
    if(equipo?.equipo?.sedeClient) sedesUnicas.add(JSON.stringify(equipo?.equipo?.sedeClient))
  });
  // Convertir el set a un array y ordenarlo alfabéticamente
  return Array.from(sedesUnicas).map(sede => JSON.parse(sede));
}