import React, { useEffect, useState } from 'react'
import { Modales } from '../Modales'
import { Formsede } from '../Ot/Formsede'
import { actualizarDatos, getDataList } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'

export const ListaSedesnew = ({user, refresh, setRefresh}) => {
   
    console.log(user)
    let conditions={
        cliente:user._id
    }
    const [dataSede, setDataSede]=useState()
    const [modal, setModal]=useState(false)
    const [sedes, setSedes]=useState()

    console.log('Data sede edit', dataSede)

    useEffect(() => {

        async function getUser() {
          console.log(conditions)
          const userDB = await getDataList("Sedes",conditions);
          setSedes(userDB.data.data)
          console.log(userDB.data.data);
        }
        getUser();

      }, [refresh]);

    const EditSede=(data)=>{
        setDataSede(data)
        setModal(true)
    }

    const EditInfo=(event)=>{
        console.log(event)
        setDataSede({
            ...dataSede,
            [event.target.name]:event.target.value
        })
    }

    const enviar=()=>{
        console.log("Editrando sede", dataSede)
        async function edicionsede(){
            try {
                const Result= await actualizarDatos('Sedes',dataSede,dataSede._id)
                console.log(Result)
                setRefresh(!refresh)
                setModal(false)
                Alertassw('success','Sede Actualizada correctamente')
            } catch (error) {
                Alertassw('error','No se pudo actualizar los datos')
            }
        }
        edicionsede()
    }
  return (
    <div>
        <Modales 
        titulo={`Editar Sede ${dataSede?.nameSede}`} 
        estado={modal} 
        cambiarestado={setModal}>
            <Formsede dataSede={EditInfo} Enviar={enviar}  data={dataSede} dataDefault={dataSede}/>
        </Modales>
        <h5>Sedes</h5>
    <div>
        <span
            className="link cursor-p"
            onClick={(e) =>
                e.target.parentNode.nextSibling.classList.toggle(
                "display-none"
                )
            }
            >
            Ver más
        </span>
    </div>
    <table  className='table table-striped w-100 table-bordered'>
        <thead class="thead-light">
            <tr>
                <th scope="col">Sede</th>
                <th scope="col">Direccón</th>
                <th scope="col">Contacto</th>
                <th scope="col">Telefono</th>
                <th scope="col">Email</th>
                <th scope="col">Editar</th>
            </tr>
        </thead>
        <tbody>
            {sedes?.map((sede, index)=>(
            <tr key={index}>
                <td>{sede?.nameSede}</td>
                <td>{sede?.dirSede}</td>
                <td>{sede?.contSede}</td>
                <td>{sede?.celContacto}</td>
                <td>{sede?.emailContacto}</td>
                <td> <i  className="bi bi-pencil-square togEdit cursor-p"  onClick={()=>{EditSede(sede)}}></i></td>
            </tr>
            ))}

        </tbody>
    </table >

    </div>
  )
}
