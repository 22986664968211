import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../commons/Pagination';
import Spinner from '../commons/Spinner';

moment.locale('es');
export const Listaringreso=({ingresos,setIngreso,page,setPage})=> {
    if(ingresos){
    }else{//console.log('no hay ingresos')
    }
    
    const navigate = useNavigate()

    const changePage= (page)=>{
        setIngreso({...ingresos,results:{}})
        setPage(page)
      }
    
    const openingreso=(ingreso)=>{
        navigate(`/Ingresos/${ingreso._id}?orden=${ingreso.numeroOrden}&origen=ingresos`)
    }
   
    const observacion=(ingreso)=>{
        //console.log(ingreso)
    }

  return (
    <div>
    {ingresos.results?.length>0?(
        <div>
            <ul className='col-6-ingreso cabeceraitem bg-primary'>
            <li>No Ingreso</li>
            <li>Cliente</li>
            <li>Autor</li>
            <li>Fecha Ingreso</li>
            <li>Fecha Salida Max</li>
            <li><i className="bi bi-eye-fill cursor-p font_sz"></i></li>
            <li>Estado</li>
        </ul>
        {ingresos?.results?.length>0? (ingresos.results.map(ingreso=>(
                <ul className='col-6-ingreso sombra-button' key={ingreso._id}>
                   <div className='linkingreso' onClick={()=>openingreso(ingreso)}><li>{'I-'+ingreso?.numeroOrden +'-'+ingreso?.consecutivo }</li></div>
                   <li>{ingreso?.equipos[0]?.equipo?.equipo?.cliente?.nombre}</li>
                   <li>{ingreso?.autor?.nombre +' '+ ingreso.autor.apellido}</li>
                   <li>{moment(ingreso?.fechaIngreso).format("YYYY/MM/DD")}</li>
                   {ingreso.estado ==='Abierta'?(<li>{moment(ingreso?.fechaSalidaEstimada).format("YYYY/MM/DD")}</li>):<li>{moment(ingreso?.fechaSalida).format("YYYY/MM/DD")}</li>}
                   {ingreso.observacion ? ( <i className="bi bi-eye-fill cursor-p font_sz" onClick={()=>observacion(ingreso)}></i>):<i></i>}
                   {ingreso.estado ==='Abierta' && moment().diff(ingreso?.fechaIngreso,"d")<9?(<li className='a-tiempo bold border-r-5'>A tiempo</li>): null}
                   {ingreso.estado ==='Abierta'&& moment().diff(ingreso?.fechaIngreso,"d")==12?(<li className='Cierrahoy bold border-r-5'>Cierra Hoy</li>):null}
                   {ingreso.estado ==='Abierta'&& moment().diff(ingreso?.fechaIngreso,"d")>12?(<li className='atrasada bold border-r-5'>Atrasado</li>):null}
                   {ingreso.estado ==='Abierta'&& moment().diff(ingreso?.fechaIngreso,"d")>=9 && moment().diff(ingreso?.fechaIngreso,"d")<12 ?(<li className='a-vencer bold border-r-5'>Próximo a cerrar</li>): null}
                   {ingreso.estado ==='Cerrada'?(<li className='cerrada bold border-r-5'>Cerrado</li>): null}  
                   {ingreso.estado ==='Anulado'?(<li className='atrasada bold border-r-5'>Anulado</li>): null}  
               </ul> 
        ))):(<Spinner />)}
        <Pagination prev={ingresos.prev} 
                  next={ingresos.next} 
                  total_pages={ingresos.total_pages} 
                  changePage={changePage} 
                  page={page} />
    </div>
  ):<div className='siningreso'><h5>No hay ingresos disponibles</h5></div>
  }
</div>
)

}