import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Modales } from '../Modales';
import Form_2EqPatron from './Form_2EqPatron';

export const ViewActividadDetalle=({actividades, tipo, setModal, setRefresh, refresh})=> {
    const [modalEditar, setModalEditar] = useState(false);
    const [dataActividad, setDataActividad]=useState()
    const [accion, setAccion]=useState(0)
    const {equipo}=actividades[0]

    console.log(actividades)

 
    const handleEditar=(e)=>{
        setDataActividad(e)
        setAccion(2)
        setModalEditar(true)
    }
    const handleCargar=(e)=>{
        console.log(e)
        let newActiv={
            idAnterior:e._id,
            tipoActividad:e.tipoActividad,
            frecuencia:e.frecuencia,
            responsable:e.responsable,
            equipo:e.equipo,
            accionVencer:e.accionVencer,
            estado:'Abierta',
            mediciones:e.mediciones
        }
        console.log(newActiv)
        setDataActividad(newActiv)
        setAccion(3)
        setModalEditar(true)
    }
  return (
    <div>
    <Modales
        estado={modalEditar}
        cambiarestado={setModalEditar}
        titulo={'Editar Actividad'}
      >
        <Form_2EqPatron dataActividad={dataActividad} setDataActividad={setDataActividad} setEstadoModal={setModalEditar} patronData={equipo} accion={accion} setRefresh={setRefresh} refresh={refresh} setdetalle={setModal}/>   
      </Modales>

        <p className="mb-0"><strong>{equipo?.item+' : '+equipo?.marca+' -> '+equipo?.modelo+' -> '+equipo?.serie}</strong></p>
        <hr className="mb-3" />
        {actividades?.map((item,index)=>(
            <div className="container-no-padding" key={index}>
                <div className="row">
                    {/* Columna Izquierda */}
                    <div className="col-md-8 border-end shadow p-3">
                        <div className="mb-0 d-flex align-items-center ">
                            <label htmlFor="clienteNombre" className="form-label fw-bold me-2 m-0">{tipo===1? 'Proveedor':'Proveedor Anterior'}:</label>
                            <p className="mb-0">{item?.responsable}</p>
                        </div>
                        {tipo===1?
                        <div className="mb-0 d-flex align-items-center ">
                            <label htmlFor="clienteNombre" className="form-label fw-bold me-2 m-0">Consecutivo:</label>
                            <p className="mb-0">{item?.consecutivo}</p>
                        </div>:null}
                        <div className="mb-0 d-flex align-items-center ">
                            <label htmlFor="clienteNombre" className="form-label fw-bold me-2 m-0">Fecha:</label>
                            <p className="mb-0">{tipo===1?moment(item?.FechaInicio).format('L'): moment(item?.FechaVencimiento).format('L')}</p>
                        </div>
                        <div className="mb-0 d-flex align-items-center ">
                            <label htmlFor="clienteNombre" className="form-label fw-bold me-2 m-0">Frecuencia:</label>
                            <p className="mb-0">{item?.frecuencia}</p>
                        </div>
                        <div className="mb-0 d-flex align-items-center ">
                            <label htmlFor="Evidencia" className="form-label fw-bold me-2 m-0">Evidencia:</label>
                            <a href={item?.evidencia} target="_blank" rel="noopener noreferrer">
                                Abrir PDF
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 border-end shadow p-3">
                        {tipo===1?<button className='btn-btn-primary w-100' onClick={()=>handleEditar(item)}>Editar</button>:null}
                        {tipo===2?<button className='btn-btn-secundary w-100' onClick={()=>handleCargar(item)}>Cargar</button>:null}
                    </div>
                </div>
            </div>))}
      
    </div>
  )
}


