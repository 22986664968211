

export const setToken = (token) =>{            //Esta funcion almacena el token en el almacenamiento local
    localStorage.setItem('token', token);      // Este comando permite almacenar el token en el almacenamiento local
}

export const getToken = () =>{                // Esta funcion devuelve el valor del token leido del almacenamiento local
    return localStorage.getItem('token');      // Extraemos el token del almacenamiento local
}

export const fromQueryParamToFilter = (searchParams) =>{
    let params = {}
    for(let key of searchParams.keys()){
        const value = searchParams.get(key)
        params = {               
            ...params,
            [key]:value
        }
    }

    return params;

}