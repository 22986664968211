import React, { useState } from 'react'
import { actualizarDatos } from '../../api/common'


export const  Otdatacliente=({infocliente, cambiarestado})=> {
    //const infocliente=infoclient.infoclient
    //console.log(infocliente)
    const [infoedit, setInfoedit]=useState({})

    const handleSubmit=async(e)=>{
        e.preventDefault();
        //console.log(infoedit)
        //console.log("editando equipo")  

        async function putItem(){
            const result = await actualizarDatos('equipos',infoedit)
            //console.log(result) 
            if(result.status===200){
                cambiarestado(false)
            }
        }
        putItem();
    }

   const handledata=(event)=>{
    //console.log(infoedit)
    setInfoedit({
        ...infoedit,
        [event.target.name]:event.target.value
    })
   }
  return (
    <div>
       <form className='col-3-grid' onSubmit={handleSubmit}>
            <div className='datauseri '>
                <label className='userselect' htmlFor='marca'>Cliente:</label>
                <input onChange={handledata} name='marca' className='titulo' type={'string'}  defaultValue={infocliente?.nombre} />
            </div>
            <div className='datauseri '>
                <label className='userselect' htmlFor='modelo'>Nit: </label>
                <input onChange={handledata} name='modelo' className='titulo' type={'string'}  defaultValue={infocliente?.identificacion} />
            </div>
            <div className='datauseri '>
                <label className='userselect' htmlFor='serie'>Ciudad:</label>
                <input onChange={handledata} name='serie' className='titulo' type={'string'}  defaultValue={infocliente?.ciudad} />
            </div>
            <div className='datauseri '>
                <label className='userselect' htmlFor='direccion'>Direccion:</label>
                <input onChange={handledata} name='direccion' className='titulo' type={'string'}  defaultValue={infocliente?.direccion} />
            </div>
            <div className='datauseri '>
                <label className='userselect' htmlFor='inventario'>Sede:</label>
                <input onChange={handledata} name='inventario' className='titulo' type={'string'}  defaultValue={infocliente?.sede} />
            </div>
            <br/>
            <hr className='three-col-grid' placeholder='datos'></hr>
            <div className='datauseri '>
                <label className='userselect' htmlFor='unicacion'>Contacto:</label>
                <input onChange={handledata} name='ubicacion' className='titulo' type={'string'}  defaultValue={infocliente?.apellido} />
            </div>
            <div className='datauseri_3'>
                <label className='userselect' htmlFor='resolucion'>Telefono contacto:</label>
                <input onChange={handledata} name='resolucion' className='titulo' type={'string'}  defaultValue={infocliente?.telefono} />
            </div>
            <div className='datauseri'>
                <label className='userselect' htmlFor='puntos'>Email:</label>
                <input  onChange={handledata} name='puntos' className='titulo' type={'string'}  defaultValue={infocliente?.correo} ></input>
            </div>
            <div className='three-col-grid'>
                <div className='datauseri '>
                    <label className='userselect' htmlFor='observacion'>Observacion</label>
                    <textarea  onChange={handledata} name='observacion' className='titulo' type={'string'}  required ></textarea>
                </div>
            </div>
            
            <button className='btn-btn-primary center-grid ' type='submit'>Guardar cambios</button> 
        </form>
    </div>
  )
}