import React from 'react'
import Swal from 'sweetalert2'

export const Alertassw = (type='', title='', text='',icon='',timer=2000,action,params) => {

    const  Toast=Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: timer,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

    switch(type){
        case 'yes/no':
          Swal.fire({
            title:text,
            showDenyButton:true,
            showCancelButton:true,
            confirmButtonText:"Si",
            denyButtonText:"No",
            cancelButtonText:"Cancelar"
          }).then(data => action(params))
          break;
        case 'charge':
            Toast.fire({
                icon: 'success',
                title: title
              })
            break;
        case 'error':
            Swal.fire({
                title:title,
                text: text,
                icon:"error"
            })
            break;
        case 'success':
            Swal.fire({
                title:title,
                text: text ,
                icon:"success",
              })
            break;
        case 'toast':
            Toast.fire({
              title:title,
              text: text ,
              icon:icon,
            })
            break;
        case 'ok':
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: title,
            showConfirmButton: false,
            timer: 2000
          })
          break;
        case 'info_input':
          Swal.fire({
            title,
            html:`
          <input style="width:80%; height: 6vh; outline: none;  background-color:#f3f0f0; " value=${text} />
            `,
            confirmButtonText:'Copiar',
            cancelButtonText:'Generar',
            showCancelButton:true
          }).then((result)=>{
            if(result.isConfirmed){
              navigator.clipboard.writeText(text)
              Swal.fire("Copiado", "", "success");
            }
            else if(result.isDenied){
              Swal.fire("Error", "", "info");
            }
            else if(result.dismiss == 'cancel'){
              console.log(result.dismiss)
            }
          })
          break;
        default:
          Swal.fire({
            title:'Type de alerta no registrada',
            text: 'Revisa la funcion Alertassw, busca el type de alerta o crea un nuevo case',
            icon:"error",
            heightAuto:false,
            imageHeight:  200
        })
          
    }
}
