import React, { useState } from 'react'

export const Mediciones = ({inputs, setInputs}) => {
    const [inputErrors, setInputErrors] = useState([]);
    
    console.log(inputs)
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        // Solo permitir números y un punto decimal
        let newValue = value.replace(/[^0-9.-]/g, '');
            // Verificar si el último carácter es un punto y si lo es, simplemente dejar el valor como está
            /*if (value.length>0) {
            if (value.charAt(value.length - 1) === '.') {
                newValue = value.replace(/[^0-9.-]/g, '');
            } else {
                if(value.charAt(0) === '-' && value.length===1 ){
                    newValue = value.replace(/[^0-9.-]/g, '');
                }else{
                    if(value.charAt(0) === '-' && parseFloat(value)!==0) newValue = parseFloat(value);
                    if(value.charAt(0) !== '-' )newValue = parseFloat(value);
                }
            }}*/
            

        const newInputs = [...inputs];
        if (name === 'error') {
            newInputs[index][name] = newValue;
            // Calcular y actualizar el valor del campo correccionIndicacion
            const correccionIndicacion = newValue ? (newValue * -1): '';
            newInputs[index]['correccionIndicacion'] = correccionIndicacion;
        } else {
            newInputs[index][name] = newValue;
        }

        // Calcular y actualizar el campo cumpleExactitud
        const cumpleExactitud = Math.abs((newInputs[index].error) + (newInputs[index].incertidumbreExpandida)) > (newInputs[index].exactitudTolerancia) ? 'No cumple' : 
        Math.abs((newInputs[index].error) - (newInputs[index].incertidumbreExpandida)) > (newInputs[index].exactitudTolerancia) ? 'No cumple' : 'Cumple';
        if(newInputs[index].error==='' || newInputs[index].exactitudTolerancia==='' || newInputs[index].incertidumbreExpandida===''){
            newInputs[index]['cumpleExactitud'] = '';
        }else{
            newInputs[index]['cumpleExactitud'] = cumpleExactitud;
        }

        setInputs(newInputs);
    };

    const handleAddInput = () => {
        const inputErrors = inputs.map((input) =>
            Object.values(input).some((value) =>value === '')
        );
        setInputErrors(inputErrors);
        if (!inputErrors.includes(true)) {
            setInputs([
                ...inputs,
                { valorNominal: '', valorMedido: '', error: '', exactitudTolerancia: '', incertidumbreExpandida: '' }
            ]);
        } else {
            alert('Completa todos los campos antes de agregar una nueva fila.');
        }
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Aquí puedes manejar la lógica para enviar los datos al backend o hacer lo que necesites con el array de inputs
        console.log(inputs);
    };


    return (
        <div>
            <form className='form_container' onSubmit={handleSubmit}>
                <table className='Tabmediciones'>
                    <thead style={{ background: '#f2f2f2', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                        <tr>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><div>Valor Nominal</div></th>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><div>Valor Medido</div></th>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><div>Error</div></th>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><div>Exactitud Tolerancia</div></th>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><div>Incertidumbre Expandida</div></th>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><div>Correccion a la indicación</div></th>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><div>Confirmación Metrológica</div></th>
                            <th style={{ fontSize: '0.7rem', maxWidth: '100px', fontWeight: 'normal', border: '2px solid #ddd' }}><i className="bi bi-pencil-square"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {inputs.map((input, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="text"
                                        name="valorNominal"
                                        value={input.valorNominal}
                                        onChange={(event) => handleInputChange(index, event)}
                                        style={{
                                            width: '100px',
                                            borderRadius: '0',
                                            border: '1px solid #ddd', // Agrega el borde liso
                                            backgroundColor: 'white', // Agrega el fondo blanco
                                            borderColor: inputErrors[index] ? 'red' : '#ddd' // Cambia el color del borde si hay un error
                                        }}
                                        required
                                    />
                                    {inputErrors[index] && !input.valorNominal && <div style={{ color: 'red', fontSize: '0.8rem' }}>Requerido</div>}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="valorMedido"
                                        value={input.valorMedido}
                                        onChange={(event) => handleInputChange(index, event)}
                                        style={{
                                            width: '100px',
                                            borderRadius: '0',
                                            border: '1px solid #ddd',
                                            backgroundColor: 'white',
                                            borderColor: inputErrors[index] ? 'red' : '#ddd'
                                        }}
                                        required
                                    />
                                    {inputErrors[index]  && <div style={{ color: 'red', fontSize: '0.8rem' }}>Requerido</div>}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="error"
                                        value={input.error}
                                        onChange={(event) => handleInputChange(index, event)}
                                        style={{
                                            minWidth: '60px',
                                            borderRadius: '0',
                                            border: '1px solid #ddd',
                                            backgroundColor: 'white',
                                            borderColor: inputErrors[index] ? 'red' : '#ddd'
                                        }}
                                        required
                                    />
                                    {inputErrors[index] && !input.error && <div style={{ color: 'red', fontSize: '0.8rem' }}>Requerido</div>}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        name="exactitudTolerancia"
                                        value={input.exactitudTolerancia}
                                        onChange={(event) => handleInputChange(index, event)}
                                        style={{
                                            width: '100px',
                                            borderRadius: '0',
                                            border: '1px solid #ddd',
                                            backgroundColor: 'white',
                                            borderColor: inputErrors[index] ? 'red' : '#ddd'
                                        }}
                                        required
                                    />
                                    {inputErrors[index] && !input.exactitudTolerancia && <div style={{ color: 'red', fontSize: '0.8rem' }}>Requerido</div>}
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        step="any" // Permitir solo números flotantes
                                        name="incertidumbreExpandida"
                                        value={input.incertidumbreExpandida}
                                        onChange={(event) => handleInputChange(index, event)}
                                        style={{
                                            width: '100px',
                                            borderRadius: '0',
                                            border: '1px solid #ddd',
                                            backgroundColor: 'white',
                                            borderColor: inputErrors[index] ? 'red' : '#ddd'
                                        }}
                                        required
                                    />
                                    {inputErrors[index] && !input.incertidumbreExpandida && <div style={{ color: 'red', fontSize: '0.8rem' }}>Requerido</div>}
                                </td>
                                <td style={{
                                    width: '100px',
                                    borderRadius: '0',
                                    border: '1px solid #ddd',
                                    backgroundColor: 'white',
                                    borderColor: inputErrors[index] ? 'red' : '#ddd'
                                }}>
                                    {input.error ? ((input.error) * -1) : ''}
                                </td>
                                <td style={{
                                    width: '100px',
                                    borderRadius: '0',
                                    border: '1px solid #ddd',
                                    backgroundColor: 'white',
                                    borderColor: inputErrors[index] ? 'red' : '#ddd',
                                    fontWeight: input.cumpleExactitud === 'No cumple' ? 'bold' : 'normal',
                                    color: input.cumpleExactitud === 'No cumple' ? 'red' : 'inherit'
                                }}>
                                    {input.cumpleExactitud ? input.cumpleExactitud : ''}
                                </td>
                                <td style={{
                                    width: '30px',
                                    borderRadius: '0',
                                    border: '1px solid #ddd',
                                    backgroundColor: 'white',
                                    borderColor: inputErrors[index] ? 'red' : '#ddd',
                                }}>
                                    {index === inputs.length - 1 ? (
                                        <button type="button" onClick={handleAddInput} style={{ border: 'none', background: 'none', padding: '0' }}>
                                            <i className="bi bi-file-plus" style={{ color: 'blue', fontSize: '1.2rem' }}></i>
                                        </button>
                                    ) : (
                                        <button type="button" onClick={() => handleRemoveInput(index)} style={{ border: 'none', background: 'none', padding: '0' }}>
                                            <i className="bi bi-trash3-fill" style={{ color: 'blue', fontSize: '1.2rem' }}></i>
                                            {inputErrors[index] && !Object.values(input).some((value) => value === '') && <div style={{ color: 'red', fontSize: '0.8rem' }}>Requerido</div>}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </form>
        </div>
    );
}


