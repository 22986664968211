import { useEffect, useState } from "react";
import { getStates, getCities } from "../../api/states";

const Tab_1 = ({ userSelected, handleInputChange }) => {

  const [states, setStates] = useState();
  const [cities, setCities] = useState();

  useEffect(() => {
    async function getStatesDB() {
      const stateDB = await getStates();
      const st = await stateDB.json();
      setStates(st);
      console.log(st[0])
      handleInputChange({
        target: {
          name: "departamento",
          value: st[0].departamento,
        },
      })
    }

    getStatesDB();
  }, []);


  useEffect(() => {
    async function getCitiesDB() {
      console.log(userSelected.departamento)
      const cityDb = await getCities(userSelected.departamento);
      const ct = await cityDb.json();
      
      setCities(ct);
      console.log(ct[0])
      handleInputChange({
        target: {
          name: "ciudad",
          value: ct[0].municipio,
        },
      });
    }

    getCitiesDB();
  }, [userSelected?.departamento]);

  return (
    <>
      <div className="form_group">
        <div className="form_group_label">Razón Social</div>
        <input
          type="text"
          name="nombre"
          defaultValue={userSelected?.nombre}
          onChange={handleInputChange}
        />
      </div>
      <div className="form_group">
        <div className="form_group_label">NIT</div>
        <input
          type="text"
          name="identificacion"
          defaultValue={userSelected?.identificacion}
          onChange={handleInputChange}
        />
      </div>
      <div className="form_group">
        <div className="form_group_label">Direccion</div>
        <input
          type="text"
          name="direccion"
          defaultValue={userSelected?.direccion}
          onChange={handleInputChange}
        />
      </div>
      <div className="form_group">
        <div className="form_group_label">Departamento</div>
        <select
          defaultValue={userSelected?.departamento}
          onChange={handleInputChange}
          name="departamento"
        >
          {states &&
            states.map((state,idx) => (
              <option key={idx} value={state.departamento}>{state.departamento}</option>
            ))}
        </select>
      </div>
      <div className="form_group">
        <div className="form_group_label">Ciudad</div>
        <select
          defaultValue={userSelected?.ciudad}
          onChange={handleInputChange}
          name="ciudad"
        >
          {cities &&
            cities.map((city) => (
              <option value={city.municipio}>{city.municipio}</option>
            ))}
        </select>
      </div>

      <div className="form_group">
        <div className="form_group_label">Telefono</div>
        <input
          type="text"
          name="telefono"
          defaultValue={userSelected?.telefono}
          onChange={handleInputChange}
        />
      </div>
    </>
  );
};

export default Tab_1;
