import React from 'react'
import { getDataList, getObtenerPame } from '../../api/common';
import { Alertassw } from '../Alertas/Alertassw';

export const PeriodoPame=(patronData, newData, setPame)=> {
  let Pame=[];
    async function consultar(){
        const Pameconsulta=  await getObtenerPame(newData,patronData._id)
        console.log(Pameconsulta.data.data)
        Pame=Pameconsulta.data.data;
        console.log(Pame.length)
        if(Pame.length===0){
          Alertassw('error','No hay suficiente data para generar Pame','Crea calibraciones anteriores e intenta nuevamente')
        }else{
          newData.frecuencia=Pameconsulta.data.data[0].nextPeriodo
          setPame(Pameconsulta.data.data)
        }
    }
consultar();
  return (Pame)
}
