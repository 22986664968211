// src/components/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <h1>Términos y Condiciones</h1>
            <p>Última actualización: [Fecha]</p>
            <p>
                Bienvenido a tinvaz. Al utilizar nuestro sitio web, aceptas cumplir y estar sujeto a los
                siguientes términos y condiciones. Por favor, léelos detenidamente.
            </p>
            <h2>1. Introducción</h2>
            <p>
                Estos Términos y Condiciones ("Términos") rigen tu acceso y uso de nuestro sitio web. 
                Al acceder o utilizar el sitio web, aceptas estos Términos en su totalidad.
            </p>
            <h2>2. Cambios en los Términos</h2>
            <p>
                Nos reservamos el derecho de modificar estos Términos en cualquier momento. Las 
                modificaciones serán efectivas inmediatamente después de su publicación en el sitio web.
            </p>
            <h2>3. Uso del Sitio Web</h2>
            <p>
                No debes usar el sitio web de ninguna manera que cause o pueda causar daño al sitio web 
                o deterioro de la disponibilidad o accesibilidad del mismo.
            </p>
            {/* Agrega más secciones según sea necesario */}
        </div>
    );
};

export default TermsAndConditions;
