import React, { useEffect, useState } from 'react'

export const EqFinalizadosTotal = ({ot}) => {
    const [query, setQuery]=useState("")
    console.log(ot)
    //const filter=ot?.equipos.filter(item=> item.estado=='Finalizado' ||  item.estado=='Cancelado' ||  item.estado=='Aprobad').
    //console.log(filter)
    const [equiposOtIni, setEquiposOtIni]=useState(ot.equipos)

    useEffect( ()=>{
        const filter=ot.equipos.filter(item=> item.estado=='Finalizado' ||  item.estado=='Cancelado' ||  item.estado=='Aprobado' ||  item.estado=='Entregado')
        setEquiposOtIni(filter)
    },[])
    const keys=["consecutivo", "marca","modelo","serie","sede","resolucion","puntos","inventario","ubicacion","nombre"]
    
        //Filtro de busqueda
        const equiposOt = !query? equiposOtIni : 
        equiposOtIni.filter(({equipo})=>
        keys.some((key)=> 
          equipo[key]? equipo[key].toLowerCase().includes(query.toLowerCase()):
          equipo.item[key]? equipo.item[key].toLowerCase().includes(query.toLowerCase()): false
          )
      );

  return (
    <div>
    {equiposOtIni.length>0?
    <>
        <input
          type="text"
          placeholder="Ingresa un dato del item a buscar"
          className="form-control"
          onChange={(e)=> setQuery(e.target.value)}
        />
        <hr/>
      <table className='table w-100 table-bordered '>
        <thead className="cabeceraitem table-info">
            <tr>
                <th>Item</th>
                <th >Marca</th>
                <th >Modelo</th>
                <th >Serie</th>
                <th >Inventario</th>
                <th >Ubicación</th>
                <th >Puntos</th>
                <th >Res</th>
                <th >Sede</th>
                <th >Consecutivo</th>
                <th >Estado</th>
            </tr>
        </thead>
        <tbody>
            {equiposOt && equiposOt?.map(eq=>(
            <tr key={eq.id}>
                    <td >{eq?.equipo?.item?.nombre}</td>
                    <td >{eq?.equipo?.marca}</td>
                    <td >{eq?.equipo?.modelo}</td>
                    <td >{eq?.equipo?.serie}</td>
                    <td >{eq?.equipo?.inventario}</td>
                    <td >{eq?.equipo?.ubicacion}</td>
                    <td >{eq?.equipo?.puntos}</td>
                    <td >{eq?.responsable?.nombre}</td>
                    <td >{eq?.equipo?.sedeClient?.nameSede}</td>
                    <td >{eq?.consecutivo}</td>
                    <td >{eq?.estado}</td>
            </tr>
                ))}
        </tbody>
      </table>
      </>:<h2>No hay equipos Finalizados</h2>}
    </div>
  )
}


