import React, { useEffect, useState } from 'react'
import { getDataList } from '../../api/common'
import { ActividadesTotal } from './ActividadesTotal'
import moment from 'moment';
import { NextFechas } from './NextFechas';

export const ActividadesPatron = ({patronData, setPageForm}) => {
    const  [actividades, setActividades] = useState()
    const  [refresh,setRefresh]=useState(false)

    useEffect(()=>{
        async function onsultarActividad(){
            let respuesta = await getDataList('Actividad',{ equipo: patronData._id });
            setActividades(respuesta.data.data)
            if(respuesta.data.data.length==0){
                setActividades('NA')
            }else{
                //const newData = await NextFechas(respuesta.data.data)
                //setActividades(newData)
            }
        }
        onsultarActividad()
    },[refresh])
  return (
    <div>
        <button className='btn-btn-primary' onClick={()=>setPageForm(2)}>Agregar Actividad</button>
        { actividades!='NA' ?
            actividades?
                <ActividadesTotal  refresh={refresh} setRefresh={setRefresh} actividades={actividades}/>
              :
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
             </div> : <h4>No hay Actividades</h4>
        }
    </div>
  )
}

