import React, { useEffect, useState } from 'react'
import {useParams, useSearchParams } from 'react-router-dom'
import { getDataList, getDataobtener, reactivarOt } from '../api/common'
import { Alertassw } from '../components/Alertas/Alertassw'
import Spinner from '../components/commons/Spinner'
import { ActivarEq, FinalizarEq, NoFinalizarEq } from '../components/EqInOt/FinalizarEq'
import { Canceleq } from '../components/Equipo/Canceleq'
import { Editareq } from '../components/Equipo/Editareq'
import { Observacioneq } from '../components/Equipo/Observacioneq'
import { Modales } from '../components/Modales'
import { Modalcarga } from '../components/Modales/Modalcarga'
import AddEquipments from '../components/Ot/AddEquipments'
import { Otdatacliente } from '../components/Ot/Otdatacliente'
import { Otsonpdf } from '../components/Ot/Otsonpdf'
import { CHILD, PARENT } from '../constants'
import { useAuth } from "../hooks/useAuth"; 
import HojasTrabajo from '../components/Aprobar/HojasTrabajo'
import {AprobarAll, AprobarSeleccion } from '../components/EqInOt/AprobarSelect'
import DateProgram from '../components/Ot/DateProgram'
import { HojasDeTrabajo } from '../components/Seguimiento/HojasTrabajo'
import { EqFinalizadosTotal } from '../components/Ot/EqFinalizados'
import { ContadoresOt } from '../components/Ot/IndicadoresOt'
import Cabecera from '../components/Ot/Cabecera'
import { ActualizarEqOtIni } from '../components/Ot/actualizarEqOtIni'
import { AddItems } from '../components/Ot/AddItems'
let equiSelect=[]
let EqSedeFilter=[]

export const Otselect = ()=> {
    const [variar, sevariar]=useState(false)
    const[modalcharge, SetModalcharge]=useState(false)
    const[hojatrabajo, setHojatrabajo]=useState({})
    const[estadomodal, setEstadomodal] = useState(false);
    const[refresh, setRefresh]=useState(false)
    const[equiposOtIni, setEquiposOtIni]=useState([])
    const[equipoinfo, setEquipoinfo] = useState({});
    const[sedeSeleted, setSedeSeleted]=useState()
    const[sedes, setSedes]=useState()
    const[historialeq, setHistorialEq]=useState()
    const[sitioSeleted, setSitioSeleted]=useState()
    const[cantApro, setCantApro]=useState()
    const[optionAproved, setOptionAproved]=useState()
    const[otFath,setOtFath]=useState()
    const[selectAll, setSelectAll]=useState(false)
    const[obsGen, setObsGen]=useState();
    const[accion, setAccion]=useState({
        tarea:'',
        titulo:''
    })
    const [query, setQuery]=useState("")
    const [searchParams]=useSearchParams()
    const {id}=useParams()
    const task=searchParams.get('task')
    //Creamos el body para guardar la hoja de trabajo
    const [Eqfinalizados, setEqfinalizados]=useState({
      _id:'',
      idFath:'',
      numeroOrden : '',
      equipos:[],
      visitas:'',
  })
  console.log("Equipos Finalizados : ", Eqfinalizados)
  //estado para guardar la informacion de la Ot 
  const [ots, setOt]=useState({})
  let userData  = useAuth();
  let result = userData.userData;

  console.log('sedes: ',sedes)
 
  //Evento finalizar Eq
  const Finalizar=async(eq)=>{
    const equipoActualizado= await FinalizarEq(eq._id,ots.numeroOrden,ots.visitas,SetModalcharge,result )
    if(equipoActualizado) ActualizarEqOtIni(setEquiposOtIni,equipoActualizado)
  }
//Evento para activar los equipos pendientes
  const Activar=async(eq)=>{
    SetModalcharge(true)
   const equipoActualizado = await ActivarEq(eq._id,ots.numeroOrden,ots.visitas,SetModalcharge,ots.equipos,result,setRefresh,refresh,task,cantApro,ots,eq,queryson_aprobar)
   if(equipoActualizado) ActualizarEqOtIni(setEquiposOtIni,equipoActualizado)
  }
  //Evento para cambiar a no finalizado
  const NoFinalizar=async(eq)=>{
    const equipoActualizado= await NoFinalizarEq(eq._id,SetModalcharge,task)
    if(equipoActualizado) ActualizarEqOtIni(setEquiposOtIni,equipoActualizado)
  }

    const handleAddEquipment = () =>{
      setEstadomodal(true)
      setAccion({
        tarea:'Adicionar Ítems',
        titulo:''
      })
    } 

    const handleHT = (e) =>{
      console.log(e)
      setEstadomodal(true)
      setAccion({
        tarea:e,
        titulo:e
      })
    } 
    const creteOt=()=>{
      if(filtradoFinalizar.length>0){
          unirobs()
          Eqfinalizados.sitioCal=sitioSeleted
          Eqfinalizados.equipos=filtradoFinalizar
          console.log("filtrados finalizados: ",Eqfinalizados)
        if(Eqfinalizados._id && Eqfinalizados.numeroOrden){
            async function putOt(){
           //const ota= await actualizarOt("Ot", Eqfinalizados, Eqfinalizados._id
            setRefresh(!refresh)
            setHojatrabajo(Eqfinalizados)
            setEstadomodal(true)
            setAccion({
              tarea:'Hoja de Trabajo',
              titulo:Eqfinalizados.numeroOrden +'-'+Eqfinalizados.visitas
            })
            //Alertassw('charge','Ha realizado'+' '+`${Eqfinalizados.equipos.length}`+' '+'Calibraciones')
         }
         putOt();
        }else{ Alertassw('error','Error de cierre','Por favor intenta nuevamente, si el error persiste comunicate con soporte técnico')}
    }else{ 
      setRefresh(!refresh)
      Alertassw('error','Error','Por favor seleccionar los equipos') }
  }
    const queryson = (orden) => ({
        conditions:{
            numeroOrden:orden,
            otType: CHILD,
            estado:'Abierta'
        },
        fields:null,
        options:{
            limit:1,
            sort:{
                visitas:-1
            }
        }
    })

    const queryson_aprobar = (orden) => ({
      conditions:{
          numeroOrden:orden,
          otType: PARENT,
      },
      fields:null,
      options:{
          limit:1,
          sort:{
              visitas:-1
          }
      }
  })

    const Editequipo=(eq)=>{
      setEquipoinfo(eq)
      setEstadomodal(true)
      setAccion({
        tarea:'Editar',
        titulo:eq.equipo?.item?.nombre
      })
    }
    
    const observacion=(eq)=>{
      setEquipoinfo(eq)
      setEstadomodal(true)
      setAccion({
          tarea:'Observacion',
          titulo:eq.equipo?.item?.nombre
        })
    }
    const Cancelareq =(eq)=>{
        setEquipoinfo(eq)
        setEstadomodal(true)
        setAccion({
            tarea:'Cancelar',
            titulo:eq.equipo?.item?.nombre
          })
    }

    const Editcliente =(ots)=>{
        setEstadomodal(true)
        setAccion({
            tarea:'Editar Cliente',
            titulo:ots?.nombre
          })
    }
  function unirobs(){
      var observaciones=''
      var j=1;
      if(obsGen){
        var observaciones =j+") "+obsGen+".\n"
        j++
      }
      for(var i=0; i<filtradoFinalizar.length; i++){
        if(filtradoFinalizar[i]?.observacion){
          var observaciones = observaciones +""+j +") " + filtradoFinalizar[i]?.equipo.item.nombre +" - "+
          filtradoFinalizar[i]?.consecutivo+" "+ filtradoFinalizar[i]?.observacion+ ".\n"
          j++;
        }
      }
      Eqfinalizados.observacion=observaciones
    }

 useEffect(() => {
        async function getOt(){
          equiSelect=[]
          setSelectAll(false)
          const otDB = await getDataobtener(id);
          setOtFath(otDB.data.data);
          if(searchParams.get('orden')){
              const otson= task==='revisar'? await getDataList('Ot',queryson_aprobar(searchParams.get('orden'))) : await getDataList('Ot',queryson(searchParams.get('orden')))
              if(otson.data.data[0]){
                setEquiposOtIni(otson.data.data[0].equipos)
                setEqfinalizados({
                  ...Eqfinalizados,
                  _id:otson.data.data[0]._id,
                  numeroOrden :otson.data.data[0].numeroOrden,
                  sede:otson.data.data[0].sede,
                  visitas:otson.data.data[0].visitas,
                  observacion:''
                })
                
                if(task==='revisar'){
                  setCantApro(otson.data.data[0].totalAprobado)
                }

              setOt(otson.data.data[0])
              /*const listeq=otson?.data.data[0]?.equipos?.map((listaeq)=>({
                '_id':listaeq._id,
                'estado':listaeq.estado
              })) */    
              }else{
                const OtFather=otDB.data.data
                const Equifilt=otDB.data.data.equipos.filter((eqInot)=>(eqInot.estado==='Finalizado'))
                if(Equifilt){
                  try {
                     OtFather.equipos=Equifilt
                     OtFather.otType='Child'
                     OtFather.estado='Abierta'
                     OtFather.visitas=OtFather.visitas+1

                     Alertassw('success','Orden creada correctamente')
                  } catch (error) {
                    //Alertassw('error','Error en la creación de la Ot')
                  }
                }
                setOt({})
                setEquiposOtIni([])
              }
            } else {
          }
        }
        getOt()
        SetModalcharge(false)
    },[refresh, id])

    const keys=["consecutivo", "marca","modelo","serie","sede","resolucion","puntos","inventario","ubicacion","nombre"]
     
    //Filtros de busqueda
    const EqSitioFilter=sitioSeleted? sitioSeleted=="Sitio"? equiposOtIni.filter(equipo=> !equipo.fechaIngreso):equiposOtIni.filter(equipo=> equipo.fechaIngreso):equiposOtIni
    EqSedeFilter=sedeSeleted? EqSitioFilter.filter(item=> item?.equipo?.sedeClient?._id==sedeSeleted):EqSitioFilter
    const filtradoFinalizar=EqSedeFilter.filter((eqEnd)=>(eqEnd.estado==='Procesado' || eqEnd.estado==='Cancelado' || eqEnd.estado==='Pendiente') && eqEnd?.responsable?._id===result._id)
    const equiposOt = !query? EqSedeFilter : 
    EqSedeFilter.filter(({equipo})=>
        keys.some((key)=> 
          equipo[key]? equipo[key].toLowerCase().includes(query.toLowerCase()):
          equipo.item[key]? equipo?.item[key].toLowerCase().includes(query.toLowerCase()): false
          )
      );

  const AprobarAllCheck=(e)=>{
    console.log(e.target.checked)
    if(e.target.checked){
      setSelectAll(true)
      equiSelect = AprobarAll(equiposOt)
    }else{
      setSelectAll(false)
      equiSelect=[]
      //setEquiSelect()
    }
  }
  const AprobarOneCheck=(eq)=>{
    if(eq){
      if (equiSelect.some(objeto => objeto._id === eq._id)) {
      // Si está, eliminarlo
      equiSelect= equiSelect.filter((item) => item._id !== eq._id);
      } else {
      // Si no está, agregarlo
      equiSelect.push(eq);
    }
  }
   setSelectAll(false)
    sevariar(!variar)
  }
    //observacion de la Ot
  const obsOtson=(event)=>{
  setObsGen(event.target.value)
  console.log(equiposOt)
}
  return (
    <div>
      {ots?<Cabecera ots={otFath} selectSede={setSedeSeleted} sedeSeleted={sedeSeleted} setSitioSeleted={setSitioSeleted} refresh={refresh} setSedes={setSedes}/>:null}
     <ContadoresOt ots={otFath} />
      <Modalcarga
        estadoIn={modalcharge} 
        cambiarestadoIn={SetModalcharge}
        >
          <Spinner />
      </Modalcarga>
        <Modales
            estado={estadomodal} 
            cambiarestado={setEstadomodal}
            //validation={true}
            titulo={`${accion.tarea} : ${accion.titulo}`}
        >
           <div>
              { accion.tarea === 'Editar'? (<Editareq infoOt={ots} refrescar={setRefresh} refresh={refresh} infoequipo={equipoinfo} cambiarestado={setEstadomodal} setEquiposOtIni={setEquiposOtIni}/>):null}
              { accion.tarea === 'Cancelar'? (<Canceleq infoOt={ots} cambiarestado={setEstadomodal} iditem={equipoinfo._id} setEquiposOtIni={setEquiposOtIni} refresh={equiposOtIni} queryson_aprobar={queryson_aprobar}/>):null}
              { accion.tarea === 'Editar Cliente'? (<Otdatacliente infocliente={ots.cliente} cambiarestado={setEstadomodal} refrescar={setRefresh} refresh={refresh} />):null}
              { accion.tarea === 'Hoja de Trabajo'? (<Otsonpdf  cambiarestado={setEstadomodal} datason={hojatrabajo} setRefresh={setRefresh} refresh={refresh} otFath={otFath}  />):null}
              { accion.tarea === 'Observacion'? (<Observacioneq cambiarestado={setEstadomodal} infoobservacion={equipoinfo?.observacion} />):null}
             { accion.tarea === "Adicionar Ítems"?<AddItems setEstadoModal={setEstadomodal} idOt={id} ot={ots} setRefresh={setRefresh} refresh={refresh} cliente={otFath.cliente} 
             sedeSeleted={sedeSeleted} sedes={sedes} SetModalcharge={SetModalcharge} historialeq={historialeq} setHistorialEq={setHistorialEq}/>:null }
             { accion.tarea === "Hojas de Trabajo"?<HojasDeTrabajo setEstadoModal={setEstadomodal} orden={otFath.numeroOrden}   setRefresh={setRefresh} refresh={refresh} />:null }
             { accion.tarea === "Equipos Finalizados"?<EqFinalizadosTotal setEstadoModal={setEstadomodal} ot={otFath}   setRefresh={setRefresh} refresh={refresh} />:null }
           </div>
        </Modales>
         <div className='obsotselect'>
            {task==='revisar'?<></>:<textarea className='obsotselect' name='observacion' autoFocus='true' placeholder='Observacion' onChange={obsOtson}></textarea> } 
         </div>
        <br></br>
         {task==='finalizar'? 
            <div class="d-flex justify-content-between"> 
              <button className="btn btn-success" onClick={creteOt}>{`Crear Hoja de Trabajo`}</button>
              <div className="Menubotonesot container text-right">
                <button className='btn-btn-trans' onClick={handleAddEquipment}>Add Ítems</button>
                <button className='btn-btn-trans' onClick={()=>handleHT("Equipos Finalizados")}>Finalizados</button>
                <button className='btn-btn-trans' onClick={()=>handleHT("Hojas de Trabajo")}>H. Trabajo</button>
                <button className='btn-btn-trans' onClick={() => DateProgram(otFath)} >
                  Exportar
                  <i className="bi bi-file-earmark-spreadsheet"></i>
                  </button>
              </div>
            </div>
         :<></>} 
         {/*openable.indexOf(ots.estado)  > -1 &&<button className="btn-btn-quaternary" onClick={handleAddEquipment}>Agregar Equipo </button>*/}

         <br/>
         {/*Boton de filtrado*/}
         {task==='revisar'?
         <>
          <div className='button-container'>
            <button  onClick={()=>setOptionAproved()} className="btn-btn-trans mg-left-90">Individual</button>
            <button name='Htrabajo'   onClick={()=>setOptionAproved('Htrabajo')} className="btn-btn-trans mg-left-90">H. Trabajo</button>
            {!optionAproved && equiSelect.length>0? <button className='btn-btn-secundary' onClick={()=>AprobarSeleccion(equiSelect,result, ots.numeroOrden,ots.visitas,SetModalcharge,setRefresh,refresh)}>Aprobar</button>:<div className='btn-null'></div>}
          </div>
          <hr/>
         </>:null


         }
        {!optionAproved? 
        <>
            <input
              type="text"
              placeholder="Ingresa un dato del item a buscar"
              className="form-control"
              onChange={(e)=> setQuery(e.target.value)}
            />
            <hr/></>:null}
            <div className='detalle' >
          <div className="table-responsive-sm">
          {!optionAproved?
            <table  className='table w-100 table-bordered '>
              <thead className="cabeceraitem table-info">
                <tr>
                        <th>No.</th>
                        <th>Item</th>
                        <th >Marca</th>
                        <th >Modelo</th>
                        <th >Serie</th>
                        <th >Inventario</th>
                        <th >Ubicación</th>
                        <th >Consecutivo</th>
                        <th >Resolución</th>
                        <th >Puntos</th>
                        <th >Res</th>
                        <th >Sede</th>
                        <th ><i className="bi bi-eye-fill font_sz "></i></th>
                        {task!=='revisar'?<th>{`${filtradoFinalizar?.length}`}</th>:<th ></th>}
                        {task!=='revisar'?<th><i className="bi bi-pencil-square font_sz " ></i></th>:<th><input
                        onChange={AprobarAllCheck}
                        checked={selectAll}
                        className="checkboox cursor-p font_sz"
                        type={"checkbox"}
                      ></input></th>}
                        {task!=='revisar'?<th><i className="bi bi-x-circle font_sz "></i></th>:<th><i className="bi bi-file-earmark-arrow-up font_sz seach"></i> </th>} 
                </tr>
              </thead>
              <tbody>
              {task==='finalizar' && equiposOt && equiposOt?.map((eq,index)=>(
                <tr key={eq._id}>
                    <td>{index+1}</td>
                    <td className="under_line"
                      onClick={() => {
                        window.open(`/perfilEq?_id=${eq.equipo._id}`, '_blank');
                      }}>{eq?.equipo?.item?.nombre}
                    </td>
                    <td >{eq?.equipo?.marca}</td>
                    <td >{eq?.equipo?.modelo}</td>
                    <td >{eq?.equipo?.serie}</td>
                    <td >{eq?.equipo?.inventario}</td>
                    <td >{eq?.equipo?.ubicacion}</td>
                    <td >{eq?.consecutivo}</td>
                    <td >{eq?.equipo?.resolucion}</td>
                    <td >{eq?.equipo?.puntos}</td>
                    <td >{eq?.responsable?.nombre}</td>
                    <td >{eq?.equipo?.sedeClient?.nameSede}</td>
                    <td>
                    {eq.observacion ? ( <i className="bi bi-eye-fill cursor-p font_sz" onClick={()=>observacion(eq)}></i>):null}
                    </td>
                    {eq.estado==='Cancelado'?(<td><i className="bi bi-x-square-fill"></i></td>)
                    :eq.estado==='Procesado'?<td><i className="bi bi-check-square-fill cursor-p font_sz"  onClick={()=>NoFinalizar(eq)}></i></td>:
                    eq.estado==='Pendiente'||eq.estado==='Aplazado'?<td><i class="bi bi-pause-circle-fill cursor-p font_sz" onClick={()=>Activar(eq)}></i></td>:<td><i className="bi bi-check-square cursor-p font_sz" onClick={()=>Finalizar(eq)}></i></td>}
                    <td><i className= {eq.estado === 'Cancelado'?'disabled_row':"bi bi-pencil-square cursor-p font_sz"} onClick={()=>Editequipo(eq)}></i></td>
                    <td><i className={eq.estado === 'Cancelado'?'disabled_row':"bi bi-x-circle cursor-p font_sz"} onClick={()=>Cancelareq(eq)}></i></td>
                </tr>))}
                
              {task==='revisar'  && equiposOt && equiposOt?.map((eq,index)=>(
                <tr  key={eq.equipo._id}>
                  <td>{index+1}</td>
                    <td className="under_line"
                                  onClick={() => {
                                    window.open(`/perfilEq?_id=${eq.equipo._id}`, '_blank');
                                  }}>{eq?.equipo?.item?.nombre}
                    </td>
                    <td >{eq?.equipo?.marca}</td>
                    <td >{eq?.equipo?.modelo}</td>
                    <td >{eq?.equipo?.serie}</td>
                    <td >{eq?.equipo?.inventario}</td>
                    <td >{eq?.equipo?.ubicacion}</td>
                    <td >{eq?.consecutivo}</td>
                    <td >{eq?.equipo?.resolucion}</td>
                    <td >{eq?.equipo?.puntos}</td>
                    <td >{eq?.responsable?.nombre}</td>
                    <td >{eq?.equipo?.sedeClient?.nameSede}</td>
                    
                    <td>
                    {eq.observacion? ( <i className="bi bi-eye-fill cursor-p font_sz" onClick={()=>observacion(eq)}></i>):null}
                    </td>
                    {eq.estado==='No Finalizado'? <td></td>: eq.estado==='Cancelado'?<td><i className="bi bi-x-square-fill"></i></td>:<td><i class="bi bi-check2"></i></td>}
                    {eq.estado==='Finalizado'?( <td><input
                        onChange={() => AprobarOneCheck(eq)}
                        value="True"
                        checked={equiSelect?.some(objeto => objeto._id === eq._id)}
                        className="checkboox cursor-p font_sz"
                        name={`${eq.equipo?._id}`}
                        type={"checkbox"}
                      ></input></td> ):  //<td className="bi bi-check-square cursor-p font_sz" onClick={()=>Finalizar(eq)}></td>
                    eq.estado==='Aprobado'? <td class="bi bi-check-square-fill cursor-p font_sz" onClick={()=>NoFinalizar(eq)}></td>:
                    eq.estado==='Entregado'?<td><i className="bi bi-check2-all"></i></td>:<td>{""}</td>
                    }
                    {!(eq.estado==='Cancelado'|| eq.estado==='No Finalizado')?<td><i className="bi bi-file-earmark-arrow-up font_sz seach"></i></td>:<></>}
                </tr>))}

              </tbody>
            </table>:null}
          </div>
          {equiposOt.length> 0 ?<> 
            {equiposOt && equiposOt.map(eq=>(
              <>
            </>
            ))} 
             </> 
            :<div className='siningreso'><h4>No hay equipos para finalizar, verifica que todos los equipos estén finalizados/cancelados, si es así descarga la Hoja de trabajo en Seguimiento</h4></div>   }
          </div>
          {optionAproved==='Htrabajo'?<HojasTrabajo Orden={ots?.numeroOrden} SetModalcharge={SetModalcharge} setRefresh={setRefresh} refresh={refresh} />:null}
    </div>
  )
}







