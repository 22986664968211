import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
 import { activateAccount, changePassword } from "../api/user";
import Spinner from "../components/commons/Spinner";
import { useForm } from "../hooks/useForm";
import logo from "../img/logo.png";

  const ActivateAccount = () =>{
    const [ searchParams] = useSearchParams()
    const [status,setStatus] = useState({
            type:null,
            msg:null,
        status:'loading'
    })
    const navigate = useNavigate()

    const [ values, handleInputChange,handlePasswordChange, setValues,setValue, error] = useForm({});

    const timeOut = () =>{
        setTimeout(() => {
            setStatus({
                ...status,
                msg:null
            })
        },3000)
    }

    const handleChangePassword = async(e) =>{
        //console.log(e)
        e.preventDefault();
        console.log(values)
             try{
                    const  passwordUpdated = await changePassword(values,searchParams.get('token'))
                    //console.log(passwordUpdated)
                    navigate('/login')
            }catch(error){
                //console.log(error)
                setStatus({
                    ...status,
                    type:'error',
                    msg:'Error configurando tu contraseña'
                })
                timeOut()
            }
    }

    useEffect(()=>{
        console.log(error)
    },[error])

    useEffect(()=>{
        async function activate(){
            try{
            console.log(searchParams.get('token'));
            const data = await activateAccount(searchParams.get('token'))
            //console.log(data)
            setValue({ email: data.data.email, prevPassword:'1234' })
            setStatus({
                type:'success',
                msg:'La cuenta asociada al correo  ha sido registrada satisfactoriamente',
                status:'activated'
            })
            //timeOut()
            }catch(error){
                //console.log("error activing")
                //console.log(error)
                setStatus({
                    type:'error',
                    msg:error.response.data.detail
                })
               
            }
            
        }
        activate()

    },[])

    return (
  
            
        <div className="right ">

        
           <div className="loginbox">
                   <div > <img className="imagen" src={logo} alt="logo_biolab"></img></div>
                   
             
            {status.status === 'loading'?
            <Spinner />:
            status.status === 'activated'?
            <div>
            <h3 className="text-center"  >Activación de Cuenta</h3>
            <form className="form_container" onSubmit={handleChangePassword}>
        
            {status.msg && <div className={status.type}>{status.msg}</div>}
           {Object.values(error).filter(e => e!= null).length>0&&<div className="error mt-3"> { error && Object.values(error).map( (err,i) => (<div key={i}>{err}</div>)) }</div>}
                <div className="form_group">
                <div className="form_group_label">Nueva Contraseña</div>
                <input
                 type="password"
                     name="nextPassword"
                    onChange={handlePasswordChange}
                    />
                </div>
                <input type="submit" 
                value="Enviar"
                className={Object.values(error).filter(e => e!= null).length>0?"disabled_button":""}
                disabled={Object.values(error).filter(e => e!= null).length>0}
                ></input>
            </form>
            </div>:
            <div className="error text-center">
                Ha ocurrido un Error a la hora de activar tu cuenta
                {status.type = 'error' && <div>
                    {status.msg}
                </div>}
            </div>
            }
           </div>
        </div>

    );

 }

 export default ActivateAccount;