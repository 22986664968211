import { app } from "../firebase"
import moment from "moment"


export const 
saveFile = async (target, type = 'images') => {
    try {
        // Verificar conexión a Internet
        if (!navigator.onLine) {
            alert("No tienes conexión a Internet. Por favor, verifica tu conexión y vuelve a intentarlo.");
            return;
        }

        // Verificar que se haya seleccionado un archivo
        let file = target.files[0];
        if (!file) {
            throw new Error("No se ha seleccionado ningún archivo.");
        }
        console.log("Archivo seleccionado:", file);

        // Obtener la extensión del archivo de manera segura
        const ext = file.name.split('.').pop();
        if (!ext) {
            throw new Error("El archivo no tiene una extensión válida.");
        }
        console.log("Extensión del archivo:", ext);

        // Crear referencia al archivo en Firebase Storage
        const storageRef = app.storage().ref();
        const pathFile = storageRef.child(`${type}/${moment().unix()}.${ext}`);
        console.log("Ruta en Firebase Storage:", pathFile.fullPath);

        // Subir el archivo
        await pathFile.put(file);
        console.log("Archivo subido correctamente.");

        // Obtener la URL de descarga del archivo
        const downloadURL = await pathFile.getDownloadURL();
        console.log("URL de descarga:", downloadURL);

        return downloadURL;

    } catch (error) {
        console.error("Error durante la subida del archivo:", error);
        alert("Hubo un problema al subir el archivo. Por favor, intenta de nuevo.");
        // Puedes devolver un valor o manejar el error según tu caso de uso
        return null;
    }
}


const singleDelete = async(url) =>{
    let ref =  app.storage().refFromURL(url)
    await ref.delete()
}

export const deleteFile = async(files) =>{
    
try{
   if( files && Array.isArray(files)) await Promise.all(files.forEach(async(url)=>{
       await singleDelete(url)
    }))
    else if(files) await singleDelete(files)
}
catch(error){
    //console.log(error)
}
}