import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../img/Tinvaz_Logo.png";
import fondo from "../img/backg.jpg";
import { loginUser } from "../api/user";
import { setToken } from "../helpers/helpers";
import UserContext from "../context/User/UserContext";
import jwt_token from "jwt-decode";
import { useForm } from "../hooks/useForm";
import { Modalcarga } from "../components/Modales/Modalcarga";
import Spinner from "../components/commons/Spinner";
import { getLockedAccount } from "../constants/text";

export const Login = () => {
  const [modalcharge, SetModalcharge]=useState(false)
  const { setPreLogin,setMessage } = useContext(UserContext);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  const [values, handleInputChange] = useForm({
    email: "",
    password: "",
  });

  //función para enviar el formulario
  const sendLogin = async (e) => {
    e.preventDefault(); //evitar reinicia
    ////console.log(values);
    try {
      SetModalcharge(true)
      let {data}= await loginUser(values);
      let { enc_email, enc_phone, email} = data
      console.log(data);
      setPreLogin({enc_email, enc_phone, email});
      SetModalcharge(false)
      navigate("/loginValidation");
    } catch (error) {
      if(error.response?.data?.detail === "Cuenta Bloqueada"){
        setMessage(getLockedAccount)
        navigate("/generalInfo");
      }
      SetModalcharge(false)
      setErrorMessage(error.response?.data?.detail || "Error Iniciando Sesión");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <div className="my_container">
      <Modalcarga
        estadoIn={modalcharge} 
        cambiarestadoIn={SetModalcharge}
        >
          <Spinner />
      </Modalcarga>
      <div className="left">
        <img className="fondo" src={fondo} alt="fondo"></img>
      </div>

      <div className="right">
        <div className="loginbox">
          <div>
            {" "}
            <img className="imagen img-pos" src={logo} alt="logo_biolab"></img>
          </div>
          <h3 className="titulo"></h3>
          {errorMessage && <div className="error">{errorMessage}</div>}
          <form onSubmit={sendLogin}>
            <label htmlFor="usuario">Usuario</label>
            <input
              type="text"
              name="email"
              placeholder="Usuario"
              onChange={handleInputChange}
            ></input>
            <label htmlFor="usuario">Contraseña</label>
            <input
              type="password"
              name="password"
              placeholder="Contraseña"
              onChange={handleInputChange}
            ></input>
            <input type="submit" value="Iniciar sesión"></input>
            <Link to="/recoverPassword" className="registrate">
              {" "}
              Recuperar contraseña
            </Link>
            <br></br>
            {/*<div>¿No tienes cuenta? </div>
            <Link to="/register" className="registrate">
              {" "}
              Registrarse
            </Link>*/}

            <hr/>
            <p>
                <Link to="/terminos-y-condiciones">Términos y Condiciones</Link>
            </p>
          </form>
          <footer style={{ textAlign: 'center'}}>
            <p>&copy; 2022 Martin Duran. Todos los derechos reservados.</p>
        </footer>
        </div>
      </div>
    </div>
  );
};
