import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import { Modalcarga } from '../Modales/Modalcarga';
import Spinner from '../commons/Spinner';
import { EqHoja } from './EqHoja';
import Sign from './Sign';
import { Alertassw } from '../Alertas/Alertassw';
import { actualizarOt, getDataObtenerUno } from '../../api/common';
import { useAuth } from "../../hooks/useAuth";
import { PdfOt } from './PdfOt';


export const  Otsonpdf =({datason,cambiarestado,refresh,setRefresh, otFath})=> {

  const navigate = useNavigate();
  const [ estadomodalIn,setEstadomodalIn ] = useState(false)
  const [estadofirma, setEstadofirma]=useState(false)
  const [status, setStatus] = useState(null);
  const [statusVer, setStatusVer]=useState(false);
  const [otFisnished, setOtFinished]=useState();
  const [estadoSig, setEstadoSig]=useState(false)
  const { userData } = useAuth();
  const [firmaUser, setFirmaUser]=useState();
  const [dataCliente, setDataCliente]=useState();

  const finalizarOt=()=>{
    console.log('Finalizando ot')
      // cambio el estado a finalizados para enviar al servidor.
      for(let i=0; i<datason.equipos.length; i++){
        if(datason.equipos[i].estado==='Procesado'){
          datason.equipos[i].estado='Finalizado'
        }
      }
    async function putOt(){
      setEstadomodalIn(true)
      console.log(datason)
      const ota= await actualizarOt("Ot", datason, datason._id)
      console.log(ota)
      if(ota.status===200){
        console.log(ota)
        setStatus('entregado')
        //setEstadoSig(true)
        setEstadomodalIn(false)
        setRefresh(!refresh)
        //setOtFinished(ota.data.data)
        Alertassw('toast','Se ha finalizado la Orden trabajo correctamente')
        setEstadofirma(false);
        const dataot=ota.data.data;
        console.log(dataot)
        PdfOt(dataot, dataot.entregado.firma, dataot.recibido.firma,false)

        Alertassw("success", "OT Firmada Exitosamente");
        cambiarestado(false)
      }
    }
    putOt()
  }

  const Firmar=()=>{
    setOtFinished(datason)
    setEstadoSig(true)
    setEstadofirma(true);
  }


  useEffect(()=>{
async function consultarSing(){
    const signDb = await getDataObtenerUno("sign", {
      user: userData._id,
    });
    const Otfath = await getDataObtenerUno("Ot", {
      _id: datason._id,
    });
    if(Otfath.status==200){
      datason.cliente=Otfath.data.data.cliente
    }
    setFirmaUser(
      signDb.data.data
    )
    if(signDb.status==200){
      setStatusVer(true);
      datason.entregado={
        firma:signDb.data.data._id,
        persona:userData.nombre+" "+userData.apellido,
        cargo:userData.role.nombre,
        fecha:new(Date) 
      }
    }
  }
  consultarSing()
  },[])

  if(status=== "fin entrega"){
    cambiarestado(false)
    Alertassw('ok', 'Has creado un hoja de trabajo','Descarga en proceso' )
    navigate(`/Ot`);
  }

  return (
    <div >
      <Modalcarga
        estadoIn={estadomodalIn} 
        cambiarestadoIn={setEstadomodalIn}
        >
          <Spinner />
      </Modalcarga>
            {status === "entregado"? (null):(<button onClick={Firmar} className='btn-btn-primary'>Solicitar firma</button>)}
            {statusVer === false? (null):(<button onClick={()=>PdfOt(datason,firmaUser,'',true)} className='btn-btn-secundary'>Ver hoja</button>)}
        <EqHoja datason={datason}/> 

       {estadoSig?
        <Sign 
        singWindow={estadofirma}  
        setSignWindow={setEstadofirma} 
        type={"ambos"} 
        ot={otFisnished} 
        onStatus={(status)=>setStatus(status)} 
        setEstadomodalIn={cambiarestado} 
        refresh={refresh} 
        finalizarOt={finalizarOt}
        setRefresh={setRefresh}/>:
        <></>
       }
        </div>
  )
}
