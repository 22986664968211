import { useEffect, useState } from "react";
import { crearDatos } from "../../api/common";
import { useAuth } from '../../hooks/useAuth';
import Modal from "../commons/Modal";

const NewEventCalendar = ({setEvents, events, setIsOpen}) => {
    const [newEvent, setNewEvent] = useState({
        title: '',
        start: '',
        end: '',
        descripcion: '',
        createdBy: '',
    });
    const [responsables, setResponsables] = useState('TODOS')
    const optionsResponsables = ['TODOS', 'CALIDAD', 'MANTENIMIENTO'];

    const {userData:{nombre, apellido}} = useAuth();

    const createEvent = async() => {
        console.log(newEvent);
        let {data:{data}} = await crearDatos('calendario', newEvent);

        data = {...data, id: data._id};
        setEvents([...events, data]);
        setIsOpen(false);
    }

    useEffect(() => {
        setNewEvent({...newEvent, createdBy: `${nombre} ${apellido}`});
    }, [])

    return(
        <div className="new-event-calendar">
            
            <label>Nombre del evento:</label>
            <input 
                type='text' 
                placeholder="Evento" 
                value={newEvent.title}
                required 
                onChange={(e) =>{
                    setNewEvent({...newEvent, title: e.target.value})}
                }
            />
            
            <div>
                <div className="new-event-calendar-container-input">
                    <label>Fecha Inicio:</label>
                    <input 
                        type='datetime-local'
                        required 
                        onChange={(e) =>(setNewEvent({ ...newEvent,start: new Date(e.target.value)}))}
                    />
                </div>
                
                <div className="new-event-calendar-container-input">
                    <label>Fecha Final:</label>
                    <input 
                        type='datetime-local'
                        required 
                        onChange={(e) =>(setNewEvent({ ...newEvent, end: new Date(e.target.value)}))}
                    />
                </div>
            </div>

            <div className="new-event-calendar-container-descripcion">
                <label>Descripción:</label>
                <textarea onChange={(e) => setNewEvent({...newEvent, descripcion: e.target.value})}></textarea>
            </div>

            {/*<div className="new-event-calendar-container-responsables">
                <label>Responsables:</label>
                <select defaultValue={"TODOS"} onChange={(event) => setResponsables(event.target.value)}>
                    {
                        optionsResponsables.map( (value) => <option value={value}>{value}</option>)
                    }
                </select>
                {
                    responsables
                }
            </div>*/}

            
                        
            <button 
            className="button-primary"
            onClick={() => {
                createEvent();
            }} 
            disabled={(!newEvent.title || !newEvent.start || !newEvent.end)}
            >
                crear evento
            </button>
        </div>
    )
}

export function AddEventCalendar({setEvents, events, isOpen, setIsOpen}) {
    
    return(
        <>
            <span className="button-add" onClick={() => setIsOpen(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
            </svg>
            </span>

            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                <NewEventCalendar setEvents={setEvents} events={events} setIsOpen={setIsOpen} />
            </Modal>
        </>
    )
}