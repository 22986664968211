

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/User/UserContext";
import { useTheme } from "../hooks/useTheme";
import { Crear } from "../components/Botones/Crear";
import { useAuth } from "../hooks/useAuth";
import { getDataObtenerUno } from "../api/common";
import { useEffect } from "react";
import { Firma } from "../components/Ot/firma";
import { Alertassw } from "../components/Alertas/Alertassw";
import { Dropdown, DropdownToggle } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ConsultNot } from "../components/NavBar/ConsultNot";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import EngineSettings from "../components/EngineSettings/EngineSettings";
import SearchBar from "../components/NavBar/Buscador";







export const Navegacion = ( ) =>{

   
    const  navigate  = useNavigate()
    
    const [estadofirma, setEstadofirma] = useState(false);
    const [recibido, setRecibido] = useState({});
    const { isOpenSide, setIsOpenSide,size } = useTheme()
    
    const { setUserData,userData } = useContext(UserContext);
    const logOut = () =>{
        localStorage.clear()
        setUserData(null)
        navigate('/login')
    }
    
    useEffect(()=>{
        async function consultarSing(){
        const signDb = await getDataObtenerUno("sign",{
            user: userData._id,
          });
          if(!signDb.data.data){
            Alertassw('error','No tienes firma registrada, por favor dibuja tu firma para continuar')
            setEstadofirma(true)
          }
        }
        consultarSing()
    },[])

    return(
        <div>
        <header className={`header ${!userData?'header-no-logged':'header-logged'}  `}
        style={{paddingLeft:isOpenSide?'200px':'40px'}}
        >
            <div className={`nav `}>
               {userData && <div className="logo" onClick={()=>{setIsOpenSide(!isOpenSide)}}>
               <i class="bi bi-list" style={{fontSize:'30px'}}></i></div>}
               
               <button className="nav-toggle">
                    <i className="bi bi-list"></i>
               </button>
             
            {userData&& <ul className="nav-menu nav-menu_visible">
            
            <SearchBar/>
            {size.width > 950 &&<>
                    {userData?.role?.nombre!=='Cliente'?
                    <div className="userNavegacion">
                    <li className="userAuth">{userData?.nombre+' '+userData?.apellido}</li>
                    <li className="roleUser">{userData?.role.nombre}</li>
                    </div>:
                    <li className="userAuth">Nombre no definido</li>}
                    </>}
                    <li href="#" className="crear">
                        <Crear/>
                    </li>
                   {size.width > 950 &&<>
                    <div className=" iconos">
                    <ConsultNot/>
                    
                        
                    </div>
                    <div className=" iconos">
                        <EngineSettings />
                    </div>
                    <div className=" iconos" onClick={()=>{navigate(`/perfil?correo=${userData.correo}`)}} >
                        <i className="bi bi-person icono-link"></i>
                    </div>
                    <div className=" iconos"  onClick={logOut} >
                        <i className="bi bi-box-arrow-right icono-link"></i>
                    </div>
                    </> } 
                </ul>}
            </div>
        </header>
        
    {estadofirma===true?
      <Firma
        recibidoin={recibido}
        setRecibidoin={setRecibido}
        setEstadofirma={setEstadofirma}
        estadofirma={estadofirma}
        usersign={"entrega"}
      />:null}
        </div>
    )
}




