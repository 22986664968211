import React from 'react'

const EquiposRep = ({Equipos}) => {
    console.log(Equipos)
  return (
    <div>
        <p style={{color:'red', fontWeight:'bold', marginBottom:0}}>La base de datos regsitra equipos con el mismo serial, modifica el serial de el/los equipos y</p>
        <p style={{color:'red', fontWeight:'bold', textAlign:'center', margin:0}}>carga el listado nuevamente</p>
        <table className='table'>
            <thead class="cabeceraitem">
                <tr>
                    <th>Item</th>
                    <th>Marca</th>
                    <th>Modelo</th>
                    <th>Serie</th>
                    <th>Ver</th>
                </tr>
            </thead>
            <tbody>
                {Equipos?.map((equipo, index)=>(
                    <tr key={index}>
                    {equipo.split('*').map((item,index)=>(
                        <td>{index<=3? item:<a href={`/perfilEq?_id=${item}`} target='_blank' >Ver</a>}</td>
                    ))}
                </tr>
               ))  }

            </tbody>

        </table>
      
    </div>
  )
}

export default EquiposRep
