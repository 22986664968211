import React, { useEffect, useState } from 'react'
import { actualizarDatos, crearDatos, getDataList, getDataObtenerUno, postDataClientes } from '../../api/common';
import { PARENT } from '../../constants';
import { useAuth } from '../../hooks/useAuth';
import { Alertassw } from '../Alertas/Alertassw';
import Spinner from '../commons/Spinner';
import { Modalcarga } from '../Modales/Modalcarga';
import { PdfActa } from './PdfActa';
import { Selecteditems } from './Selecteditems';
import Select from 'react-dropdown-select';

export const Actaentrega=({origen, dataot, setModal, setRefresh, refresh})=>{
    const[infOt, setInfOt]=useState();
    const[contEq, setContEq]=useState(0)
    const[totaleqot, setTotaleqot]=useState()
    const[spinnerMod, setSpinnerMod]=useState(false)  
    const { userData } = useAuth();  
    const[tentregadoOt, settentregado]=useState()
    const[listSede, setListSede]=useState([ ])
    const[sedeSeleted, setSedeSeleted]=useState()
    const[obsGen, setObsGen]=useState();
    const[equiposDis, setEquiposDis]=useState([
      {
        equipo:'',
        tipoEntrega:'',
        fechaIngresado:new Date()
     }])
     console.log(equiposDis);
    const[infoacta, setInfoacta]=useState({
      Equipos:[],
      entrega:'Certificado',
      tipo:'total',
      observacion:'',
      numeroOrden:'',
      orden:'',
      autor:'',
      actas:'',
      sede:'Principal',
      consecutivo:'',
      version:2
    })

    console.log(infoacta)
    const obsActa=(e)=>{
      setObsGen(e.target.value)
    }
    const handleacta=(e)=>{
     setInfoacta({
        ...infoacta,
        [e.target.name]:e.target.value
      })
    }

const Validacion=()=>{
     infoacta.tipo='total'
    console.log(equiposDis)
    if(equiposDis.length>0){  
      //Funcion para filtrar y colocar el tipo de entrega de cada equipo de acuerdo a su estado y movimeinto
      if(infoacta.entrega==='Certificado y Equipo'){
        infoacta.Equipos=equiposDis.filter((eqing)=>eqing.equipo.estado==='Aprobado' || eqing.equipo.equipo.movimiento==='Ingresado' )
        console.log(infoacta)
      if(infoacta.Equipos.length>0){
        infoacta.Equipos.forEach(equipo => {
          console.log(equipo)
          if(equipo.equipo.equipo.movimiento==='Ingresado' && equipo.equipo.estado==='Aprobado' ){
            equipo.tipoEntrega='Certificado y Equipo'
          }else{
            if(equipo.equipo.equipo.movimiento==='Ingresado' && equipo.equipo.estado==='No Finalizado' ){
              equipo.tipoEntrega='Equipo'
            }else{
              equipo.tipoEntrega='Certificado'
            }
          }
        });
        console.log(infoacta.Equipos)
        crearActa()
      }else{
        setRefresh(!refresh)
        Alertassw('error', 'No hay equipos para entrega de certificado y equipos')
      }
    } 

    console.log(infoacta)
    /// cambio todos los tipos de entrega de cada equipo 
    if(infoacta.entrega==='Certificado'  || infoacta.entrega==='Equipo'){



      for(var i=0; i<infoacta.Equipos.length; i++){
        console.log('entrando a cambiar el tipo entrega de cada item',infoacta )
        infoacta.Equipos[i].tipoEntrega = infoacta.tipo
        console.log(infoacta)
      }
  }
    // Función para crear acta total de certificado 
    if(infoacta.entrega==='Certificado'){
      let filter=equiposDis.filter((eqing)=>eqing.equipo.estado ==='Aprobado'  &&  eqing.equipo.equipo.movimiento !=='Ingresado' )
      infoacta.Equipos=filter.map(function(item){
        item.tipoEntrega= infoacta.entrega
        return item;
      });
      if(infoacta.Equipos.length!=0){
      crearActa()
     }else{
      setRefresh(!refresh)
      Alertassw('error', 'No hay equipos para entrega de certificados')
     }
    }

    //Funcion para crear acta totalde equipos
    if(infoacta.entrega==='Equipo'){
      let filter=equiposDis.filter((eqing)=>eqing.equipo.equipo.movimiento ==='Ingresado' )
      infoacta.Equipos=filter.map(function(item){
        item.tipoEntrega= infoacta.entrega
        return item;
      });

        crearActa()
    }

   }else{
    Alertassw('error','No hay equipos disponibles para entrega')
    setRefresh(!refresh)
    setModal(false)
  }
}
const crearActa=async()=>{
  setSpinnerMod(true)
  unirobs()

  // si se crea el acta del total de los equipos
  const filCert = infoacta.Equipos.filter((eqcert)=>eqcert.tipoEntrega==='Certificado' || eqcert.tipoEntrega==='Certificado y Equipo' )
  let totalentregaOt= tentregadoOt + filCert.length
    
  console.log(infoacta.Equipos)

    const result= await crearDatos('Actas',infoacta)   // crea un acta de entrega
    if(result.status===200){
      const otmod=await actualizarDatos('Ot',totalentregaOt===totaleqot.Tequipos?{...infOt, 
        actas:infoacta.consecutivo,
        totalEntrega:totalentregaOt,
        estado:totaleqot.Facturado === true? 'Cerrada':'Facturar',
      }:
      {...infOt, 
        actas:infoacta.consecutivo,
        totalEntrega:totalentregaOt,
      }, infOt._id)
      if(otmod.status===200){
        PdfActa(result.data.data)
        //settentregado(otmod.data.dataReturn.totalEntrega)
        setRefresh(!refresh)
        setSpinnerMod(false)
        setModal(false)
      }
    }
  }

  function unirobs(){
    var observaciones=''
    var j=1;
    if(obsGen){
      var observaciones =j+") "+obsGen+".\n"
      j++
    }
    for(var i=0; i<infoacta.Equipos.length; i++){
      if(infoacta.Equipos[i]?.equipo.observacion){
        var observaciones = observaciones +""+j +") " + infoacta.Equipos[i]?.equipo.equipo.item.nombre +" - "+
        infoacta.Equipos[i]?.equipo.consecutivo+" "+infoacta.Equipos[i]?.equipo.observacion+ ".\n"
        j++;
      }
    }
    infoacta.observacion=observaciones
  }

const selectSede = (value)=>{
  setSedeSeleted(value[0].value)
  setInfoacta({
    ...infoacta,
    sede:value[0].value
  })
  if(value[0].value==="Todas"){

    setSedeSeleted()
    setInfoacta({
      ...infoacta,
      sede:'Principal'
    })
  }
  setRefresh(!refresh)
}

useEffect(() => {
  let equipos=[]
  try{
    async function getOtdata(){
      const DataOt = await getDataList('Ot',{conditions:{numeroOrden:dataot.numeroOrden, otType:PARENT}})
      let otserch=DataOt.data.data[0]
      if(DataOt.status===200){

        let clientSede = DataOt.data.data[0].cliente?.sedes?.map((sed)=>({
          value:sed?.nameSede,
          label:sed?.nameSede
        }))
        if(clientSede)
        {
          clientSede.push({value:"Principal", label:"Principal"},{value:"Todas", label:"Todas"})
        }else{clientSede=([{value:"Principal", label:"Principal"},{value:"Todas", label:"Todas"}])}

        setListSede(clientSede)
        settentregado(otserch.totalEntrega)
        setTotaleqot({
          Tequipos: otserch.totalEq-otserch.canceledEq,
          Facturado:otserch.totalFacturado>=otserch.valorOt && otserch.totalFacturado>0 ? true:false
        })
        setInfOt(DataOt.data.data[0])

        if(sedeSeleted){
          console.log("Sede selccionada", sedeSeleted)
          const eqfiltradoSede=dataot.equipos.filter(eqf=> eqf.equipo.sede===sedeSeleted)
          console.log("Equipos filtrados : ",eqfiltradoSede);
          let EquiposSede = eqfiltradoSede.map((eqFilt)=>({
            equipo:eqFilt,
            tipoEntrega:'',
            fechaIngresado:new Date()
          }))

          setEquiposDis(EquiposSede)
          setInfoacta({
            ...infoacta,
            orden:dataot._id,
            autor:userData._id,
            actas:dataot?.actas,
            sede:sedeSeleted,
            consecutivo:otserch?.actas + 1
          })
        }else{
          let EquiposSede = dataot.equipos.map((eqFilt)=>({
            equipo:eqFilt,
            tipoEntrega:'',
            fechaIngresado:new Date()
          }))

          setEquiposDis(EquiposSede)
          setInfoacta({
            ...infoacta,
            orden:dataot._id,
            autor:userData._id,
            actas:dataot?.actas,
            sede:'Principal',
            consecutivo:otserch?.actas + 1
          })
        }
      }
    }
    getOtdata()

  }catch{Alertassw ('error','No se pudo actualizar la infromacion inicial')}
},[refresh])

  return (
    <div>
      	<Modalcarga 
          estadoIn={spinnerMod} 
          cambiarestadoIn={setSpinnerMod}>
          <Spinner />
       </Modalcarga>
            <div className='actaselected'>   
              <div className='groupsel'>
                <label>¿Que se entrega? </label> 
                  <select name='entrega' className='selectacta' onChange={handleacta}required>
                      <option value='Certificado' label='Certficados'>Certificados</option>
                      <option value='Equipo' label='Equipos'>Equipos</option>
                      <option value='Certificado y Equipo' label='Certificados y equipos'>Certificados y equipos</option>
                  </select>
              </div>
              <div className='groupsel'>
                  <label>Sede:</label>
                  <div>
                      <Select  searchable='true' onChange={selectSede} options={listSede} placeholder={"Todas"}/>
                    </div>
              </div>
              <div className='groupsel rowacta'>
                <label>Observación</label>
                <textarea name='observacion' onChange={obsActa} placeholder='Agrega las observaciones del acta de entrega'></textarea>
              </div>
            </div>
            <Selecteditems setContEq={setContEq} contEq={contEq} equipos={equiposDis} origen={origen} infoActa={infoacta} setModal={setModal} crearActa={crearActa} Validacion={Validacion}/>
    </div>
  )
}
