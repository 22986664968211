import React, { useState } from 'react'
import { actualizarDatos, crearDatos } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'
import { Formsede } from './Formsede'

function AddSede({Cliente, setEstadoModal, setSedeOn, listSede, setEstadomodalIn, selectSede, sedesdata}) {
    const[infoSede, setInfoSede]=useState({
      departamento:Cliente.departamento,
      ciudad:Cliente.ciudad
    })
    console.log(infoSede)
    
    const dataSede=(event)=>{
        console.log(event)
        setInfoSede({
            ...infoSede,
            [event.target.name]:event.target.value
        })
    }
    const Enviar=()=>{
      
        async function agregarSede(){
        setEstadomodalIn(true);
       const addsedecliente= await actualizarDatos("usuarios",Cliente.sedes? {
            sedes:[...Cliente.sedes,infoSede]
        }:{sedes:infoSede},Cliente._id)

        const clientesDB = await crearDatos('Sedes',
        {
          cliente:Cliente._id,
          nameSede:infoSede?.nameSede,
          dirSede:infoSede?.dirSede || Cliente.direccion,
          contSede:infoSede?.contSede ||Cliente.contacto,
          celContacto:infoSede?.celContacto ||Cliente.telefono,
          emailContacto:infoSede.emailContacto || Cliente.correo
        })

        sedesdata.push(clientesDB.data.data)

        selectSede([{
          value:clientesDB.data.data._id,
          label:clientesDB.data.data.nameSede
        }])

        if(addsedecliente.status===200){
            Cliente.sedes=addsedecliente.data.data.sedes
            listSede.push({value:clientesDB.data.data._id, label:clientesDB.data.data.nameSede})

              Alertassw('ok','Ha creado un sede exitosamente')
              setSedeOn(true)
              setEstadoModal(false)
              setEstadomodalIn(false);
        }
    }
    agregarSede();
    }
  return (
    <Formsede dataSede={dataSede} Enviar={Enviar} dataDefault={infoSede}/>

  )
}

export default AddSede
