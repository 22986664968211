import { useEffect, useState } from "react";
import { getDataList } from "../api/common";
import ListClients from "../components/Clientes/ListClients";
import { RegisterClientForm } from "../components/Clientes/RegisterClientForm";
import { Modales } from "../components/Modales";
import { getClientRoles } from "../components/users/settings";
import { useAuth } from "../hooks/useAuth";
import { RutasCrear, RutasSideBar } from "../routers/RoutesPermisse";


export const Clientes = ()=> {

  const [isOpen, setIsOpen] = useState(false);
  const { setUserSelected} = useAuth();  
  const [roles,setRoles] = useState(null);

  const setModal = (modal ) =>{
    setIsOpen(modal)
    setUserSelected({})
    ////console.log(isOpen)
   } 

   useEffect(()=>{
    async function getRoles(){
      const dbRoles = await getDataList('Roles',{})
      setRoles(dbRoles.data.data)
    }
    getRoles()
  },[])
    return (
      <div>
      <div className='cabecera'>
        <span className='titulo'>Clientes</span>
      </div>

      <button className='dark-button mb-3 mt-5' 
        onClick={()=> setModal(true)}
        >Crear Cliente</button>

{roles&& <ListClients 
        onModal={(modal)=>setModal(modal)}
        modal={isOpen}
        roles={roles}
         />}
      <Modales  estado={isOpen}  cambiarestado={setIsOpen}>
          <RegisterClientForm      onModal={(modal)=>setModal(modal)} />
      </Modales>
      </div>
    );
}

