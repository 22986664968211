import React, { useState, useEffect } from "react";
import { useNavigate,useParams, useSearchParams } from "react-router-dom";
import {
  actualizarDatos,
  actualizarOt,
  deactivateOt,
  deleteById,
  reactivarOt,
} from "../api/common";
import moment from "moment";
import { Modales } from "../components/Modales";
import { SetOt } from "../components/Ot/SetOt";
import { paginate } from "../api/paginate";
import Spinner from "../components/commons/Spinner";
import { estados, estadosicon } from "../components/Ot/settings";
import { Pagination } from "../components/commons/Pagination";
import { useAuth } from "../hooks/useAuth";
import { Modalcarga } from "../components/Modales/Modalcarga";
import { PARENT } from "../constants";
import SelectSearch from "../components/commons/SelectSearch";
import { PdfOt } from "../components/Ot/PdfOt";
import { ExcelExportHelper } from "../components/Ot/Excelstyle";
import { Actaentrega } from "../components/Pdfs/Actaentrega";
import { useTheme } from "../hooks/useTheme";
import ResponsiveTable from "../components/commons/ResponsiveTable";
import { Alertassw } from "../components/Alertas/Alertassw";
import { ObservacionOT, Observacioneq } from "../components/Equipo/Observacioneq";
import { Facturar } from "../components/Seguimiento/Facturar";
import ClienteSelect from "../components/commons/ClienteSelect";
import DateProgram from "../components/Ot/DateProgram";
import ValidarPermiso from "../components/Roles/ValidarPermiso";
import { APROVEEDITEM, CALIBRATE, CREATEACTAS, PROGRAMOT } from "../components/Roles/ConstantRoute";
import { ContadoresOt } from "../components/Ot/IndicadoresOt";

moment.locale("es");

export const Ot = () => {
  const [spinnerMod, setSpinnerMod] = useState(false);
  const [page, setPage] = useState(1);
  const { userData } = useAuth();
  const [filters, setFilters] = useState({});
  const [estadomodalIn, setEstadomodalIn] = useState(false);
  const [modal, setModal] = useState(false);
  const[modalObs, setModalObs]=useState(false);
  const[modalFac,setModalFact]=useState(false);
  const[modalinfo,setModalInfo]=useState(false);
  const [ordendata, setOrdendata] = useState();
  const { size } = useTheme();
  const[accion, setAccion]=useState({
    tarea:'',
    titulo:''
})
const permisos =userData.role.permisos; // Lista de permisos del usuario;

const {id}=useParams()
console.log(filters)

  const changePage = (page) => {
    setOtPage({ ...oT, results: {} });
    setPage(page);
  };

  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  //Funcion para crear Hijo y abrir OT
  const continueOt = (ot) => {
    if(ValidarPermiso(CALIBRATE,permisos)!==-1){
    navigate(`/Ot/${ot._id}?orden=${ot.numeroOrden}&task=finalizar`);
    }else{
      alertaPermiso()
    }
  };
  const openOt = async (ot) => {
    //console.log(ot);
    if(ValidarPermiso(CALIBRATE,permisos)!==-1){
    setSpinnerMod(true);
    const ota = await actualizarDatos(
      "Ot",
      { ...ot, estado: "Abierta" },
      ot._id
    );
    //console.log(ota);
    if (ota.status === 200) {
      setSpinnerMod(false);
      setRefresh(!refresh);
      navigate(`/Ot/${ot._id}?orden=${ot.numeroOrden}&task=finalizar`);
    }
  }else{
    alertaPermiso()
  }
  };
  const seguimiento = (orden) => {
    navigate(`/Seguimiento/${orden}?type=Ot`);
  };

  const alertaPermiso=()=>{
    Alertassw('error','PERMISO NO AUTORIZADO','Su rol no tiene habilitado este permiso, solicita a la persona autorizada la asignación')
  }
  //Funcion para programar OT
  const programOt = (ot) => {
    if(ValidarPermiso(PROGRAMOT,permisos)!==-1){
    setOtinfo({
      numero_orden: ot.numeroOrden,
      id: ot._id,
    });
    setEstadomodal(true);
   }else{
    alertaPermiso()
   }
  };

  //Funcion para ir a la ruta indicada por el boton
  const funciones = {
    REQUESTED: "Solicitud",
    Solicitud: programOt,
    Programada: openOt,
    Abierta: continueOt,
    "Lista para Cerrar": "Cerrada",
    Cerrada: "Pagada",
    Pagada: "Entragada",
  };

  //Estado para activar el modal
  const [estadomodal, setEstadomodal] = useState(false);

  //Informacion titulo para el modal
  const [otInfo, setOtinfo] = useState({
    numero_orden: "",
    id: "",
  });

  const [oT, setOtPage] = useState({});
  //console.log(oT);
  useEffect(()=>{
    console.log(id)
    if(id!="total"){
      setFilters({
        ...filters,
        _id:id,
    })
    }else(setFilters());

  },[id])
  useEffect(() => {
    setEstadomodalIn(true);
    async function getPaginatedOt() {
      setEstadomodalIn(true);
      const paginatedOt = await paginate(
        `/Ot/paginar?page=${page}&perPage=10`,
        {
          conditions: id!='total'?  { otType: PARENT,_id:id, $or:[{activated: true},{activated: null}], ...filters } : { otType: PARENT, $or:[{activated: true},{activated: null}], ...filters },
          sort: { _id: -1 },
        }
      );
      console.log('datos usuario',userData);
      const data = paginatedOt.data.data;
      //console.log(paginatedOt.data.data)
      setOtPage(data);
      setEstadomodalIn(false);
    }
    if (userData) getPaginatedOt();
    setEstadomodalIn(false);
  }, [page, refresh, filters, id]);

  const handleSetFilters = (e) => {
    console.log(e);
    setFilters({
      ...filters,
      [e.target.name]:e.target.value,
    });
    //console.log(filters);
  };

  const createacta = (data) => {
    setOrdendata(data);
    setModal(true);
  };

  const deleteOt = async (params) => {
    const { id } = params;
    try {
      const data = await deleteById("Ot", id);
      console.log(data);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };
 
  const Reactivar= async(ot)=>{
    try {
      setEstadomodalIn(true)
      const resultOt = await reactivarOt('Ot',ot,ot._id)
      const ota = await actualizarDatos(
        "Ot",
        { ...ot, 
          estado: "Abierta",
          childs: ot.childs+1,
          visitas: ot.visitas+1
        },
        ot._id
      );
      console.log(resultOt)
      setRefresh(!refresh)
      setEstadomodalIn(false)
      Alertassw('success','Orden activada correctamente aahora puedes abrirla')
    } catch (error) {
      console.log(error)
      Alertassw('error','Error en la activacion de la Ot')
    }
  }
  const deactivateOts = async (params) => {
    const { id } = params;
    try {
      await deactivateOt(id);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  /// Permite facturar la orden de trabajo
  const invoice=(ot)=>{
    setOrdendata(ot);
    setModalFact(true)
  }

  const handleDeleteOt = (id) => {
    Alertassw(
      "yes/no",
      "",
      "¿Esta seguro deseea eliminar la Orden? \n Esto eliminará la base de datos",
      "",
      0,
      deleteOt,
      { id }
    );
  };

  const observacion=(ot)=>{
    setOrdendata(ot);
    setModalObs(true)
  }
  const infoOt=(ot)=>{
    setOrdendata(ot);
    setModalInfo(true)
  }

  const handleDeactivatedOt = (id) => {
    Alertassw(
      "yes/no",
      "",
      "¿Esta seguro deseea desactivar la Orden?",
      "",
      0,
      deactivateOts,
      { id }
    );
  };

  return (
    <div>
      <Modalcarga estadoIn={spinnerMod} cambiarestadoIn={setSpinnerMod}>
        <Spinner />
      </Modalcarga>
      {oT ? (
        <>
          <Modales
            estado={modal}
            cambiarestado={setModal}
            titulo={"Acta de entrega"}
          >
            <Actaentrega
              origen={"Ot"}
              setOrdendata={setOrdendata}
              dataot={ordendata}
              setModal={setModal}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Modales>
          <Modales
            estado={modalObs}
            cambiarestado={setModalObs}
            titulo={"Observación"}
          >
            <ObservacionOT cambiarestado={setModalObs} ordendata={ordendata} setRefresh={setRefresh} refresh={refresh} setSpinnerMod={setSpinnerMod}/>
          </Modales>
          <Modales
            estado={modalinfo}
            cambiarestado={setModalInfo}
            titulo={"Información de Ítems Cargados"}
          >
            <ContadoresOt ots={ordendata}/>

          </Modales>

          <Modales
            estado={modalFac}
            cambiarestado={setModalFact}
            titulo={"Facturar"}
          >
            <Facturar ordendata={ordendata} setModal={setModalFact} setRefresh={setRefresh} refresh={refresh}/>

          </Modales>

          <Modalcarga
            estadoIn={estadomodalIn}
            cambiarestadoIn={setEstadomodalIn}
          >
            <Spinner />
          </Modalcarga>

          <div className="cuerpoContenido">
            <div className="cabecera">
              <a className="titulo">Ordenes de trabajo</a>
            </div>
            <hr className="linea" />

          {id=="total"?(
            <>
            <div
              className={`flex space-between ${
                size.width < 800 ? "flex-vertical" : ""
              }`}
            >
              <div className="form_group">
                <div className="form_group_label">Estado</div>
                <select
                  name="genero"
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      estado:
                        e.target.value === "Todas" ? undefined : e.target.value,
                    });
                  }}
                  defaultValue="Todas"
                >
                  <option value="Solicitud">Solicitud</option>
                  <option value="Programada">Programada</option>
                  <option value="Abierta">Abiertas</option>
                  <option value="Cerrada">Cerradas</option>
                  <option value="Revisión">Revisión</option>
                  <option value="Entregar">Entregar</option>
                  <option value="Facturar">Facturar</option>
                  <option selected value="Todas">
                    Todas
                  </option>
                </select>
              </div>
              <div className="form_group ">
                <div className="form_group_label">Cliente</div>
                <ClienteSelect
                  handledata={handleSetFilters}
                  name="cliente"
                  collection={"Usuarios"}
                  field={"nombre"}
                  baseFilter={{ "role.nombre": "Cliente" }}
                />
              </div>

              <div className="form_group">
                <div className="form_group_label">Responsable</div>
                <SelectSearch
                  handledata={handleSetFilters}
                  name="responsable"
                  collection={"Usuarios"}
                  field={"nombre"}
                  baseFilter={{ "role.nombre": "~Cliente" }}
                />
              </div>
              <div className="form_group">
                <div className="form_group_label">Autor</div>
                <SelectSearch
                  handledata={handleSetFilters}
                  name="coordinador"
                  collection={"Usuarios"}
                  field={"nombre"}
                  baseFilter={{ "role.nombre": "~Cliente" }}
                />
              </div>
            </div></>) : (
            <>
              <br/>
              <button type="button" class="btn btn-secondary btn-sm" onClick={()=> navigate("/otlista/total")}>Mostrar todas las ordenes</button>
              <br/>
            </>
)}

            <div className="flex">
              <div>
                <input
                  type="checkbox"
                  className="checkbox"
                  onChange={(e) => {
                    const user = e.target.checked ? userData._id : undefined;
                    setFilters({
                      ...filters,
                    });
                  }}
                />{" "}
                Mis Ordenes de Trabajo
              </div>
              {/*
              Ver ordenes desactivadas
              userData.role.nombre === "Admin" && (
                <div className="ml-5">
                  <input
                    type="checkbox"
                    className="checkbox"
                    onChange={(e) => {
                    console.log(filters)
                  filters["$or"] = !e.target.checked?[{activated: !e.target.checked},{activated: null}]:[{activated: !e.target.checked}];
                   console.log(filters)
                      setFilters({
                        ...filters
                      });
                    }}
                  />{" "}
                  Ver Ordenes desactivadas
                </div>
                  )*/}
            </div>
            <ResponsiveTable>
              <ul className="col-8ot cabeceraitem ">
                <li> No </li>
                <li> Cliente </li>
                {userData.role.nombre === "Metrologo" ?<li>Contacto</li>:<li> Autor </li>}
                <li> Fecha inicio calibración </li>
                <li> Responsable </li>
                <li> Estado </li>
                <li> Avance </li>
                <li> Revisión </li>
                <li> Entrega </li>
              </ul>
              {oT.results?.length === 0 ? (
                <div>No hay Ordenes Creadas</div>
              ) : oT.results?.length > 0 ? (
                oT.results.map((ot) => (
                  <div key={ot._id}>
                    <details>
                      <summary className="colorot">
                        <ul className="col-8ot sombra-button">
                          <li> {ot.numeroOrden} </li>
                          <li> {ot.equipos[0]?.equipo?.cliente?.nombre +" "+ `(${ot.equipos[0]?.equipo?.cliente?.ciudad})`}</li>
                          {userData.role.nombre === "Metrologo" ?<li>{ot.equipos[0]?.equipo?.cliente?.contacto}</li>:
                          <li>
                            {ot.coordinador
                              ? ot.coordinador?.nombre.charAt(0).toUpperCase() +
                                "." +
                                " " +
                                ot.coordinador?.apellido
                              : "No Asignado"}{" "}
                          </li>}
                          <li>
                            {" "}
                            {ot?.fechaEntrega
                              ? moment(ot?.fechaEntrega).format("L")
                              : "No definido"}{" "}
                          </li>
                          <li>
                            {" "}
                            {ot.responsable?.nombre
                              ? ot.responsable?.nombre.charAt(0).toUpperCase() +
                                "." +
                                " " +
                                ot.responsable?.apellido
                              : "No definido"}
                          </li>
                          <li> {ot.estado} </li>
                          <li> {`${Math.round(ot.avance * 100, 3)}%`}</li>
                          <li>
                            {" "}
                            {`${Math.round(
                              (ot.totalAprobado /
                                (ot.totalEq - ot.canceledEq)) *
                                100,
                              3
                            )}%`}
                          </li>
                          <li>
                            {" "}
                            {`${Math.round(
                              (ot.totalEntrega / (ot.totalEq - ot.canceledEq)) *
                                100,
                              3
                            )}%`}
                          </li>
                          <li>
                            {ot.observacion ? (
                              <i className="bi bi-eye-fill font_sz" onClick={()=>observacion(ot)}></i>
                            ) : null}
                          </li>
                        </ul>
                        <hr className="linea" />
                      </summary>
                      <div className="detalle_style">
                        {/* Seccion de botones*/}
                        {userData.role.nombre !== "Cliente" ? (
                          <div className="Menubotonesot">
                            {ot.estado === "Abierta" ||
                            ot.estado === "Programada" ||
                            (ot.estado === "Solicitud" )? (
                              <button
                                className="btn-btn-trans mg-left-90"
                                onClick={() => funciones[ot.estado](ot)}
                              >
                                {estados[ot.estado]}
                                <i
                                  className={`bi ${estadosicon[ot.estado]}`}
                                ></i> 
                              </button>
                            ) : null}
                              <>
                                {ot.finishedEq > ot.totalAprobado  && ValidarPermiso(APROVEEDITEM,permisos)!==-1? ( //verifico el estado y las cantidades de equipos aprobados 
                                  <button 
                                    className="btn-btn-trans mg-left-90"
                                    onClick={() =>
                                    
                                      navigate(
                                        `/Ot/${ot._id}?orden=${
                                          ot.numeroOrden
                                        }&task=revisar&totaleq=${
                                          ot.totalEq - ot.canceledEq
                                        }&aprobados=${ot.totalAprobado}`
                                      )
                                    }
                                  >
                                    Aprobar
                                    <i class="bi bi-check2-all"></i>
                                  </button>
                                ) : null}

                                {ValidarPermiso(CREATEACTAS,permisos)!==-1 && !(
                                  ot.estado === "Cerrada" ||
                                  ot.estado === "Facturar" 
                                ) ? (
                                  <button
                                    className="btn-btn-trans"
                                    onClick={() => createacta(ot)}
                                  >
                                    Crear Acta
                                    <i className="bi bi-files"></i>
                                  </button>
                                ) : null}
                              </>
                            <button
                              className="btn-btn-trans"
                              onClick={() => seguimiento(ot.numeroOrden)}
                            >
                              Seguimiento
                              <i className="bi bi-clock-history"></i>
                            </button>

                            {ot.estado === "Abierta" ? (
                              <button
                                className="btn-btn-trans"
                                onClick={() => PdfOt(ot.numeroOrden)}
                              >
                                Descargar
                                <i className="bi bi-file-earmark-pdf"></i>
                              </button>
                            ) : null}
                            <button
                              className="btn-btn-trans"
                              onClick={() => DateProgram(ot)}
                            >
                              Exportar
                              <i className="bi bi-file-earmark-spreadsheet"></i>
                            </button>
                            <button
                              className="btn-btn-trans"
                              onClick={() => infoOt(ot)}
                            >
                              Info
                              <i className="bi bi-info-square"></i>
                            </button>

                            {userData.role.nombre === "Admin" && (
                              <>
                              {/*<button
                                  className="btn-btn-trans"
                                  onClick={() => handleDeleteOt(ot._id)}
                                >
                                  Eliminar
                                  <i className="bi bi-trash3-fill"></i>
                            </button>*/}
                               {ot.estado==='Facturar'? 
                               <button
                                  className="btn-btn-trans"
                                  onClick={()=>invoice(ot)}
                                >
                                  Facturar
                                  <i className="bi bi-coin"></i>
                                </button>:null}
                               {/* <button
                                  className="btn-btn-trans"
                                  onClick={()=>Reactivar(ot)}
                                >
                                  Reactivar
                                  <i className="bi bi-coin"></i>
                          </button>*/}
                              </>
                            )}
                          </div>
                        ) : null}

                        {/* Fin de botones */}
                        <ul className="col-8eq sombra-button bg-gray">
                          <li>No.</li>
                          <li>Item</li>
                          <li>Marca</li>
                          <li>Modelo</li>
                          <li>Serie</li>
                          <li>Puntos</li>
                          <li>Ubicacion</li>
                          <li>Consecutivo</li>
                          <li>Estado</li>
                        </ul>
                        {ot.equipos &&
                          ot.equipos.map((eq,index) => (
                            <div key={eq._id}>
                              <ul className="col-8eq sombra-button">
                                <li>{index+1}</li>
                                <li
                                  className="under_line"
                                  onClick={() => {
                                    navigate(`/perfilEq?_id=${eq.equipo._id}`);
                                  }}
                                >
                                  {eq.equipo?.item.nombre}
                                </li>
                                <li>{eq.equipo?.marca}</li>
                                <li>{eq.equipo?.modelo}</li>
                                <li>{eq.equipo?.serie}</li>
                                <li>{eq.equipo?.puntos}</li>
                                <li>{eq.equipo?.ubicacion}</li>
                                <li>{eq.consecutivo}</li>
                                <li>{eq.estado}</li>
                                {eq.equipo?.movimiento === "Ingresado" &&
                                eq.estado !== "Entregado" ? (
                                  <li>
                                    <i
                                      class="bi bi-pin-map-fill cursor-p"
                                      onClick={() =>
                                        navigate(
                                          `/Ingresos/${eq._id}?orden=${ot.numeroOrden}&origen=equipo`
                                        )
                                      }
                                    />
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          ))}
                      </div>
                    </details>
                  </div>
                ))
              ) : (
                <Spinner />
              )}

              {oT.results?.length > 0 && (
                <Pagination
                  prev={oT.prev}
                  next={oT.next}
                  total_pages={oT.total_pages}
                  changePage={changePage}
                  page={page}
                />
              )}
            </ResponsiveTable>
            <div className="ordenblock"></div>
            <Modales
              estado={estadomodal}
              cambiarestado={setEstadomodal}
              titulo={`Programar Orden No : ${otInfo.numero_orden}`}
            >
              <SetOt
                otinfo={otInfo}
                cambiarestado={setEstadomodal}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Modales>
          </div>
        </>
      ) : (
        <Modalcarga estadoIn={estadomodalIn} cambiarestadoIn={setEstadomodalIn}>
          <Spinner />
        </Modalcarga>
      )}
    </div>
  );
};
