import React, { useEffect, useState } from 'react'
import { actualizarDatos, getDataList } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'
import { Permisos } from './Permisos'

 export const EditarRoles=({rolData, cambiarestado , setRefresh, refresh, setSpinnerMod})=> {
    console.log(rolData)
    let listPermisos = Permisos();
    const[addPermise, setAddPermise]=useState([])
    const[Actualizar, setActualizar]=useState(false)
    const [Areas, setAreas]=useState()
    console.log(Areas)
    const[dataAct, setDataAct]=useState({
        _id:rolData._id,
        permisos:rolData.permisos
    })
    console.log(dataAct)

    useEffect(()=>{

        async function consultarArea(){
            const areasDB = await getDataList('areas');
            const miArray = areasDB.data.data
            miArray.unshift({});
            setAreas(miArray)
            
        }

        consultarArea()

    },[])

    const saveChange=(e)=>{
        e.preventDefault()
        try {
          if(addPermise.length>0){
            dataAct.permisos=dataAct.permisos.concat(addPermise)
          }
            setSpinnerMod(true)
            async function guardarCambios(){
               const result=  await actualizarDatos('Roles',dataAct)
                console.log(result.data.data)
                Alertassw('charge','Cambio Guardado correctamente')
                setRefresh(!refresh)
                setSpinnerMod(false)
                cambiarestado(false)
            }
            guardarCambios()
        } catch (error) {
          Alertassw('error','Ha ocurrido un error','Intenta nuevamente, si el error persiste reporta el fallo a soporte tecnico') 
          cambiarestado(false);
          setRefresh(!refresh)
          setSpinnerMod(false)
        } 
    }
    const addPermiso=(event)=>{
      if(addPermise.indexOf(event.target.value) === -1 && rolData.permisos.indexOf(event.target.value)=== -1){
        addPermise.push(event.target.value)
        setActualizar(!Actualizar)
      }else{
        Alertassw('charge','Permiso ya agregado')
      }}
    const del=(index)=>{
      addPermise.splice(index,1)
      setActualizar(!Actualizar)
    }

    const handleData=(event)=>{
     console.log(event)
     setDataAct({
        ...dataAct,
        [event.target.name]:event.target.value
    })
    }
  return (
<div>
<form onSubmit={saveChange}>
    <div className='col-1MD'>
                <label className='mg-5-all' htmlFor='nombre' required>Nombre del Rol</label>
                <input  className='mg-5-all' name='nombre' onChange={handleData} type='string' defaultValue={rolData.nombre}></input>
                <label className='mg-5-all' htmlFor='area'>Area</label>
                <select className='mg-5-all' name='area' onChange={handleData} >
                    {Areas?.map((area, index)=>(
                        <option key={index} value={area._id}>{area.nombre}</option>
                    )) }                    
               </select>
                <label className='mg-5-all'  htmlFor='descripcion'>Descripción</label>
                <textarea  className='mg-5-all' name='descripcion' onChange={handleData} type='string' defaultValue={rolData.descripcion}></textarea>
                <label className='mg-5-all'  htmlFor='permisos'>Adicionar permiso</label>
                <select name='permisos' onChange={addPermiso}  >
                  {listPermisos.map((permiso, index)=>(
                    <option label={permiso} value={permiso} key={index} defaultValue={''}>
                    {permiso}
                    </option>
                  ))}
                </select>
                
                {addPermise?.length>0? 
                 <>
                 <hr></hr>
                {addPermise?.map((permise, index)=>(
                  <div className='ListPermisoAdd' key={index}>
                    <h6>{permise}</h6>
                    <div onClick={()=>{del(index)}}><label className="bi bi-trash cursor-p"></label></div>
                  </div>))}
                </>
                :null
                }
                <hr></hr>
                <button className='btn-btn-primary w-100 mg-20-top' type='submit' >Guardar cambios</button>
        </div>
</form>
</div>

  )
}
