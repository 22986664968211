import React from 'react'

export const EqHoja=({datason})=> {
    const equipos=datason.equipos
  return (
    <div className='sheetw'>
        <ul className='sheetWork cabeceraitem'>
            <li>Ítem</li>
            <li>Marca</li>
            <li>Modelo</li>
            <li>Serie</li>
            <li>Ubicación</li>
            <li>Invetario</li>
            <li>Consecutivo</li>
            <li>Estado</li>
        </ul>
        <div className='contentitem'>
            {equipos?.map(equipo=>(
                <ul key={equipo._id} className='sheetWork sombra-button'>
                    <li>{equipo.equipo.item.nombre}</li>
                    <li>{equipo.equipo.marca}</li>
                    <li>{equipo.equipo.modelo}</li>
                    <li>{equipo.equipo.serie}</li>
                    <li>{equipo.equipo.ubicacion}</li>
                    <li>{equipo.equipo.inventario}</li>
                    <li>{equipo.consecutivo}</li>
                    <li>{equipo.estado}</li>
                </ul>
            ))}
        </div>
        {datason.observacion?<div className='obsSheet'>{datason.observacion}</div>:null}
    </div>
  )
}
