import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from 'moment'
import DateProgram from "./DateProgram";
import { EstatesDateExcel } from "./ArraysValidation";
moment().locale('es')

export const ExcelExportHelper = (data, dates) => {

  console.log(data)
 const EstadoNoDateExcel=[
    'Cancelado',
    'Aplazado',
    'Pendiente'
  ]

  let clientSede = data.cliente?.sedes?.map((sed)=>({
    sede:sed,
  }))


  //console.log(data)
  const fechaProgram=dates
  

  const createDownLoadData = () => {
    handleExport().then((url) => {
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", `OT-${data.numeroOrden}.xlsx`);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };
    const wbout = XLSX.write(workbook, wopts);
   
    // El tipo MIME application/octet-stream se utiliza para archivos binarios desconocidos.
    // Conserva el contenido del archivo, pero requiere que el receptor determine el tipo de archivo,
    // por ejemplo, de la extensión del nombre de archivo.

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });
    return blob;
  };
  const s2ab = (s) => {
    // El constructor ArrayBuffer() se usa para crear objetos ArrayBuffer.
    // crear un ArrayBuffer con un tamaño en bytes
    const buf = new ArrayBuffer(s.length);
    
   //crear una matriz de enteros de 8 bits
    const view = new Uint8Array(buf);

    //charCodeAt El método charCodeAt() devuelve un número entero entre 0 y 65535 que representa el código UTF-16
    for (let i = 0; i !== s.length; ++i) {
     //console.log(s.charCodeAt(i));
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };
 
  //Al presionar boton se obtiene toda la informacion de la tabla
  const handleExport = () => {
    const Body = [{
        Consecutivo: 'Consecutivo',
        Cliente: 'Cliente',
        Nit: 'Nit',
        Dirección: 'Dirección',
        Telefono: 'Telefono',
        Ciudad: 'Ciudad',
        Contacto: 'Contacto',
        Sede: 'Sede',
        Ítem: 'Ítem',
        Marca: 'Marca',
        Modelo: 'Modelo',
        Serie: 'Serie',
        Inventario:'Inventario',
        Ubicación: 'Ubicación',
        Responsable: 'Responsable',
        FechaCargue: 'Fecha de creación',
        FechaProgramacion: 'Fecha de programación',
        FechaCalibracion: 'Fecha de calibración',
        FechaAprobacion: 'Fecha de aprobación',
        FechaEntrega: 'Fecha de entrega',
        FechaIngreso: 'Fecha de Ingreso',
        FechaSalida: 'Fecha de Salida'
    }]
data.equipos.forEach((eq)=>{
  console.log(eq)
  console.log(Body)
  try {
    Body.push({
        Consecutivo: eq?.consecutivo,
        Cliente: eq?.equipo?.cliente?.nombre.toUpperCase(),
        Nit: eq?.equipo?.cliente?.identificacion.toUpperCase(),
        Dirección: eq?.equipo?.sedeClient?.dirSede? eq?.equipo?.sedeClient?.dirSede.toUpperCase() : eq.equipo.cliente.direccion.toUpperCase(),
        Telefono:eq?.equipo?.sedeClient? eq?.equipo?.sedeClient.celContacto : eq.equipo.cliente.telefono,
        Ciudad: eq?.equipo?.sedeClient.ciudad? `${eq?.equipo?.sedeClient.ciudad.toUpperCase()} - ${eq?.equipo?.sedeClient.departamento.toUpperCase()} ` :   `${eq?.equipo?.cliente.ciudad.toUpperCase()} - ${eq?.equipo?.cliente.departamento.toUpperCase()}`,
        Contacto: eq?.equipo?.sedeClient? eq?.equipo?.sedeClient?.contSede.toUpperCase() : eq.equipo.cliente.contacto.toUpperCase(),
        Sede:eq?.equipo?.sedeClient? eq?.equipo?.sedeClient.nameSede :'PRINCIPAL',
        Ítem: eq?.equipo?.item?.nombre.toUpperCase(),
        Marca: eq?.equipo?.marca?.toUpperCase(),
        Modelo: eq?.equipo?.modelo?.toUpperCase(),
        Serie: eq?.equipo?.serie.toUpperCase(),
        Inventario: eq?.equipo?.inventario.toUpperCase(),
        Ubicación: eq?.equipo?.ubicacion.toUpperCase(),
        Responsable: eq?.responsable? eq?.responsable?.nombre.toUpperCase() +" "+ eq?.responsable?.apellido.toUpperCase():'',
        FechaCargue:data?.createdAt? moment(data?.createdAt).toDate():'',
        FechaProgramacion:fechaProgram?.fechaProgram? moment(fechaProgram.fechaProgram).toDate():'',
        FechaCalibracion:EstadoNoDateExcel.indexOf(eq.estado) ===-1 && eq?.fechaProcesado?  moment(eq?.fechaProcesado).toDate():'',
        FechaAprobacion:eq?.fechaAprobado? moment(eq?.fechaAprobado).toDate(): eq?.fechaEntrega? moment(eq?.fechaEntrega).toDate():'' ,
        FechaEntrega:eq?.fechaEntrega? moment(eq?.fechaEntrega).toDate():'',
        FechaIngreso:eq?.fechaIngreso? moment(eq?.fechaIngreso).toDate():'',
        FechaSalida:eq?.fechaSalida? moment(eq?.fechaSalida).toDate():'',
    })
    } catch (error) {
      console.log(error)
    }
  })


    const finalData = [...Body];  //informacion completa de las tablas concatenadas y el titulo
    console.log(finalData);
    //create a new libro en blanco
    const wb = XLSX.utils.book_new();
    //Se crea un hoja con la informacion concatenada
    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    // Se agrega la hoja al libro
    XLSX.utils.book_append_sheet(wb, sheet, "Listado Items");

    // objeto grande binario
    // Dado que los blobs pueden almacenar datos binarios, pueden usarse para almacenar imágenes u otros archivos multimedia.

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      tbodyRange: `A1:V${finalData.length}`
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {

    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {     //itero la hojas del libro
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        //Tamaño de columnas
        sheet.column("A").width(15);
        sheet.column("B").width(25);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(20);
        sheet.column("I").width(25);
        sheet.column("L").width(20);
        sheet.column("M").width(20);
        sheet.column("N").width(20);
        sheet.column("O").width(14);
        sheet.column("P").width(14);
        sheet.column("Q").width(16);
        sheet.column("R").width(14);
        sheet.column("S").width(14);
        sheet.column("T").width(14);
        sheet.column("U").width(14);
        sheet.column("V").width(14);
        //cabecera tabla
        sheet.range("A1:V1").style({
            fill: "22abb4",
            bold: true,
            wrapText:true
          });

          //Estilos para el body 
          if (dataInfo.tbodyRange) {
            console.log(dataInfo)
            sheet.range(dataInfo.tbodyRange).style({
                border:true,
                borderStyle:'thin',
              horizontalAlignment: "center",
            });
          }
            sheet.range(`P2${dataInfo.tbodyRange.substring(2)}`).style("numberFormat", "dd/mm/yyyy;@");
          
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
 
  createDownLoadData()
  return (
    <button
      onClick={() => {
        createDownLoadData()
      }}
      className="btn-btn-secundary float-end"
    >
        Exportar
    </button>
  );
};

//export default ExcelExportHelper;
