
import moment from 'moment';
import React from 'react'
const options = { year: 'numeric', month: 'long', day: 'numeric' }; 

moment.locale("es");

const PatronesList = ({patrones, setPageForm, setPatronData, setEstadoModal, setTitulo, add}) => {

    const verActividades =(data)=>{
        setPatronData(data)
        setPageForm(5);
        setTitulo('Actividades')
        setEstadoModal(true)
    }
  return (
    <div>
        <table className='table w-100 table-bordered'>
        <thead className="cabeceraitem">
            <tr>
                <th scope="col" >Index</th>
                <th scope="col" >Equipo</th>
                <th scope="col" >Marca</th>
                <th scope="col" >Modelo</th>
                <th scope="col" >Serie</th>
                <th scope="col" >Inventario</th>
                <th scope="col" >Ubicación</th>
                <th scope="col" >Tipo de equipo</th>
                <th scope="col" >Estado</th>
                <th scope="col" >Movilidad</th>
                <th scope="col" >Actividades</th>
                <th><i class="bi bi-pencil-square"></i></th>
            </tr>
        </thead>
        <tbody>
        {patrones?.map((patron,index)=>(
        <tr  key={index}>
            <td>{index}</td>
            <td
                className="under_line"
                onClick={() => {
                  window.open(`/PerfilPatron/${patron._id}`, '_blank');
                }} 
            >{patron.item}</td>
            <td>{patron.marca}</td>
            <td>{patron.modelo}</td>
            <td>{patron.serie}</td>
            <td>{patron.inventario ? patron.inventario : "No tiene"}</td>
            <td>{patron.ubicacion}</td>
            <td>{patron.tipoEquipo? patron.tipoEquipo:'No definido' }</td>
            <td>{patron.estado}</td>    
            <td>{patron.movimiento}</td>
            <td><a className='cursor-p' onClick={()=>verActividades(patron)}>Ver</a></td>
            <td><i className="bi bi-pencil-square cursor-p" onClick={()=>add(patron)}></i></td>
        </tr>))}
            </tbody>
        </table>

    </div>
  )
}

export default PatronesList
