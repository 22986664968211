const formData = {
    correo:'',
    password:'',
    nombre:'',
    apellido:'',
    identificacion:'',
    genero:''
}


const getRoleFormatOutput  = (input,type) =>{
  
    if(type === "id") return input._id
    else return { _id:input._id, nombre:input.nombre }
}
 const getClientRoles = (roles,include,type="id") =>{
    const clientRoles = ['cliente']
    //console.log(roles)
    return include?
    
    roles.filter((rol)=>clientRoles.indexOf(rol.nombre.toString().toLowerCase())>=0)
    .map((rol)=>getRoleFormatOutput(rol,type))
    :roles.filter((rol)=>clientRoles.indexOf(rol.nombre.toString().toLowerCase()) === -1).map((rol)=>getRoleFormatOutput(rol,type))

}

module.exports = {
    formData,
    getClientRoles
}