

export const getStates = async() =>{

   return await fetch(`https://www.datos.gov.co/resource/xdk5-pm3f.json?$select=departamento&$group=departamento&$order=departamento`,{
    method:"GET",
    headers:{
        'Content-Type': 'application/json'
    }
   })

}

export const getCities = async(departamento) =>{
    return await fetch(`https://www.datos.gov.co/resource/xdk5-pm3f.json?departamento=${departamento}&$limit=1000`,{
        method:"GET",
        headers:{
            'Content-Type': 'application/json'
        }
       })
}


