import axios from 'axios';
import { baseUrl, baseUrlLocal } from './config';     // importamos la URL base 




export const initAxios = () =>{
    axios.defaults.baseURL = process.env.NODE_ENV === 'development' ?baseUrlLocal:baseUrl;    // Definimos la baseURL por defecto por 
    
}

