//En esta seccion se agregan los metodos para realizar las peticiones al servidor

import { request } from "../libs/http/http"

// Metodo para solicitar el listado de los objetos de las diferentes colecciones
export const getDataList = (collection, data={}) =>{
    return request('post',`/${collection}/listar`,data);
}

//Con esta ruta consulto el lote de equipos cargados con archivo, verifico que no esten creados.
export const consultarLoteEq = (collection, data={}) =>{
    return request('post',`/${collection}/consultarLote`,data);
}

export const getDataobtener = (id) =>{
    return request('get',`/Ot/obtener/${id}`);
}

export const getDataObtenerUno = (collection,data) =>{
    return request('post',`/${collection}/obtenerUno`,data);
}
//metodo para pedir clientes con filtro, en la data viene el filtro que se desea pedir
export const postDataClientes = (data) =>{
    return request('post',`usuarios/filtro`,data);
}
//metodo para pedir Equipos con filtro, en la data viene el filtro que se desea pedir
export const postDataEquipos = (data) =>{
    return request('post',`Equipos/listar`,data);
}
export const actualizarDatos = (collection,data,id) => {
    return request('put',`/${collection}/actualizar/${id}`,data)
}
export const actualizarEquipo = (collection,data,id) => {
    return request('put',`/${collection}/actualizarUser/${id}`,data)
}
export const aprobarEquipo = (collection,data) => {
    return request('put',`/${collection}/aprobar`,data)
}
export const buscarConsecutivo = (collection,data) => {
    return request('post',`/${collection}/consecutivo`,data);
}
export const actualizarEqOt = (collection,data,id,numeroOrden) => {
    return request('put',`/${collection}/actualizar/${id}?orden=${numeroOrden}`,data)
}

export const cancelarEquipo = (collection,data,id,numeroOrden) => {
    return request('put',`/${collection}/deleteEquipos/${id}?orden=${numeroOrden}`,data)
}

export const actualizarOt = (collection,data,id) => {
    return request('put',`/${collection}/actualizarEquipos/${id}`,data)
}
export const reactivarOt = (collection,data,id) => {
    return request('put',`/${collection}/reactivar/${id}`,data)
}
export const actualizarEntrega = (collection,data,id) => {
    return request('put',`/${collection}/actualizarEntrega/${id}`,data)
}
export const crearDatos = (collection,data) => {
    return request('post',`/${collection}/crear`,data)
}

export const postDataMagnitud = (data) =>{
    return request('post',`magnitud/crear`,data);
}

export const postDataItem = (data) =>{
    return request('post',`Items/crear`,data);
}
export const postIngreso = (data) =>{
    return request('post',`IngresoEquipos/crear`,data);
}


export const postDataEquipo = (data) =>{
    return request('post',`Equipos/crear`,data);
}

export const postDataEquiposlotes = (data) =>{
    return request('post',`Equipos/crear/lote`,data);
}

export const crearLotes = (collection,data) =>{
    return request('post',`/${collection}/crear/lote`,data);
}
export const postDataOt = (data) =>{
    return request('post',`Ot/crear`,data);
}

export const filterData = (collection,data) =>{
    return request('post',`/${collection}/filtro`,data);
}

export const deleteById = (collection, id) => {
    return request('delete', `/${collection}/delete/${id}`);
}

export const addEquipments = (ot_id,orden,type,body) =>{
    return request('post',`/Ot/addEquipos/${ot_id}?orden=${orden}&type=${type}`,body)
}

export const deactivateOt = (id) =>{
    return request('put',`Ot/deactivate/${id}`)
}

export const getObtenerPame = (body,id) =>{
    console.log("body: ", body)
    return request('post',`/Actividad/consutarPame/${id}`,body);
}