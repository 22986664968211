import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { recoverPassword } from "../../api/user";
import { useForm } from "../../hooks/useForm";
import { Navegacion } from "../../views/Navegacion";
import { Alertassw } from "../Alertas/Alertassw";
import jwt_token from "jwt-decode";
const RecoverPassword = ( ) =>{

    const navigate = useNavigate();
    const [values, handleInputChange] = useForm({
        email:""
      });
      const [ searchParams] = useSearchParams()
      const [errorMessage, setErrorMessage] = useState();
      const [expired,setExpired] = useState(false)
      const [equals, setEquals] = useState(true)

      useEffect(()=>{
        console.log(Date.now()/1000)
        console.log(jwt_token(token).exp)
            setExpired((Date.now()/1000)>jwt_token(token).exp)
      },[])
    
    const token = searchParams.get("token");


    const verifyToken = (e) =>{
        console.log(e.target.value.length)
        console.log(values.password)
        setEquals(((e.target.value.length===0)||(e.target.value === values.password)))
    }
    


      const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
          if(equals){
            await recoverPassword(values,searchParams.get("token"))
            Alertassw('toast','Contraseña recuperada','La contraseña ha sido recuperada','success')
            navigate('/login')
          }else{
            Alertassw('toast','Error','Las contraeña no coinciden','error')
          }
    
        } catch (error) {
          setErrorMessage(error.response?.data?.detail || "Error Recuperando Contraseña");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      };


    return(
        <>
            <Navegacion />
            <div className="main-container pd-side-20 pdt-5">
        <form className="  form_container" onSubmit={handleChangePassword}>

        <h4>Recuperar Contraseña</h4>
        {errorMessage&&<div className="error">{errorMessage}</div>}
        {!equals&&<div className="error">Las contraseñas no coinciden</div>}

        {expired?<div>La Solicitud de recuperación de contraseña ha expirado</div>:<>
        <div className="form_group">
        <div className="form_group_label">Contraseña</div>
        <input
          type="password"
          name="password"
          onChange={handleInputChange}
        />
      </div>

      <div className={`form_group ${!equals?"form_group_error":""}`}>
        <div className="form_group_label">Repetir Contraseña</div>
        <input
          type="password"
          name="rePassword"
          onChange={verifyToken}
        />
      </div>

      <div className="form_group mt-5">
        <button isDisabled={!equals} type="submit">Enviar</button>
      </div>
      </>}
      </form>
      </div>
      </>);
}

export default RecoverPassword;