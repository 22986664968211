import * as XLSX from "xlsx";

export const ExcelItems=(sheetData)=> {
        
    console.log(sheetData)

        var wb=XLSX.utils.book_new()
        var ws=XLSX.utils.json_to_sheet(sheetData)

        console.log(ws)
        XLSX.utils.book_append_sheet(wb,ws, "Listado Items")

        XLSX.writeFile(wb, "Lista-Items.xlsx")

}

