import { useState } from "react";
import { changePassword } from "../../api/user";
import { getToken } from "../../helpers/helpers";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "../../hooks/useForm";

const ChangePassword = ({setIsOpen}) => {
    const { userData } = useAuth()
  const [values, handleInputChange] = useForm({
    email:userData.correo || ""
  });
  const [errorMessage, setErrorMessage] = useState();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
       await changePassword(values, getToken());
      if(setIsOpen)return setIsOpen(false)

    } catch (error) {
      setErrorMessage(error.response?.data?.detail || "Error Cambiando Contraseña");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <form className="form_container" onSubmit={handleChangePassword}>
      {errorMessage&&<div className="error">{errorMessage}</div>}
      {!setIsOpen&&   <div className="form_group">
        <div className="form_group_label">Correo</div>
        <input
          type="text"
          name="correo"
          onChange={handleInputChange}
        />
      </div>}
      <div className="form_group">
        <div className="form_group_label">Contraseña Previa</div>
        <input
          type="password"
          name="prevPassword"
          onChange={handleInputChange}
        />
      </div>
      <div className="form_group">
        <div className="form_group_label">Nueva Contraseña</div>
        <input
          type="password"
          name="nextPassword"
          onChange={handleInputChange}
        />
      </div>

      <div className="form_group mt-5">
        <button type="submit">Enviar</button>
      </div>
    </form>
  );
};

export default ChangePassword;
