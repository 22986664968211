import React, { useEffect, useState } from 'react'
import { getDataList } from '../../api/common'
import { ActividadesTotal } from './ActividadesTotal'
import moment from 'moment'

export const ConsulActividades = () => {
    const [actividades, setActividades]=useState()
    const [refresh, setRefresh]=useState(false);

    useEffect( ()=>{  
        async function  consultarActividades(){
            const resultado=await getDataList('Actividad')
            console.log('Resultado consulta: ',resultado);
            setActividades(resultado.data.data)
        }
        consultarActividades()
    },[refresh])
    
  return (
    <div>
      <ActividadesTotal refresh={refresh} setRefresh={setRefresh} actividades={actividades} />
    </div>
  )
}

