import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getDataList, postDataMagnitud } from '../api/common'
import { EditMg } from '../components/Magnitud/EditMg'
import { Modales } from '../components/Modales'

export const Magnitud =() => {
    //creo el estado para almacenar las magnitudes guardadas
    const [magnitudes, setMagnitudes]=useState([])
    const [estadomodal, setEstadomodal] = useState(false);
    const [infoMg, setInfoMg]=useState()
    const [refresh, setRefresh]=useState(false)

    const [magnitud, setMagnitud]=useState({
        nombre:'',
        consecutivo:'',
        numero:'',
    })
    //evento para capturar la informacion de los inputs del formulario de magnitud
    const datamagnitud = (event) =>{
        setMagnitud({
            ...magnitud,
         [event.target.name]:event.target.value
        })
    }
    // para elimiar de la lista un objeto 
    const del = (index)=>{
        //console.log(index)
        magnitudes.splice(index,1)
        setMagnitudes([
          ...magnitudes
        ])
        //console.log(magnitudes)
      }

    //Evento para almacenar las magnitudes
    const savemagnitud = (e)=>{
       // //console.log(magnitud)
       if (magnitud.nombre && magnitud.consecutivo && magnitud.numero){
        e.preventDefault()
        async function postMagnitud(){
            const magnitudDB = await postDataMagnitud(magnitud);
            setMagnitudes([
                ...magnitudes,
                magnitudDB.data.data
            ]);
           // //console.log(magnitudDB)
        }
        postMagnitud()
    }}
    // Funcion para traer todas las magnitudes almacenadas
    useEffect(() => {        
        async function getMagnitud(){
            const magnitudDB = await getDataList('magnitud');
            ////console.log(magnitudDB.data.data)
            setMagnitudes(magnitudDB.data.data);
            //console.log(magnitudes)
        }
        getMagnitud()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const editMag=(data)=>{
        console.log(data)
        setEstadomodal(true)
        setInfoMg(data)
    }

  return (
    <div>
        <Modales
            estado={estadomodal} 
            cambiarestado={setEstadomodal}
            validation={true}
            titulo={`Editar Magnitud : ${infoMg?.nombre}`}
        >
            <EditMg infoMg={infoMg} setRefresh={setRefresh} refresh={refresh} setEstadomodal={setEstadomodal}/>
        </Modales>
        <div className='cabecera'>
        <label className='titulo'>Magnitudes / Clasificación</label>
      </div>
      <div >
            <form className='conten_mg flex wrap'>
                <input className='datauseri' onChange={datamagnitud} type={'string'} name='nombre' placeholder='Magnitud' required='true'/>
                <input className='datauseri' onChange={datamagnitud} type={'string'} name='consecutivo' placeholder='consecutivo' required='true'/>
                <input className='datauseri' onChange={datamagnitud} type={'string'} name='numero' placeholder='Numero de inicio' required='true'/>
               <div className="botonrol"> <button className='btn-btn-secundary  submit' onClick={savemagnitud} type='submit'>Crear</button> </div>
            </form>
      </div>
      <div className='conten_mg flex wrap space-around ' >
          {magnitudes && magnitudes.map((mg , index)=>(
                    <div className='card  mb-5 mt-5' key={index}>
                        <label className='title'>{mg.nombre}</label>
                        <div className='contenido'>
                            <label className='contenido_i' htmlFor='consecutivo'>Consecutivo:</label>
                            <label className='contenido_i' name='consecutivo'>{mg.consecutivo}</label>
                        </div>
                        <div className='contenido'>
                                <label className='contenido_i' htmlFor='numero'>Registros:</label>
                                <label className='contenido_i' name='numero'>{mg.numero}</label>
                        </div>
                        <div className='botones'> 
                            <i className="bi bi-pencil-square togEdit cursor-p" onClick={()=>editMag(mg)}></i>
                            <i onClick={()=>{del(index)}} className="bi bi-trash togDel"></i>
                        </div>
                 </div>
          ))}
      </div>
    </div>
  )
}


