
import { getDataList } from '../../api/common'
import { CHILD, Logoformato } from '../../constants'
import autoTable from 'jspdf-autotable';
import { jsPDF } from "jspdf";
import moment from 'moment';
import 'moment/locale/es';
import { logoBiolab } from '../../helpers/imagen/Imagen';
moment().locale('es')

export const PdfOt = (datason, firmaUser, firmaClient={}, tipo)=> {

  console.log(datason)
  console.log(firmaClient)
    var paginas=0;
    var contador=0;
    const sedeOT= datason.sede
     
    const doc = new jsPDF({
      unit:'cm',
      orientation:'l',
      format:'A4',
      encryption:{
        ownerPassword:"Biolab26#.",
        userPermissions:["print","copy"]
      }
  })
    const entregado = firmaUser;
    const recibido = firmaClient;
    const sizefont=8
     
      //tabla para informacion del cliente
      autoTable(doc, {
        //theme:'plain',
        startY:3.5,
        styles:{lineColor:10, lineWidth:0.02, fontSize:sizefont}, 
        headStyles:{fontStyle:'bold', fillColor:[34,171,180], halign:'center', valign:'middle'},
        bodyStyles:{halign:'center', valign:'middle'},
        theme:'plain',
        head: [[{  content : `Hoja de trabajo N°: ${datason?.numeroOrden + '-' +datason?.visitas }`,  colSpan : 9 ,  rowSpan : 1 ,  styles : {  halign : 'center', cellWidth: 6, fillColor:[255,255,255]} }],
               ['Cliente',{content:'Nit',styles:{cellWidth:2.5}} ,{content:'Dirección',styles:{cellWidth:3}},'Tel. Contacto','Sede','Ciudad / Departamento','Persona de Contacto',{content:'Propuesta comercial',styles:{cellWidth:2.5}}, {content:'Formato de Certificado',styles:{cellWidth:2.5}}],
              ],
        body:[ [`${datason?.cliente?.nombre}`,`${datason?.cliente?.identificacion}`,`${sedeOT[0]?.dirSede || datason?.cliente?.direccion}`,`${sedeOT[0]?.celContacto || datason?.cliente?.telefono}`,`${sedeOT[0]?.nameSede || datason?.sede}`,`${datason?.cliente?.ciudad} - ${datason?.cliente?.departamento}`,`${sedeOT[0]?.contSede || datason?.cliente?.contacto}`,`${datason?.numeroOrden}`, `${datason?.cliente?.certificado}`],]
      })
        //Tabla de contenido
        autoTable(doc, {
          margin:{top:3.3, bottom:2.7},
          headStyles:{fontStyle:'bold', fillColor:[34,171,180], textColor:[0,0,0], halign:'center', valign:'middle'},
          showHead:'everyPage',
          showFoot:'lastPage',
          rowPageBreak:'avoid',
          styles:{lineColor:10, lineWidth:0.01, fontSize:sizefont},
          theme:'plain',
          bodyStyles:{textColor:[0,0,0], valign:'middle', halign:'center'},
          head: [
            [{ content : 'Datos' ,  colSpan : 10, styles : {  halign : 'center' }}],
            ['Item' , {content:'Marca', styles:{cellWidth:3}}, 'Modelo', 'Serie',{content:'Código inventario',styles:{cellWidth:2.5}},'Ubicación','Resolución','Puntos a calibrar', {content:'Número Certtif.', styles:{cellWidth:2}},'Estado'],
          ],
          body: datason.equipos.map(({equipo,estado, consecutivo})=>[
            equipo.item?.nombre,equipo.marca, equipo.modelo, equipo.serie,equipo.inventario,equipo.ubicacion,
            equipo.resolucion,equipo.puntos, consecutivo,!(estado==='Finalizado'|| estado==='Aplazado' || estado==='No finalizado' || estado==='Cancelado')?'Finalizado':estado 
          ]),
        didDrawPage: function (data) {
          // Cabecera del formato
          autoTable (doc, {
              //theme:'plain',
              startY:1,
              showHead:'everyPage',
              headStyles:{ fontStyle:'bold'},
              styles:{lineColor:10, lineWidth:0.02, fontSize:sizefont}, 
              theme:'plain',
              head: [[{  content : 'SISTEMA INTEGRADO DE GESTIÓN' ,  colSpan : 3 ,  rowSpan : 1 ,  styles : {  halign : 'center', cellWidth: 6, fillColor:[34,171,180] } }],
                     [{  content : '' ,  colSpan : 1 ,  rowSpan : 2,  styles : {  halign : 'center'  }  }, {content:'Proceso: Prestación del Servicio de Calibración', styles:{halign:'center'}}, 'Código: MIS-PCB-FOR-001'],
                     [{content:'Formato: Hoja de trabajo', styles:{halign:'center'}}, 'Versión: 05'],
                    ],
              didDrawCell: (data) => {
                if (data.section === 'head' && data.column.index === 0 && data.row.index===1) {
                  var base64Img =logoBiolab;
                  doc.addImage(base64Img, 'JPEG', data.cell.x+1.2, data.cell.y+0.2, 3, 1)
                }},
            })
            //-------> Pie de Página <-------//
            autoTable(doc, {
              startY:18.5,
              showHead:'everyPage',
              footStyles:{ fillColor:[255,255,255], textColor:[0,0,0], fontSize:sizefont},
              styles:{lineColor:10, lineWidth:0.02}, 
              foot:[['Elaboro: Director de Laboratorio','Revisó: Director de calidad', 'Aprobó: Gerente'],],
            })
          // Footer
          var str = "Página " + doc.internal.getNumberOfPages()
          doc.setFontSize(10);
          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          doc.setFontSize(8);
          console.log(str)
          paginas=doc.internal.getNumberOfPages()
          console.log(paginas)
          doc.text(str,data.settings.margin.right, 20);
         }
        })
      //Seccion para la observación
      autoTable(doc,{
        margin:{top:3.3, bottom:2.7},
        showHead:'everyPage',
        showFoot:'lastPage',
        rowPageBreak:'avoid',
        styles:{lineColor:10, lineWidth:0.01, fontSize:sizefont},
        theme:'plain',
        foot:[[{content:'Observación', styles:{halign:'center', fillColor:[34,171,180], textColor:[0,0,0]}}],
        [{content:`${datason?.observacion}`, styles:{minCellHeight:1, textColor:[0,0,0], fontStyle:'normal', fillColor:[255,255,255]}}],   
        ],
      })
    //seccion para campos de firma
    autoTable(doc, {
      bodyStyles:{fillColor:[255,255,255], valign:'bottom', fontSize:sizefont},
      theme:'plain',
      margin:{top:3.3, bottom:3},
      pageBreak:'avoid',
      body: [
        [{content:'Recibe:', styles:{cellWidth:1.5}},
        {content:'', colSpan:2, styles:{ minCellHeight:2 }},
        {content:'Entrega:', styles:{cellWidth:1.5}},
        {content:'',colSpan:2}],
        ['Nombre:',{content:datason?.recibido? datason?.recibido?.persona:'Sin Recibir', styles:{cellWidth:10}},'',
        'Nombre:',{content:datason?.entregado?.persona?datason?.entregado?.persona:'Usuario Sin Firma Regsitrada', styles:{cellWidth:8}}],
        ['Fecha:', datason?.recibido?.fecha?
        moment(datason?.recibido?.fecha).format('lll'):'','',
        'Fecha:',datason?.entregado?.fecha?
        moment(datason?.entregado?.fecha).format('lll'):'']      
    ],
    didDrawCell:(data)=>{
      if(data.section==='body' && data.column.index === 1 && data.row.index === 1 && recibido?.sign){
        doc.addImage(recibido?.sign,'png',data.cell.x, data.cell.y-2.6, 3.5, 2.5)
      }
      if(data.section==='body' && data.column.index === 4 && data.row.index === 1 && entregado?.sign){
        doc.addImage(entregado?.sign,'png',data.cell.x, data.cell.y-2.6, 3.5, 2.5)
      }
      if(contador===0 && paginas<doc.internal.getNumberOfPages()){
        contador++;
      autoTable (doc, {
        startY:1,
        showHead:'everyPage',
        styles:{lineColor:10, lineWidth:0.02, fontSize:sizefont}, 
        theme:'plain',
        head: [[{  content : 'SISTEMA INTEGRADO DE GESTIÓN' ,  colSpan : 3 ,  rowSpan : 1 ,  styles : {  halign : 'center', cellWidth: 6, fillColor:[34,171,180] } }],
               [{  content : '' ,  colSpan : 1 ,  rowSpan : 2,  styles : {  halign : 'center'  }  }, {content:'Proceso: Prestación del Servicio de Calibración', styles:{halign:'center'}}, 'Código: MIS-PCB-FOR-001'],
               [{content:'Formato: Hoja de trabajo', styles:{halign:'center'}}, 'Versión: 05'],
              ],
        didDrawCell: (data) => {
          if (data.section === 'head' && data.column.index === 0 && data.row.index===1) {
            var base64Img = logoBiolab;
            doc.addImage(base64Img, 'JPEG', data.cell.x+1.2, data.cell.y+0.2, 3, 1)
          }},
      })

      autoTable(doc, {
        startY:18.5,
        showHead:'everyPage',
        footStyles:{ fillColor:[255,255,255], textColor:[0,0,0]},
        styles:{lineColor:10, lineWidth:0.02, fontSize:sizefont}, 
        foot:[['Elaboro: Director de Laboratorio','Revisó: Director de calidad', 'Aprobó: Gerente'],],
      })
      // Footer
      var str = "Página " + doc.internal.getNumberOfPages()
      doc.setFontSize(10);
      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      var pageSize = doc.internal.pageSize;
      doc.setFontSize(8);
      doc.text(str,data.settings.margin.right, 20);
    
    }
}})

   //doc.save('table.pdf')
   if(tipo==true){    ///True para abrir otra ventana
    window.open(doc.output('bloburl'))
   }else{
    doc.save(`HT- ${datason.numeroOrden}-${datason.visitas}-${datason.cliente.nombre}.pdf`)
   }
   
   
    //return doc
}



