import React, { useEffect, useState } from 'react'
import { actualizarDatos, getDataList, postDataClientes, postIngreso } from '../api/common'
import { Equiposot } from '../components/Ingresos/Equiposot';
import { Listaequipos } from '../components/Ingresos/Listaequipos';
import { Modales } from '../components/Modales'
import jwt_token from 'jwt-decode';
import Select from 'react-dropdown-select';
import { Listaot } from '../components/Ingresos/Listaot';
import moment from 'moment'
import { Modalcarga } from '../components/Modales/Modalcarga';
import Spinner from '../components/commons/Spinner';
import { Alertassw } from '../components/Alertas/Alertassw';
import { PARENT } from '../constants';
import { Alert } from 'bootstrap';
import { useAuth } from '../hooks/useAuth';
import ResponsiveTable from '../components/commons/ResponsiveTable';
import { useTheme } from '../hooks/useTheme';
import { PdfIngreso } from '../components/Ingresos/PdfIngreso';
import DateEnd from '../components/Ingresos/DateEnd';
moment.locale('es');

export const Ingresos=()=>{
  //manejador de modales
  const { userData } = useAuth();  
    const[estadomodal, setEstadomodal] = useState(false);
    const[estadomodalIn, setEstadomodalIn] = useState(false);
    const { size } = useTheme()
    const[banderalupa1, setBanderaluba1]=useState(true)
    const[banderalupa2, setBanderaluba2]=useState(true)
    const [listadoot, setListadoot]=useState([])
    const [listClient, setClient] = useState([]);         // estado que me permite mostrar la lista de clientes
    
    //funcion para Cargar // este es el estado para guardar todos los clientes 
    const [clientes, setClientes] = useState([]);          // este estado alamcena todos los ususarios registrados en la base de datosla lista de equipos
    const [selectCliente, setUser] = useState([]);        // Este estado almacena todos los datos del cleinte seleccionado
    
    // client representa el filtro a realizar solicita que se devuelva todos los usuarios con rol clientes
    const client = {
      "role.nombre":"Cliente",
      "type":"foreign",
        "populate":[  ]
    }
    const[pestaña, setPestaña]= useState(0);
    const[ordendata, setOrdendata]=useState({})
    const[Orden, setOrden]=useState()
    const[Eqselect, setEqselect]=useState([])

    console.log(ordendata)
    const [bodyingreso, setBodyingreso]=useState({
      equipos:[],
      autor:userData._id,
      fechaIngreso:moment().format(),
      fechaSalida:'',
      fechaSalidaEstimada:DateEnd(12),
      estado:'Abierta',
      numeroOrden:'',
      nameClient:'',
      consecutivo:'',
      observacion:'',
      orden:''
    })
    console.log(bodyingreso)

    function limpiarfiltros(){
      setBanderaluba1(true)
      setBanderaluba2(true)
      setEqselect([])
      setOrdendata({})
      setPestaña(0)
    }
    const HandledataOT=(event)=>{
        //console.log(event.target.value)
        setOrden({
            ...Orden,
            [event.target.name]:event.target.value
        })
    }
      // evento que se produce cuando seleccionamos el cliente
      const onDropdownchange = (value) => { 
        setPestaña(0) 
        setEqselect([])
        setOrdendata({})
         if (value.length>0){        // evento que ocurre cuando se selecciona el cliente con select
          const result = clientes.filter(word => word._id === value[0].value); //aplico filtro para obtener los datos del cliente seleccionado 
         setUser(result[0]);
      } else{
        setBanderaluba1(true)
        setBanderaluba2(true)
        setUser([])
      }
    }
    const buscar=()=>{    //buscar por orden
    setBanderaluba2(false)
    async function getOtdata(){
        const DataOt = await getDataList('Ot',{conditions:{numeroOrden:Orden.numeroOrden, otType:PARENT}})
        let otinfo=DataOt.data.data
        console.log(otinfo)
        if(otinfo.length>0){
            setPestaña(1)
            setOrdendata(otinfo[0])
            setBodyingreso({
              ...bodyingreso,
              numeroOrden:otinfo[0].numeroOrden,
              nameClient:otinfo[0].cliente?.nombre,
              orden:otinfo._id,
              consecutivo:otinfo.ingresos? otinfo.ingresos+1 : 1
            })
        }else{//console.log("Orden no existe")
        }
      }
      getOtdata()
    }

    const buscarot=()=>{     //Buscar por ot
      setBanderaluba1(false)
     if(selectCliente._id) {
      async function getOtdata(){
        const DataOt = await getDataList('Ot',{conditions:{cliente:selectCliente._id, otType:PARENT}})
        let infots=DataOt.data.data.filter((ot)=> ot.estado==='Abierta' || ot.estado==='Programada' || ot.estado==='Solicitud')
        if(DataOt.data.data.length>0){
             setListadoot(infots)
            setEstadomodal(true)
        }else{Alertassw('error','Cliente no tiene ordenes abiertas')}
      }
      getOtdata()
    }else{//console.log('no hay cliente seleccionado')
    }
    }
    const crearingreso=()=>{
      setBodyingreso({
        ...bodyingreso,
        numeroOrden:ordendata.numeroOrden,
        nameClient:ordendata.cliente?.nombre,
        orden:ordendata._id
      })
       //console.log(bodyingreso)
       async function postdataingreso(){
        try{
          setEstadomodalIn(true)
          console.log(bodyingreso)
          const respuesta=await postIngreso(bodyingreso)
          PdfIngreso(respuesta.data.data)
          const otmod=await actualizarDatos('Ot',{...ordendata, ingresos:bodyingreso.consecutivo}, ordendata._id)
            Alertassw('success','Ingreso Creado', 'cliente: '+ ordendata?.cliente?.nombre)
            setEstadomodalIn(false)
            limpiarfiltros()
        }catch{Alertassw('error','No se pudo crear el ingreso')}

       }
      postdataingreso()
    }

    useEffect(() => {
      async function postClientes(){
       const clientesDB = await postDataClientes(client);
       //console.log(clientesDB.data.data)
         const cl = clientesDB.data.data.map((client)=>({
          value:client._id,
          label:client.nombre
      }));
      setClientes([...clientesDB.data.data])
      setClient(cl)
      }
      postClientes()
  },[])

  const observacionin=(e)=>{
    setBodyingreso({
      ...bodyingreso,
      observacion:e.target.value
    })
  }
  return (
    <div>
      <Modalcarga 
        estadoIn={estadomodalIn} 
        cambiarestadoIn={setEstadomodalIn}
        >
          <Spinner />
      </Modalcarga>
      <Modales
        estado={estadomodal} 
        cambiarestado={setEstadomodal}
        titulo={`${'Ordenes del cliente : '+ selectCliente?.nombre }`}
       >
        <Listaot bodyingreso={bodyingreso} setBodyingreso={setBodyingreso} estado={estadomodal} cambiarestado={setEstadomodal} setOrdendata={setOrdendata} listadoot={listadoot} setPestaña={setPestaña}/>
      </Modales>

        <div className='cabecera'>
        <label className='titulo'>Nuevo ingreso de equipos</label>
      </div>
     
        <div className={`${size.width<800?"col-1-ingreso":"Col-5-Ingreso"}`}>
            <div className='col-1-ingreso'>
              <label htmlFor='numeroOrden'>No Propuesta:</label>
              <div className='col-2-ingreso'>
                {banderalupa1===true?(<input onChange={HandledataOT} className='userselect' name = "numeroOrden" placeholder='No'/>):<label className='cuadro'>{ordendata?.numeroOrden}</label>}
                {banderalupa1===true?(<i className="bi bi-search userselect seach" onClick={buscar}></i>):<></>}
              </div>
            </div>
            <div className='col-1-ingreso'>
              <label  htmlFor='contacto'>Cliente :</label>
              <div className='col-2-ingreso'>
                {banderalupa2===true?(<Select  className='selector' searchable='true' onChange={onDropdownchange} options={listClient}/>):<label className='cuadro'>{ordendata?.cliente?.nombre}</label> }
                {banderalupa2 ===true?(<i className="bi bi-search userselect seach" onClick={buscarot}></i>):<></>}
              </div>
            </div>
            <div className='col-1-ingreso'>
              <label  htmlFor='contacto'>Contacto :</label>
              <div className='cuadro' >{ordendata?.cliente?.contacto}</div>
            </div>
            <div className='col-1-ingreso'>
              <label   htmlFor='fechaEntrega'>Fecha Ingreso :</label>
              <div className='cuadro'>{moment().format("YYYY/MM/DD")}</div>
            </div>
            <div className='col-1-ingreso'>
              <label   htmlFor='fechaEntrega'>Fecha Entrega Max:</label>
              <div className='cuadro'>{moment(DateEnd(12)).format("YYYY/MM/DD")}</div>
            </div>
            <div className='limpiar'>
                <label className='textlimpiar' onClick={()=>limpiarfiltros()}>Limpiar filtros</label>
              </div>
            <div className='crear-limpiar'>  
              {pestaña===2?(<div ><button className='btn-btn-primary' onClick={crearingreso}>Crear Ingreso</button></div>):<></>}
            </div>
            {pestaña===2?(
               <textarea className='observacioningreso' placeholder='Ingresa las observaciones del ingreso' onChange={observacionin}></textarea>
            ):null}
        </div>
        
        <ResponsiveTable>
             {pestaña===1?(<Equiposot setPestaña={setPestaña} ordendata={ordendata} setOrdendata={setOrdendata} bodyingreso={bodyingreso} setBodyingreso={setBodyingreso} setEqselect={setEqselect} Eqselect={Eqselect}/>):null}
             {pestaña===2?(<Listaequipos Eqselect={Eqselect} />):null} 
             {pestaña===0?(
              <div className='sinorden'>
                <h2 className='textso'>Seleccionar una orden de trabajo</h2>
              </div>
             ):null}
          </ResponsiveTable>
        </div>
  )
}
