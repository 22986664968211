import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Dropdown } from "react-bootstrap";
import { Modales } from '../Modales';
import { GetToken } from './GetToken';



const EngineSettings = () => {


    const[specificModal, setSpecificModal] =  useState(null)
    const [isOpen, setIsOpen]=useState(false)


    const components = {
        getToken: GetToken
    };

    const CustomModal = components[specificModal];

    const setModal = (modal) =>{
        setIsOpen(modal)
       } 

    
    return ( 
    <>
        <Modales  estado={isOpen}  cambiarestado={setIsOpen}>
          <CustomModal onModal={(modal)=>setModal(modal)} />
       </Modales>

        



                <DropdownButton
                                as={ButtonGroup}
                                key='Success'
                                id={`dropdown-variants-Success`}
                                variant={'light'.toLowerCase()}
                                title={
                                <span>
                                    <i className="bi bi-gear icono-link"></i>
                                </span>}
                            >
                            
                                    <Dropdown.Item eventKey="1" onClick={() =>{
                                        setSpecificModal('getToken')
                                        setIsOpen(true)
                                
                                }} >Generar Token</Dropdown.Item>
                            
                            </DropdownButton>
    
    </> );
}
 
export default EngineSettings;