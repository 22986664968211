import React, { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { routeCrear } from '../../routers/IndexRouter';
import { Modales } from '../Modales';
import { RegisterUserForm } from '../users/RegisterUserForm';
import { RutasCrear } from '../../routers/RoutesPermisse';


export const Crear=()=>{
    const { userData } = useAuth() 
    const navigate = useNavigate()
    const[isOpen, setIsOpen]=useState(false)
    const { setUserSelected} = useAuth();  

    
   const setModal = (modal) =>{
    setIsOpen(modal)
    userData({})
    ////console.log(isOpen)
   } 

    return(
        <>
        <Modales  estado={isOpen}  cambiarestado={setIsOpen}>
          <RegisterUserForm onModal={(modal)=>setModal(modal)} />
       </Modales>

    <DropdownButton
        as={ButtonGroup}
        key='Success'
        id={`dropdown-variants-Success`}
        variant={'light'.toLowerCase()}
        title={'Crear'}
      >
        {RutasCrear(userData.role.permisos)?.map(({name,route})=>(
            <div key={name} className='brcrear'>
            <Dropdown.Item eventKey="4" onClick={()=>navigate(route)}>{name}</Dropdown.Item>
            </div>
        ))}
    </DropdownButton>
    </>
    )
}