import React, { useState } from 'react'
import { actualizarDatos, cancelarEquipo } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'

export const  Observacioneq=({infoobservacion, cambiarestado})=> {

    //console.log(infoobservacion)

  return (
    <div>
            <div style={{width:'100%'}}>
                            <h6 >{infoobservacion}</h6>
                    </div>

    </div>
  )
}


export const  ObservacionOT=({ordendata, cambiarestado , setRefresh, refresh, setSpinnerMod})=> {

    const [observacion, setObservación]=useState()
    
    
    const handledata=(event)=>{
      setObservación(event.target.value)
    }

    console.log(observacion)

    const handleSubmit=(e)=>{
      try {
        e.preventDefault()
      async function putItem(){
        setSpinnerMod(true)
          const actOt= await actualizarDatos('Ot',{
            ...ordendata,
            observacion:observacion
          },ordendata._id)
          if(actOt.status===200){
            setSpinnerMod(false)
            Alertassw('toast','Cambio guardado exitosamente')
            setRefresh(!refresh)
            cambiarestado(false)
          }
      }
      putItem() 
      } catch (error) {
        Alertassw('error','Ha ocurrido un error guardando la observacion')
      }
      
    }

  return (
    <div>
            <div style={{width:'100%'}}>
              <form className='col-1-grid w-100' onSubmit={handleSubmit}>
                <div className='w-100'>
                                <textarea  onChange={handledata} name='observaacion' className='titulo w-100' type={'string'}  required defaultValue={ordendata.observacion} ></textarea>
                        </div>
                <button className='btn-btn-primary center-grid ' type='submit'>Guardar Cambios</button>
              </form>
            </div>

    </div>
  )
}