import { useTheme } from "../../hooks/useTheme";


const HorizontalBox = ({ children} ) =>{

    const {size} = useTheme()


    return(
<>        

        <div className="top_half text-center bg-primary">
            <p className="title-1 mt-5">Bienvenido a BIOLAB</p>
        </div>

        <div className="bottom_half  bg-light-gray  ">

        </div>

        <div className="center-all  z-index-1 full-content">
            <div className={`${size.width<800?'w-90':''} horizontal_box bdr`}>
                {children}
            </div>
        </div>

</>

    )
}

export default HorizontalBox;