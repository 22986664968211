import React, { useState } from "react";
import { crearDatos } from "../../api/common";
import { useAuth } from "../../hooks/useAuth";
import Spinner from "../commons/Spinner";
import { Historial } from "../Crearot/Historial";
import { Modalcarga } from "../Modales/Modalcarga";
import FormEquipments from "./FormEquipments";
import HeaderEquipments from "./HeaderEquipments";



const ListEquipments = () =>{
  const { userData } = useAuth();
  const [equipo,setEquipo] = useState({
    cliente:userData?.role?.nombre === "Cliente"?userData?._id:undefined
  })
  const [refresh, setRefresh] = useState(true)
  const [estadomodalIn, setEstadomodalIn ] = useState(false);
  const handledata = (event) =>{
    setEquipo({
      ...equipo,
      [event.target.name]:event.target.value
    })

    //console.log(equipo);
  } 
  const selectitem = (value)=>{
  
    setEquipo({     // aqui almaceno el Id del item seleccionado en el nombre del equipo 
        ...equipo,
        item:value[0].value || '',
        nombreitem:value[0].label || ''
      })

      //console.log(equipo);
  }

  const add = async() => {
    setEstadomodalIn(true)
    const equipoDb = await crearDatos('Equipos',equipo)
    //console.log(equipoDb)
    if(equipoDb.status === 200){
      setRefresh(!refresh)
      setEstadomodalIn(false)
    }
  }

    return(
        <div>

      <Modalcarga 
        estadoIn={estadomodalIn} 
        cambiarestadoIn={setEstadomodalIn}
        >
          <Spinner />
      </Modalcarga>

          <div>
         {/* <div className='cabecera'>
        <label className='titulo'>Equipos</label>
      </div>
          <HeaderEquipments cliente={true} />
          <FormEquipments selectitem={selectitem} handledata={handledata}  add={add} cliente={true} role={userData?.role?.nombre} />

          </div> 
      <div  className="mt-5" >
      <div className='cabecera'>
        <label className='titulo'>Historial de Equipos</label>
      </div>*/}
          <Historial look={true} refresh={refresh} />
        </div>
      </div>
        )
    
}

export default ListEquipments;