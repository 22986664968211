import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es';
import { PdfActa } from '../Pdfs/PdfActa';
import { actualizarDatos, getDataList } from '../../api/common';
import { Alertassw } from '../Alertas/Alertassw';
import { Modalcarga } from '../Modales/Modalcarga';
import Spinner from '../commons/Spinner';
moment.locale('es')

export const ActasList = ()=> {
    const[actasOt, setActasOt]=useState([])
    const[refresh, setRefresh]=useState(false)
    const[spinnerMod, setSpinnerMod]=useState(false)
    
    const  actualizar=(data)=>{
         async function actualizar(){
    try {  
            setSpinnerMod(true)
            const EqActa=data.equipos
            console.log(data)
            data.Equipos=EqActa.map((eqAct)=>({
                equipo:eqAct,
                tipoEntrega:data.entrega,
                fechaIngresado:data.createdAt
            }))
            console.log(data)

            const actaACtuliazado =await actualizarDatos('Actas',{
                ...data,
                Equipos:data.Equipos,
                version:2
            })
            actulizarEqOt(actaACtuliazado.data.data)
            setRefresh(!refresh) 
            Alertassw('success','Actualización correcta')
    } catch (error) {
        Alertassw('error','No se pudo realizar la actulización')
    }
 }

 async function actulizarEqOt(Actadata){
  console.log(Actadata)
  if(Actadata.entrega==='Equipo')
  {
    for(let i=0; i<Actadata.Equipos.length; i++){
      const EqOtACtuliazado =await actualizarDatos('EqInOt',{
        _id:Actadata.Equipos[i].equipo._id,
        fechaSalida:Actadata.createdAt,
     })
    }
  }
  if(Actadata.entrega==='Certificado y Equipo')
  {
    console.log('actualizando certificado y equipo')
    console.log(Actadata)
    for(let i=0; i<Actadata.Equipos.length; i++){
      const EqOtACtuliazado =await actualizarDatos('EqInOt',{
        _id:Actadata.Equipos[i].equipo._id,
        fechaSalida:Actadata.createdAt,
        fechaEntrega:Actadata.createdAt
     })
     console.log(EqOtACtuliazado.data.data)
    }
  }
  if(Actadata.entrega==='Certificado')
  {
    for(let i=0; i<Actadata.Equipos.length; i++){
      const EqOtACtuliazado =await actualizarDatos('EqInOt',{
        _id:Actadata.Equipos[i].equipo._id,
        fechaEntrega:Actadata.createdAt
     })
    }
  }

 }
        actualizar();
    }
    useEffect(() =>{
        async function getOtdata(){
            const Actas =  await getDataList('Actas')
            console.log(Actas)
            setActasOt(Actas.data.data)
        }
        getOtdata()
        setSpinnerMod(false)
    },[refresh])

  return (
    <div>
        <Modalcarga 
          estadoIn={spinnerMod} 
          cambiarestadoIn={setSpinnerMod}>
          <Spinner />
       </Modalcarga>
      <ul className='actatitle cabeceraitem bg-primary'>
        <li>No Acta</li>
        <li>Tipo Entrega</li>
        <li>Entrega</li>
        <li>Fecha Creación</li>
        <li>Autor</li>
        <li><i class="bi bi-file-earmark-arrow-down font_sz"></i></li>
      </ul>
      {actasOt.map(acta=>(
        <ul className='actatitle sombra-button' key={acta._id}>
          <li>{'A-'+acta?.numeroOrden+'-'+acta?.consecutivo}</li>
          <li>{acta.tipo}</li>
          <li>{acta.entrega}</li>
          <li>{moment(acta.createdAt).format('lll') }</li>
          <li>{acta.autor.nombre+acta.autor.apellido}</li>
          {acta.version===1?<li><i onClick={()=>actualizar(acta)} className="bi bi-arrow-clockwise font_sz cursor-p"></i></li>:
          <li><i onClick={(e)=>PdfActa(acta)} class="bi bi-file-earmark-arrow-down font_sz cursor-p"></i></li>}
        </ul>
      ))}
    </div>
  )
}
