import React, { useState } from 'react'
import { actualizarDatos } from '../../api/common';
import { Modales } from '../Modales';
import Spinner from '../commons/Spinner';

export const ConfirmarDel=({index, rol, setModalDel })=> {
    let permisodel=rol.permisos[index]
    let permisostot=rol.permisos;
    const[modalSpinner, setModalSpinner]=useState(false)

    const eleminar=()=>{
        setModalSpinner(true)
        permisostot.splice(index,1)
        console.log(permisostot)
        guardarCambios();

    }
    async function guardarCambios(){
        const result=  await actualizarDatos('Roles',{
            _id:rol._id,
            permisos:permisostot
        })
        console.log(result.data.data)
        setModalSpinner(false)
        setModalDel(false)
    }

  return (
    <div>
        <Modales
        estado={modalSpinner}
        cambiarestado={setModalSpinner}
        >
          <Spinner/>
    </Modales>
        <div>
            <div className='Confirm'><h4>¿Estas seguro que deseas eliminar el permiso: {permisodel}?</h4></div>
            <br></br>
            <button className='btn-btn-secundary w-100' onClick={eleminar}>Aceptar</button>
        </div>
    </div>
  )
}

