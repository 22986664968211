import React, { useEffect, useState } from 'react'
import { getDataList } from '../../api/common'
import { CHILD } from '../../constants'
import { Listarequipos } from './Listarequipos'
import moment from 'moment'
import 'moment/locale/es';
import { Alertassw } from '../Alertas/Alertassw'
import { PdfOt } from '../Ot/PdfOt'

export const HojasDeTrabajo = ({orden}) => {
    const[ordenson,setOrdenson]=useState({})
    const [modalEmail, setModalEmail]=useState()
    const [id, setId] = useState()
    const [datasonSelected, setDatasonSelected] = useState(null);
    const [estadofirma, setEstadofirma]=useState(false)

    const downloadPdf = async(e,ot) =>{
        console.log(ot)
        e.preventDefault();
        Alertassw('toast','Descargando OT espera unos segundos')
        PdfOt(ot,ot.entregado.firma, ot.recibido.firma,false)
      }

      const handleSendEmailOt = async(e,id) =>{
        e.preventDefault()
        setId(id)
        setModalEmail(true)
          }

    console.log(ordenson)

    useEffect( ()=>{
        async function consult(){
            const DataOtson = await getDataList('Ot',{conditions:{numeroOrden:orden, otType:CHILD}})
            setOrdenson(DataOtson.data.data)
        }
        
    consult()
    },[])

  return (
    <div>
        {ordenson?.length > 0 ? (
              <div>
                {ordenson?.map((orden) => (
              
                  <details key={orden._id} >
                        
                    <summary >
                    <table className='table w-100 table-bordered' >
                    <thead className=" table-light">
                      {orden?.estado !== "Abierta" ? (
                        <tr>
                          <th>{orden?.numeroOrden + "-" + orden.visitas}</th>
                          <th>
                            {orden?.responsable?.nombre +
                              " " +
                              orden?.responsable?.apellido}
                          </th>
                          <th>{orden?.estado}</th>
                          <th>{moment(orden?.fechaRealEntrega).format("L")}</th>
                          <th>
                            <i className="bi bi-eye-fill font_sz"></i>
                          </th>
                          {(orden?.estado === "Cerrada" && orden?.entregado?.firma)? (
                            <>
                              <th onClick={(e) => downloadPdf(e, orden)}>
                                <i class="bi bi-file-earmark-pdf"></i>
                              </th>
                              <th
                                onClick={(e) => handleSendEmailOt(e, orden._id)}
                              >
                                {" "}
                                <i class="bi bi-envelope"></i>{" "}
                              </th>
                            </>
                          ) : (
                            <><th onClick={()=>{
                              setDatasonSelected(orden)
                              setEstadofirma(true)    
                            }}>
                                <i class="bi bi-pen"></i>
                              </th>
                              <th onClick={(e) => downloadPdf(e, orden)}>
                                <i class="bi bi-file-earmark-pdf"></i>
                              </th>
                              <th>
                               </th></>
                          )}
                        </tr>
                      ) : null}
                      </thead>
                      </table>
                    </summary>
                    
                    <div className="detalle_style">
                      <Listarequipos
                        listado={orden.equipos}
                        type={"vereqson"}
                      />
                    </div>
                  </details>
                 
                  
                ))}
              </div>) :<h1>No se encontró la orden de trabajo</h1>}
    </div>
  )
}


