import { useState } from "react";
import { sendEmailOt } from "../../api/sign";

const Email = ({ id,setModal }) => {
  const [emails, setEmail] = useState([]);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [error, setError] = useState(null);

  const handleAddEmail = (e) => {
    e.preventDefault();
    setEmail([...emails, currentEmail]);
    setCurrentEmail("");
  };

  const timeOut = () =>{
    setTimeout(() => {
        setError(null);
      }, 3000);
  }
  const deleteEmail = (index) => {
    const  temp = [...emails]
    temp.splice(index,1);
    setEmail(temp);
  };

  const handleSendEmailOt = async (e) => {
    e.preventDefault();
    if(emails.length < 1) { 
        setError("Debe indicar al menos un destinatario")
        timeOut();
        return
    }
    try {
      await sendEmailOt(id, {
        emails,
      });
      setModal(false)
    } catch (error) {
      setError("Error al enviar correos");
     
    }
    timeOut();
  };
  return (
    <div className="fix-600">
      {error && <div className="error mb-3">{error}</div>}
      <div className="border-gray flex w-100 wrap">
        {emails.length > 0 &&
          emails.map((email, index) => (
            <div className="border-gray br-5 m-1  pr-2 pl-2 flex">
              {email}{" "}
              <div className="m-1 close-component" onClick={ ()=> deleteEmail(index) }>
                x
              </div>
            </div>
          ))}
        <form onSubmit={handleAddEmail}>
          <input
            className="not-outline no-border ml-2"
            type="email"
            value={currentEmail}
            onChange={(e) => setCurrentEmail(e.target.value)}
          />
        </form>
      </div>

      <button className="btn-btn-primary" onClick={handleSendEmailOt}>
        Enviar Correo
      </button>
    </div>
  );
};

export default Email;
