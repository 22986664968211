import { request } from "../libs/http/http"
import axios from "axios";

export const loginUser = (data) =>{
    return request('post','/usuarios/login',data);
}

export const sendLoginVerification = ( data ) =>{
    return request('post','/usuarios/sendEmailToLogin',data);
}

export const changeUserStatus = (data) =>{
    return request('put','/usuarios/changeStatus',data)
}


export const sendVerificationCode = ( data ) =>{
    return request('post','/usuarios/validateCodeLogin',data);
}


export const adminCreate = (data) =>{
    return request('post','/usuarios/crear/admin',data)
}

export const activateAccount = (token ) =>{
    return request('get',`/usuarios/activation?token=${token}`,{},{} )
}


export const changePassword = (data,token) =>{
    axios.defaults.headers.common.jwt_token  = token   
   return request('post', '/usuarios/changePassword',data,{
    jwt_token:token,
    Accept: 'application/json',
    'Content-Type': 'application/json'
   })
}

export const recoverPassword = (data,token) =>{
    axios.defaults.headers.common.jwt_token  = token   
   return request('post', '/usuarios/recoverPassword',data,{
    jwt_token:token,
    Accept: 'application/json',
    'Content-Type': 'application/json'
   })
}

export const sendRecoverPassword = (data) =>{
   return request('post', '/usuarios/sendEmailToRecover',data)
}

export const renewToken = (data) => {
    return request('post',`/usuarios/renewToken`,data)
}

export const getTokenToExternal = () => {
    return request('get',`/usuarios/tokenToExternal`,{})
}

export const postTokenToExternal = () => {
    return request('post',`/usuarios/tokenToExternal`,{})
}