import { useContext, useEffect, useState } from "react";
import { getDataList, getDataObtenerUno } from "../../api/common";
import { useAuth } from "../../hooks/useAuth";
import { BarChar } from "./BarChart";
import { CardDashboard } from "./CardsDashboard";
import { CircularChart } from "./CircularChart";
import UserContext from "../../context/User/UserContext";


export function Dashboard() {
  const { userData } = useAuth();
  const [data, setData] = useState({})
  const { setUserData } = useContext(UserContext);
  const {allOt, allEquipos, allClientes, OtOpenClose, clientesByZone} = data;

  const getDataDashBoard = async() => {
    const {data} = await getDataList('dashboard');
    setData(data);
    console.log(data);
  }

  useEffect( () => {
    getDataDashBoard();
  }, []);

    return(
      <>
      {userData.role.nombre!=='Cliente'?
        <div className="dashboard-container-main">
          <div className="dashboard-group">
            <CardDashboard value={allOt} description={'Total Ordenes'}/>
            <CardDashboard value={allClientes} description={'Total Clientes'}/>
            <CardDashboard value={allEquipos} description={'Total Equipos'}/>
          </div>

          <div className="dashboard-group">
            <CircularChart labels={['Abiertas', 'Cerradas', 'Programada', 'Solicitud', 'Revisión', 'Entrega', 'Facturar']} data={OtOpenClose} title={'Estado de Ordenes'}/>
            <BarChar title={'Clientes por zonas'} data={clientesByZone}/>
           {/* <BarChar />*/}
          </div>

          {/*<div className="dashboard-list">
          <div>
            <ul className="col-8ot cabeceraitem ">
              <li> No </li>
              <li> Cliente </li>
              <li> Autor </li>
              <li> Fecha inicio calibración </li>
              <li> Responsable </li>
              <li> Estado </li>
              <li> Avance </li>
              <li>
                <i className="bi bi-eye-fill"></i>
              </li>
            </ul>
            {oT.results?.length === 0 ? (
              <div>No hay Ordenes Creadas</div>
            ) : oT.results?.length > 0 ? (
              oT.results.map((ot) => (
                <div key={ot._id}>
                  <details>
                    <summary className="colorot">
                      <ul className="col-8ot sombra-button">
                        <li> {ot.numeroOrden} </li>
                        <li> {ot.equipos[0]?.equipo?.cliente?.nombre}</li>
                        <li>
                          {" "}
                          {ot.coordinador
                            ? ot.coordinador?.nombre +
                              " " +
                              ot.coordinador?.apellido
                            : "No Asignado"}{" "}
                        </li>
                        <li>
                          {" "}
                          {ot?.fechaEntrega
                            ? moment(ot?.fechaEntrega).format("L")
                            : "No definido"}{" "}
                        </li>
                        <li>
                          {" "}
                          {ot.responsable?.nombre
                            ? ot.responsable?.nombre +
                              " " +
                              ot.responsable?.apellido
                            : "No definido"}
                        </li>
                        <li> {ot.estado} </li>
                        <li> {`${Math.round(ot.avance * 100, 3)}%`}</li>
                        <li>
                          {ot.observacion ? (
                            <i className="bi bi-eye-fill font_sz"></i>
                          ) : null}
                        </li>
                      </ul>
                      <hr className="linea" />
                    </summary>

                    <div className="detalle_style">
                      <ul className="col-8eq sombra-button bg-gray">
                        <li>Item</li>
                        <li>Marca</li>
                        <li>Modelo</li>
                        <li>Serie</li>
                        <li>Inventario</li>
                        <li>Ubicacion</li>
                        <li>Consecutivo</li>
                        <li>Estado</li>
                      </ul>
                      {ot.equipos &&
                        ot.equipos.map((eq) => (
                          <div key={eq._id}>
                            <ul className="col-8eq sombra-button">
                              <li>{eq.equipo?.item.nombre}</li>
                              <li>{eq.equipo?.marca}</li>
                              <li>{eq.equipo?.modelo}</li>
                              <li>{eq.equipo?.serie}</li>
                              <li>{eq.equipo?.inventario}</li>
                              <li>{eq.equipo?.ubicacion}</li>
                              <li>{eq.consecutivo}</li>
                              <li>{eq.estado}</li>
                            </ul>
                          </div>
                        ))}
                      <div>
                        <button
                          className="btn-btn-primary mg-left-90"
                          onClick={() => funciones[ot.estado](ot)}
                        >
                          {estados[ot.estado]}
                        </button>
                        <button
                          className="btn-btn-secundary"
                          onClick={() => seguimiento(ot.numeroOrden)}
                        >
                          Seguimiento
                        </button>
                      </div>
                    </div>
                  </details>
                </div>
              ))
            ) : (
              <Spinner />
            )}

            {oT.results?.length > 0 && (
              <Pagination
                prev={oT.prev}
                next={oT.next}
                total_pages={oT.total_pages}
                changePage={changePage}
                page={page}
              />
            )}
          </div>
            </div>*/}
            
        </div>:
        <div>
          <h6>No tienes permiso</h6>
            <i class="bi bi-slash-circle"></i>
        </div>
        }
        </>
    )


}