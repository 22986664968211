import React, { useEffect, useState } from 'react'
import Select from 'react-dropdown-select';
import { crearDatos, getDataList, postDataClientes } from '../../api/common';
import { useAuth } from '../../hooks/useAuth';
import { Alertassw } from '../Alertas/Alertassw';
import { Crear } from '../Botones/Crear';




export const Cotizacion=()=>{

    const[ac, setAc]=useState(false)
    const { userData } = useAuth();
    const[listeq, setListeq]=useState([])
    const[datacliente, setDatacliente]=useState({})
    const[clientes, setClientes]=useState({})
    const[items, setItems]=useState({})
    const[listClient, setListclient]=useState({})
    const [selectCliente, setSelectcliente] = useState([]);        // Este estado almacena todos los datos del cleinte seleccionado
    const[seleItem, setSeleItem]=useState({ })
    const[bodyCot, setBodyCot]=useState({
      cliente:'',
      items:[],
      observacion:'',
      autor:userData._id
    })
    //console.log(bodyCot)

    const dataUsers = {
        "role.nombre":"Metrologo",
        "type":"foreign",
         "populate":[]
    }
    const client = {
        "role.nombre":"Cliente",
        "type":"foreign",
         "populate":[]
    }

    const selClient=(value)=>{
      //console.log(value)
      if(value.length>0){
        setBodyCot({
          ...bodyCot,
          cliente:value[0].value
        })
      }else{
        setBodyCot({
          cliente:''
        })

      }


    }


    useEffect(() => {
        async function postClientes(){
         const clientesDB = await postDataClientes(client);
         const metrologos = await postDataClientes(dataUsers);
         //console.log(clientesDB.data.data)
          const itemDB = await getDataList('Items');
            ////console.log(clientesDB.data.data)
            const itemlist = itemDB.data.data.map((listitems)=>({
              value:listitems._id,
              label:listitems.nombre
            }))
         // //console.log(clientesDB.data.data)
           const cl = clientesDB.data.data.map((client)=>({
            value:client._id,
            label:client.nombre
        }));
        setItems(itemlist)
        setClientes([...clientesDB.data.data])
        setListclient(cl)
        }
        postClientes()
        if(userData?.role?.nombre==='Cliente'){
          setBodyCot({
            ...bodyCot,
            cliente:userData._id
          })
        }
    },[])

      const add=(e)=>{
        e.preventDefault()
          const filt=listeq.filter((eq)=>eq.item===seleItem.item)
          if(filt.length>0){
          for(let i=0; i<listeq.length; i++){
            if(listeq[i].item===seleItem.item){
              let cantidad=parseInt(listeq[i].cantidad)
              let sumar=parseInt(document.getElementById("inputcant").value)
              listeq[i].cantidad = cantidad+sumar
              setAc(!ac)
            }
          }
        }else{
          seleItem.cantidad=document.getElementById("inputcant").value
          if(seleItem.item && seleItem.cantidad){
            setListeq([
              ...listeq,
              seleItem
            ])
          }else{
            Alertassw('error','Falta información')
          }
        }
      }
      const selectitems=(value)=>{
        //console.log(value)
        if(value.length>0){
        setSeleItem({
          ...seleItem,
          nombreItem:value[0].label,
          item:value[0].value
        })
      }else{
        setSeleItem({
          ...seleItem,
          nombreItem:'',
          item:''
        })
      }
    }
    const del = (index)=>{
      //console.log(index)
      listeq.splice(index,1)
      setListeq([
        ...listeq
      ])
    }
    const enviar= async()=>{
      bodyCot.items=listeq
      //console.log(bodyCot)

      const postcot=await crearDatos('Cotizacion',bodyCot)
      //console.log(postcot.data.data)
      if(postcot.status===200){
        setListeq([])
      }
    }
    return(
        <div>
            <Select  searchable='true' onChange={selClient} options={listClient}/>

          <div className='body-cot'>
            <ul className='col-5-cot cabeceraitem'>
              <li>No</li>
              <li>Ítem/Equipo</li>
              <li>Cantidad</li>
              <li></li>
            </ul>
            {listeq && listeq?.map((eq,index)=>(
              <ul key={index} className='col-5-cot sombra-button'>
                <li>{index+1}</li>
                <li>{eq.nombreItem}</li>
                <li>{eq.cantidad}</li>
                <li onClick={()=>{del(index)}}><label className="bi bi-trash"></label></li>
              </ul>
            ))}
            <form type='Submit' onSubmit={add} className='col-5-cot'>
              <div className='selectItem' ><Select  searchable='true'  onChange={selectitems} options={items}/></div>
              <input type={'number'} id='inputcant' placeholder='Cantidad' required></input>
              <button type='Submit'><i className="bi bi-plus-square"></i></button>
            </form >
          </div>
          <br/>
          <button className='btn-btn-primary' onClick={enviar}>Enviar Solicitud</button>
        </div>
    )
}