import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Seguimiento_item } from './Seguimiento_item'
import { SeguimientoOt } from './Seguimiento_ot'


export const SeguimientoControler=()=>{
    const [searchParams]=useSearchParams()
    const {id}=useParams()
    //console.log(searchParams.get('type'))
    //console.log(id)

  return (
    <div>
      {searchParams.get('type')=='Ot'?(<SeguimientoOt orden={id} />):null}
    </div>
  )
}
