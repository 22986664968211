const DetailUser = ({ user, editUser }) => {
  console.log(user);
  return (
    <div className="border-base mb-2 ">
    <div className="pos-rel">
      <div className="gray-words">Nombre:</div>
      <div>
        {user.nombre} {user.apellido}
      </div>
      <div className="gray-words">Correo:</div>
      <div>{user.correo} </div>
      <div className="gray-words"> Teléfono:</div>
      <div>{user.telefono} </div>
      <div className="gray-words"> Rol:</div>
      <div>{user.role.nombre} </div>

      <button
        className="btn-btn-primary pos-abs bottom-0 right-0 "
        onClick={() => {
          editUser(user);
        }}
      >
        Editar
      </button>
      <div className={`pos-abs right-0 top-0 ${ user.status?.toUpperCase() === "ACTIVO"
                                ? " active-user"
                                : " inactive-user"} `}>{user.status}</div>
</div>

    </div>
  );
};

export default DetailUser;
