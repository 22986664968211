export const termsAndConditions = `

<div>
<strong>BIOLAB</strong> Laboratorio Metrológico SA.S asegura la confidencialidad de la información suministrada por el cliente y los resultados de la prestación del servicio. Con laaceptación de los términos de la propuesta comercial está autorizando al laboratorio el uso y tratamiento de toda la información requerida conforme a la política <strong>EST-DIR-POL-002 </strong>Política de Confidencialidad y Declaración de Imparcialidad. Publicada en la pagina web https://biolabsas.com/index.html Este documento es valido como aceptación de la propuesta comercial u orden de servicio y el digenciamiento del mismo es indispensable para la programación de la prestación del servicio. 
</div>
<br>

 <div class="text-justify">
1. BIOLAB Laboratorio Metrológico S.A.S. se abstiene de declarar conformidad, basado en un análisis riesgo, no obstante, el laboratorio a través de los certificados de calibración emitidos reporta la información necesaria del instrumento bajo calibración, como los datos de los errores y las incertidumbres de medición. Será responsabilidad del cliente evaluar esta información y elimpacto que tenga dentro de sus procesos internos.
</div>
<br>
<div class="text-justify">
2. Es responsabilidad del cliente diligenciar este formato con todos los campos incluyendo la información de ítem(s) a calibrar como: Nombre de Ítem, marca, modelo, serie, código de inventario,ubicación, resolución y observación si aplica 
</div>
<br>
<div class="text-justify">
3. Para el caso de los puntos a calibrar si el cliente no entrega esta información al laboratorio, este calibrará según los puntos estándares en el alcance de acreditación ofertado.
</div>
<br>
<div class="text-justify">
4. Para la magnitud de temperatura el cliente debe especificar los puntos a calibrar teniendo en cuenta el intervalo de trabajo de su ítem. 
</div>
<br>
<div class="text-justify">
5. Cuando el laboratorio sea requerido por ley o autorizado por las disposiciones contractuales, para revelar información confidencial, se debe notificar al cliente o a la persona interesada lainformación proporcionada, salvo que esté prohibido por ley <strong> (ISO IEC/17025:2017 Numeral 4.2.2 ) </strong>.
</div>
<br>
<div class="text-justify">
6. El cliente tiene diez (10) días habiles para realizar alguna observación luego de finalizada la prestación del servicio. 
</div>
<br>
<div class="text-justify"> 
7. Cuando el cliente solicite presenciar el proceso del servicio contratado en las instalaciones de BIOLAB Laboratorio Metrológico S.A.S se le proporcionaran un acceso razonable a las áreas pertinentes.
</div>
<br>
</ul>
`;


export const getLockedAccount = 
`
Tu cuenta ha sido bloqueado por intentos repetitivos de ingreso
para restaurarla es necesario que describas detalladamente tu caso enviando 
un correo a <span class="bold-text title-2"> soportetecnico.tinvaz@gmail.com </span>
o un mensaje de Whatsapp al número  <span class="bold-text title-2"> 3215873582  </span>
`;