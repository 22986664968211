import React, { useEffect, useState } from 'react'
import { actualizarDatos, consultarLoteEq, getDataList, getDataObtenerUno } from '../../api/common'
import { CHILD } from '../../constants'
import { Alertassw } from '../Alertas/Alertassw'
import { Modales } from '../Modales'
import { UploadSign } from './UploadSing'

export const SheetsWork=()=> {

    const [HojasResult, setHojasResult]=useState([])
    const [query, setQuery]=useState("")
    const [Sign1, setSign1]=useState({})
    const [Sign2, setSign2]=useState({})
    const [estadomodal, setEstadoModal]=useState(false)
    const [sign3, setSign3]=useState()
    //console.log(Sign1)
    //console.log(Sign2)
    

    const upload=(ot)=>{
      setSign3(ot)
      setEstadoModal(true)
    }


    const consultar=(e)=>{
        e.preventDefault();
        async function consSheet(){
            console.log('consultando')

            const result = await getDataList ('Ot', {conditions:{$and: 
                [ 
                  {otType:CHILD}, 
                  {estado:'Cerrada'},
                  {recibid:{persona:'kelvin'}} 
                ] 
              }})
              setHojasResult(result.data.data)
    }
        consSheet()
    }

 const modificar=async(firma, ot , text)=>{
  try {
    console.log(ot)
    const signedDocument = await actualizarDatos("Ot",{recibido:{
      firma: firma._id,
      persona: ot?.recibido?.persona || "No registrado",
      cargo: ot?.recibido?.cargo,
      fecha: ot?.fechaRealEntrega
    }},ot._id);

    console.log(signedDocument.data)

    Alertassw('success', `${text} agregada` )
    console.log('asignado 1 firma')
    
  } catch (error) {
    Alertassw('error','Error asignando la firma')
  }

 }

    const Hojas = !query? HojasResult : 

    HojasResult.filter((Hoja)=> Hoja?.recibido?.persona.toLowerCase().includes(query.toLowerCase()))

    useEffect(()=>{
        async function consSign(){
          const signDb1 = await getDataObtenerUno("sign", {
            user: '631f486a7add44d1345d2c9d'
          });

          setSign1(signDb1.data.data)

          const signDb2 = await getDataObtenerUno("sign", {
            user: '631bcc0534af5e98d906c04c',
          });
          setSign2(signDb2.data.data)
        }
        consSign();
    },[])
  return (
    <div>
      <Modales 
      estado={estadomodal}
      cambiarestado={setEstadoModal}
      titulo='Subir firma'
      >
        <UploadSign ot={sign3} cambiarestado={setEstadoModal}/>
      </Modales>

        <form onSubmit={consultar}>
            <button type='submit' className='btn-btn-secundary'>Consultar</button>
        </form>
        <input
          type="text"
          placeholder="Ingresa un dato del item a buscar"
          className="form-control"
          onChange={(e)=> setQuery(e.target.value)}
        />
        <table  className='table w-100 table-bordered '>
              <thead class="cabeceraitem">
                <tr>
                  <th>Ítem</th>
                  <th>Hoja</th>
                  <th >Cliente</th>
                  <th >Entrega</th>
                  <th >fecha</th>
                  <th >Recibido</th>
                  <th >Firma</th>
                  <th >firma R</th>
                  <th >Asignar</th>
                  <th >F Maria T</th>
                  <th >Asignar</th>
                </tr>
              </thead>
              <tbody>
              {Hojas && Hojas?.map((Sheet,index)=>(
                <tr key={Sheet._id}>
                    <td>{index}</td>
                    <td >{Sheet?.numeroOrden+" - "+Sheet?.visitas}</td>
                    <td >{Sheet?.cliente?.nombre}</td>
                    <td >{Sheet?.entregado?.persona}</td>
                    <td >{Sheet?.entregado?.fecha}</td>
                    <td >{Sheet?.recibido?.persona}</td>
                    <td ><img src={Sheet?.recibido?.firma?.sign} width={'150px'} ></img></td>
                    <td ><button className='btn-btn-primary' onClick={()=>upload(Sheet)}> Subir</button></td>
                    <td ><button className='btn-btn-tertiary cursor-p' onClick={()=>modificar(Sign1, Sheet, 'Firma 1')}>OK</button></td>
                    <td ><img src={Sign2?.sign} width={'150px'} ></img></td>
                    <td><button className='btn-btn-tertiary cursor-p' onClick={()=>modificar(Sign2, Sheet, 'Firma 2')}>OK</button></td>
                </tr>))}
              </tbody>
            </table>
    </div>
  )
}

export default SheetsWork
