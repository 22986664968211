import { request } from "../libs/http/http"



export const signDocument = (ot,data) =>{
    const {numeroOrden, visitas, otType} = ot
    return request('post',`/Ot/sign?numeroOrden=${numeroOrden}&visitas=${parseInt(visitas)}&otType=${otType}`,data)
}

export const sendEmailOt = (id,data) =>{
    return request('post',`/Ot/emailOt/${id}`,data)
}