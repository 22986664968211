import { useState } from "react";
import { useEffect } from "react";
import { paginate } from "../../api/paginate";
import { useForm } from "../../hooks/useForm";
import { Pagination } from "../commons/Pagination";
import { Modalcarga } from "../Modales/Modalcarga";
import Spinner from "../commons/Spinner";
import ResponsiveTable from "../commons/ResponsiveTable";
import { actualizarDatos } from "../../api/common";

const EqInOtList = () => {
  const [page, setPage] = useState(1);
  const [eqInOT, setEqInOt] = useState({});
  const [values, handleInputChange, setValues] = useForm({});
  const [loading, setLoading] = useState(false);
  console.log(eqInOT)

  const changePage = (page) => {
    setEqInOt({ ...eqInOT, results: [] });
    setPage(page);
  };

  const submitForm = async (id) => {
    try {
      setLoading(true);
      console.log(values)
      const data = await actualizarDatos("EqInOt", {_id:id,...values}, id);
      console.log(data)
    } catch (error) {
      console.log(error)
    }
    setValues({})
    setLoading(false);
  };

  useEffect(() => {
    async function getEqInOt() {
      const eqInOtDB = await paginate(
        `/EqInOt/paginar?page=${page}&perPage=15`,
        { conditions: {}, sort: {} }
      );
      setEqInOt(eqInOtDB.data.data);
    }
    getEqInOt();
  }, [page]);

  return (
    <div>
      <Modalcarga   estadoIn={loading}>
      
        <Spinner />
      </Modalcarga>
      <ResponsiveTable>
        <div className="w-100 pointer">
          <ul className="cabeceraitem col-6HEQ">
            <li className="item">Equipo</li>
            <li className="item">Marca</li>
            <li className="item">Serie</li>
            <li className="item">Consecutivo</li>
            <li className="item">Orden</li>
            <li className="item">Estado</li>
          </ul>

          {eqInOT.results?.length === 0
            ? "No hay equipos creados"
            : eqInOT.results?.length > 0
            ? eqInOT.results?.map((eq) => (
                <form onBlur={() => submitForm(eq._id)}>
                  <ul key={eq._id} className="col-6HEQ no-border-outline">
                    <li className="item">{eq?.equipo?.item?.nombre}</li>
                    <li className="item">{eq?.equipo?.marca}</li>
                    <li className="item">{eq?.equipo?.serie}</li>
                    <li className="item">
                      <input
                        name="consecutivo"
                        type="text"
                        onChange={handleInputChange}
                        className=" no-border-outline"
                        defaultValue={eq.consecutivo}
                      />
                    </li>
                    <li className="item">{eq.ot}</li>
                    <li className="item">{eq.estado}</li>
                  </ul>
                </form>
              ))
            : null}
        </div>
      </ResponsiveTable>

      <Pagination
        prev={eqInOT.prev}
        next={eqInOT.next}
        total_pages={eqInOT.total_pages}
        changePage={changePage}
        page={page}
      />
    </div>
  );
};

export default EqInOtList;
