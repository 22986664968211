import React, { useState } from 'react'
import { actualizarDatos, cancelarEquipo, getDataList } from '../../api/common'
import Spinner from '../commons/Spinner'
import { Modalcarga } from '../Modales/Modalcarga'
import { Alertassw } from '../Alertas/Alertassw'
import { useAuth } from "../../hooks/useAuth";
import { ActualizarEqOtIni } from '../Ot/actualizarEqOtIni'

export const  Canceleq=({infoOt, iditem, cambiarestado, setEquiposOtIni, refresh,queryson_aprobar})=> {


    const [modalcharge, SetModalcharge]=useState(false)
    const { userData } = useAuth();
    console.log(userData)
    const [datacancel, setDatacancel]=useState({
        equipo:iditem,
        comment:'',
        responsable:userData._id
    })
    const handledata=(event)=>{
        //console.log(datacancel)
        setDatacancel({
            ...datacancel,
            [event.target.name] : event.target.value
      })
    }

    const wait=()=>{
      if(datacancel.comment && datacancel.comment!==''){
        async function putItem(){
          SetModalcharge(true);
          const result = await actualizarDatos('EqInOt',
          {
              _id:iditem,
              estado:'Pendiente',
              observacion:datacancel.comment,
              responsable:userData._id,
              responsableChange:userData,
              visita:infoOt.visitas,
              numeroOrden:infoOt.numeroOrden,
              change:true
              
          },iditem,infoOt._id)
          if(result.status===200){
            const otFather=await getDataList('Ot',queryson_aprobar(infoOt.numeroOrden))
            const Otpadre=(otFather.data.data[0])
            console.log(otFather)
            const ota = await actualizarDatos(
              "Ot",
              { ...Otpadre,
                   totalAplazado: parseInt(Otpadre.totalAplazado)+1
                   },
                Otpadre._id
            )
            console.log(ota)
            if(ota.status===200){
              Alertassw('toast','Cambio registrado exitosamente')
              SetModalcharge(false)
              cambiarestado(false)
              ActualizarEqOtIni(setEquiposOtIni,result.data.data)
              //refrescar(!refresh)
            }
        }
      }
      putItem();
      }else{Alertassw('error','Por favor describe el motivo')}
  }

    const handleSubmit=(e)=>{
        e.preventDefault();
        editarcoment()
        console.log(datacancel)
        async function putItem(){
            SetModalcharge(true)
            const result = await cancelarEquipo('Ot',datacancel,infoOt._id,infoOt.numeroOrden)
            console.log(result) 
            if(result.status===200){
                SetModalcharge(false)
                cambiarestado(false)
                ActualizarEqOtIni(setEquiposOtIni,result.data.updateEqInOt)
            }
        }
        putItem();
    }
     function editarcoment(){
      datacancel.comment="Cancelado : "+ datacancel.comment
      console.log(datacancel.comment)
    }

  return (
    <div>
        <Modalcarga
        estadoIn={modalcharge} 
        cambiarestadoIn={SetModalcharge}
        >
          <Spinner />
        </Modalcarga>
       <form className='col-1-grid w-100' onSubmit={handleSubmit}>
            <div className='w-100'>
                            <p className='color-red'>* Describa el motivo por el cual se realiza la cancelacion del equipo</p>
                            <textarea  onChange={handledata} name='comment' className='titulo w-100' type={'string'}  required ></textarea>
                    </div>
            <button className='btn-btn-primary center-grid ' type='submit'>Cancelar equipo</button>
       </form>
       <button className='btn-btn-secundary center-grid' onClick={wait}>Pendiente</button>
    </div>
  )
}
