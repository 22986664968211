import React, { useState } from 'react'
import { actualizarDatos } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'
import UsuariosSelect from './UsuariosSelect'
import SelectMult from '../SettingNot/SelectMult'

 export const EditarItem=({itemData, cambiarestado , setRefresh, refresh, setSpinnerMod})=> {
    console.log(itemData)
    const[dataAct, setDataAct]=useState({
        _id:itemData._id
    })
    console.log(dataAct)

    const saveChange=(e)=>{
        e.preventDefault()
        try {
            setSpinnerMod(false)
            async function guardarCambios(){
                const result=  await actualizarDatos('Items',dataAct)
                console.log(result)
                Alertassw('charge','Cambio Guardado correctamente')
                setRefresh(!refresh)
                setSpinnerMod(false)
                cambiarestado(false)
            }
            guardarCambios()
        } catch (error) {
          Alertassw('error','Ha ocurrido un error','Intenta nuevamente, si el error persiste reporta el fallo a soporte tecnico') 
          cambiarestado(false);
          setRefresh(!refresh)
          setSpinnerMod(false)
        } 
    }
    const handleData=(event)=>{
     console.log(event)
     setDataAct({
        ...dataAct,
        [event.target.name]:event.target.value
    })
    }
  return (
<form onSubmit={saveChange}>
    <div className='col-1MD'>
                <label className='mg-5-all' htmlFor='nombre'>Nombre del Ítem</label>
                <input  className='mg-5-all' name='nombre' onChange={handleData} type='string' defaultValue={itemData.nombre}></input>
                <label className='mg-5-all' htmlFor='tiempo'>Tiempo/min </label>
                <input  className='mg-5-all' name='tiempo' onChange={handleData} type='number' defaultValue={itemData.tiempo}></input>
                <label className='mg-5-all'  htmlFor='cantidad' >Cantidad/día</label>
                <input  className='mg-5-all' name='cantidad' onChange={handleData} type='number'defaultValue={itemData.cantidad}></input>
                <label className='mg-5-all'  htmlFor='grupo'>Grupo</label>
                <input  className='mg-5-all' name='grupo' onChange={handleData} type='number'defaultValue={itemData.grupo}></input>
                <label className='mg-5-all'  htmlFor='userAutorizados'>Usuarios Autorizados</label>
                <div class="mb-3">
                <SelectMult
                dataDefault={dataAct?.userAutorizados}
                handledata={handleData}
                name='userAutorizados'
                collection={"Usuarios"}
                field={"nombre"}
                baseFilter={{ 
                    "role.nombre": "~Cliente",
                    "type":"foreign",
                    "populate":[] }}
                />
            </div>
                {/*<UsuariosSelect name="userAutorizados"  handledata={handleData}/>*/}
                <label className='mg-5-all'  htmlFor='patrones'>Patrones Autorizados</label>
                
                <label className='mg-5-all'  htmlFor='descripcion'>Descripción</label>
                <textarea  className='mg-5-all' name='descripcion' onChange={handleData} type='string' defaultValue={itemData.descripcion}></textarea>
                <button className='btn-btn-primary w-100 mg-20-top' type='submit' >Guardar cambios</button>
        </div>
</form>

  )
}
