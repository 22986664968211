import React, { useEffect, useState } from 'react'
import { actualizarDatos } from '../../api/common';
import { useAuth } from '../../hooks/useAuth';
import moment from 'moment'
import 'moment/locale/es';
import { Modalcarga } from '../Modales/Modalcarga';
import Spinner from '../commons/Spinner';
import { Alertassw } from '../Alertas/Alertassw';
moment.locale('es')

export const Facturar=({ordendata, setModal, setRefresh, refresh})=> {
    
    const [modalcharge, SetModalcharge]=useState(false)
    const { userData } = useAuth();  
    const[factura, setFactura]=useState({
        autor:userData._id,
        fechaCarga:moment()
    })
    console.log(factura);
    console.log(ordendata);

    const guardar=async(e)=>{
        e.preventDefault()
        SetModalcharge(true)
        const postfact1=await actualizarDatos('Ot',
        {
            factura:[...ordendata.factura, factura ],
            estado:'Cerrada',
        },ordendata._id)

        console.log(postfact1)
        if(postfact1.status===200){
            Alertassw('success','La orden de trabajo se ha cerrado exitosamente')
            SetModalcharge(false)
            setModal(false)
            setRefresh(!refresh)
        }
    }

  return (
    <div>
        <Modalcarga
            estadoIn={modalcharge} 
            cambiarestadoIn={SetModalcharge}
            >
            <Spinner />
        </Modalcarga>
        <form type='Submit' className='col-1MD' onSubmit={guardar}>
        <div>
            <h3>¡Felicidades!</h3>
            <h4>Estas a un clic de cerrar la orden de trabajo</h4>
        </div>
        <button type='Submit' className='btn-btn-primary w-100 mg-20-top'>Aceptar</button>
        </form>
    </div>

  )
}
