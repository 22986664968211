import React from "react";




const Spinner = () =>{
    return(
        <div className="w-100 center-all h-50">
            <div className="spinner">
            </div>
        </div>
        );
}

export default Spinner;