import React, { useState } from "react";
import { Navegacion } from "./Navegacion";
import { Sidebar } from "./Sidebar";
import ContentRouter from "../routers/ContentRouter";
import { useTheme } from "../hooks/useTheme";
import { Modales } from "../components/Modales";
import RenewToken from "../components/commons/RenewToken";
export const Home = () => {
  const { isOpenSide } = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  console.log(process.env)
  setInterval(() => setIsOpen(true), process.env.REACT_APP_renewTokenTime);

  return (
    <>
      <Sidebar />
      <Navegacion />
      <div className={`main_container ${isOpenSide ? "pd-25" : "pd-5"}`}>
        <ContentRouter />
      </div>

      <Modales
        estado={isOpen}
        cambiarestado={setIsOpen}
        titulo={"Actualizar Sesión"}
      >
        <RenewToken setModal={setIsOpen} />
      </Modales>
    </>
  );
};
