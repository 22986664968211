import React, { useEffect, useState } from 'react'
import { obtenerSede } from './ObtenerSedes';
import { IndicadoresOt, contarEquipos } from './IndicadoresOt';
import { ChartComponent } from './GraficoItemsOt';
import { Modales } from '../Modales';

let indicadores={}
let sedes=[]

const Cabecera = ({ots, selectSede, sedeSeleted, setSitioSeleted, setSedes}) => {
  const [sedeSelect, setSedeSelect]=useState()
  const [estadomodal, setEstadomodal]=useState(false)
  const [DataChart, setDataChart]=useState([])
  const [showChart, setShowChart] = useState(false); // Estado para controlar la visibilidad del gráfico

    const handleToggleChart = () => {
    const resulta=contarEquipos(ots?.equipos)
    setDataChart(resulta)
    setShowChart(!showChart); // Alternar el estado del gráfico
  };

useEffect(()=>{
    if(ots?.equipos){
      indicadores =IndicadoresOt(ots?.equipos)
      sedes=obtenerSede(ots?.equipos)
      setSedes(sedes)
  }
},[ots])

const handleSelectChange=(e)=>{
    if(e.target.value=="todas"){
        selectSede()
        setSedeSelect()
    }else{
        const sedeFilter =sedes.filter((item)=> item._id==e.target.value)
        setSedeSelect(sedeFilter[0])
        selectSede(e.target.value)
    } 
}

const handleSelectSitio=(e)=>{
    console.log(e)
    if(e.target.value=="todos"){
      setSitioSeleted()
    }else{
      setSitioSeleted(e.target.value)
    }
    
}
    return (
      <>
      <div className="container-no-padding">
        <div className="row">
          {/* Columna Izquierda */}
          <div className="col-md-8 border-end shadow p-3">
            <h5 className="mb-3">Orden de trabajo  {ots?.numeroOrden}-{ots?.visitas}</h5>
            <hr className="mb-3" />
            <div className="row">
              <div className="col-md-6">
                {/* Componente de cliente */}
                <div className="mb-0 d-flex align-items-center ">
                  <label htmlFor="clienteNombre" className="form-label fw-bold me-2 m-0">Cliente:</label>
                  <p className="mb-0">{ots?.cliente?.nombre}</p>
                </div>
                <div className="mb-0 d-flex align-items-center ">
                  <label htmlFor="NitNombre" className="form-label fw-bold me-2 m-0">Nit:</label>
                  <p className="mb-0">{ots?.cliente?.identificacion}</p>
                </div>
                <div className="mb-0 d-flex align-items-center">
                  <label htmlFor="CiudadNombre" className="form-label fw-bold me-2 m-0">Ciudad:</label>
                  <p className="mb-0">{sedeSelect?.ciudad || ots?.cliente?.ciudad}</p>
                </div>
                <div className="mb-0 d-flex align-items-center m-0">
                  <label htmlFor="clienteNombre" className="form-label fw-bold me-2 m-0">Sede:</label>
                  <p className="mb-0">{sedeSelect?.nameSede || 'Todas'}</p>
                </div>
                {/* Otros campos del cliente */}
              </div>
              <div className="col-md-6">
                {/* Componente de persona de contacto */}
                <div className="mb-0 d-flex align-items-center m-0">
                  <label htmlFor="clienteNombre" className="form-label fw-bold me-2 m-0">Dirección:</label>
                  <p className="mb-0">{sedeSelect?.dirSede || ots?.cliente?.direccion}</p>
                </div>
                <div className="mb-0 d-flex align-items-center">
                  <label htmlFor="contactoNombre" className="form-label fw-bold me-2 m-0">Contacto:</label>
                  <p className="mb-0">{sedeSelect?.contSede || ots?.cliente?.contacto}</p>
                </div>
                <div className="mb-0 d-flex align-items-center">
                  <label htmlFor="contactoTelefono" className="form-label fw-bold me-2 m-0">Teléfono:</label>
                  <p className="mb-0">{sedeSelect?.celContacto || ots?.cliente?.telefono}</p>
                </div>
                <div className="mb-0 d-flex align-items-center">
                  <label htmlFor="contactoEmail" className="form-label fw-bold me-2 m-0">Correo:</label>
                  <p className="mb-0" style={{overflowWrap:'break-word'}}>{ots?.cliente?.correo}</p>
                </div>
              </div>
              <div className="col-md-12">
              <hr className="my-3" />
            </div>
              {/* Selects */}
              <div className="col-md-6">
                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="selectSede" className="form-label fw-bold me-2">Selecciona la sede:</label>
                  <select id="selectSede" className="form-select" onChange={handleSelectChange}>
                  {sedes && sedes?.map(sede => (
                    <option key={sede._id} name={sede.nameSede} value={sede._id}>{sede.nameSede}</option>
                  ))}
                    <option value={"todas"}>Todas</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="selectOpciones" className="form-label fw-bold me-2" >Lugar de Calibración:</label>
                  <select id="selectOpciones" className="form-select" onChange={handleSelectSitio}>
                    <option value={"todos"}>Todos</option>
                    <option value="Sitio">Sitio</option>
                    <option value="Laboratorio">Laboratorio</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* Columna Derecha */}
          <div className="col-md-4 border-start shadow p-3">
          <h5 className="mb-3">Indicador General</h5>
          <hr/>
          {indicadores?.Finalizados>0?
          <> <p className="mb-0">Finalizados</p>
                <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" style={{ width: `${indicadores?.Finalizados}%`}} aria-valuenow="29" aria-valuemin="0" aria-valuemax="100">{indicadores?.Finalizados}%</div>
          </div></> : null }
          {indicadores?.No_finalizados>0 ? 
              <>
                <p className="mb-0">No Finalizados</p>
                <div class="progress">
                    <div class="progress-bar bg-secondary" role="progressbar" style={{ width: `${indicadores?.No_finalizados}%` }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">{indicadores?.No_finalizados}%</div>
                </div>
              </>:null}
          {indicadores?.Procesados>0?
              <>
                <p className="mb-0">Procesados</p>
                <div class="progress">
                    <div class="progress-bar bg-info" role="progressbar" style={{ width: `${indicadores?.Procesados}%` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">{indicadores?.Procesados}%</div>
                </div>
              </>:null}
          {indicadores?.Pendientes>0?
              <>
                <p className="mb-0">Pendientes</p>
                <div class="progress">
                    <div class="progress-bar bg-warning" role="progressbar" style={{ width: `${indicadores?.Pendientes}%` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">{indicadores?.Pendientes}%</div>
                </div>
              </>:null}
          {indicadores?.Cancelados>0?
              <>
                <p className="mb-0">Cancelados</p>
                <div class="progress">
                    <div class="progress-bar bg-danger" role="progressbar" style={{ width: `${indicadores?.Cancelados}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">{indicadores?.Cancelados}%</div>
                </div>
              </>:null}
          {indicadores?.Aprobados>0?
              <>
                <p className="mb-0">Aprobados</p>
                <div class="progress">
                    <div class="progress-bar bg-primary" role="progressbar" style={{ width: `${indicadores?.Aprobados}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">{indicadores?.Aprobados}%</div>
                </div>
              </>:null}
              <hr/>
              <div>
              {/* Indicadores y enlace "Ver más" */}
              <a className='cursor-p' onClick={handleToggleChart}>
                {showChart ? 'Ver menos' : 'Ver más'}
              </a>
            </div>
          </div>
        </div>
      </div>
      {showChart && <ChartComponent data={DataChart} />}
      </>
    );
  }
  
  export default Cabecera;
  
  
  
  



