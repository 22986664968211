import { Alertassw } from '../Alertas/Alertassw'


export const VerifiedSerialList = (listado,equipo) => {
   let repetido=[]
  if(equipo.serie!==""){
    repetido=listado.filter(item=> item.serie===equipo.serie)  
  if(repetido.length>0){
    Alertassw('error','Serial Repetido','Ya existe un equipo listado con la misma serie, modifica y vuelve a intentarlo')
  }
}
  return (repetido)
}

