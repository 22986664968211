import React, { useEffect, useState } from 'react'
import {actualizarDatos, actualizarEqOt, getDataList } from '../../api/common'
import Spinner from '../commons/Spinner'
import { Modalcarga } from '../Modales/Modalcarga'
import Select from 'react-dropdown-select'
import { Alertassw } from '../Alertas/Alertassw'
import { ActualizarEqOtIni } from '../Ot/actualizarEqOtIni'
import SelectSearch from '../commons/SelectSearch'
import SelectConsult from '../commons/SelectConsult'

export const  Editareq=({infoOt, infoequipo, cambiarestado, refresh, refrescar, origen, setEquiposOtIni})=> {

    const [modalcharge, SetModalcharge]=useState(false)
    const [sedesClient, SetSedesClient]=useState()
    const[sedeOn, setSedeOn]=useState(true) //Estado para quitar el select
    const equipo=infoequipo.equipo? infoequipo.equipo: infoequipo
    console.log(equipo)
    const [infoedit, setInfoedit]=useState({
        _id:infoequipo?.equipo? infoequipo?.equipo?._id : infoequipo._id
    })
 
    console.log(infoedit)
    const selectSede = (value)=>{
        console.log(value);
        equipo.sede=(value[0].label)
        equipo.sedeClient=(value[0].value)
        setSedeOn(true);
        if(value[0].value){
          setInfoedit({
            ...infoedit,
            sede:value[0].label,
            sedeClient:value[0].value
          })
        }
      }

    const handleSubmit=async(e)=>{
            e.preventDefault();
            async function putItem(){
                try {
                    SetModalcharge(true)
                        const result= origen==='historial'? 
                        await actualizarDatos('Equipos',infoedit,infoequipo.equipo._id):
                        await actualizarEqOt('Equipos',infoedit,infoequipo._id,infoOt? infoOt?.numeroOrden:'') 
                        console.log(result) 
                    if(result.status===200){
                        Alertassw('success','Cambio registrado correctamente')
                        SetModalcharge(false)
                        cambiarestado(false)
                        if(infoequipo.equipo){
                            console.log('actualizando array de equipos: ',result.data.data)
                            console.log('equipo a editar:',infoequipo)
                            ActualizarEqOtIni(setEquiposOtIni,result.data.data)
                        }else{
                           refrescar(!refresh)
                        }
                    }
                } catch (error) {
                    SetModalcharge(false)
                    if(error.response.data.error.message==='EQUIPOS EXISTENTES'){
                        Alertassw('error','Serial Repetido', 'Hay un equipo creado con el mismo serial, modifica y vuelve a intentarlo')
                    }
                }
            }
           putItem();
    }
   const handledata=(event)=>{
    if(event.target.value || event.target.name=='serie'){
    setInfoedit({
        ...infoedit,
        [event.target.name]:event.target.value
    })
    }else{
        //eliminar campode infoedir
        // Clonar el objeto actual sin la propiedad vacía
        const { [event.target.name]: _, ...newInfoedit } = infoedit;
        console.log('nuevo objeto', newInfoedit)
        setInfoedit(newInfoedit);

    }
   }
       //Solicito el listado de sedes pertenecientes al cliente 
       useEffect(()=>{
        console.log('hola mundo')
        async function  consultarOt(){
            const sedesDB = await getDataList("Sedes",{cliente:infoequipo.equipo.cliente._id});
            const sedesClientemap=sedesDB.data.data.map((sedes)=>({
                value:sedes._id,
                label:sedes.nameSede
              }))
            SetSedesClient(sedesClientemap)
          }
          consultarOt()
        },[sedeOn])
  return (
    <>
        <Modalcarga
        estadoIn={modalcharge} 
        cambiarestadoIn={SetModalcharge}
        >
          <Spinner />
        </Modalcarga>
       <form className='form_container' onSubmit={handleSubmit}>
            <div className="form-group w-100 px-4">
                <label className='form_group_label' htmlFor='marca'>Ítem:</label>
                <SelectConsult
                    dataDefault={equipo?.item.nombre}
                    handledata={handledata}
                    name='item'
                    collection={"Items"}
                    field={"nombre"}
                    baseFilter={{ 
                        "magnitud.consecutivo": `${equipo.item?.magnitud.consecutivo}`,
                        "type":"foreign",
                        "populate":[] }}
                    />
            </div>
            <div className='form_group'>
                <label className='form_group_label' htmlFor='marca'>Marca:</label>
                <input onChange={handledata} name='marca' className='titulo' type={'string'}  defaultValue={equipo?.marca} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='modelo'>Modelo: </label>
                <input onChange={handledata} name='modelo' className='titulo' type={'string'}  defaultValue={equipo?.modelo} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='serie'>Serie:</label>
                <input onChange={handledata} name='serie' className='titulo' type={'string'}  defaultValue={equipo?.serie} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='inventario'>Inventario:</label>
                <input onChange={handledata} name='inventario' className='titulo' type={'string'}  defaultValue={equipo?.inventario} />
            </div>
            <div className='form_group '>
            <label className='form_group_label' htmlFor='sede'>Sede:</label>
            {sedeOn===false?(
                 <Select  searchable='true' onChange={selectSede} options={sedesClient} placeholder={"Todas"}/>):
                  <div className='flex-extremos'>
                    <div className='sedeEq cursor-p' onClick={()=>setSedeOn(false)}>{equipo?.sede}</div>
                </div>
              }               
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='unicacion'>Ubicación:</label>
                <input onChange={handledata} name='ubicacion' className='titulo' type={'string'}  defaultValue={equipo?.ubicacion} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='resolucion'>Resolución:</label>
                <input onChange={handledata} name='resolucion' className='titulo' type={'string'}  defaultValue={equipo?.resolucion} />
            </div>
            <div className='form_group'>
                <label className='form_group_label' htmlFor='puntos'>Puntos a calibrar:</label>
                <textarea  onChange={handledata} name='puntos' className='titulo' type={'string'}  defaultValue={equipo?.puntos} ></textarea>
            </div>
                <div className='form_group'>
                    <label className='form_group_label' htmlFor='observacion'>Observacion:</label>
                    <textarea  onChange={handledata} name='observacion' className='titulo' type={'string'}  defaultValue={infoequipo.observacion}></textarea>
                </div>
            <div className='form_group'> 
            <button type='submit'>Guardar cambios</button>
            </div>
        </form>
    </>
  )
}
