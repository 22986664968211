import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as XLSX from 'xlsx';
import { crearLotes } from '../../api/common';
import Swal from 'sweetalert2';
import { Alertassw } from '../Alertas/Alertassw';

const CargaArchivo = ({setEstadoModal, setEstadoIn, setRefresh, refresh}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');
    const [jsonData, setJsonData] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const validFormats = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        if (file && validFormats.includes(file.type)) {
            const reader = new FileReader();
            reader.onload = (e) => {
                console.log('evento e: ', e)
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJsonData(json);
                console.log(json); // Aquí puedes ver el JSON en la consola
            };
            reader.readAsArrayBuffer(file);
            setSelectedFile(file);
            setError('');
        } else {
            setSelectedFile(null);
            setJsonData(null);
            setError('El formato del archivo debe ser .xls o .xlsx');
            document.getElementById('file').value = '';
        }
    };

    const handleFileRemove = () => {
        setSelectedFile(null);
        setJsonData(null);
        document.getElementById('file').value = '';
    };
    const handleSubmit = async(event) => {
      try {
        setEstadoIn(true)
        event.preventDefault();
        if (!selectedFile) {
          setEstadoIn(false)
            setError('Por favor, selecciona un archivo antes de enviar.');
            return;
        }
        const dataSend = await crearLotes('Patrones',jsonData)
        console.log(dataSend);
        setRefresh(!refresh);
        Alertassw('toast','Listado cargado correctamente')
        setEstadoModal(false)
        setEstadoIn(false)        
      } catch (error) {
        Alertassw('error','No se pudo cargar el listado', 'Verifica la información e intentalo de nuevo')
        
      }
    };

    const renderTable = () => {
        if (!jsonData || jsonData.length === 0) {
            return null;
        }
        const headers = Object.keys(jsonData[0]);
        return (
            <table className="table table-striped mt-3">
                <thead>
                    <tr>
                        {headers.map((header) => (
                            <th key={header}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {jsonData.map((row, index) => (
                        <tr key={index}>
                            {headers.map((header) => (
                                <td key={header}>{row[header]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="container mt-5">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="file">Carga tu listado</label>
                    <input 
                        type="file" 
                        className="form-control-file" 
                        id="file" 
                        name="file" 
                        onChange={handleFileChange} 
                    />
                </div>
                {error && <div className="alert alert-danger mt-2">{error}</div>}
                {selectedFile && (
                    <div className="d-flex align-items-center mt-2">
                        <span className="mr-2">{selectedFile.name}</span>
                        <button 
                            type="button" 
                            className="btn btn-danger btn-sm" 
                            onClick={handleFileRemove}
                        >
                            Eliminar
                        </button>
                    </div>
                )}
                <button type="submit" className="btn btn-primary mt-3">Enviar</button>
            </form>
            {renderTable()}
        </div>
    );
};

export default CargaArchivo
