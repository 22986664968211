import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveFile } from '../../helpers/firebase';
import { actualizarDatos } from '../../api/common';

export const OtrosDocAdjuntos = ({ show, handleClose, dataPatron, setDataPatron }) => {
  const [bodyDocument, setBodyDocument] = useState({});
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({ name: '', file: '' });

  console.log('Data_Patron: ',dataPatron)
  const handleFileChange = (e) => {
    setFile(e.target);
  };

  const handleNameChange = (e) => {
    setBodyDocument({...bodyDocument,
      nombre: e.target.value
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '', file: '' };

    if (!bodyDocument.nombre) {
      newErrors.name = 'El nombre del documento es obligatorio.';
      valid = false;
    }

    if (!file) {
      newErrors.file = 'Debe seleccionar un archivo.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

 const handleSubmit = async () => {
    if (validateForm()) {
        try {
            // Asegúrate de que `file` esté definido antes de llamar a `saveFile`
            if (!file) {
                throw new Error("No se ha seleccionado ningún archivo para subir.");
            }
            console.log('Archivo a subir:', file);

            // Llamar a saveFile para subir el archivo y obtener el enlace
            const link = await saveFile(file, 'documents');
            if (!link) {
                throw new Error("No se pudo obtener el enlace del archivo subido.");
            }
            alert('Archivo subido con éxito:', link);

            // Aquí puedes implementar la lógica para manejar el enlace del archivo
            // Ejemplo:
            bodyDocument.documento = link;
            // dataPatron.otrosDocumentos.push(bodyDocument);
            // console.log('Documento actualizado:', bodyDocument);
            const actualizarPatron = await actualizarDatos('Patrones', {
                 ...dataPatron,
               otrosDocumentos: [...dataPatron.otrosDocumentos, bodyDocument]
             }, dataPatron._id);
             console.log('Patrón actualizado:', actualizarPatron);
             setDataPatron({...dataPatron,
              otrosDocumentos: [...dataPatron.otrosDocumentos, bodyDocument]
             })

            // Cerrar el modal después de completar el proceso
            handleClose();

        } catch (error) {
            // Captura y maneja cualquier error que ocurra durante el proceso
            console.error("Error durante la actualización:", error);
            alert("Ocurrió un error al procesar la solicitud. Por favor, intenta nuevamente.");
        }
    } else {
        console.log("El formulario no es válido.");
    }
};


  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cargar Documento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formDocumentName">
            <Form.Label>Nombre del Documento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre del documento"
              value={bodyDocument?.nombre}
              onChange={handleNameChange}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFileUpload">
            <Form.Label>Seleccionar Archivo</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              isInvalid={!!errors.file}
            />
            <Form.Control.Feedback type="invalid">
              {errors.file}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!bodyDocument?.nombre || !file} // Deshabilitar si falta algún campo
        >
          Cargar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


