import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { getDataObtenerUno } from "../../api/common"
import { fromQueryParamToFilter } from "../../helpers/helpers"
import { genderDetail } from "../Profile/setting"
import CalibrationHistory from "./CalibrationHistory"
import {useNavigate} from 'react-router-dom'



 const ProfileEquipments = () =>{
    const [ searchParams] = useSearchParams()
    const [equipment, setEquipment] = useState({})
    const [sede, setSede]=useState()
    const navigate = useNavigate()
    console.log(equipment)
    //console.log(Array.from(searchParams.keys))

    useEffect(()=>{
        let params = fromQueryParamToFilter(searchParams)
        async function getEquipment(){
            const equipmentDB = await getDataObtenerUno('Equipos',params)
            console.log(equipmentDB.data.data);
            const sedeEq=equipmentDB.data.data.cliente.sedes.filter(sedeEqui=> sedeEqui.nameSede=== equipmentDB.data.data.sede)
            console.log(sedeEq)
            if(sedeEq.length>0)setSede(sedeEq[0])
            setEquipment(equipmentDB.data.data)
        }
        getEquipment()
        
    },[])
    return(
        <>
        {equipment &&<div className="one-col">
          <div className="two-cols">
                <div className=" border-base">
                <div>
                    <span className="main_text">{equipment.item?.nombre || ''}</span>
                     <span className="role"> {equipment.item?.magnitud?.nombre ||'N/A'} </span>
                </div>
                <div className="two-cols mt-3">
                    <div>
                        <strong>Marca </strong>
                        <div>
                            {equipment.marca || 'No registra marca'}
                        </div>
                    </div>

                    <div>
                        <strong>Modelo </strong>
                        <div>
                            {equipment.modelo || 'No registra marca'}
                        </div>
                    </div>

                    <div>
                        <strong>Serie </strong>
                        <div>
                            {equipment.serie || 'No registra marca'}
                        </div>
                    </div>
                    <div>
                        <strong>Ubicacion </strong>
                        <div>
                            {equipment.ubicacion || 'No suministrado'}
                        </div>
                    </div>
                    <div>
                        <strong>Inventario </strong>
                        <div>
                            {equipment.inventario || 'No Aplica'}
                        </div>
                    </div>
                    <div>
                        <strong>Resolución </strong>
                        <div>
                            {equipment.resolucion}
                        </div>
                    </div>
                    <div>
                        <strong>Puntos </strong>
                        <div>
                            {equipment.puntos || 0}
                        </div>
                    </div>
                    <div>
                        <strong>Último consecutivo </strong>
                        <div>
                            {equipment.consecutivo || 'N/A'}
                        </div>
                    </div>
                    <div><button  onClick={()=>navigate(`/seguimientoEquipo/${equipment._id}`)} className="dark-button" >Seguimiento</button></div>
                    <div></div>
                </div>
                </div>
                <div className=" border-base">
                
                <div className="under_line" onClick={
                    ()=>{navigate(`/perfil?correo=${equipment.cliente?.correo}`)
                }}>  <h5> Informacion Cliente</h5></div>

<div className="two-cols mt-3">
    <div>
        <strong>Nombre</strong>
        <div>
            {equipment.cliente?.nombre || 'No registra Nombre'}
        </div>
    </div>
    <div>
        <strong>Correo</strong>
        <div>
            {equipment.sedeClient?.emailContacto }
        </div>
    </div>
    <div>
        <strong>Ciudad</strong>
        <div>
            {equipment.cliente?.ciudad || 'No registra ciudad'}
        </div>
    </div>
    <div>
        <strong>Sede </strong>
        <div>
            {equipment?.sedeClient?.nameSede}
        </div>
    </div>
    <div>
        <strong>Teléfono </strong>
        <div>
            {equipment?.sedeClient?.celContacto}
        </div>
    </div>

    <div>
        <strong>Contacto </strong>
        <div>
            {equipment?.sedeClient?.contSede}
        </div>
    </div>

    <div>
        <strong>Direccion </strong>
        <div>
            {equipment?.sedeClient?.dirSede}
        </div>
    </div>
    <div>
        <strong>NIT </strong>
        <div>
            {equipment?.cliente?.identificacion }
        </div>
    </div>
    <div>
        <strong>Estado </strong>
        <div>
            {equipment.cliente?.status || 'No registra NIT'}
        </div>
    </div>
</div>

                </div>
          </div>
        
          <div className="border-base one-col">
                <h4>Historia de Calibraciones</h4>

                {equipment?(<CalibrationHistory eq={equipment._id} />):null}
            </div>


        </div>}
        </>)
 }

 export default ProfileEquipments;