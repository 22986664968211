import axios from "axios";
import { getToken } from "../../helpers/helpers"; // Importamos el token almacenado en el local storage

// se definen las cabecer por defecto
const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

// se crea una constante de respuesta que recibe el metodo de la peticion, la ruta, y los datos para hacer las diferentes peticiones con axxios
export const request = async (
  method,
  url,
  payload = {},
  customHeaders = null
) => {
  axios.defaults.headers.common.jwt_token = getToken(); //Asiganamos el token a la cabcera de axxios para hacer la peticion
  const headers = customHeaders ? { ...customHeaders } : { ...defaultHeaders };
  const options = { method, url, headers, data: JSON.stringify(payload) }; // constante que almacena toda la ifnromacion para hacer la peticion a axxios
  const response = await axios({ ...options }); //Peticion se realiza a axxios
  return response;
};

