import React, { createContext, useState } from "react";
import { useEffect } from "react";

const ThemeContext = createContext();

const ThemeState = ({ children }) => {
  useEffect(() => {
    function handleResize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", handleResize);

    return (_) => window.removeEventListener("resize", handleResize);
  });

  const [isOpenSide, setIsOpenSide] = useState(false);
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  return (
    <ThemeContext.Provider
      value={{
        isOpenSide,
        setIsOpenSide,
        size,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
  // Props.children van a ser los componentes que van a estar dentro de Usercontext.provider
  // la propiedad value donde indicamos a que informacion pueden acceder los componentes que estan dentro
};

export default ThemeState;

export { ThemeContext };
