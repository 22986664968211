import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import {
  actualizarDatos,
  actualizarOt,
  crearDatos,
  getDataObtenerUno,
} from "../../api/common";
import { useAuth } from "../../hooks/useAuth";
import { Alertassw } from "../Alertas/Alertassw";
import { Modalfirma } from "./Modalfirma";
import moment from "moment";
import { Modalcarga } from "../Modales/Modalcarga";
import Spinner from "../commons/Spinner";
import { sendEmailOt, signDocument } from "../../api/sign";
import { PdfOt } from "./PdfOt";
moment.locale("es");

export const Firma = ({
  setRecibidoin = null,
  recibidoin = null,
  setEstadofirma,
  estadofirma,
  ot,
  usersign,
}) => {
  const [modalcharge, SetModalcharge] = useState(false);
  const [bandera, setBandera] = useState(usersign);
  const [userSign, setUserSign] = useState(usersign.toString());
  const [signdbdata, setSigndbdata] = useState();
  const [signRecibe, setSingRecibe] = useState({
    recibido: {
      firma: undefined,
      persona: "",
      cargo: "",
      fecha: moment().subtract(5, "hours").format(),
    },
  });

  const { userData } = useAuth();
  console.log(userData)
  const sigCanvas = useRef({});
  const [sign, setSign] = useState(null);

  useEffect(() => {
    async function getSign() {
      const signDb = await getDataObtenerUno("sign", {
        user: userSign === "recibe" ? ot.cliente._id : userData._id,
      });
      console.log(signDb);
      setSigndbdata(signDb);

      if (signDb?.data?.data && sigCanvas?.current?.toDataURL()) {
        limpiar();
        setSign(signDb.data.data._id);
        if(userSign === "entrega") sigCanvas.current.fromDataURL(signDb.data.data.sign);
      }
    }
    getSign();
  }, [estadofirma,userSign]);

  const limpiar = () => {
    sigCanvas.current.clear();
    setBandera("");
  };

  const firmarecibe = async () => {
    if (signRecibe.recibido.persona) {
      SetModalcharge(true);
      const db = await signDataBase();
     // console.log(db);
      if (db.status === 200) {
        signRecibe.recibido.firma = db.data.data._id;
        const ediotson = await actualizarDatos("Ot", signRecibe, ot._id);
        console.log(ediotson);
        if (ediotson.status === 200) {
          limpiar();
          SetModalcharge(false);
          setUserSign("entrega"); //cambio para que la proxima firma sea del metrologo
          //console.log(userSign);
          Alertassw("success", "Firma Guardada");

          if (signdbdata?.data?.data && sigCanvas?.current?.toDataURL()) {
            sigCanvas.current.fromDataURL(signdbdata.data.data.sign);
            setSign(signdbdata.data.data._id);
          }
        } else {
          Alertassw("error", "Error al guardar la firma");
        }
      } else {
        Alertassw("error", "firma no guardada");
      }
    } else {
      Alertassw("error", "Ingresar persona que recibe");
    }
  };

  const firmaentrega = () => {
    firmar();
  };
  const funciones = {
    recibe: firmarecibe,
    entrega: firmaentrega,
  };

  const userrecibe = (event) => {
    signRecibe.recibido.persona = event.target.value;
  };

  const signDataBase = async () => {
    let db;
console.log(sign)
console.log( signdbdata?.data?.data)
    SetModalcharge(true);
    if (!sign ) {
      db = await crearDatos("sign", {
        user: userSign === "recibe" ? ot.cliente._id : userData._id,
        sign: sigCanvas.current.toDataURL(),
      });
    } else {
      db = await actualizarDatos(
        "sign",
        {
          _id: sign,
          sign: sigCanvas.current.toDataURL(),
        },
        sign
      );
    }

    return db;
  };

  const firmar = async () => {
    console.log('firmando')
    //console.log(userData.nombre+" "+userData.apellido)
    try {
      SetModalcharge(true);
      const db = await signDataBase();
      
      if (setRecibidoin && recibidoin) {
        
        setRecibidoin({
          ...recibidoin,
          firma: db.data.data._id,
          persona: userData.nombre+" "+userData.apellido || "No registrado",
          cargo: userData.role.nombre,
          fecha: moment().subtract(5, "hours").format(),
        });
      }

      if(ot &&setRecibidoin && recibidoin ){
        console.log('firmando ot')
        console.log(ot)
        const signedDocument = await actualizarDatos("Ot",{entregado:{
          firma: db.data.data._id,
          persona: userData.nombre + userData.apellido || "No registrado",
          cargo: userData.role.nombre,
          fecha: moment().subtract(5, "hours").format(),
        }},ot._id);
        Alertassw("success", "OT Firmada Exitosamente");
        PdfOt(signedDocument.data.dataReturn,signedDocument.data.dataReturn.entregado.firma, signedDocument.data.dataReturn.recibido.firma,false)
      }

      SetModalcharge(false);
      setEstadofirma(false);
      setBandera(usersign);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modalfirma estadofirma={estadofirma}>
        <>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "firmas",
            }}
          />
          <div>
            <div className="limpiar-name">
              {userSign === "recibe" ? (
                <input
                  type={"text"}
                  placeholder="Recibe a satisfaccón"
                  className="w-100"
                  onChange={userrecibe}
                ></input>
              ) : (
                <input
                  type={"text"}
                  className="w-100"
                  value={userData.nombre + " " + userData.apellido}
                  disabled
                ></input>
              )}
              <button onClick={limpiar} className="btn-limpiar">
                Limpiar
              </button>
            </div>
            <div className="botones">
              <button
                className="btn-btn-tertiary"
                onClick={() => setEstadofirma(false)}
              >
                Cancelar
              </button>
              <button
                className="btn-btn-secundary"
                onClick={() => funciones[userSign]()}
              >
                Firmar
              </button>
            </div>
          </div>
        </>
      </Modalfirma>
      <Modalcarga estadoIn={modalcharge} cambiarestadoIn={SetModalcharge}>
        <Spinner />
      </Modalcarga>
    </div>
  );
};
