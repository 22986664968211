import React, {useEffect, useReducer, useState} from 'react'
import UserReducer from './UserReducer'    //este componente contiene las funciones que queremos ejecutar
import UserContext from "./UserContext"
import axios from 'axios'
import { GET_PROFILE, GET_LOGIN} from '../types'
import jwt_token from 'jwt-decode';
import { getToken } from '../../helpers/helpers'


const UserState =(props) =>{
    // aqui almacenamos el estado del usuario
    const initialState ={
        user:null,
        userSelected:null
    }
    const token = getToken();

    const [userData, setUserData] = useState(null);
    const [preLogin, setPreLogin] = useState(null);
    const [ userSelected, setUserSelected] = useState(null);
    const [ message, setMessage] = useState(null);


      useEffect(()=>{

        async function getUserData(){
          try{
        const user = await jwt_token(token);
        setUserData(user)
      }catch(error){
        //console.log(error)
      }
        }
        getUserData()
      },[setUserData,token])
      

      

    
      
    
      


    return(
        <UserContext.Provider value = {{
            setUserData,
            userData,
            userSelected, 
            setUserSelected,
            message,
            setMessage,
            preLogin,
            setPreLogin    
            }}>
            {props.children}            
        </UserContext.Provider>
    )
    // Props.children van a ser los componentes que van a estar dentro de Usercontext.provider
    // la propiedad value donde indicamos a que informacion pueden acceder los componentes que estan dentro
}

export default UserState; 


