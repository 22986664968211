
import { getDataList } from "../../api/common"
import { ExcelExportHelper } from "./Excelstyle"


async function DateProgram(data) {
    console.log(data)
    const dataChange={
        fechaProgram:'',
        fechaAsigned:''
    }
    const Dataot=data
    console.log(Dataot)

try {
const changes = await getDataList('Changes',{conditions:{object_id:Dataot._id}})
   const result = changes.data.data[0].changes.filter((chang)=> chang.type === 'fields')
   console.log(result)
   for(let j=0; j<result.length; j++){
       //console.log(result[j])
       for(let i=0; i<result[j].changes.length; i++){
        if(result[j].changes[i].field==='fechaEntrega'){
            dataChange.fechaAsigned=result[j].changes[i].nextValue
        }
        if(result[j].changes[i].field==='estado' && result[j].changes[i].nextValue==='Programada' ){
        console.log(result[j])
        console.log(result[j].changes[i].field)
        dataChange.fechaProgram=result[j].date
        console.log(dataChange)
        break
       }
     }
    }
    ExcelExportHelper(data , dataChange)
} catch (error) {
    
}

 return(dataChange)
}

export default DateProgram
