import React, { useState } from 'react'
import { Observacioneq } from '../Equipo/Observacioneq';
import { Modales } from '../Modales'
import TitleEq from './TitleEq';
import { ViewImage } from './ViewImage';

export const ListaequiposIn= ({Eqselect})=> {
  
    //console.log(Eqselect)
    const [equipoinfo, setEquipoinfo] = useState({});
    const[estadomodal, setEstadomodal] = useState(false);
    const[tarea, setTarea]=useState()
    const observacion=(eq)=>{
        //console.log(eq)
        setEquipoinfo(eq)
        setTarea('obs')
        setEstadomodal(true)

      }
      const viewImg=(eq)=>{
        setEquipoinfo(eq)
        setTarea('img')
        setEstadomodal(true)
  
      }
  return (
    <div>
        <div >
            <Modales
            estado={estadomodal} 
            cambiarestado={setEstadomodal}
            titulo={tarea==='obs'? `Observación Ingreso`:'Evidencias Fotograficas'}
            >
               {tarea==='obs'? <Observacioneq cambiarestado={setEstadomodal} infoobservacion={equipoinfo?.observacion}/> :null}
               {tarea==='img'? <ViewImage cambiarestado={setEstadomodal} eqinfo={equipoinfo}/> :null}
            </Modales>
            <TitleEq/>
          {Eqselect?.map(eq=>(
            <ul className='Col-10-Ingreso sombra-button' key={eq._id}>
                <li>{eq?.equipo?.equipo.item?.nombre}</li>
                <li>{eq?.equipo?.equipo.marca}</li>
                <li>{eq?.equipo?.equipo.modelo}</li>
                <li>{eq?.equipo?.equipo.serie}</li>
                <li>{eq?.equipo?.consecutivo}</li>
                <li>{eq?.equipo?.estado}</li>
                {eq?.accesorios?.length>0?(<li>Si</li>):<li>No</li>}
                {eq?.accesorios?.length>0?(<li>{eq.accesorios}</li>):<li>NINGUNO</li>}
                {eq.observacion ? ( <i className="bi bi-eye-fill cursor-p font_sz" onClick={()=>observacion(eq)}></i>):null}
                <li>{eq.fotos.length>0?<i class="bi bi-images cursor-p" onClick={()=>viewImg(eq)}></i>:<></>}</li>
            </ul>
          ))} 
        </div>
    </div>
  )
}
