import React, { useEffect, useState } from 'react'
import { paginate } from '../../api/paginate'
import { useTheme } from '../../hooks/useTheme'
import ResponsiveTable from '../commons/ResponsiveTable'
import { Listaringreso } from './Listaringreso'

export const Settinglistingreso=()=> {
    const [ingresos, setIngreso]=useState([])
    const [page,setPage] = useState(1)
    const {size} = useTheme()

    useEffect(()=>{
      async function getDataingreso(){
      //const consulta = await getDataList('I|ngresoEquipos',{options:{sort:{fechaIngreso:-1}}})
      const consulta = await paginate(`/IngresoEquipos/paginar?page=${page}&perPage=10`,{conditions:{}, sort:{fechaIngreso:-1}})
      //console.log(consulta.data.data)
      setIngreso(consulta.data.data)
      }
      getDataingreso()
  },[page])

  return (
    <div>
        <div className='cabecera'>
            <a className='titulo'>Ingresos de equipos</a>
        </div>
   <ResponsiveTable>
        <Listaringreso ingresos={ingresos}  setIngreso={setIngreso} page={page} setPage={setPage} />
      </ResponsiveTable>
    </div>
  )
}
