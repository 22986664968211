import React from 'react'

export default function TitleEq() {
  return (
    <div>
        <ul className='Col-9-Ingreso cabeceraitem'>
            <li>Item</li>
            <li>Marca</li>
            <li>Modelo</li>
            <li>Serie</li>
            <li>Consecutivo</li>
            <li>Estado</li>
            <ul className='accesorios'>
              <li>Accesorios</li>
                  <ul className='Col-3-Ingreso'>
                    <li>Si/No</li>
                    <li>¿Cuales?</li>
                  </ul>
            </ul>
            <li></li>
        </ul>
    </div>
  )
}
