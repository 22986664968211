import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { actualizarDatos, getDataList } from '../../api/common'
import Spinner from '../commons/Spinner'
import moment from 'moment'
import { Modalcarga } from '../Modales/Modalcarga'
import { estadoIn, estados } from './SettingsIn'
import { PARENT } from '../../constants'
import { Modales } from '../Modales'
import { ListaequiposIn } from './listarequiposIn'
import { PdfIngreso } from './PdfIngreso'
import AnularIngreso from './AnularIngreso'
import { CALIBRATE, PROGRAMOT } from '../Roles/ConstantRoute'
import { useAuth } from '../../hooks/useAuth'
import ValidarPermiso from '../Roles/ValidarPermiso'
import { Alertassw } from '../Alertas/Alertassw'
import { estadosicon } from '../Ot/settings'
import { SetOt } from '../Ot/SetOt'
moment.locale('es');

let counentry=0
export const ViewIngreso=()=> {
  const { userData } = useAuth();

  const permisos =userData.role.permisos; // Lista de permisos del usuario;

  var equiposIn=[]
  var equiposorden=[]
  //Extraemos la informacion que vienes en los query params url dinamicas
    const {id}=useParams()
    const [searchParams]=useSearchParams()
    const origen=searchParams.get('origen')
    const [estadoAn, setEstadomodalAn]=useState(false);
    const [spinnerMod, setSpinnerMod] = useState(false);
      //Estado para activar el modal
    const [estadomodal, setEstadomodal] = useState(false);
    
  //constante para guardar la informacion del ingreso
    const [ordendata, setOrdendata]=useState()
    const [Eqselect, setEqselect]=useState([])
    const[estadomodalIn, setEstadomodalIn] = useState(false);
    const [refrest, setRefrest]=useState(false)
      //Informacion titulo para el modal
    const [otInfo, setOtinfo] = useState({
      numero_orden: "",
      id: "",
    });
    const [ot, setOt]=useState()
    const navigate = useNavigate();

    console.log(ot)
    const queryson = (orden) => ({
      conditions:{
          numeroOrden:orden,
          otType:PARENT
      },
  })
    useEffect(()=>{
        async function Dataingreso(){
            const resultado = origen==='ingresos'?await getDataList('IngresoEquipos',{conditions:{_id:id}})
            :
            await getDataList('IngresoEquipos',
            {
                conditions:{
                   'equipos.equipo':{ $in:[`${id}`]}
                },
                fields:null,
                options:{
                }
          })
            const orden= await getDataList ('Ot', queryson(searchParams.get('orden')))
            setOt(orden.data.data[0])
            setOrdendata(resultado.data.data[0])
            equiposIn=(resultado.data.data[0].equipos)
            equiposorden=(orden.data.data[0].equipos)
            for(var i=0; i<equiposorden.length; i++){  //for para cambiar el estado de los equipos del ingreso
              const filtro=equiposIn.filter((equipo)=>equipo.equipo._id === equiposorden[i].equipo._id)
              if(filtro.length>0){
                filtro[0].estado = equiposorden[i].estado
              }
            } 
            const entregados=equiposIn.filter((equipo)=>equipo.equipo.equipo.movimiento === 'Cliente')

            if(entregados.length===equiposIn.length && resultado.data.data[0].estado!=='Cerrada' && resultado.data.data[0].estado!=='Anulado'){
              const cierre=await actualizarDatos('IngresoEquipos', {...resultado.data.data[0], estado:'Cerrada',totalEntregado:entregados.length , fechaSalida:moment().format()}, resultado.data.data[0]._id)
              if(cierre.status)setOrdendata(cierre.data.data)
            }else{
              counentry=entregados.length
            }
            setEqselect(equiposIn)
            setEstadomodalIn(false)
        }
        Dataingreso()
    },[refrest, id])

    const AnularEntry=()=>{
      setEstadomodalAn(true)
      
    }

    const openOt = async (ot) => {
      //console.log(ot);
      if(ValidarPermiso(CALIBRATE,permisos)!==-1){
      setSpinnerMod(true);
      const ota = await actualizarDatos(
        "Ot",
        { ...ot, estado: "Abierta" },
        ot._id
      );
      //console.log(ota);
      if (ota.status === 200) {
        setSpinnerMod(false);
        setRefrest(!refrest);
        navigate(`/Ot/${ot._id}?orden=${ot.numeroOrden}&task=finalizar`);
      }
    }else{
      alertaPermiso()
    }
    };

    const programOt = (ot) => {
      if(ValidarPermiso(PROGRAMOT,permisos)!==-1){
      setOtinfo({
        numero_orden: ot.numeroOrden,
        id: ot._id,
      });
      setEstadomodal(true);
     }else{
      alertaPermiso()
     }
    };

      //Funcion para crear Hijo y abrir OT
  const continueOt = (ot) => {
    if(ValidarPermiso(CALIBRATE,permisos)!==-1){
    navigate(`/Ot/${ot._id}?orden=${ot.numeroOrden}&task=finalizar`);
    }else{
      alertaPermiso()
    }
  };

  const alertaPermiso=()=>{
    Alertassw('error','PERMISO NO AUTORIZADO','Su rol no tiene habilitado este permiso, solicita a la persona autorizada la asignación')
  }

    //Funcion para ir a la ruta indicada por el boton
    const funciones = {
      REQUESTED: "Solicitud",
      Solicitud: programOt,
      Programada: openOt,
      Abierta: continueOt,
      "Lista para Cerrar": "Cerrada",
      Cerrada: "Pagada",
      Pagada: "Entragada",
    };

  return (
    <>
      <Modales
              estado={estadomodal}
              cambiarestado={setEstadomodal}
              titulo={`Programar Orden No : ${otInfo.numero_orden}`}
            >
              <SetOt
                otinfo={otInfo}
                cambiarestado={setEstadomodal}
                refresh={refrest}
                setRefresh={setRefrest}
              />
            </Modales>
      <Modales
                estado={estadoAn} 
                cambiarestado={setEstadomodalAn}
                titulo={'Anular Ingreso'}
      >
        <AnularIngreso ingreso={ordendata} setEstadomodalAn={setEstadomodalAn} refrest={refrest} setRefrest={setRefrest}/>
      </Modales>

      {Eqselect.length>0?(
       <div> 
        <div className='cabecera'>
        <label className='titulo'>Ingreso de equipos</label>
      </div>
      <div className='Col-5-Ingreso'> 
            <div className='col-1-ingreso'>
              <label htmlFor='numeroOrden'>No Ingreso:</label>
              <label className='cuadro'>{ordendata?.numeroOrden+'-'+ordendata?.consecutivo}</label>
            </div>
            <div className='col-1-ingreso'>
              <label  htmlFor='contacto'>Cliente :</label>
               <label className='cuadro'>{ordendata?.equipos[0]?.equipo?.equipo?.cliente?.nombre}</label>
            </div>
            <div className='col-1-ingreso'>
              <label  htmlFor='contacto'>Contacto :</label>
              <label className='cuadro' >{ordendata?.equipos[0]?.equipo?.equipo?.cliente?.contacto}</label>
            </div>
            <div className='col-1-ingreso'>
              <label   htmlFor='fechaEntrega'>Fecha Ingreso :</label>
              <label className='cuadro'>{moment(ordendata?.fechaIngreso).format('L') }</label>
            </div>
            <div className='col-1-ingreso'>
              <label   htmlFor='fechaEntrega'>Fecha Entrega:</label>
              <label className='cuadro'>{moment(ordendata?.fechaSalidaEstimada).format('L')}</label>
            </div>
            <div className='estadoin'>  
              <h4>{estados[ordendata.estado]}</h4>
            </div>
            <div className='estadoin'>  
              <h4>{`${Math.round(counentry/ordendata.total*100,3)}%`}</h4>
            </div>
               <label className='observacioningreso border'> {ordendata?.observacion}</label>
        </div>
        <div className="Menubotonesot">
        {ot.estado === "Abierta" ||
            ot.estado === "Programada" ||
            (ot.estado === "Solicitud" )? (
              <button
                className="btn-btn-trans mg-left-90"
                onClick={() => funciones[ot.estado](ot)}
              >
                {'Ir a la OT --> '}
                <i
                  className={`bi ${estadosicon[ot.estado]}`}
                ></i> 
              </button>
            ) : null}
            <button 
              className="btn-btn-trans mg-left-90"
              onClick={()=>PdfIngreso(ordendata)}>
                Descargar PDF
              </button>
              {ordendata.estado==='Cerrada' || ordendata.estado==='Anulado'? null:
              <button 
              className="btn-btn-trans mg-left-90"
              onClick={AnularEntry}>
                Anular Ingreso
              </button>
              }
        </div>
        <hr/>
      <ListaequiposIn Eqselect={Eqselect}/>
      <p className='pieingreso'>{`Ingreso creado por : `+ ordendata.autor.nombre + ' '+ordendata.autor.apellido}</p>

      {ordendata.estado=='Anulado'?<><p className='pieingreso'>{`Ingreso Anulado por : `+ ordendata.anuladoBy.nombre +
      ' '+ordendata.anuladoBy.apellido + ' : ' + moment(ordendata?.fechaAnulado).format('LLL')}</p>
      <p>{`Motivo : ${ordendata.motivoAnulado}`}</p></>
      :null}
    </div>
      ):<div>
        <Modalcarga 
        estadoIn={true} 
        >
          <Spinner />
        </Modalcarga>
        </div>}
        </>
  )
}
