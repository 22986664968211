import React, { useEffect, useState } from "react";
import { getDataList } from "../../api/common";
import { CHILD } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import { downloadPDF } from "../../api/pdf";
import moment from "moment";
import "moment/locale/es";
import { Listarequipos } from "../Seguimiento/Listarequipos";
import Sign from "../Ot/Sign";

moment.locale("es");

export const OtsonClient = () => {
  const [refresh, setrefresh] = useState(false);
  const [estadofirma, setEstadofirma] = useState(false);
  const [datasonSelected, setDatasonSelected] = useState(null);
  const { userData } = useAuth();
  const [ordenson, setOrdenson] = useState();

  const downloadPdf = async (e, ot) => {
    e.preventDefault();
    const pdf_file = await downloadPDF(ot);
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${btoa(pdf_file.data)}`;
    link.download = "document.pdf";
    link.click();
  };

  useEffect(() => {
    async function getOtdata() {
      const consulta = await getDataList("Ot", {
        conditions: { cliente: userData._id, otType: CHILD },
        sort: { _id: -1 },
      });
      console.log(consulta.data.data);
      setOrdenson(consulta.data.data);
    }
    getOtdata();
  }, [refresh,estadofirma]);

  return (
    <div>
      <div className="cabecera">
        <a className="titulo">Mis Visitas</a>
      </div>
      <ul className="col-9HT sombra-button bg-primary">
        <li>N°</li>
        <li>Responsable</li>
        <li>Estado</li>
        <li>Fecha Realizado</li>
        <li>Recibido</li>
        <li>Firmar</li>
        <li>Obs</li>
        <li>
          <i class="bi bi-file-earmark-pdf"></i>
        </li>
      </ul>
      {ordenson?.length > 0 ? (
        <div>
          {ordenson?.map((orden) => (
            <details key={orden._id}>
              <summary>
                {orden.estado !== "Abierta" ? (
                  <ul className="col-9HT sombra-button ">
                    <li>{orden.numeroOrden + "-" + orden.visitas}</li>
                    <li>
                      {orden?.responsable?.nombre +
                        " " +
                        orden?.responsable?.apellido}
                    </li>
                    <li>{orden?.estado}</li>
                    <li>{moment(orden.updatedAt).format("L")}</li>
                    {orden.recibido?.firma ? (
                      <li>Firmado</li>
                    ) : (
                      <li>No Firma</li>
                    )}
                    {orden.recibido?.firma ? (
                      <li></li>
                    ) : (
                      <li onClick={
                        ()=>{
                          setDatasonSelected(orden)
                          setEstadofirma(true)
                        }
                      }>
                        <i class="bi bi-pen"></i>
                      </li>
                    )}
                    <li>
                      <i className="bi bi-eye-fill font_sz"></i>
                    </li>
                    {orden.estado === "Cerrada" ? (
                      <>
                        <li onClick={(e) => downloadPdf(e, orden)}>
                          <i class="bi bi-file-earmark-pdf"></i>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                ) : null}
              </summary>
              <div className="detalle_style">
                <Listarequipos listado={orden.equipos} type={"vereqson"} />
              </div>
            </details>
          ))}
        </div>
      ) : (
        <div className="siningreso">
          <h5>No hay hojas de trabajo creadas </h5>
        </div>
      )}

      <Sign
        singWindow={estadofirma}
        setSignWindow={setEstadofirma}
        type={"recibe"}
        ot={datasonSelected}
      />
    </div>
  );
};
