



const TabTwo = ({userSelected,handleInputChange}) => {
    return(
        <>
        <div className="form_group">
          <div className="form_group_label">Nombre contacto</div>
          <input
            type="text"
            name="contacto"
            defaultValue={userSelected?.contacto}
            onChange={handleInputChange}
          />
        </div>

        <div className="form_group">
          <div className="form_group_label">Correo</div>
          <input
            type="text"
            name="correo"
            defaultValue={userSelected?.correo}
            onChange={handleInputChange}
          />
        </div>

        
        <div className="form_group">
          <div className="form_group_label">Cargo</div>
          <input
            type="text"
            name="cargoContacto"
            defaultValue={userSelected?.cargoContacto}
            onChange={handleInputChange}
          />
        </div>

    </>

        );
}

export default TabTwo;