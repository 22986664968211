import React, { useState } from 'react'
import { actualizarDatos } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'

export const EditMg=({infoMg, setRefresh, refresh, setEstadomodal})=> {
    console.log(infoMg)
    const [cambios, setcambios]=useState({
        _id:infoMg._id
    })
    console.log(cambios)


const changes=(e)=>{

    setcambios({
        ...cambios,
        [e.target.name]:e.target.value
    })

}

const editar=(e)=>{
    
    e.preventDefault()
    if(cambios){

        async function EditarMg (){
            console.log(cambios)
            const result= await actualizarDatos('magnitud',cambios,infoMg.Id)
            console.log(result.data.data)
            if(result.status===200){
                setRefresh(!refresh)
                setEstadomodal(false)
            }
        }
        EditarMg()

    }else{Alertassw('error','No se han registrado cambios')}

}
  return (
    <div>
        <form type='submit' onSubmit={editar}>
            <label>Nombre:</label>
            <input  type={'string'} className='w-100' name='nombre' defaultValue={infoMg.nombre} onChange={changes}></input>
            <br/>
            <br/>
            <label>Consecutivo:</label>
            <input  type={'string'} className='w-100' name='consecutivo' defaultValue={infoMg.consecutivo} onChange={changes}></input>
            <br/>
            <br/>
            <label>Registros:</label>
            <input  type={'number'} className='w-100' name='numero'defaultValue={infoMg.numero} onChange={changes}></input>
            <hr/>
            <button type='submit' className='btn-btn-secundary w-100'>Guardar cambios</button>
        </form>
    </div>
  )
}
