import React from 'react'
import moment from 'moment';

export const PameEqPatron=({pame})=>{

    console.log("Seccion Pame",pame)
  return (
    <div>
      <table className='table table-striped Tabmediciones'>
        <thead >
            <tr >
                <th style={{ background: '#05a1b6', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} scope="col">Intervalo Real</th>
                <th style={{ background: '#05a1b6', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} scope="col">Proxima Calibración</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{pame[0]?.intervaloReal}</td>
                <td>{pame[0]?.nextPeriodo}</td>
            </tr>
        </tbody>
      </table>

      <table className='table table-striped Tabmediciones'>
        <thead>
            <tr>
                <th style={{ background: '#05a1b6', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} colSpan={10} scope="col">Registro Anterior</th>
            </tr>
            <tr>
                <th className='TabMediciones_Cabe' scope="col">Fecha de Calibración</th>
                <th className='TabMediciones_Cabe' scope="col">Proveedor de Calibración</th>
                <th className='TabMediciones_Cabe' scope="col">N° Certificado</th>
                <th className='TabMediciones_Cabe' scope="col">Valor Nominal</th>
                <th className='TabMediciones_Cabe' scope="col">Valor Medido</th>
                <th className='TabMediciones_Cabe' scope="col">Error</th>
                <th className='TabMediciones_Cabe' scope="col">Corrección a la indicación</th>
                <th className='TabMediciones_Cabe' scope="col">Exactitud o Tolerancia</th>
                <th className='TabMediciones_Cabe' scope="col">Incertidumbre Expandida</th>
                <th className='TabMediciones_Cabe' scope="col">Confirmación Metrológica</th>
            </tr>
        </thead>
        <tbody>
            {pame[1]?.mediciones.map((medicion, index)=>(
                <tr  key={index}>
                    <td>{moment(pame[1]?.FechaInicio).format('L')}</td>
                    <td>{pame[1]?.responsable}</td>
                    <td>{pame[1]?.consecutivo}</td>
                    <td>{medicion?.valorNominal}</td>
                    <td>{medicion.valorMedido}</td>
                    <td>{medicion.error}</td>
                    <td>{medicion.correccionIndicacion}</td>
                    <td>{medicion.exactitudTolerancia}</td>
                    <td>{medicion.incertidumbreExpandida}</td>
                    <td>{medicion.cumpleExactitud}</td>
            </tr>
            ))}
 
        </tbody>
      </table>
      <table className='table table-striped Tabmediciones'>
        <thead>
            <tr>
                <th style={{ background: '#05a1b6', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}colSpan={10} scope="col">Registro Actual</th>
            </tr>
            <tr>
                <th className='TabMediciones_Cabe' scope="col">Fecha de Calibración</th>
                <th className='TabMediciones_Cabe' scope="col">Proveedor de Calibración</th>
                <th className='TabMediciones_Cabe' scope="col">N° Certificado</th>
                <th className='TabMediciones_Cabe' scope="col">Valor Nominal</th>
                <th className='TabMediciones_Cabe' scope="col">Valor Medido</th>
                <th className='TabMediciones_Cabe' scope="col">Error</th>
                <th className='TabMediciones_Cabe' scope="col">Corrección a la indicación</th>
                <th className='TabMediciones_Cabe' scope="col">Exactitud o Tolerancia</th>
                <th className='TabMediciones_Cabe' scope="col">Incertidumbre Expandida</th>
                <th className='TabMediciones_Cabe' scope="col">Confirmación Metrológica</th>
            </tr>
        </thead>
        <tbody>
            {pame[2]?.mediciones.map((medicion, index)=>(
                <tr   key={index}>
                    <td>{moment(pame[2]?.FechaInicio).format('L')}</td>
                    <td>{pame[2]?.responsable}</td>
                    <td>{pame[2]?.consecutivo}</td>
                    <td>{medicion?.valorNominal}</td>
                    <td>{medicion.valorMedido}</td>
                    <td>{medicion.error}</td>
                    <td>{medicion.correccionIndicacion}</td>
                    <td>{medicion.exactitudTolerancia}</td>
                    <td>{medicion.incertidumbreExpandida}</td>
                    <td>{medicion.cumpleExactitud}</td>
            </tr>
            ))}
        </tbody>
      </table>
      <hr/>
      <table className="table table-striped Tabmediciones">
        <thead >
            <tr>
                <th style={{ background: '#05a1b6', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} colSpan={7} scope="col">Resultado PAME</th>
            </tr>
            <tr>
                <th className='TabMediciones_Cabe' scope="col">Valor Nominal </th>
                <th className='TabMediciones_Cabe' scope="col">Deriva Total (entre dos periodos de calibración) </th>
                <th className='TabMediciones_Cabe' scope="col">Deriva Mensual (entre dos periodos de calibración)</th>
                <th className='TabMediciones_Cabe' scope="col">Incertidumbre Debido a la Deriva</th>
                <th className='TabMediciones_Cabe' scope="col">Próxima Calibración (Meses)</th>
            </tr>
        </thead>
        <tbody>
            {pame[3]?.map((medicion, index)=>(
                <tr  key={index}>
                    <td>{medicion.valorNominal}</td>
                    <td>{medicion.derivaTotal}</td>
                    <td>{medicion.derivaMensual}</td>
                    <td>{medicion.uDeriva}</td>
                    <td>{medicion.periodo? medicion.periodo: 'Indefinido'}</td>
            </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}


