import { 
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes
} from "react-router-dom";
//import { PrivateRoute } from "./PrivateRoute";
import React from "react";
// importamos los componentes creados

import { Login } from "../views/Login";
import { Home } from "../views/Home";
import UserContext from "../context/User/UserContext";
import { useContext } from "react";
import { getToken } from '../helpers/helpers';
import { useAuth } from "../hooks/useAuth";
import ActivateAccount from "../views/ActivateAccount";
import Register from "../views/Register";
import Information from "../views/Information";
import SendRecoverPassword from "../components/ChangePassword/SendRecoverPassword";
import RecoverPassword from "../components/ChangePassword/RecoverPassword";
import TwoFactorValidation from "../views/TwoFactorValidation";
import GeneralInformation from "../views/GeneralInformation";
import TermsAndConditions from "../components/TerminosCondiciones/TermsAndConditions";





export const AppRouter = () =>{


    const { userData } = useAuth()
    return (
            <div className="body">
                <Router>
                    <Routes>
                    {userData && getToken()?(    
                            <Route  path="*" element={<Home/>}/>):
                             ( <>
                                <Route  path="/Login" element={<Login/>}/>
                                <Route path="/terminos-y-condiciones" element={<TermsAndConditions/>} />
                                <Route  path="/" element={<Login/>}/>
                                <Route path="/activateAccount" element={<ActivateAccount />} />
                                <Route  path="/register" element={<Register/>} />
                                <Route path="/info" element={<Information/>} />
                                <Route path="/recoverPassword" element={<SendRecoverPassword/>} />
                                <Route path="/newPassword" element={<RecoverPassword/>} />
                                <Route exact path="/loginValidation" element={<TwoFactorValidation/>} />
                                <Route exact path="/generalInfo" element={<GeneralInformation/>} />
                                </>
                                 )
                            }                
                </Routes>
                </Router>
              
            </div> 
    );
}

