import firebase from 'firebase/compat/app'
import "firebase/compat/storage"
import "firebase/compat/firestore"


export const app = firebase.initializeApp({
    "projectId": "biolab-storage",
    "appId": "1:27663579649:web:0b817f5344be7f52899e00",
    "storageBucket": "biolab-storage.appspot.com",
    "locationId": "northamerica-northeast1",
    "apiKey": "AIzaSyAUKVlkxTr2sNmz_xImloRemjvlSNKPIu8",
    "authDomain": "biolab-storage.firebaseapp.com",
    "messagingSenderId": "27663579649"
  });
  