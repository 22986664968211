




const TabPageForm = ({children,idTab,currentTab}) =>{

    return(
        <>
        {idTab === currentTab && <>{children}</>}

        </>
        )
}


export default TabPageForm;