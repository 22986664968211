import React from 'react'

export const EqInlab=({setVentana, itemIn, setModalitem, crearActa, refresh, setRefresh, setModal})=> {

console.log(itemIn)
    const cancelar=()=>{
        setRefresh(!refresh)
        setVentana(false)
        //setModal(false)
        setModalitem(false)
        
    }
    const continuar=()=>{
        crearActa()
        setRefresh(!refresh)
      //setModal(false)
        setModalitem(false)
    }

  return (
    <div>
      <p>Los siguientes equipos no estan listos para crear acta de certificados</p>
      <p>Nota: Solo se creara acta certificado para los equipos Finalizados y que no esten ingresados</p>
      <p>si el equipo esta ingresado genera un acta de equipo y certificado</p>
      <div className='eqNoActa'>
      <div className='eqIngresado cabeceraitem' >
            <li>Ítem</li>
            <li>Serial</li>
            <li>Consecutivo</li>
            <li>Certificado</li>
            <li>Ubicación Real</li>
            <li>Estado</li>
        </div>

      {itemIn.map(item=>(
        <div className='eqIngresado sombra-button' key={item._id}>
            <li>{item.equipo.item.nombre}</li>
            <li>{item.equipo.serie}</li>
            <li>{item.consecutivo}</li>
            <li>{item.estadoEntrega}</li>
            <li>{item.equipo.movimiento}</li>
            <li>{item.estado}</li>
        </div>
      ))}
      </div>
      <button className='btn-btn-tertiary' onClick={cancelar}>Cancelar</button>
      <button className='btn-btn-primary' onClick={continuar}>Continuar</button>
    </div>
  )
}
