import { useState } from 'react';
import {Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'

import { useEffect } from 'react';
import { getDataList } from '../../api/common';
import 'moment/locale/es'; 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useAuth } from '../../hooks/useAuth';


import { AddEventCalendar } from './AddEventCalendar';
import { DetailEvent } from './DetailEvent';
const localizer = momentLocalizer(moment);

export function CalendarioApp(){
    const [events, setEvents] = useState([]);
    const [detailEvent, setDetailEvent] = useState();
    const [isOpenAddEvent, setIsOpenAddEvent] = useState(false);
    const [isOpenDetailEvent, setIsOpenDetailEvent] = useState(false);

    const {userData:{_id:userId}} = useAuth();

    const getEvents = async() => {
        let {data:{data}} = await getDataList('calendario');
        data = data.map( (item) => (
            {
                id: item._id,
                title: item.title,
                allDay: true,
                start: item.start,
                end: item.end,
                bgColor: item.idResponsable === userId ? '#E92E2E' : item.bgColor,
                descripcion: item.descripcion,
                createdBy: item.createdBy,
                idResponsable: item.idResponsable,
                responsable: item.responsable,
                zonaCliente: item.zonaCliente,
                numeroEquipos: item.numeroEquipos,
                otNumeroOrden: item.otNumeroOrden,
                type: item.type,
            }
        ));
        console.log(data)
        setEvents(data);
    }
    console.log('calendario');

    const handleDetailEvent = (event) => {
        setIsOpenDetailEvent(true);
        setDetailEvent(event);
    }

    useEffect(() => {
        getEvents();
    },[setEvents]);



    return(
            <>
            <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{height: '100vh'}}
            eventPropGetter={(event ,start, end, isSelected) => ({
                event,
                start,
                end,
                isSelected,
                style: { backgroundColor: event.bgColor, padding: '7px 5px', fontSize: '22px', fontWeight: 'bold'}
            })}
            messages={{
                next: ">",
                previous: "<",
                today: "Hoy",
                month: "Mes",
                week: "Semana",
                day: "Día",
                noEventsInRange: 'No hay eventos para los proximos días.'
              }}
            onSelectEvent={(event) => handleDetailEvent(event)}
            />

            <AddEventCalendar setEvents={setEvents} events={events} isOpen={isOpenAddEvent} setIsOpen={setIsOpenAddEvent}  />    

            {detailEvent &&
                <DetailEvent event={detailEvent}  setEvents={setEvents} events={events}  isOpen={isOpenDetailEvent} setIsOpen={setIsOpenDetailEvent} />
            }
        </>
    )
}