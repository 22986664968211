import React, { useState } from 'react'
import { Historial } from '../Crearot/Historial'
import AddedEquipments from '../Equipo/AddedEquipments'
import FormEquipments from '../Equipo/FormEquipments'
import { addEquipments, postDataEquiposlotes } from '../../api/common'
import { Alertassw } from '../Alertas/Alertassw'
import { CHILD } from '../../constants'

export const AddItems = ({setEstadoModal, idOt, ot, cliente, setRefresh, refresh, ListFather, SetModalcharge, sedeSeleted, sedes, historialeq, setHistorialEq}) => {
    const [pestaña, setPestaña]=useState(false)
    const [equipos, setEquipos]=useState([])
    const sedePrincipal = sedes.find(item => item.nameSede === 'Principal');
    console.log('sedeSeleted: ',sedeSeleted)
    console.log('sede principal: ',sedePrincipal)
    const [equipo, setEquipo]=useState(
        {
          cliente:cliente._id,
          sede:'Principal',
          item:'',
          marca:'',
          modelo:'No Presenta',
          serie:'',
          inventario:'No Presenta',
          ubicacion:'No Informa',
          resolucion:'No Informa',
          sedeClient:sedeSeleted? sedeSeleted: sedePrincipal._id ,
          puntos:'No Informa',
          observacion:'',
          nombreitem:''     //verificar que reciba en la base de dato 
        }
      )

    console.log('equipo: ', equipo)
    const nuevos =()=>{
        setPestaña(false)
      }
    const historialtoggle=()=>{
    //console.log(_id._id)
        setPestaña(true)
    }

    const AdicionarItems=()=>{
      postItem();
    }

    async function postItem(){
      SetModalcharge(true)
      try {
        console.log(equipos)
        const eq = await postDataEquiposlotes(equipos)
        console.log(idOt)
        console.log(ot.numeroOrden)
        if(eq.status===200 )
        {
          //ot.equipos=eq.data.equipos
          const eqPayload = eq.data.equipos.map(item => ({equipo:item.equipo,serial:item.serie}));
          const body = {
            comment: 'values.comment',
            equipos: eqPayload,
          };
          const data = await addEquipments(ot._id||'1234', ot.numeroOrden, CHILD, body);
          console.log(data)
          setRefresh(!refresh)
          //postOt();
          Alertassw('charge','Equipos cargados Creando OT')
          SetModalcharge(false);
          setEstadoModal(false)
        }
      } catch (error) {
        console.log(error)
       /* if(error.response.data.error.message==='EQUIPOS EXISTENTES'){
          errors=error.response.data.detail.split('|')
          console.log(errors)
          setModalEqExist(true) 
        }*/
      }
     }
    const selectitem =(value) => {
        //console.log(ot)
        if (value.length>0){     // pregunto si esta vacio
           // //console.log(value[0].label)
            setEquipo({     // aqui almaceno el Id del item seleccionado en el nombre del equipo 
              ...equipo,
              item:value[0].value,
              nombreitem:value[0].label
            })
            //console.log(equipo)
      } else {setEquipo({
        ...equipo,
        item:''
      })}
    }
  return (
    <div>
        <div className='pestañas'>
          <div  className='pestaña '><label className={`textpestaña ${pestaña===true? null: 'pestañaActive'}`} onClick={nuevos}>Nuevos</label></div>
          <div  className='pestaña ' onClick={historialtoggle}><label className={`textpestaña ${pestaña===true? 'pestañaActive':null}`} >Historial</label></div>
          {equipos.length>0 && pestaña===false?<button className='btn-btn-secundary' onClick={AdicionarItems}>Adcionar Ítems</button>:null}
        </div>

        {pestaña=== true?(
          <Historial pestaña={setPestaña} cliente={cliente} addequipos={equipos}  listarequipos={setEquipos} sede={sedeSeleted?.nameSede} ListFather={ListFather} historialeq={historialeq} setHistorialEq={setHistorialEq}/>):(
        <div>
        {/*<HeaderEquipments />*/}
        <AddedEquipments setEquipos={setEquipos} equipos={equipos} />
        <hr></hr>
        <FormEquipments selectitem={selectitem} setEquipo={setEquipo} equipo={equipo} equipos={equipos} setEquipos={setEquipos}/>
        <hr/>
        <br/>       
        </div> )} 
    </div>
  )
}

