import React, { useEffect, useState } from 'react'
import { actualizarDatos, postDataClientes, crearDatos, getDataobtener } from '../../api/common';
import jwt_token from 'jwt-decode';
import Select from 'react-dropdown-select';
import Swal from 'sweetalert2'
import swal from 'sweetalert';
import { Modales } from '../Modales';
import { useAuth } from '../../hooks/useAuth';
import { Alertassw } from '../Alertas/Alertassw';
import Spinner from '../commons/Spinner';
import { Modalcarga } from '../Modales/Modalcarga';

export const SetOt=({otinfo, cambiarestado, refresh, setRefresh })=> {

  const client = {
    "role.nombre":"Metrologo" ,
    "type":"foreign",
    "populate":[  ]
  }

  const[spinnerMod, setSpinnerMod]=useState(false)
  const[dataprogramer, setdataprogramer]=useState({
    fechaEntrega:'',
    responsable:'',
    estado:'Programada'
  })
  const [user, setUserData]=useState({})

  const {userData:{nombre, apellido}} = useAuth();

  //estado para almacenar los usuarios responsables para las OT
  const [responsable, setResponsable]=useState([])
  
  const responSelect = (value) =>{
    if(value.length>0){ 
      //console.log(value)
      setdataprogramer({
        ...dataprogramer,
        responsable:value[0].value
      })

    }else{
      setdataprogramer({
        ...dataprogramer,
        responsable:''
      })
    }
  }
    const fechaInit = (event)=>{
      setdataprogramer({
        ...dataprogramer,
        [event.target.name]:event.target.value
    })
    
  }
  const fechaInEnd=(event)=>{
    setdataprogramer({
      ...dataprogramer,
      [event.target.name]:event.target.value,
      fechaCierre:event.target.value
   })
  }
  console.log(dataprogramer)
  const Enviar=()=>{

    if(dataprogramer.responsable && dataprogramer.fechaEntrega){
      //console.log(otinfo)
      async function putOt(){
        setSpinnerMod(true)
        const ota=await actualizarDatos("Ot", dataprogramer, otinfo.id)
        if(ota.status===200){
          setSpinnerMod(false)
          Alertassw('ok', 'Orden programada correctamente')
          setRefresh(!refresh)
          cambiarestado(false)

          const {data:{data:ot}} = await getDataobtener(otinfo.id);
          console.log(ot);
          const newEvent = {
            title: `${ot.cliente.nombre} - ${ot.numeroOrden}`,
            start: dataprogramer.fechaEntrega, 
            end: dataprogramer.fechaCierre,
            descripcion: ot.observacion,
            createdBy: `${nombre} ${apellido}`,
            idResponsable: ot.responsable._id,
            responsable: `${ot.responsable.nombre} ${ot.responsable.apellido}`,
            zonaCliente: ot.cliente.ciudad,
            numeroEquipos: ot.equipos.length,
            otNumeroOrden: ot.numeroOrden,
            type: 'ot',
          }
          console.log(newEvent)
          let {data:{data}} = await crearDatos('calendario', 
                                                newEvent
                                              );
          console.log(data);
        }
    } 
    putOt();
    }else{
      Alertassw('error','Por favor ingresar información completa')
    }

  }
    useEffect(() => {
        async function postClientes(){
         const clientesDB = await postDataClientes(client);
         //console.log(clientesDB.data.data)
         // //console.log(clientesDB.data.data)
           const cl = clientesDB.data.data.map((client)=>({
            value:client._id,
            label:client.nombre + " "+ client.apellido ,
        }));
        setResponsable(cl)
        //console.log(cl)
        }
        async function getUserData(){
          const user = await jwt_token(localStorage.getItem('token'));
          setUserData(user)
          }
          getUserData()
        postClientes()
    },[])


  return (
    <>
      <Modalcarga 
          estadoIn={spinnerMod} 
          cambiarestadoIn={setSpinnerMod}>
          <Spinner />
       </Modalcarga>

      <div className='col-1MD'>
        <label className='mg-5-all' htmlFor='responsable'>Responsable</label>
        <Select className='mg-5-all' name='responsable' onChange={responSelect} searchable='true' options={responsable} />
        <label className='mg-5-all' htmlFor='fechainicio'>Fecha inicio</label>
        <input  className='mg-5-all' name='fechaEntrega' onChange={fechaInEnd} type={"datetime-local"}></input>
        <label className='mg-5-all' >Fecha Cierre</label>
        <input  className='mg-5-all' name='fechaCierre' onChange={fechaInit} type={"datetime-local"}></input>
        <label className='mg-5-all' >Fecha Estimada Cierre</label>
        <input  className='mg-5-all' name='fechaEstimada' onChange={fechaInit} type={"datetime-local"}></input>
        <button className='btn-btn-primary w-100 mg-20-top' onClick={Enviar}>Programar</button>
    </div>

    </>

  )
}

