import { useEffect, useState } from "react";
import { filterData, getDataList } from "../../api/common";
import moment from "moment";

const SelectSearch = ({disabled,className, collection, field, handledata, baseFilter,name }) => {
  const [options, setOptions] = useState(null);
  const [search, setSearch] = useState(null);
  const [selected, setSelected] = useState("");
  const ID = moment().unix()

  const client = {
    "role.nombre":"Cliente", 
    "type":"foreign",
     "populate":[]
}

  useEffect(() => {

    async function getOptions() {
      const conditions = search
        ? { regex:[ { [field]:search  } ],
            ...baseFilter
          }
        : { regex:null };
      try{
        console.log('condiciones: ', conditions)
      const opt = await filterData(collection, { ...conditions });
      setOptions(opt.data.data);
      }catch(error){
       // //console.log(error)
        
      }
    }
    ////console.log(search);
    if(search !== null ) getOptions();
  }, [search]);

  const handleChangeSearch = (e) => {
    ////console.log(e.target.value);
    setSearch(e.target.value);
    if(!e.target.value) handleSetValue("",undefined)
  };

  const handleSetValue = (correo, id) => {
    setSearch(null);
    setSelected(correo);
    document.getElementById(`input-text-${ID}`).value = correo;
    let event = {
      target: {
        name: name,
        value: id,
      },
    };
   // //console.log(event)
    handledata(event);
  };
  
  return (
    <div className="border-none">
      <input
        id={`input-text-${ID}`}
        defaultValue={selected}
        type="text"
        className={`${className} w-100 bdr`}
        onChange={handleChangeSearch}
        placeholder={name}
        disabled={disabled}
      />
      {options && search && (
        <ul className={` ${options ? "list-group" : ""}`}>
          {options &&
            options.map((user) => (
              <li className="list-group-item" onClick={() => handleSetValue(user[field], user._id)}>
                {collection=='Usuarios'? user[field] +"  "+ user['apellido']:user[field] }
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default SelectSearch;
