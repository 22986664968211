import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendRecoverPassword } from "../../api/user";
import { useForm } from "../../hooks/useForm";
import { Navegacion } from "../../views/Navegacion";
import { Alertassw } from "../Alertas/Alertassw";

const SendRecoverPassword = ( ) =>{

    const navigate = useNavigate()
    const [values, handleInputChange] = useForm({
        email:""
      });
      const [errorMessage, setErrorMessage] = useState();
    
      const handleChangePassword = async (e) => {
        e.preventDefault();
        try {
            await sendRecoverPassword(values)
            Alertassw('toast','Correo enviado','Se ha enviado el correo para recuperar su contraseña','success')
            navigate('/login')
    
        } catch (error) {
          setErrorMessage(error.response?.data?.detail || "Error Recuperando Contraseña");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      };


    return(
        <>
            <Navegacion />
            <div className="main-container pd-side-20 pdt-5">
        <form className="  form_container" onSubmit={handleChangePassword}>

        <h4>Recuperar Contraseña</h4>
        {errorMessage&&<div className="error">{errorMessage}</div>}

        <div className="mt-5 mb-5 text-justify">
            Ingrese el correo con el que se ha registrado, luego recibirá un correo donde se indicará
            los pasos a seguir para recuperar su contraseña
        </div>

        

        <div className="form_group">
          <div className="form_group_label">Correo</div>
          <input
            type="text"
            name="email"
            onChange={handleInputChange}
          />
        </div>
    
  
        <div className="form_group mt-5">
          <button type="submit">Enviar</button>
        </div>
      </form>
      </div>
      </>);
}

export default SendRecoverPassword;