import React from 'react'
import moment from 'moment'
import 'moment/locale/es';
moment.locale('es')

export const FacturasList=({facturas})=>{
    //console.log(facturas)
  return (
    <div>
        <ul className='factList cabeceraitem bg-primary'>
            <li>No Factura</li>
            <li>Fecha Creación</li>
            <li>Valor</li>
            <li>Autor</li>
            <li>Fecha de Carga</li>
        </ul>
        {facturas?.map((factura,index)=>(
            <ul className='factList sombra-button' key={index}>
                <li>{factura.numero}</li>
                <li>{moment(factura.fechaCreacion).format('L') }</li>
                <li>{factura.valor}</li>
                <li>{factura.autor.nombre+ factura.autor.apellido}</li>
                <li>{factura.fechaCarga? moment(factura?.fechaCarga).format('lll'):('NA') }</li>
            </ul>
        ))}
      
    </div>
  )
}
