import { useTheme } from "../../hooks/useTheme"

const ResponsiveTable = ({children}) =>{
    const {size} = useTheme()
    return(
        <div className={`${size.width<800?"table_responsive":""}`}>   
          <div className={`${size.width<800?"fix-table":""}`}>
          {children}
          </div>
          </div>)
}

export default ResponsiveTable;