import React from 'react'
import { useTheme } from '../hooks/useTheme'
import { useNavigate, Link } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { Spinner } from 'react-bootstrap'
import logo from "../img/logo.png";
import { RutasSideBar } from '../routers/RoutesPermisse'
export const Sidebar = () => {

  const { isOpenSide,size } = useTheme() 
  const navigate = useNavigate()
  const { userData,setUserData } = useAuth() 


  const logOut = () =>{
    localStorage.clear()
    setUserData(null)
    navigate('/login')
}
 console.log(userData)
  
  return (
    <div className='cuerpo'>
        {userData?(<div className='sidebars' style={{width:isOpenSide?'220px':'60px', pointerEvents:isOpenSide?'auto':'none' }} >
          
          <div className='sidebar_logo'>{isOpenSide?
          <div className='LogoBG'>
          <img className="logoSide" src={logo} alt="logo_biolab"></img>
          </div>:"B"}</div>
          <ul  className="nav_link">
            <li  className='icon-link iconos'>
              <Link to='/'>
                <i className="bi bi-grid iconos"></i>
                <span className='Link_name'>Inicio</span>
              </Link>
            </li>
            
            
            {RutasSideBar(userData.role.permisos)?.map(({name,icon, route, subRoute})=>(
                <li key={name} className='link-content' onClick={()=>subRoute?null:navigate(route)} >
                  <div className="icon-link ">
                  <i className={`bi ${icon} iconos`}></i>
                  <span className='Link_name'>{name}</span>
                {subRoute?
                <div>
                <i className="bi bi-chevron-down iconos"></i>
                </div>:null
                }
              </div>
              {subRoute?<ul className="sublink">
                {subRoute.map(({name,route})=>(
                  <li key ={name} onClick={
                    ()=>navigate(route)
                  }>{name}</li>
                ))}
              </ul>:null}
              </li>
              ))
            }
            {size.width < 950 &&<>
            <li  className='icon-link iconos'>
              <Link to={`/perfil?correo=${userData.correo}`}>
                <i className="bi bi-person iconos"></i>
                <span className='Link_name'>Perfil</span>
              </Link>
            </li>
            <li  className='icon-link iconos'>
              <a href="#" onClick={logOut}>
                <i className="bi bi-box-arrow-right iconos"></i>
                <span className='Link_name'>Salir</span>
              </a>
            </li>
            </>}
          </ul>
        </div>):(<Spinner />)}
      </div>
  )
}
