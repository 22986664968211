import React, { useState } from 'react'
import * as XLSX from 'xlsx'
import { Alertassw } from '../Alertas/Alertassw'

export const Upload=({equipos,sede, sedeClient, idcliente, setEquipos, setEstadomodal, setPestaña})=> {

    const[exceleFile, setExceleFile]=useState(null)
   // const[exceleFileError, setExceleFileError]=useState(null)
   // const [exceleData, setExcelData]=useState(null)

    const fileType=['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const handleFile=(e)=>{
        let selectedFile=e.target.files[0];
        if(selectedFile){
            if(selectedFile&&fileType.includes(selectedFile.type)){
                let reader= new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload=(e)=>{
                    setExceleFile(e.target.result)
                }
            }else{
                Alertassw('error','Por favor selecciona un archivo tipo excel')
                setExceleFile(null)
                }
        }
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        if(exceleFile!==null){
            const workbook=XLSX.read(exceleFile,{type:'buffer'});
            const worksheetname=workbook.SheetNames[0];
            const worksheet=workbook.Sheets[worksheetname]
            const data=XLSX.utils.sheet_to_json(worksheet)
            const errores=data.filter((equipo)=> equipo?.Estado==="Error")
            const filtrado=data.filter((equipo)=> equipo?.item!=="")
        if(errores.length===0){
            const eqcharged= filtrado.map((eqlist)=>({
                cliente:idcliente._id,
                sede:sede,
                sedeClient:sedeClient._id,
                item:eqlist.item,
                nombreitem:eqlist.Items,
                inventario:eqlist.inventario || 'No Informa',
                marca:eqlist.marca || 'No Informa',
                modelo:eqlist.modelo ||'No Informa',
                observacion:eqlist.observacion.charAt(0).toUpperCase() + eqlist.observacion.slice(1).toLowerCase(),
                puntos:eqlist.puntos? eqlist.puntos:'No Informa',
                resolucion:eqlist.resolucion? eqlist.resolucion:'No Informa',
                serie:eqlist.serie,
                ubicacion:eqlist.ubicacion.charAt(0).toUpperCase() + eqlist.ubicacion.slice(1).toLowerCase(),
            }))
            console.log(eqcharged)
            setEquipos([
                ...equipos,
                ...eqcharged
            ])
            setEstadomodal(false)
            setPestaña(false)
        }else{
            Alertassw('error','Items no valido en la lista','verifica la información y vuelve a cargar')
            setEstadomodal(false)
            setPestaña(false)
        }
        }else{
            Alertassw('error','Por favor selecciona un archivo Excel')
         }
    }
  return (
    <div>
        <div>
            <form className='form-group' autoComplete='off' onSubmit={handleSubmit}>
                <br></br>
                <input type={'file'} className='form-control' required
                onChange={handleFile}></input>
                <br></br>
                <button className='btn-btn-secundary' type='submit'>Submit</button>
                <hr></hr>
            </form>
        </div>
    </div>
  )
}
