import React, { useEffect, useState } from 'react'
import { getCities, getStates } from '../../api/states';
import Select from 'react-dropdown-select';

export const Formsede = ({dataSede, Enviar, data="", dataDefault}) => {


  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  let depto= dataDefault?.departamento? dataDefault?.departamento.charAt(0).toUpperCase()+dataDefault?.departamento.slice(1) : " " //Cpapialize la primera letra del departameno

  const [remountKey, setRemountKey] = useState(0);
   
  
  console.log(depto)
  console.log(dataDefault)

  useEffect(() => {
    async function getStatesDB() {
      const stateDB = await getStates();
      const st = await stateDB.json();
      const deptolist = st.map((depto)=>({
        value:depto.departamento,
        label:depto.departamento
      }))
      setStates(deptolist);

    }

    getStatesDB();
  }, []);


  useEffect(() => {
    async function getCitiesDB() {
      const cityDb = await getCities(depto);
      const ct = await cityDb.json();
      const citylist = ct.map((city)=>({
        value:city.municipio,
        label:city.municipio
      }))
      setCities(citylist);
    }
    getCitiesDB();
  }, [remountKey, dataSede.departamento]);

  const dateState=(event)=>{
    if (event.length>0){ 
      dataSede({
        target: {
          name: "departamento",
          value: event[0].value,
        },
      })
      handleClearSelection()
    }
  }


  const dateCity=(event)=>{
    if (event.length>0){ 
      dataSede({
        target: {
          name: "ciudad",
          value: event[0].value,
        },
      })
    }
  }

  const handleClearSelection=()=>{
    setRemountKey(remountKey + 1);
  }

  const handleSubmit=(e)=>{
    e.preventDefault()
    Enviar()
  }

  return (
    <div >
      <form className='col-1MD' onSubmit={handleSubmit}>

        <label className='mg-5-all' htmlFor='responsable'  >Sede *</label>
        <input  required className='mg-5-all' name='nameSede' onChange={dataSede} defaultValue={data?.nameSede}></input>
        <div className="form_group_label">Departamento</div>
        <Select
          className='mg-5-all'
          placeholder={depto}
          onChange={dateState}
          name="departamento"
          options={states}
        />

        <div className="form_group_label">Ciudad *</div>
        <Select
          className='mg-5-all'
          key={remountKey}
          onChange={dateCity}
          name="ciudad"
          options={cities}
          required
        />

        <label className='mg-5-all' >Direccion *</label>
        <input  required className='mg-5-all' name='dirSede' onChange={dataSede} defaultValue={data?.dirSede}></input>
        <label className='mg-5-all' >Contacto</label>
        <input  className='mg-5-all' name='contSede' onChange={dataSede} defaultValue={data?.contSede}></input>
        <label className='mg-5-all' >Telefono</label>
        <input  className='mg-5-all' name='celContacto' onChange={dataSede} defaultValue={data?.celContacto}></input>
        <label className='mg-5-all' >Correo</label>
        <input  className='mg-5-all' name='emailContacto' onChange={dataSede} defaultValue={data?.emailContacto}></input>
        <button className='btn-btn-primary w-100 mg-20-top' type='submit'>Guardar</button>

        </form>
    </div>
  )
}


