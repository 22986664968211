import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es';
import { PdfActa } from '../Pdfs/PdfActa';
import { actualizarDatos, getDataList } from '../../api/common';
import { Alertassw } from '../Alertas/Alertassw';
import { Modalcarga } from '../Modales/Modalcarga';
import Spinner from '../commons/Spinner';
moment.locale('es')

export const ActualizarDate = ()=> {
    const[actasOt, setActasOt]=useState([])
    const[refresh, setRefresh]=useState(false)
    const[spinnerMod, setSpinnerMod]=useState(false)
    

    const  actualizar=(Actadata)=>{

    async function actualizarEq(){
    try {  
            setSpinnerMod(true)
            console.log(Actadata)
            for(let i=0; i<Actadata.equipos.length; i++){
                console.log(Actadata.equipos[i])
               const EqOtACtuliazado =await actualizarDatos('EqInOt',{
                  _id:Actadata.equipos[i].equipo._id,
                  fechaIngreso:Actadata.fechaIngreso,
               })
               console.log(EqOtACtuliazado.data.data)
               console.log(i)
               if(i===Actadata.equipos.length-1){
                Alertassw('success','Actualización correcta')
                setSpinnerMod(false)
               }
              }
      } catch (error) {
        Alertassw('error','No se pudo realizar la actulización')
    }
  }

  actualizarEq();
}
    useEffect(() =>{
        async function getOtdata(){
            const IngresosAll = await getDataList ('IngresoEquipos')
            console.log(IngresosAll)
            setActasOt(IngresosAll.data.data)
        }
        getOtdata()
        setSpinnerMod(false)
    },[refresh])

  return (
    <div>
        <Modalcarga 
          estadoIn={spinnerMod} 
          cambiarestadoIn={setSpinnerMod}>
          <Spinner />
       </Modalcarga>
      <ul className='actatitle cabeceraitem bg-primary'>
        <li>No Acta</li>
        <li>Estado</li>
        <li>Canr. Equipos</li>
        <li>Fecha Creación</li>
        <li>Autor</li>
        <li><i class="bi bi-file-earmark-arrow-down font_sz"></i></li>
      </ul>
      {actasOt.map(acta=>(
        <ul className='actatitle sombra-button' key={acta._id}>
          <li>{'A-'+acta?.numeroOrden+'-'+acta?.consecutivo}</li>
          <li>{acta.estado}</li>
          <li>{acta.total}</li>
          <li>{moment(acta.fechaIngreso).format('lll') }</li>
          <li>{acta.autor.nombre+" "+acta.autor.apellido}</li>
         <li><i onClick={()=>actualizar(acta)} className="bi bi-arrow-clockwise font_sz cursor-p"></i></li>:
        </ul>
      ))}
    </div>
  )
}
