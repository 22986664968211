import React from 'react'
import { Alertassw } from '../Alertas/Alertassw'
import { actualizarDatos } from '../../api/common'

export const InactivarRol=({dataRol, cambiarestado , setRefresh, refresh, setSpinnerMod})=> {
  
    console.log(dataRol.status)
    const Innactivar=async()=>{
    try {
        setSpinnerMod(true);
        const result=  await actualizarDatos('Roles',{
            _id:dataRol._id,
            status:dataRol.status==='activo'? 'inactivo':'activo'
        })
        console.log(result.data.data)
        cambiarestado(false)
        setSpinnerMod(false)
        setRefresh(!refresh)
    } catch (error) {
        Alertassw('error','Ha ocurrido un error intentalo nuevamente')  
    }        
    }
  return (
    <div>
      <div className='col-1MD'>
      {dataRol.status==='activo'?
      <>
        <p>Esta acción eviatará que todos los usuarios con este rol asignado no puedan realizar las actividades dentro del aplicativo</p>
        <p>Si esta de acuerdo por favor presiona Inactivar</p></>:
        <>
        <p>Genia!  Estas a un clic de activar el rol y todos los permisos asignados </p>
        <p>Si esta de acuerdo por favor presiona Activar</p></>
        }
        
        <hr></hr>
        {dataRol.status==='activo'?
        <button className='btn-btn-tertiary' onClick={Innactivar}>Inactivar</button>:
        <button className='btn-btn-primary' onClick={Innactivar}>Activar</button>
        }
      </div>

    </div>
  )
}


