import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { getDataObtenerUno } from "../../api/common";
import { fromQueryParamToFilter } from "../../helpers/helpers";
import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../hooks/useTheme";
import user_img from "../../img/user.png";
import ChangePassword from "../ChangePassword/ChangePassword";
import { RegisterClientForm } from "../Clientes/RegisterClientForm";
import Spinner from "../commons/Spinner";
import { Historial } from "../Crearot/Historial";
import { Modales } from "../Modales";
import { Firma } from "../Ot/firma";
import { RegisterUserForm } from "../users/RegisterUserForm";
import { ProfileOt } from "./ProfileOt";
import { genderDetail } from "./setting";
import { ListSede } from "./ListSede";
import { ListaSedesnew } from "./ListaSedesnew";

export const Profile = () => {
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { userData, setUserSelected } = useAuth();
  const location = useLocation();
  const { size } =useTheme()
  const [refresh, setRefresh]=useState(false)
  console.log(user)
  ////console.log(Array.from(searchParams.keys));

  const [recibido, setRecibido] = useState({});
  const [estadofirma, setEstadofirma] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    let params = fromQueryParamToFilter(searchParams);
    async function getUser() {
      const userDB = await getDataObtenerUno("usuarios", params);
      console.log(userDB.data.data);
      setUser(userDB.data.data);
    }
    getUser();
  }, [location, isOpen, refresh]);


  return (
    <>
    {user? 
    <div className="two-rows">
      <div className={`${size.width>800?"two-cols":"one-col"}`}>
        <div className="two-rows">
          <div className="flex border-base">
            <div className="profile_img">
              <img src={user_img} alt="profile" />
            </div>
            <div className={`${size.width>800?"two-cols":"one-col"} w-100`}>
              <div className="profile_basic">
                <div>
                  <span>
                    {user?.nombre || ""} {user?.apellido || ""}
                  </span>
                  <span className="role"> {user?.role?.nombre || "N/A"} </span>
                </div>
                <div>{user?.identificacion || "Sin Identificacion"}</div>
                <div>
                  <span>{user?.direccion || "Direccion No Registrada"}</span>
                </div>
                <div>{user?.correo || "N/A"}</div>
              </div>
              <div className="two-rows">
                {user?._id === userData?._id ? (
                  <>
                    <button
                      onClick={() => setEstadofirma(true)}
                      className="dark-button"
                    >
                      Mi firma
                    </button>
                    <button
                      onClick={() => setPassword(true)}
                      className="dark-button"
                    >
                      Cambiar Contraseña
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="border-base ">
            <div>
              {" "}
              <h5> Informacion Detallada</h5>{" "}
            </div>

            <div className="two-cols ">
              <div>
                <strong>Ciudad </strong>
                <div>{user?.ciudad || "No registra ciudad"}</div>
              </div>

              <div>
                <strong>Teléfono </strong>
                <div>{user?.telefono || "No registra teléfono"}</div>
              </div>

              <div>
                <strong>Contacto </strong>
                <div>{user?.contacto || "No registra Contacto"}</div>
              </div>

              <div>
                <strong>Género </strong>
                <div>{genderDetail[user.genero] || "No registra Género"}</div>
              </div>
              <div></div>
              <div className="flex-end">
                {user._id === userData?._id&&<button
                  className="dark-button"
                  onClick={() => {
                    setUserSelected(user);
                    setIsOpen(true);
                  }}
                >
                  Editar mi perfil
                </button>}
              </div>
            </div>
          </div>
        </div>

        <div className="border-base">
          <h5> Mis Ordenes de Trabajo</h5>
          {user._id ? <ProfileOt user={user._id} /> : <Spinner />}
        </div>
      </div>

      {user.role?.nombre === "Cliente" && (
        <>
         { /*<div className="border-base ">
            <ListSede user={user} refresh={refresh} setRefresh={setRefresh}/>
      </div>*/
      }
          <div className="border-base ">
            <ListaSedesnew user={user} refresh={refresh} setRefresh={setRefresh}/>
          </div>
          <div className="border-base ">
            <h5> Mis Equipos</h5>
            <Historial cliente={user} />
          </div>
        </>
      )}
    {estadofirma===true?
      <Firma
        recibidoin={recibido}
        setRecibidoin={setRecibido}
        setEstadofirma={setEstadofirma}
        estadofirma={estadofirma}
        usersign={"entrega"}
      />:null}

      <Modales estado={isOpen} cambiarestado={setIsOpen}>
        {user.role?.nombre !== "Cliente" ? (
          <RegisterUserForm onModal={(modal) => setIsOpen(modal)} />
        ) : (
          <RegisterClientForm onModal={(modal) => setIsOpen(modal)} />
        )}
      </Modales>

      <Modales
        estado={password}
        cambiarestado={setPassword}
        titulo="Cambiar Contraseña"
      >
        <ChangePassword setIsOpen={setPassword} />
      </Modales>
    </div>:null}
    </>
  ); 
};
