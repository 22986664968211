const Icon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className="dashboards-card-icon"  viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z"/>
        </svg>
    )
}

const CardBackground = () => {
    return(
        <svg className="dashboard-card-background" viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: '100%', width: '100%'}}>
            <defs>
                <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                    <stop stop-color="rgba(243, 106, 62, 1)" offset="0%" />
                    <stop stop-color="rgba(255, 179, 11, 1)" offset="100%" />
                </linearGradient>
            </defs>
            <path d="M-31.88,85.38 C149.99,150.00 349.20,-49.98 544.29,4.47 L500.00,150.00 L0.00,150.00 Z" style={{stroke: 'none', fill: 'url(#sw-gradient-0)'}}></path>
        </svg>
    )
}

export function CardDashboard({value=0, description='Descripción'}) {

    return(
       <div className="dashboards-card-container dashboard-item">
        <div className="dashboards-card-header">
            <div className="dashboards-card-header-value"><h2>{value}</h2></div>
            <Icon />
        </div>
        <CardBackground />
        <div className="dashboards-card-description"><h5>{description}</h5></div>
       </div>
    )
}