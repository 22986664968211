import React from 'react'


//type me sirve para saber que mostrar (uploadfile- vereqson)
export const Listarequipos=({listado, type})=> {
  //console.log(listado)
  return (
    <div>

<table  className='table w-100 table-bordered '>
              <thead className="table-info">
                <tr>
                        <th>Item</th>
                        <th >Marca</th>
                        <th >Modelo</th>
                        <th >Serie</th>
                        <th >Inventario</th>
                        <th >Ubicación</th>
                        <th >Consecutivo</th>
                        <th >{type==='uploadfile'?('Revisión'):('Puntos')}</th>
                        <th >Estado</th>
                        <th >{type==='uploadfile'?<i class="bi bi-pin-map-fill"></i>:null}</th>
                        <th >{type==='uploadfile'?('Ver'):<></>}</th>
                 </tr>
          </thead>
          <tbody> 
          {listado?.map(eq=>(
                  <tr key={eq._id}>
                          <td >{eq.equipo?.item.nombre}</td>
                          <td >{eq.equipo?.marca}</td>
                          <td >{eq.equipo?.modelo}</td>
                          <td >{eq.equipo?.serie}</td>
                          <td >{eq.equipo?.inventario}</td>
                          <td >{eq.equipo?.ubicacion}</td>
                          <td>{type==='uploadfile'?(`${eq.estadoEntrega}`):(`${eq.equipo?.puntos}`)}</td>
                          <td >{eq.consecutivo}</td>
                          <td >{eq.estado}</td>
                          <td>{type=='uploadfile' && eq.equipo.movimiento==='Ingresado'?<i class="bi bi-pin-map-fill"></i>:null}</td>
                          <td>{type=='uploadfile'?(<i className="bi bi-file-earmark-pdf font_sz seach"></i>):<i className="bi bi-eye-fill font_sz"></i>}</td>
                        </tr>
                        ))}
            </tbody>
         </table>
     
    </div>
  )
}
