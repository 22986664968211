const { CREATEACTAS, CREATEOT, CREATEENTRY, CREATEUSER, CREATECLIENT, CREATEITEM, CREATEMAG, CREATEROLE, APROVEEDITEM, CALIBRATE, PROGRAMOT, VIEWENTRY, VIEWROLE, VIEWCLIENT, VIEWCALENDAR, VIEWOT, TECNICHAL } = require('./ConstantRoute');

export const  Permisos =()=> {
   const ListaPermisos=[
    "",
    CREATEACTAS,
    CREATEENTRY,
    CREATEOT,
    CREATEUSER,
    CREATECLIENT,
    CREATEITEM,
    CREATEMAG,
    CREATEROLE,
    APROVEEDITEM,
    CALIBRATE,
    PROGRAMOT,
    VIEWENTRY,
    VIEWOT,
    VIEWCALENDAR,
    VIEWCLIENT,
    VIEWROLE,
    TECNICHAL
  ]
return(ListaPermisos)
}

