import React from 'react'

export  const  Modalcarga = ({children, estadoIn}) => {
  return (
    <>
        {estadoIn &&
            <div className='modalbgch'>
                    {children}
            </div>
        }
    </>
  )
}