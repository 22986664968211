import React, { useState } from 'react'
import { actualizarDatos } from '../../api/common';
import { useAuth } from '../../hooks/useAuth';

const AnularIngreso = ({ingreso, setEstadomodalAn, refrest, setRefrest}) => {

    const {userData} = useAuth();
    const [observacionAnu, setObservacion]=useState()


    const ObservacionAn=(e)=>{
        setObservacion(e.target.value)
    }
    const anularentry=async(e)=>{
        
        e.preventDefault();
        try {
            console.log(observacionAnu)
            const anular=await actualizarDatos('IngresoEquipos', 
            {...ingreso, 
                estado:'Anulado', 
                fechaAnulado:new Date,
                motivoAnulado:observacionAnu,
                anuladoBy:userData._id,
             }, 
            ingreso._id) 

            console.log(anular)
            
            ingreso.equipos.map(async(equipo)=>{
                console.log(equipo.equipo.equipo)
                let actualizado=await actualizarDatos('Equipos',{
                    movimiento:'Cliente',
                    _id:equipo.equipo.equipo._id
               },equipo.equipo.equipo._id)
                 console.log(actualizado)
               })

               
               setRefrest(!refrest);
               setEstadomodalAn(false);

        } catch (error) {
            
        }




    }
  return (
    <div>
        <form type='submit' onSubmit={anularentry}>
            <label>Observación</label>
            <textarea className='w-100' onChange={ObservacionAn} placeholder='Ingresa un motivo para la anulación del registro' required ></textarea>
            <button type='submit' className='btn-btn-tertiary w-100'> Anular ingreso</button>
        </form>
        <button className='btn-btn-primary w-100' onClick={()=>setEstadomodalAn(false)} > Cancelar</button>

      
    </div>
  )
}

export default AnularIngreso
