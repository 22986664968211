import React, { useState } from 'react'
import { Modales } from '../Modales'
import { ConfirmarDel } from './ConfirmarDel'

export const ListaRoles = ({rol})=> {
    console.log(rol)

    const [index, setIndex]=useState()
    const[modalDel,setModalDel]=useState(false)

    const del = (index)=>{
      setModalDel(true)
      setIndex(index)
    }
  return (
  <div>
        <Modales
        estado={modalDel}
        cambiarestado={setModalDel}
        titulo={`Eliminar Permiso`}
        >
        <ConfirmarDel
          index={index}
          rol={rol}
          setModalDel={setModalDel}
        ></ConfirmarDel>
    </Modales>
    <div className='ListaPermisos'>
    {rol?.permisos?.length>0?
      <table>
        <thead>
            <tr>
                <th>Permisos Asignados</th>
            </tr>
        </thead>
        <tbody>
            {rol?.permisos && rol?.permisos?.map((permiso, index)=>(
                <tr key={index}>
                    <td>
                      <div>{permiso}</div>
                      <div onClick={()=>{del(index)}}><label className="bi bi-trash cursor-p"></label></div>
                    </td>
                </tr>
            ))}
        </tbody>
      </table>:
      <div className='Nopermisos'>
        <h2>No tiene permisos asignados </h2>
      </div>
      }
    </div>
    </div>
  )
}

