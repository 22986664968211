import React, { useEffect } from 'react'
import { useState } from 'react';
import Select from 'react-dropdown-select';
import { MultiSelect } from "react-multi-select-component";
import { filterData, getDataList, postDataClientes } from '../../api/common';


export const SelectConsult = ({handledata, collection, name, field, baseFilter, placeholder, dataDefault}) => {
    console.log(placeholder)
    //console.log(baseFilter)
    const [search, setSearch] = useState(null);
    const [options, setOptions]=useState([]);
    const [selected, setSelected]=useState(dataDefault)

    const customStyles = {
        menu: (provided) => ({
          ...provided,
          width: '100%',
        }),
        container: (provided) => ({
          ...provided,
          width: '100%', // Ancho del contenedor del select
        }),
        control: (provided) => ({
          ...provided,
          width: '100%', // Ancho del control del select
        }),
      };

    useEffect(()=>{
        async function consult(){
            const result =  await filterData(`${collection}`,baseFilter)
            console.log("resultado de filtro", result)
            const opt=result.data.data.map((item)=>({
                value:item._id,
                label:collection==='Usuarios'?item[field]+" "+item['apellido']:item[field]
            }));
            setOptions(opt)
        }
        consult()
    },[])

    const mulsele=(e)=>{
      setSelected(e)
      console.log(e)
      if(e.length>0){
      let event = {
        target: {
          name: name,
          value: e[0].value,
        },
      };
      handledata(event);
      }else{
        let event = {
            target: {
              name: name,
              value: '',
            },
          };
          handledata(event);
      }
  }
  return (
    <div >
        <Select
            placeholder={dataDefault}
            options={options}
            defaultValue={dataDefault}
            onChange={mulsele}
            labelledBy="Select"
            styles={customStyles}
      />
    </div>
  )
}

export default SelectConsult