import { useState } from "react";
import { RegisterClientForm } from "../components/Clientes/RegisterClientForm";
import { Navegacion } from "./Navegacion";
import logo from "../img/logo.png";
import { useNavigate } from "react-router-dom";

const Register = () => {
const navigate = useNavigate()
  const [registered, setRegistered] = useState({
    state: false,
    email: "correo@mail.com",
  });
  return (
    <>
      <Navegacion />

      {!registered.state ? (
        <div className="main-container pd-side-20 pdt-5 ">
          <RegisterClientForm logged={false} setRegistered={setRegistered} />
        </div>
      ) : (
        <div className="right ">
          <div className="loginbox">
            <div>
              {" "}
              <img className="imagen" src={logo} alt="logo_biolab"></img>
            </div>
            <div className="text-center">
              <div className="general-title">
                <h3> Registro Exitoso </h3>{" "}
                <div>
                  Su cuenta ha sido registrada exitosamente revisa su correo{" "}
                  <strong>{registered.email}</strong> para activar
                </div>
                <div>
                  <button className="btn-btn-primary" 
                  onClick={()=>navigate('/login')}
                  > Volver a Logín </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
