import React from 'react'
import moment from 'moment'
import 'moment/locale/es';
import { PdfActa } from '../Pdfs/PdfActa';
moment.locale('es')

export const ActasOt=({actasOt})=> {
  console.log(actasOt[0])
  return (
    <div>
      <ul className='actatitle cabeceraitem bg-primary'>
        <li>No Acta</li>
        <li>Tipo Entrega</li>
        <li>Entrega</li>
        <li>Fecha Creación</li>
        <li>Autor</li>
        <li><i class="bi bi-file-earmark-arrow-down font_sz"></i></li>
      </ul>
      {actasOt.map(acta=>(
        <ul className='actatitle sombra-button' key={acta._id}>
          <li>{'A-'+ (acta?.numeroOrden || acta?.orden?.numeroOrden) +'-'+acta?.consecutivo}</li>
          <li>{acta.tipo}</li>
          <li>{acta.entrega}</li>
          <li>{moment(acta.createdAt).format('lll') }</li>
          <li>{acta.autor.nombre+acta.autor.apellido}</li>
          <li><i onClick={(e)=>PdfActa(acta)} class="bi bi-file-earmark-arrow-down font_sz cursor-p"></i></li>
        </ul>
      ))}
    </div>
  )
}
