import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDataObtenerUno } from "../../api/common";
import { getMesage, paramSeeMore } from "../../helpers/changes";

moment.locale('es');

const TracingEquipment = () => {
  const { id } = useParams();
  const [changesEquipment, setChanges] = useState(null);
  const [equipment, setEquipment] = useState(null);

  console.log(changesEquipment)
  useEffect(() => {
    // console.log(id)
    async function getData() {
      const data = await getDataObtenerUno("Changes", {
        object_id: id,
        typeChange: "History_Changes",
      });
      setChanges(data.data.data);
      console.log(data);

      const equipmentData = await getDataObtenerUno("Equipos", {
        _id: id,
      });
      console.log(equipmentData)
      setEquipment(equipmentData.data.data)
    }

    if (id) getData();
  }, [id]);

  return (
    <>
     {equipment && <div className=" pd-none border-bottom-gray general-title"><h3>{equipment.item.nombre} - {equipment.marca} - {equipment.modelo}</h3></div>}
      {changesEquipment &&
        changesEquipment.changes.map((item) => (
          <div key={item._id} className="pt-2 pb-2 border-bottom-gray">
            <span>
              {" "}
              <span>
                {" "}
                <strong>{item.user.name}</strong>{" "}
              </span>{" "}
              <span>{getMesage(item)["main"]}</span>{" "}
              <span className="gray-words">
                {moment(item.date).fromNow()}
              </span>
            </span>
            <div>
              {paramSeeMore.indexOf(item.type)=== -1&&<span
                className="link cursor-p"
                onClick={(e) =>
                  e.target.parentNode.nextSibling.classList.toggle(
                    "display-none"
                  )
                }
              >
                Ver más
              </span>}
            </div>
            {
              <div
                className="display-none"
                dangerouslySetInnerHTML={{ __html: getMesage(item)["detail"] }}
              />
            }
          </div>
        ))}
    </>
  );
};

export default TracingEquipment;
