import React from 'react'
import moment from 'moment'
moment.locale('es');

const DateEnd = (dias) => {
    let n=0;
    let count=0;
    let date=0


    while(count<dias){
       n++
       const dia =moment().add(n,'d').day()
       //console.log(moment().add(n,'d').day())
       if(dia>0 && dia<=5){
        count++
        date=moment().add(n,'d').format()
        //console.log(moment().add(n,'d').format())
       }
       
    }
  return (date)

}

export default DateEnd
