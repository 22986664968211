import moment from "moment";
const mapperMessage = {
  "Create Equipment": "ha creado el equipo",
};

export const paramSeeMore = ["Add equipment to Ot"]

const listDetail = (fields) => {
  return `<div class="pl-2">
    ${fields
      .map(
        (item) =>
          `<div>
        <div class="gray-words"> ${item.field} </div>
        <div>${
          item.field.toString().includes("fecha")
            ? moment(item.prevValue).format("lll")
            : item.prevValue || "Nada"
        } → ${
            item.field.toString().includes("fecha")
              ? moment(item.nextValue).format("lll")
              : item.nextValue
          } </div>
        </div>
        `
      )
      .join("")}
   
    </div>
    `;
};

const getCalibrateDetail = (detail,date) =>(
    `<div class="pl-2">
    <div> Consecutivo → ${detail[0].equipment}  </div>
    <div> Visita  → ${detail[0].visita}</div>
    <div> Fecha   → ${moment(date).format("lll")}</div>
    </div>
    `
);

const getEditEquitment = (cambios) =>{
 let changes ='';
 for(let i=0; i<cambios.length; i++){
  changes=changes+(
    `<div class="pl-2">
    <div> Cambio    → ${cambios[i].field}  </div>
    <div> Anterior  → ${cambios[i].prevValue}</div>
    <div> Actual    → ${cambios[i].nextValue}</div>
    <hr/>
    </div>
    `
    )
 }
  return(changes)
};


const listFieldDetail = (fields) => {
  return `<div class="pl-2">
    ${fields
      .map(
        (item) =>
          `<div>
        <div class="gray-words"> ${item.field} </div>
        <div>${
          item.field
        } → ${
            item.field.toString().includes("fecha")
              ? moment(item.nextValue).format("lll")
              : item.nextValue
          } </div>
        </div>
        `
      )
      .join("")}
   
    </div>
    `;
};

export const getMesage = (inputData) => {
  let output = {};
  switch (inputData.type) {
    case "Create Equipment":
      output["main"] = mapperMessage[inputData.type];
      output["detail"] = listDetail(inputData.changes);
      break;
    case "Add equipment to Ot":
      output[
        "main"
      ] = `ha agregado el equipo a la orden de trabajo  ${inputData.ot} `;
      break;
    case "Programación":
      output["main"] = ` ha programado el equipo en la orden ${inputData.ot}`;
      output["detail"] = listFieldDetail(inputData.changes);
      break;
    case "Close equipment":
      output["main"] = `ha calibrado el equipo en la orden ${inputData.ot}`;
      console.log(getCalibrateDetail(inputData.changes,inputData.date))
      output["detail"] = getCalibrateDetail(inputData.changes,inputData.date);
      break;
    case "Postponed equipment":
      output["main"] = `ha Aplazado el equipo en la orden ${inputData.ot}`;
      output["detail"] = ` <div > Comentario  → ${inputData?.comment} </div>
      <div > Fecha  → ${moment(inputData.date).format("lll")} </div>`;
      break;
    case "Pending equipment":
      output["main"] = `ha colocado pendiente el equipo en la orden ${inputData.ot}`;
      output["detail"] = ` <div > Comentario  → ${inputData.comment} </div>
      <div > Fecha  → ${moment(inputData.date).format("lll")} </div>`;
      break;
    case "equipment updated":
      output["main"] = `ha editado el equipo en la orden ${inputData.ot}`;
      output["detail"] = ` <div > Comentario  → ${inputData.comment} </div>`;
      output["detail"] = getEditEquitment(inputData.changes)
      break;
    case "Activating equipment":
      output["main"] = `ha hábilitado el equipo en la orden ${inputData.ot}`;
      output["detail"] = getCalibrateDetail(inputData.changes,inputData.date);
      break;
    case "Process equipment":
      output["main"] = `ha procesado el equipo en la orden ${inputData.ot}`;
      output["detail"] = getCalibrateDetail(inputData.changes,inputData.date);
      break;
    case "Aproved equipment":
      output["main"] = `ha aprobado el equipo en la orden ${inputData.ot}`;
      output["detail"] = getCalibrateDetail(inputData.changes,inputData.date);
      break;
    case "Delete equipment in Ot":
        output["main"] = `se ha cancelado de la orden ${inputData.ot}`;
        output["detail"] = ` <div > Comentario  → ${inputData.comment} </div>`;
        break;
    case "Entry equipment":
      output["main"] = `se ha hecho ingreso del equipo en la orden ${inputData.ot}`;
      output["detail"] = ` <div > Fecha Ingreso  → ${moment(inputData.date).format("lll")} </div>`;
      break;
    default:
      break;
  }

  return output;
};
