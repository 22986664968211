import React, { useEffect, useState } from "react";
import { paginate } from "../../api/paginate";
import { PARENT } from "../../constants";
import { Pagination } from "../commons/Pagination";
import Spinner from "../commons/Spinner";




export const ProfileOt = ({user}) =>{

    const [page,setPage] = useState(1)
    const [oT ,setOtPage] = useState({})
    

const changePage= (page)=>{
    setOtPage({...oT,results:{}})
    setPage(page)
  }

  
    useEffect(() => {
      async function getPaginatedOt(){
        const paginatedOt = await paginate(`/Ot/paginar?page=${page}&perPage=5`,{conditions:{otType:PARENT,$or:[{cliente:user},{responsable:user},{coordinador:user}]}})
        const data = paginatedOt.data.data;
       // //console.log(paginatedOt)
        setOtPage(data);
      }
      getPaginatedOt()
    },[page]) 

    return(
    <div className="center-table" >
        {oT.results?.length>0?(
            <>
            <ul className='two-cols cabeceraitem '>
                <li > Numero Orden  </li>
                <li > Estado </li>
            </ul>
        {oT.results.map((ot)=>(
            <ul key={ot._id} className="two-cols sombra-button">
                <li >{ot.numeroOrden}</li>
                <li>{ot.estado}</li>
            </ul>
        ))}
        </>):
        oT.results?.length===0?(
            <div>No hay ordenes de trabajo </div>
            ):
        (<Spinner />)
        }

        {oT.results?.length>0?(<Pagination prev={oT.prev} 
                  next={oT.next} 
                  total_pages={oT.total_pages} 
                  changePage={changePage} 
                  page={page} />):null}

    </div>)
}