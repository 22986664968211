import React from 'react'

export  const  Modalfirma = ({children, estadofirma}) => {
  return (
    <>
        {estadofirma &&
            <div className='modalbg'>
                <div className='contentmodal'>
                    {children}
                </div>
            </div>
        }
    </>
  )
}