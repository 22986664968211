import React, { useEffect, useState, useContext } from "react";
import { getDataList } from "../../api/common";
import UserContext from "../../context/User/UserContext";
import Spinner from "../commons/Spinner";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../hooks/useTheme";
import DetailUser from "../commons/DetailUser";
import { statusUser } from "../../constants/optionsForm";
import { changeUserStatus } from "../../api/user";

const ListUsers = ({ onModal = (modal) => modal, modal,roles }) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const { setUserSelected } = useContext(UserContext);
  const { size } = useTheme()

  const changePage = (page) => {
    setUsers({ ...users, results: [] });
    setPage(page);
  };


  const handleStatusUser = async(e,id) =>{
      await changeUserStatus({status:e.target.value.toLowerCase(),userId:id})
      setPage(page+1)
  }

  roles=roles.filter((rol)=>rol.nombre.toString().toLowerCase()==='cliente')
  roles=[roles[0]._id]    //con este valor indico cual rol debe ser excluido

  //roles = getClientRoles(roles,false);   //con esta funcion traigo el listado de todos los roles


  const openSettings = (e) => {
    const display = e.target.children[0].style.display;
    e.target.children[0].style.display = display === "none" ? "block" : "none";
  };

  useEffect(() => {
    async function getUsers() {
      const usersDB = await getDataList(
        'usuarios',
        { conditions: {
          role:{ $ne:roles }
        }, sort:{ } }
      );
      //console.log(usersDB.data.data);
      setUsers(usersDB.data.data);
    }
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, page,setPage]);

  const editUser = (data) => {
    onModal(true);
    setUserSelected(data);
  };
  return (
    <>
      {users && (
        <table className={size.width>800?"my_table":"w-100"}>
          {size.width>800&&<thead>
            <tr className="cabeceraitem">
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Correo</th>
              <th>Teléfono</th>
              <th>Rol</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>}
          <tbody>
            {users.length > 0 ? (
              users?.map((user) => (
                <>{size.width>800?(
                <tr key={user._id}>
                  <th
                   className="under_line"
                   onClick={() => navigate(`/perfil?correo=${user.correo}`)}
                  >{user.nombre || ""}</th>
                  <th>{user.apellido || ""}</th>
                  <th>
                    {user.correo || ""}
                  </th>
                  <th> 
                  <i class="bi bi-telephone-fill mr-5"></i>
                  <span>{user.telefono || "No registrado"}</span></th>
                  <th>
                  <div  className="color-box">
                  {user.role?.nombre || ""}
                  </div>
                
                  </th>

                  <th>
                    <>
                 <select
          defaultValue={user.status?.toUpperCase()}
          onChange={(e) => handleStatusUser(e,user._id)}
          className={`   
                            ${
                              user.status?.toUpperCase() === "ACTIVO"
                                ? " active-user"
                                : " inactive-user"
                            }
                            `}
          name="ciudad"
        >
          {statusUser &&
            statusUser.map((val) => (
              <option value={val?.toUpperCase() }>{val?.toUpperCase() }</option>
            ))}
              </select>

            </>	
                  </th>


                  <th style={{ cursor: "pointer" }}>
                    <div onClick={openSettings} >
                      . . .
                      <div className="fixed-box">
                        <div
                          onClick={() => {
                            editUser(user);
                          }}
                        >
                          {" "}
                          Editar
                        </div>
                        <div>Eliminar</div>
                      </div>
                    </div>
                  </th>
                </tr>
              ):<DetailUser user={user} editUser={editUser}  />}</>))
            ) : (
              <Spinner />
            )}
          </tbody>
        </table>
      )}
      {/*<Pagination
        prev={users.prev}
        next={users.next}
        total_pages={users.total_pages}
        changePage={changePage}
        page={page}
      />*/}
    </>
  );
};
export default ListUsers;
