import io from 'socket.io-client';
import { baseUrl, baseUrlLocal } from './config';     // importamos la URL base 


const initWebsockets=()=>{
    //const socket = io(process.env.NODE_ENV === 'development' ?baseUrlLocal:baseUrl); // Reemplaza con la URL y puerto correctos
    
   const urlConnect = process.env.NODE_ENV === 'development' ?baseUrlLocal.split("/api")[0]:baseUrl.split("/api")[0]
    
    console.log('url conexion socket: ', urlConnect)
    const socket=io(urlConnect, { transports: ['websocket'] })

    socket.on('connection', () => {
      console.log('Conectado al servidor');
    });

    return socket

}

export  {initWebsockets}