import { useContext } from "react"
import HorizontalBox from "../components/commons/HorizontalBox"
import UserContext from "../context/User/UserContext"



const GeneralInformation = () =>{

    const { message } = useContext(UserContext)
    return(
        <>
            <HorizontalBox>
                <div className="text-justify title-2 pd-3 margin-auto text-wrap" dangerouslySetInnerHTML={{__html:message}} />
            </HorizontalBox>
        </>
    )
}


export default GeneralInformation