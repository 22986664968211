import { useState } from 'react';




export function useForm(initialState = {}){
    const [values, setValues] = useState(initialState);
    const [error, setError] = useState({});
    const upperCaseRegex = /(?=.*?[A-Z])/;
    const lowerCaseRegex = /(?=.*[a-z])/;
    const numberRegex = /(?=.*[0-9])/;
    const specialCharRegex = /(?=.*[!@#$%^&*._-])/;


    const handleInputChange = ({target}) =>{
   
        console.log(target);

        if(target){
        setValues({
            ...values,
            [target.name]:target.value
        })

    }


      
    }

    const appendObject = (obj)=>{   
        setError((error)=>({...obj,...error}))
    }

    const handlePasswordChange = ({target}) =>{

        //console.log(target)
        if(!target) return
   
        let password = target.value

        //console.log(password)
        if(password.length < 8){
            error["lenChar"] =  "Minimo 8 caracteres"
            setError(error)
        }
        else{
            error["lenChar"] = null
            setError(error)
        }   
        
  
        if(!upperCaseRegex.test(password)){
            error["upperCase"] = "Al menos una mayúscula"
            setError(error)
        }
        else{
            error["upperCase"] = null
            setError(error)
        }

        if(!lowerCaseRegex.test(password)){
            error["lowerCase"] = "Al menos una minúscula"
            setError(error)
        }
        else{
            error["lowerCase"] = null
            setError(error)
        }

        if(!numberRegex.test(password)){
            error["numberCase"] = "Al menos un número"
            setError(error)
        }
        else{
            error["numberCase"] = null
            setError(error)
        }


        if(!specialCharRegex.test(password)){
            error["specialCase"] = "Al menos un caracter especial"
            setError(error)
        }
        else{
            error["specialCase"] = null
            setError(error)
        }

        
        //console.log(error)
        
        setValues({
            ...values,
            [target.name]:target.value
        })
    }
        //console.log(values);
    

    const setValue = (newValue)=>{
        console.log("asd")
        console.log(newValue)
        setValues({
            ...newValue,
            values
        })

        console.log(values)
    }



    return [ values, handleInputChange,handlePasswordChange, setValues,setValue, error]

}