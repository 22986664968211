import React, { useEffect, useState } from 'react'
import {  deleteFile } from '../../helpers/firebase';
import { Alertassw } from '../Alertas/Alertassw';
import { Modales } from '../Modales'
import { Accesorios } from './Accesorios';
import { Editareq } from '../Equipo/Editareq';
import { getDataList, getDataobtener } from '../../api/common';

export function Inarray(array,objeto){    // Funcion para determinar si el equipo esta checkeado
  return array.some(item=>item.equipo._id=== objeto.equipo._id)
}

export const Equiposot =({ordendata, setOrdendata, setEqselect, Eqselect, setPestaña, bodyingreso, setBodyingreso})=> {

  console.log('Equipos seleccionado: ',Eqselect)
  const [estadomodal, setEstadomodal] = useState(false);
  const[estadomodalEd, setEstadomodalEd] = useState(false);
  const [equipoinfo, setEquipoinfo]=useState()
  const [equnico, setEqunico]=useState({})
  const[refresh, setRefresh]=useState(false)
  const [query, setQuery]=useState("")

  var eqbody=[]

  useEffect(()=>{
 
    console.log(Eqselect)
    async function  consultarOt(){
      const result=await getDataobtener(ordendata._id)
      let Eqdisp= result.data.data.equipos.filter(eqfilter=> eqfilter.estado==='No Finalizado' && eqfilter.equipo.movimiento!=='Ingresado')
      setOrdendata(result.data.data)

      if(Eqselect.length>0){
        const nuevosObjetos = JSON.parse(JSON.stringify(Eqselect));
        for(let i =0 ; i<nuevosObjetos.length; i++){
          for(let j=0; j<Eqdisp.length; j++){
            if(nuevosObjetos[i]._id===Eqdisp[j]._id){
              nuevosObjetos[i].equipo=Eqdisp[j].equipo
            }
          }
        }
        setEqselect(nuevosObjetos)
      }

    }
    consultarOt()
  },[refresh])


  const closeModal = async() =>{
    setEstadomodal(false)
    await deleteFile(equnico.fotos)
    equnico.fotos = []
  }
  const listar=()=>{
    if(Eqselect.length>0){
    Eqselect?.map(eq=>(
      eqbody.push({
        equipo:eq._id,
        accesorios:eq.accesorios,
        observacion:eq.observacion,
        fotos:eq.fotos
      })
    ))
    setBodyingreso({
      ...bodyingreso,
      equipos:eqbody
    })
    setPestaña(2)
  }else{Alertassw('error','Equipos no seleccionados')}
  }

  const handlecheckbox=(event)=>{
    //Con la funcion filter reviso si el equipo esta seleccionado 
    if(event.target.checked===true){      // si el check fue seleccionado 
      const equipo=ordendata.equipos.filter((item)=> item.equipo._id ===event.target.name)
      setEqunico(equipo[0])
      setEstadomodal(true)
    }else{ // si el check ha sido deseleccionado  //si el equipo esta seleccionado lo elimino de la lista 
      const addsel=Eqselect.filter((listsel)=> listsel.equipo._id !== event.target.name)
      setEqselect(addsel)
    }

  }
  const Editequipo=(eq)=>{
    setEquipoinfo(eq)
    setEstadomodalEd(true)
  }

  const keys=["consecutivo", "marca","modelo","serie","sede","resolucion","puntos","inventario","ubicacion","nombre"]
  
  console.log(ordendata.equipos)

  const equiposOt = !query? ordendata.equipos : 

        ordendata.equipos.filter(({equipo})=>
        keys.some((key)=>
        equipo[key]? equipo[key].toLowerCase().includes(query.toLowerCase()):
        equipo?.item[key]? equipo?.item[key].toLowerCase().includes(query.toLowerCase()): false
          )
      );

  
  return (
    <div>
        <Modales
          estado={estadomodalEd}
          cambiarestado={setEstadomodalEd}
          titulo={`Editar equipo`}
        >
          {<Editareq infoOt={ordendata} refrescar={setRefresh} refresh={refresh} infoequipo={equipoinfo} cambiarestado={setEstadomodalEd} />} 
        </Modales>
        <Modales 
        estado={estadomodal} 
        cambiarestado={closeModal}
        titulo={`${'Ingresar : '+ equnico?.equipo?.item?.nombre}`}
        >
          <Accesorios equnico={equnico} Eqselect={Eqselect} setEqselect={setEqselect} setEstadomodal={setEstadomodal} />
        </Modales>

        <input
          type="text"
          placeholder="Ingresa un dato del item a buscar"
          className="form-control"
          onChange={(e)=> setQuery(e.target.value)}
        />
        <hr/>

        <div > 
            <div className='col-9-eqin listaot'>
                <li>Item</li>
                <li>Marca</li>
                <li>Modelo</li>
                <li>Serie</li>
                <li>Inventario</li>
                <li>Consecutivo</li>
                <li>Estado</li>
                <i className="bi bi-pencil-square cursor-p font_sz" ></i>
                <i className="bi bi-arrow-down-square UP_select" onClick={listar}></i>
            </div>
            {equiposOt.map(eq=>(
                <ul className='col-9-eqin sombra-button' key={eq._id}>
                    <li>{eq.equipo.item.nombre}</li>
                    <li>{eq.equipo.marca}</li>
                    <li>{eq.equipo.modelo}</li>
                    <li>{eq.equipo.serie}</li>
                    <li>{eq.equipo.inventario}</li>
                    <li>{eq.equipo.consecutivo}</li>
                    <li>{eq.estado}</li>
                    <i className="bi bi-pencil-square cursor-p font_sz" onClick={()=>Editequipo(eq)}></i>
                    {eq.estado==='No Finalizado' && eq.equipo.movimiento!=='Ingresado'?
                    (<li><input id={eq._id} onChange={handlecheckbox} className='checkboox' name={`${eq.equipo._id}`} type={'checkbox'} value="True" checked={Inarray(Eqselect,eq)}></input></li>):<li>N/A</li>}
              </ul>
            ))}
        </div>
    </div>
  )
}
