import jsPDF from 'jspdf'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { actualizarDatos, actualizarOt, getDataList } from '../../api/common'
import { Listaringreso } from '../Ingresos/Listaringreso'
import { Changes } from './Changes'
import { Listarequipos } from './Listarequipos'
import { downloadPDF } from '../../api/pdf'
import { CHILD, PARENT } from '../../constants'
import { paginate } from '../../api/paginate'
import { Actaentrega } from '../Pdfs/Actaentrega'
import { Modales } from '../Modales'
import { sendEmailOt } from '../../api/sign'
import Email from '../commons/Email'
import Spinner from '../commons/Spinner'
import { Modalcarga } from '../Modales/Modalcarga'
import 'moment/locale/es';
import { FacturasList } from './FacturasList'
import { Alertassw } from '../Alertas/Alertassw'
import { ActasOt } from './ActasOt'
import { useNavigate } from 'react-router-dom'
import { Firma } from '../Ot/firma'
import Sign from '../Ot/Sign'
import { useAuth } from '../../hooks/useAuth'
import { ADMIN, ATTENDEE, MANAGER, METROLOGIST, PRACTICING } from './Constanst'
import DateEnd from '../Ingresos/DateEnd'
import { PdfOt } from '../Ot/PdfOt'

moment.locale('es')

export const SeguimientoOt = ({orden})=> {
    const navigate = useNavigate();
    const[refresh, setRefresh]=useState(true)
    const[tareaMod, setTareaMod]=useState()
    const[estadomodalIn, setEstadomodalIn]=useState(false)
    const [modal, setModal]=useState(false)
    const [modalEmail, setModalEmail]=useState()
    const [id, setId] = useState()
    const [page,setPage] = useState(1)
    const[ordendata,setOrdendata]=useState()
    const[ordenson,setOrdenson]=useState({})
    const[changesot, setChangesot]=useState({})
    const [ingresos, setIngreso]=useState([])
    const[actasOt, setActasOt]=useState()
    const [estadofirma, setEstadofirma]=useState(false)
    const [recibido, setRecibido] = useState({});
    const [datasonSelected, setDatasonSelected] = useState(null);
    const {userData} = useAuth();
    const rol=userData.role.nombre
    

    const downloadPdf = async(e,ot) =>{
      console.log(ot)
      e.preventDefault();
      Alertassw('toast','Descargando OT espera unos segundos')
      PdfOt(ot,ot.entregado.firma, ot.recibido.firma,false)
    }

    const handleSendEmailOt = async(e,id) =>{
  e.preventDefault()
  setId(id)
  setModalEmail(true)
    }
    useEffect(()=>{
        async function getOtdata(){
          setEstadomodalIn(true)
            const DataOt = await getDataList('Ot',{conditions:{numeroOrden:orden, otType:PARENT}})
            const DataOtson = await getDataList('Ot',{conditions:{numeroOrden:orden, otType:CHILD}})
            const changes = await getDataList('Changes',{conditions:{object_id:DataOt.data.data[0]._id}})
            const Actas =  await getDataList('Actas',{conditions:{$or: 
              [ 
                {numeroOrden:orden}, 
                {orden:DataOt.data.data[0]._id} 
              ] 
            }})
            const consulta = await paginate(`/IngresoEquipos/paginar?page=${page}&perPage=10`,{conditions:{numeroOrden:DataOt.data.data[0].numeroOrden}, sort:{fechaIngreso:-1}})        
            console.log(DataOt.data.data[0]._id)
            if(DataOt.data.data.length>0){
                rol===METROLOGIST? setActasOt():setActasOt(Actas.data.data)
                setOrdenson(DataOtson.data.data)
                setChangesot(changes.data.data[0])
                setIngreso(consulta.data.data)
                let infoot=(DataOt.data.data[0])
                setOrdendata(infoot)
                if(!infoot.duracionEst){
                  let timetot=0;
                  infoot?.equipos.map(equipo=>{
                    let timeeq=(equipo?.equipo?.item?.tiempo? parseInt(equipo?.equipo?.item?.tiempo):0)
                    timetot=timeeq+timetot
                  })
                  console.log(timetot)
                  const duracion =await actualizarDatos('Ot',{...infoot, duracionEst:timetot},infoot._id)
                  console.log(duracion)
                  if(duracion.status===200){setRefresh(!refresh)}         
                }
            }
          }
          getOtdata()
          setEstadomodalIn(true)
    },[refresh,estadofirma,orden])
  return (
    <div>
    {ordendata?
    (<div>
        <div className="cuerpoContenido">
          <div className="cabecera">
            <a className="titulo">{`Seguimiento orden No : ${orden}`}</a>
          </div>
          <hr className="linea" />
         </div>
        <Modales 
                estado={modal}
                cambiarestado={setModal}
                titulo={`${tareaMod}`}
        >
        </Modales>
        <div>
          <Modales
            estado={modal}
            cambiarestado={setModal}
            titulo={`${tareaMod}`}
          >
            {tareaMod === "Acta" ? (
              <Actaentrega
                origen={"Ot"}
                dataot={ordendata}
                setModal={setModal}
              />
            ) : null}
          </Modales>
        <div> 
        {rol!==METROLOGIST?
          <div className="col-3-seg border-ot">
            <div>
              <div className="Col-2-sub mg-r-5">
                <p className="titleot" htmlFor="cliente">
                  Cliente:{" "}
                </p>
                <p
                  className="under_line"
                  onClick={() =>
                    navigate(`/perfil?correo=${ordendata?.cliente?.correo}`)
                  }
                  name="cliente"
                >
                  {ordendata?.cliente?.nombre}
                </p>
              </div>
              <div className="Col-2-sub mg-r-5 ">
                <p className="titleot" htmlFor="nit">
                  Autor:
                </p>
                <p name="nit">
                  {ordendata?.coordinador?.nombre +
                    " " +
                    ordendata?.coordinador?.apellido}
                </p>
              </div>
              <div className="Col-2-sub mg-r-5 ">
                <p className="titleot" htmlFor="ciudad">
                  Responsable:
                </p>
                <p name="ciudad">
                  {ordendata?.responsable?.nombre +
                    " " +
                    ordendata?.responsable?.apellido}
                </p>
              </div>
              <div className="Col-2-sub mg-r-5">
                <p className="titleot" htmlFor="ciudad">
                  Estado:
                </p>
                <p name="ciudad">{ordendata?.estado}</p>
              </div>
              <div className="Col-2-sub mg-r-5 ">
                <p className="titleot" htmlFor="sede">
                  Avance:
                </p>
                <p name="sede">{`${Math.round(
                  ordendata?.avance * 100,
                  3
                )}%`}</p>
              </div>
            </div>
            <div>
              <div className="Col-2-sub mg-r-5 ">
                <p className="titleot" htmlFor="sede">
                  Fecha Creada:
                </p>
                <p name="sede">
                  {moment(ordendata?.createdAt).format("lll")}
                </p>
              </div>
              <div className="Col-2-sub mg-r-5">
                <p className="titleot" htmlFor="ciudad">
                  Fecha Inicio:
                </p>
                <p name="ciudad">
                  {moment(ordendata?.fechaEntrega).format("lll")}
                </p>
              </div>
              <div className="Col-2-sub mg-r-5">
                <p className="titleot" htmlFor="ciudad">
                  Fecha cierre:
                </p>
                {ordendata.fechaCierre ? (
                  <p name="ciudad">
                    {moment(ordendata?.fechaCierre).format("lll")}
                  </p>
                ) : (
                  <p>No programada</p>
                )}
              </div>
              <div className="Col-2-sub mg-r-5">
                <p className="titleot" htmlFor="sede">
                  Fecha Entrega:
                </p>
                {ordendata?.fechaRealEntrega ? (
                  <p className="" name="sede">
                    {moment(ordendata?.fechaRealEntrega).format("lll")}
                  </p>
                ) : (
                  <p>Pendiente</p>
                )}
              </div>
            </div>
          </div>:null}
          {/*Mostrar los diferentes componentes */}
          <details>
            <summary>
              <p className="cabecera-100 sombra-button">equipos</p>
            </summary>
            <div className="detalle_style">
              <div>
                <Listarequipos
                  listado={ordendata?.equipos}
                  type={"uploadfile"}
                />
              </div>
            </div>
          </details>
          <details>
            <summary>
              <p className="cabecera-100 sombra-button">Hojas de trabajo</p>
            </summary>
            {ordenson?.length > 0 ? (
              <div>
                {ordenson?.map((orden) => (
                  <details key={orden._id} >
                    <summary >
                      {orden?.estado !== "Abierta" ? (
                        <ul className="col-7HT sombra-button bg-primary" style={{margin:'0'}}>
                          <li>{orden?.numeroOrden + "-" + orden.visitas}</li>
                          <li>
                            {orden?.responsable?.nombre +
                              " " +
                              orden?.responsable?.apellido}
                          </li>
                          <li>{orden?.estado}</li>
                          <li>{moment(orden?.fechaRealEntrega).format("L")}</li>
                          <li>
                            <i className="bi bi-eye-fill font_sz"></i>
                          </li>
                          {(orden?.estado === "Cerrada" && orden?.entregado?.firma)? (
                            <>
                              <li onClick={(e) => downloadPdf(e, orden)}>
                                <i class="bi bi-file-earmark-pdf"></i>
                              </li>
                              <li
                                onClick={(e) => handleSendEmailOt(e, orden._id)}
                              >
                                {" "}
                                <i class="bi bi-envelope"></i>{" "}
                              </li>
                            </>
                          ) : (
                            <><li onClick={()=>{
                              setDatasonSelected(orden)
                              setEstadofirma(true)    
                            }}>
                                <i class="bi bi-pen"></i>
                              </li>
                              <li onClick={(e) => downloadPdf(e, orden)}>
                                <i class="bi bi-file-earmark-pdf"></i>
                              </li>
                              <li>
                               </li></>
                          )}
                        </ul>
                      ) : null}
                    </summary>
                    <div className="detalle_style">
                      <Listarequipos
                        listado={orden.equipos}
                        type={"vereqson"}
                      />
                    </div>
                  </details>
                ))}
              </div>
            ) : (
              <div className="siningreso">
                <h5>No hay hojas de trabajo creadas </h5>
              </div>
            )}
          </details>
          <details>
            <summary>
              <p className="cabecera-100 sombra-button">Ingresos</p>
            </summary>
            <div>
              <Listaringreso
                ingresos={ingresos}
                setIngreso={setIngreso}
                page={page}
                setPage={setPage}
              />
            </div>
          </details>
          {rol!==METROLOGIST?
          <>
            <details>
              <summary>
                <p className="cabecera-100 sombra-button">Actas de entrega</p>
              </summary>
              <div>
                <ActasOt actasOt={actasOt} />
              </div>
            </details>
            <details>
              <summary>
                <p className="cabecera-100 sombra-button">Facturas</p>
              </summary>
              <div>
                <FacturasList facturas={ordendata.factura} />
              </div>
            </details>
          </>:null}
          <details>
            <summary>
              <p className="cabecera-100 sombra-button">Cambios</p>
            </summary>
            <div>
              <Changes changesot={changesot} />
            </div>
          </details>
        </div>
        </div>
    
    </div>) : 
     <Modalcarga 
          estadoIn={true} 
          //cambiarestadoIn={setSpinnerMod}
          >
          <Spinner />
       </Modalcarga>
      }
      <Modales
        estado={modalEmail}
        cambiarestado={setModalEmail}
        titulo="Enviar email"
      >
        <Email id={id} setModal={setModalEmail} />
      </Modales>
   
      <Firma 
        recibidoin={recibido}
        setRecibidoin={setRecibido}
        setEstadofirma={setEstadofirma} 
        estadofirma={estadofirma}  
        ot={datasonSelected}
        usersign={"entrega"}
        />
    </div>
  
  );
}
