import { useEffect, useState } from "react";
import { filterData, postDataClientes } from "../../api/common";
import moment from "moment";
import Select from "react-dropdown-select";

const ClienteSelect = ({disabled,className, collection, field, handledata, baseFilter,name }) => {
  const [options, setOptions] = useState(null);
  const [search, setSearch] = useState(null);
  const [selected, setSelected] = useState("");
  const [listClient, setClient]=useState()
  const ID = moment().unix()

  const client = {
    "role.nombre":"Cliente",
    "type":"foreign",
     "populate":[]
}

  useEffect(() => {

    async function getOptions() {
        const clientesDB = await postDataClientes(client);

        const cl = clientesDB.data.data.map((client)=>({
            value:client._id,
            label:client.nombre
        }));
        
        setClient(cl)
    }

    getOptions();

  }, [search]);

  const handleChangeSearch = (e) => {
    ////console.log(e.target.value);
    setSearch(e.target.value);
    if(!e.target.value) handleSetValue("",undefined)
  };

  const handleSetValue = (correo, id) => {
    setSearch(null);
    setSelected(correo);
    document.getElementById(`input-text-${ID}`).value = correo;
    let event = {
      target: {
        name: name,
        value: id,
      },
    };
   // //console.log(event)
    handledata(event);
  };

  const onDropdownchange = (value) => { 
        console.log(value)
     if (value.length>0){        // evento que ocurre cuando se selecciona el cliente con select
        handledata({
            target:{
             name:"cliente",
             value:value[0].value
            }
        }
        );
  } else{
    handledata({
        target:{
         name:"cliente",
         value:undefined
        }
    });
    
    }
}
  return (
    <div className="border-none">
        <Select  searchable='true' onChange={onDropdownchange} options={listClient}/>
    </div>
  );
};

export default ClienteSelect;
