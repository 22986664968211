import React, { useEffect, useState } from 'react'
import { getDataList } from '../../api/common'
import { CHILD } from '../../constants'
import moment from 'moment'
import { useAuth } from '../../hooks/useAuth'
import { AprobarSeleccion } from '../EqInOt/AprobarSelect'

export const AprovedVerified=(orden)=>{
  console.log(orden)
let filter=orden.equipos.filter(equipo=>equipo.estado==='Finalizado')
  if (filter.length>0){
    return(true)
  }
  else{
    return (false)
  }
}
export default function HojasTrabajo({Orden,SetModalcharge,setRefresh,refresh}) {
  const  [Ordenes, setOrdenes] = useState([])
  let userData  = useAuth();
  let result = userData.userData;

    useEffect(()=>{
      if(Orden){
        async function consultaHt(){
            const DataOtson = await getDataList('Ot',{conditions:{numeroOrden:Orden, otType:CHILD, estado:'Cerrada'}})
            setOrdenes(DataOtson.data.data)
            console.log(DataOtson)
        }
        consultaHt()
      }       
    },[Orden, refresh])

  return (
    <div>
      <div class="accordion" id="accordionExample">
        {Ordenes?.map((orden, index)=>(

          <div className="accordion-item" key={index}>
          <div className="d-flex justify-content-between align-items-center accordion-header">
            <button
              className={`accordion-button collapsed`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index + 1}`}
              aria-expanded='false'
              aria-controls={`collapse${index + 1}`}
            >
              <div>{orden.numeroOrden +"-"+ orden.visitas + " -----> " +orden.responsable.nombre +" "+orden.responsable.apellido + " -----> " + moment(orden.createdAt).format('lll')}</div>
      
            </button>
            {AprovedVerified(orden)? <button className="btn-btn-secundary ml-2"onClick={()=>AprobarSeleccion(orden.equipos,result, orden.numeroOrden,orden.visitas,SetModalcharge,setRefresh,refresh, orden.equipos)} >Aprobar</button>:null}
          </div>
          
          <div
            id={`collapse${index + 1}`}
            className={`accordion-collapse collapse `}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <table className="table table-striped table-hover">
            <thead class="table-secondary" >
              <th>Item</th>
              <th>Marca</th>
              <th>Modelo</th>
              <th>Serie</th>
              <th>Inventario</th>
              <th>Ubicacion</th>
              <th>Resolución</th>
              <th>Puntos</th>
              <th>Consecutivo</th>
              <th>Estado</th>
              <th className="bi bi-eye-fill"></th>
            </thead>
            <tbody>
              {orden?.equipos.map((equipo, index)=>(
                <tr key={index}>
                  <td>{equipo.equipo.item.nombre}</td>
                  <td>{equipo.equipo.marca}</td>
                  <td>{equipo.equipo.modelo}</td>
                  <td>{equipo.equipo.serie}</td>
                  <td>{equipo.equipo.inventario}</td>
                  <td>{equipo.equipo.ubicacion}</td>
                  <td>{equipo.equipo.resolucion}</td>
                  <td>{equipo.equipo.puntos}</td>
                  <td>{equipo.consecutivo}</td>
                  <td>{equipo.estado}</td>
                  <td>{equipo.observacion ? ( <i className="bi bi-eye-fill cursor-p " ></i>):null}</td>

                </tr>
              ))}
            </tbody>
            </table>
           </div>
          </div>
        </div>
        
        ))}
       </div>
       <hr/>
       <br/>

    </div>
  )
}
