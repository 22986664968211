import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_token from "jwt-decode";
import HorizontalBox from "../components/commons/HorizontalBox";
import { useForm } from "../hooks/useForm";
import UserContext from "../context/User/UserContext";
import { sendLoginVerification, sendVerificationCode } from "../api/user";
import { setToken } from "../helpers/helpers";
import { Alertassw } from "../components/Alertas/Alertassw";
import { useTheme } from "../hooks/useTheme";


const TwoFactorValidation = () => {
  const navigate = useNavigate();
  const { preLogin,setUserData } = useContext(UserContext);
  const {size} = useTheme()

  const [twoFactor, setTwoFactor] = useState({ window: 1 });
  const [showError, setShowError] = useState({
    show:false,
    message:''
  });
  const [values, handleInputChange] = useForm({});

  const sendVerifyCode = async () => {
    try{
    await sendLoginVerification({
      type: values.auth_mode,
      email: preLogin.email,
    });

    setTwoFactor({ ...twoFactor,window:2})
    Alertassw('toast','Validación','Se ha enviado el código de validación','success')

  }
    catch(err){
        setShowError({show:true,message:'Error Enviando Código de Verificación'})
        setTimeout(() => {
          setShowError({show:false,message:''})
        }, 1000);
    }
  };

  const verifyLogin = async () => {
    console.log(values);
    try{
    const res = await sendVerificationCode({
      code: values.code,
      email: preLogin.email,
    });

    setToken(res.data.token);
    setUserData(jwt_token(res.data.token));
    Alertassw('toast','Validación','Código validado','success')

    navigate("/");
    
    }catch(err){
      setShowError({show:true,message:'Error Validando Código'})
      setTimeout(() => {
        setShowError({show:false,message:''})
      }, 1000);
    }
   
  };

  return (
    <HorizontalBox>
      <div className="ml-5 title-1 mt-2">
        {" "}
        Estas intentando acceder a BIOLAB{" "}
      </div>
      <div className="ml-5 title-2 ">
        {" "}
        para tu tranquilidad te enviaremos un código de seguridad
      </div>

      {showError.show &&<div className="center-all mt-2">
        <div className="error w-50 title-3">{showError.message}</div>
      </div>}

      {twoFactor.window === 1 ? (
        <div className={`${size.width<800?'one-col':'two-cols'} ml-5 mt-5`} onChange={handleInputChange}>
          {/*<div>
            <div className="title-2 mb-2">POR SMS (no disponible)</div>
            <input
              type="radio"
              value="Phone"
              name="auth_mode"
              disabled
            />{" "}
            <span className="title-3">{preLogin?.enc_phone}</span>
      </div>*/}
          <div>
            <div className="title-2 mb-2">POR CORREO</div>
            <input type="radio" value="Email" name="auth_mode" />{" "}
            <span className="title-3">{preLogin?.enc_email}</span>
          </div>
        </div>
      ) : (
        <div className="center-all ml-2 mt-2 ">

          <div className={`form_group ${size.width<800?"w-90":"w-50"}`}>
            <div className="form_group_label title-2">Código de Verificacion</div>
            <input type="text"
             name="code"
              onChange={handleInputChange}
               placeholder="Ingresa tu código"
               defaultValue={""}
               />
          </div>
        </div>
      )}

      <div className={`${size.width<800?'flex-vertical':''} center-all mt-5 mb-5`}>
       
        <button
           disabled={!values.auth_mode}
          className= {`btn-btn-primary big-button ${values.auth_mode?'':'bg-gray'} ${size.width<800?"w-90":""}`}
          onClick={twoFactor.window === 1 ? sendVerifyCode : verifyLogin}
        >
         {twoFactor.window === 1 ? 'Enviar' : 'Validar'}
        </button>

        {twoFactor.window === 2&&<button
       
          className= {`btn-btn-primary big-button ${size.width<800?"w-90 ":""}`}
          onClick={sendVerifyCode}
        >
          Reenviar
        </button>}

      </div>
    </HorizontalBox>
  );
};

export default TwoFactorValidation;
