import React, { useContext, useEffect, useState } from "react";
import { useForm } from "../../hooks/useForm";
import { useNavigate } from 'react-router-dom';
import UserContext from "../../context/User/UserContext";
import { actualizarDatos } from "../../api/common";
import { adminCreate } from "../../api/user";
import { Modalcarga } from "../Modales/Modalcarga";
import Spinner from "../commons/Spinner";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import TabForm from "../commons/TabForm";
import TabPageForm from "../commons/TabPageForm";
import TabThree from "./TabThree";
import { Alertassw } from "../Alertas/Alertassw";
import { Modales } from "../Modales";
import { termsAndConditions } from "../../constants/text";

export const RegisterClientForm = ({ onModal = (modal) => modal, logged=true,setRegistered }) => {
  const { userSelected } = useContext(UserContext);
  const [values, handleInputChange] = useForm(userSelected);
  const [estadomodalIn, setEstadomodalIn] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [checkedDetails, setCheckedDetails] = useState(false)
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const TOTAL_TABS = 3;

  console.log(values)

  const handleSubmit = async (e) => {
    setEstadomodalIn(true);
    e.preventDefault();
    try{
    if (userSelected?._id) {
     await actualizarDatos("usuarios", values, userSelected._id);
      onModal(false);
      setEstadomodalIn(false);
    } else {
      await adminCreate(values);
      if(!logged) setRegistered({ state: true,email: values.correo})
      Alertassw('toast','Registro de Cliente',"Cliente Registrado Exitosamente",'success')
      onModal(false);
      setEstadomodalIn(false);
    }}catch(error){
      Alertassw('toast','Registro de Cliente',error.response?.data?.detail || "Error Registando Cliente",'error')
      setEstadomodalIn(false);
     
    }
  };

  useEffect(()=>{
    if(logged) setCheckedTerms(true)
  })


  return (
    <>
      <form className="form_container" onSubmit={handleSubmit}>
        <h3>{userSelected?._id ? "Editar" : "Registrar"} Cliente</h3>
        <TabForm
          tabs={TOTAL_TABS}
          setTab={setTab}
          tab={tab}
          titles={[
            { title: "Datos Generales", icon: "" },
            { title: "Datos de contacto", icon: "bi-person-lines-fill" },
            { title: "Datos de Facturación", icon: "bi-credit-card-fill" },
          ]}
        />

        <TabPageForm idTab={1} currentTab={tab}>
          <TabOne userSelected={values} handleInputChange={handleInputChange} />
        </TabPageForm>

        <TabPageForm idTab={2} currentTab={tab}>
          <TabTwo userSelected={values} handleInputChange={handleInputChange} />
        </TabPageForm>

        <TabPageForm idTab={3} currentTab={tab}>
          <TabThree
            userSelected={values}
            handleInputChange={handleInputChange}
          />
        </TabPageForm>

        {(TOTAL_TABS=== tab&&logged===false)&&<div  className="form_group text-left under_line" > <input type="checkbox" onChange={()=>setCheckedTerms(!checkedTerms)}/> <span onClick={()=>setCheckedDetails(true)}> Términos y Condiciones </span></div>}

        <div className="form_group mt-5 flex space-around" >
          <span className={` btn-form ${tab<=1?'disabled_button':''}`}
          onClick={()=> setTab(tab-1) }
          >
            Anterior
          </span>

          {TOTAL_TABS=== tab?<button  className={`${!checkedTerms?'disabled_button':''}`} disabled={!checkedTerms} type="submit">
            {userSelected?._id ? "Editar" : "Guardar"}
          </button>:<span className={` btn-form`}
          onClick={()=> setTab(tab+1) }
          >
            Siguiente
          </span>}
        </div>
      </form>
      
      <Modales titulo={"Términos y Condiciones"} estado={checkedDetails} cambiarestado={setCheckedDetails} >
      <div className="text-justify" dangerouslySetInnerHTML={{__html:termsAndConditions}} />
      
      </Modales>
      <Modalcarga estadoIn={estadomodalIn} cambiarestadoIn={setEstadomodalIn}>
        <Spinner />
      </Modalcarga>
    </>
  );
};
