module.exports = {
    CREATEACTAS:'Crear Actas',
    CREATEENTRY:'Crear Ingresos de Ítems',
    CREATEOT: 'Crear Orden de trabajo',
    CREATEUSER:'Crear Usuario',
    CREATECLIENT:'Crear Cliente',
    CREATEITEM:'Crear Ítem',
    CREATEMAG:'Crear Magnitud',
    CREATEROLE:'Crear Roles', 
    APROVEEDITEM:'Aprobar Ítems',
    CALIBRATE:'Calibrar Ítems',
    PROGRAMOT:'Porgramar Ordenes de trabajo',
    VIEWENTRY:'Ver ingresos',
    VIEWOT:'Ver ordenes de trabajo',
    VIEWCALENDAR:'Ver calendario',
    VIEWCLIENT:'Ver lista de clientes',
    VIEWROLE:'Ver roles',
    TECNICHAL:'Gestion Tecnica'
  }