import { CHILD } from "../../constants"

export const estados = ({
    REQUESTED:'Solicitud',
    Solicitud:'Programar',
    Programada: 'Iniciar ',
    Abierta:'Calibrar',
    Cerrada:'Lista para cerrar',
    Pagada:'Entragada'
})

export const estadosicon = ({
    REQUESTED:'bi-calendar2-week',
    Solicitud:'bi-calendar2-week',
    Programada: 'bi-caret-right-square ',
    Abierta:'bi-check2'
})

export const estadoEq=({
    

})
export const queryson = (orden) => ({
    conditions:{
        numeroOrden:orden,
        otType:CHILD
    },
    fields:null,
    options:{
        limit:1,
        sort:{
            visitas:-1
        }
    }
})

