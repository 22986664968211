import React, { useEffect, useState } from 'react'
import { crearDatos, getDataList } from '../api/common'
import { Modales } from '../components/Modales'
import { EditarRoles } from '../components/Roles/EditaRoles'
import Spinner from '../components/commons/Spinner'
import { Modalcarga } from '../components/Modales/Modalcarga'
import { Alertassw } from '../components/Alertas/Alertassw'
import { ListaRoles } from '../components/Roles/ListaRoles'
import { InactivarRol } from '../components/Roles/InactivarRol'

export const Rolescreate=()=> {
    const [estadomodal, setEstadomodal]=useState(false)
    const [getroles, setGetroles]=useState([])
    const [datarole, setDatarole]=useState({})
    const [refresh, setRefresh]=useState(false)
    const[modaleEdit, setModalEdit]=useState(false);
    const [modalDel,setModalDel]=useState(false);
    const[rolData, setRolData]=useState();
    const[spinnerMod, setSpinnerMod]=useState(false);
    const[modalPermisos,setModalPermisos]=useState(false)
  
    const[infomodal, setInfomodal]=useState({})
    const viewDescripcion=(rol)=>{
        //console.log(rol)
        setInfomodal(rol)
        setEstadomodal(true)
    }
    const viewPermisos=(rol)=>{
        console.log(rol)
        setRolData(rol)
        setInfomodal(rol)
        setModalPermisos(true)
    }
    const saveRol=(e)=>{
        e.preventDefault();
        //console.log(datarole)
        try {
            async function postRol(){
                setSpinnerMod(true)
                const saverol=await crearDatos('roles',datarole)
                if(saverol.status===200){
                    setRefresh(!refresh)
                }
                Alertassw('success', 'Rol creado correctamente')
                setSpinnerMod(false)
            }
            postRol();
            
        } catch (error) {
            Alertassw('error','Error en la creación','Verifica los campos e ingresa los valores correctos')
            setSpinnerMod(false)
        }

    }
    const Inactivar=(rol)=>{
        setRolData(rol);
        setModalDel(true);
    }
    const EditRol=(rol)=>{
        setRolData(rol);
        setModalEdit(true);
    }
    const dataRol=(e)=>{
        setDatarole({
            ...datarole,
            [e.target.name]:e.target.value
        })
    }
    useEffect(()=>{
        async function getRoles(){
            const result =await getDataList('roles')
            setGetroles(result.data.data)
        }
        getRoles()
    },[refresh])
  return (
    <div>
        <Modalcarga
          estadoIn={spinnerMod} 
          cambiarestadoIn={setSpinnerMod}>
          <Spinner />
       </Modalcarga>
        <Modales
        estado={estadomodal} 
        cambiarestado={setEstadomodal}
        titulo={`Descripcion del rol: ${infomodal.nombre} `}
        >
            <h6 className='descripcionmodal'>{infomodal.descripcion}</h6>
        </Modales>
        <Modales
            estado={modaleEdit}
            cambiarestado={setModalEdit}
            titulo={`Editar rol`}>
        <EditarRoles 
                rolData={rolData} 
                cambiarestado={setModalEdit}  
                setRefresh={setRefresh} 
                refresh={refresh}
                setSpinnerMod={setSpinnerMod} />
        </Modales>
        <Modales
            estado={modalDel}
            cambiarestado={setModalDel}
            titulo={`Inactivar Rol`}>
        <InactivarRol 
                dataRol={rolData} 
                cambiarestado={setModalDel}  
                setRefresh={setRefresh} 
                refresh={refresh}
                setSpinnerMod={setSpinnerMod}
                />
        </Modales>
        <Modales
            estado={modalPermisos}
            cambiarestado={setModalPermisos}
            titulo={`${rolData?.nombre}`}
        >
            <ListaRoles
            rol={rolData}
            ></ListaRoles>
        </Modales>
        <div className='cabecera'>
            <label className='titulo'>Roles</label>
        </div>
        <br/>
        <h6>Crear nuevo rol</h6>
        <div >
            <form className='rol-header border-ot ' onSubmit={saveRol}>
                <input type={'string'} className='datauseri pd-5px' onChange={dataRol}  name='nombre' placeholder='Nombre del Rol' required/>
                <input type={'string'} className='datauseri pd-5px' onChange={dataRol}  name='area' placeholder='Área del Rol' required/>
                <textarea className='datauseri pd-5px' onChange={dataRol} type={'string'} name='descripcion' placeholder='Breve descripcion de este rol' required/>
                <div className='botonrol'><button className='btn-btn-secundary  submit w-100' type='submit'>Crear Rol</button></div>
            </form>
      </div>
      <br/>
      <h6>Roles creados</h6>
   
      <div className='conten_mg flex wrap space-around' >
        {getroles && getroles?.map(rol=>(
                <div className='card  mb-5 mt-5' key={rol._id}>
                        <label className='title'>{rol.nombre}</label>
                        <div className='contenido'>
                            <label className='contenido_i bold ' htmlFor='consecutivo'>Area:</label>
                            <label className='contenido_i capitalize' name='consecutivo'>{rol?.area}</label>
                        </div>
                        <div className='contenido'>
                            <label className='contenido_i bold ' htmlFor='consecutivo'>Estado:</label>
                            <label className='contenido_i capitalize' name='consecutivo'>{rol.status}</label>
                        </div>
                        <div className='contenido'>
                                <label className='contenido_i bold' htmlFor='numero'>Descripción:</label>
                                <i onClick={()=>viewDescripcion(rol)} className="bi bi-eye-fill descripcion "></i>
                        </div>
                        <div className='contenido'>
                                <label className='contenido_i bold' htmlFor='permisos'>Permisos:</label>
                                <i onClick={()=>viewPermisos(rol)} className="bi bi-list-ul descripcion "></i>
                        </div>
                        {rol.status==='inactivo'?
                            <button className='btn-btn-primary' onClick={()=>Inactivar(rol)}>Activar</button>:
                        <div className='botones'>
                            <i onClick={()=>EditRol(rol)} className="bi bi-pencil-square togEdit cursor-p"></i>
                            <i className="bi bi-archive-fill togDel cursor-p" onClick={()=>Inactivar(rol)}></i>
                        </div>}
                 </div>
        ))}

      </div>
      
    </div>
  )
}
