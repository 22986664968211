import { renewToken } from "../../api/user";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const RenewToken = ({setModal}) =>{
    const navigate = useNavigate()
    const { setUserData } = useAuth() 

    async function handleRenewToken(){
        try{
        const token = await renewToken({token:localStorage.getItem('token')})
        localStorage.setItem('token',token.data.token)
        setModal(false)
        }catch(error){
            localStorage.clear()
            navigate("/Login")
        }
    }

    function returnLogin(){
        console.log("ada")
        setUserData({})
        localStorage.removeItem('token')
        navigate("/Login")
    }

    return(
        <div className="center-all">
            <button onClick={handleRenewToken} className="btn-btn-primary"> Renovar Sesión </button>
            <button onClick={returnLogin} className="btn-btn-primary"> Volver a inicio de Sesión </button>
        </div>);

}


export default RenewToken;