import { useEffect, useState } from "react"
import { getTokenToExternal, postTokenToExternal } from "../../api/user"



export const GetToken =  ({ onModal = (modal) => modal }) => {

    
    const [externalToken, setExternalToken] = useState('')
    const getNewToken   = async() =>{
        const tokenExternalData = await getTokenToExternal()
        setExternalToken(tokenExternalData.data.token)
        }

        const postNewToken = async() =>{
            const tokenExternalData = await postTokenToExternal()
            setExternalToken(tokenExternalData.data.token)
            }



    return(
        <div>
        <div  className="form_group">
            <div className="form_group_label text_black title-2 text-center "> Token Externo </div>
            
            <div className="flex space-around">            
                <input 
            type="text"
            name="externalToken"
            defaultValue={externalToken}
            placeholder="Generar token"
            />
            <div className="pl-2 pr-2" onClick={
                () => {   navigator.clipboard.writeText(externalToken) }
            }>
            <i class="bi bi-clipboard title-2" style={{color:'black'}}></i>
            </div>
            </div>

        </div>

        <div className="two-cols w-100">           
             <div className="btn-btn-primary" onClick={
                getNewToken
             }>
                Obtener
            </div>

            
            <div className="btn-btn-primary" onClick={
                postNewToken
             }>
                Generar
            </div>

            </div>

        </div>
    )
}