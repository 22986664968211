import React, { useEffect, useState } from 'react'
import { SettingCreate } from './SettingCreate'
import { Modales } from '../Modales'
import { getDataList } from '../../api/common'

export const SettingNot = () => {
  const [estadomodal, setEstadomodal]=useState(false)
  const [refresh, setRefresh] = useState(false)
  const [dataSet, setDataSet] = useState([])
  const [itemSeleccionado, setItemSeleccionado] = useState()

  const extraccionArray=(datos,field, field_2 )=>{
    console.log(datos)
    if(datos){
    const nombres = field_2? datos?.map(item => item[field] +" "+item[field_2]): datos?.map(item => item[field]);
    const cadenaNombres = nombres.join(', ');
    if(cadenaNombres?.length >0){
      return `${cadenaNombres}`;
    }else{
      return "No Asignado"
    }
  }else return "No Asignado"
}
    
  useEffect (()=>{
    async function consultarSet(){

      const  respuesta=await getDataList('NotificacionSet')
      console.log(respuesta)
      setDataSet(respuesta.data.data)
    }

    consultarSet()
  },[refresh])

  const editarRegistro=(data)=>{
    console.log(data)
    setItemSeleccionado(data)
    setEstadomodal(!estadomodal)
  }
  const  crearRegistro=()=>{
    setItemSeleccionado()
    setEstadomodal(true)
  }


  return (
    <div>
      <button className='btn-btn-primary' onClick={()=>crearRegistro()}>Crear Setting</button>
        <Modales
          estado={estadomodal} 
          cambiarestado={setEstadomodal}
          validation={true}
          titulo={itemSeleccionado?"Editar set de Notificaciones":`Crear set de Notificaciones`}
        >
          <SettingCreate setVentana={setEstadomodal} setRefresh={setRefresh} refresh={refresh} dataEdit={itemSeleccionado}/>
        </Modales>    
        {/*crear codigo html con una tabla y el body es un es un map de dataSet? */ }   
        
        <table class="table">
            <thead class="table-primary">
              <tr>
                <th scope="col">Set</th>
                <th scope="col">Usuarios</th>
                <th scope="col">Roles</th>
                <th scope="col">Areas</th>
                <th><i className="bi bi-pencil-square font_sz" ></i></th>
              </tr>
            </thead>
            <tbody>
              {dataSet?.map((item, index)=>{
                return(
                    <tr key={index}>
                      <td>{item.nameNotificacion}</td>
                      <td>{extraccionArray(item?.users, 'nombre', "apellido")}</td> 
                      <td>{extraccionArray(item?.roles, 'nombre')}</td> 
                      <td>{extraccionArray(item?.areas, 'nombre')}</td>   
                      <td><i className="bi bi-pencil-square font_sz cursor-p"  onClick={() => editarRegistro(item)}></i></td>  
                    </tr>)
                })
              }
            </tbody>
        </table>
    </div>
  )
}

