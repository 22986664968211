import React from "react";

export const Pagination = ({ prev, next, total_pages, page, changePage }) => {
  return (
    <div>
      <div className="paginate-group size-2x">
        <span
          className={prev ? "arrow-button" : "disabled_button"}
          onClick={() => changePage(page - 1)}
        >
          <i className="bi bi-caret-left-fill"></i>
        </span>
        <span>
          {page} de {total_pages}
        </span>
        <span
          className={next ? "arrow-button" : "disabled_button"}
          onClick={() => changePage(page + 1)}
        >
          <i className="bi bi-caret-right-fill"></i>
        </span>
      </div>
    </div>
  );
};
