import './App.css';
import React from "react";
import { AppRouter } from './routers/AppRouter';
import UserState from './context/User/UserState';
import ThemeState from './context/theme/ThemeState';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <ThemeState>
      <UserState>
        <AppRouter />
      </UserState>
    </ThemeState>
  );
}

export default App;
