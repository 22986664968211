

const Information = ({text="Mensage"}) =>{

    return(
        <div className = "w-100 center-all hvw-100" >
           {text}     
        </div>
    )

}

export default Information;