import React, { useEffect, useState } from 'react'
import {RegisterUserForm} from '../components/users/RegisterUserForm'
import ListUsers from '../components/users/ListUsers'
import { Modales } from '../components/Modales'
import { useAuth } from '../hooks/useAuth'
import { getDataList } from '../api/common'

export const Users = () => {

  const [isOpen, setIsOpen] = useState(false);
  const { setUserSelected} = useAuth();  
  const [roles,setRoles] = useState(null);


   const setModal = (modal ) =>{
    setIsOpen(modal)
    setUserSelected({})
    ////console.log(isOpen)
   } 

   useEffect(()=>{
    async function getRoles(){
      const dbRoles = await getDataList('Roles',{})
      setRoles(dbRoles.data.data)
      //console.log(dbRoles.data.data)
    }

    getRoles()


  },[])

  return (
    <div>
      <div className='cabecera'>
        <span className='titulo'>Usuarios</span>
      </div>
        <button className='dark-button mb-3 mt-5' 
        onClick={()=> setModal(true)}
        >Crear Usuario</button>

        {roles&& <ListUsers 
        onModal={(modal)=>setModal(modal)}
        modal={isOpen}
        roles={roles}
         />}
      
      <Modales  estado={isOpen}  cambiarestado={setIsOpen}>
          <RegisterUserForm      onModal={(modal)=>setModal(modal)} />
      </Modales>
       
    </div>
  )
}




