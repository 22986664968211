import { Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,LinearScale,BarElement,Title, ChartDataLabels);

const options = {
  responsive: true, 
  maintainAspectRatio: true,
  aspectRatio: 1,
  plugins: {
    legend: {
      position: 'bottom'
    },
    datalabels: {
      display: false,
      color: 'black',
      backgroundColor: 'white',
      borderRadius: '100',
      padding: '4',
      textAlign: 'center',
      anchor: 'end',
      borderWidth: '1',
      borderColor: 'white',
      textStrokeWidth: 'center'
  }
  }
}



export function CircularChart({title='Grafico', labels:dataLabels, data}) {
  const labels = data && dataLabels.map( (label, index) => `${label}: ${data[index]}`);
  console.log(labels)
  const dataDefault = {
    labels: labels || ['Value1', 'Value2','Value3','Value4','Value5','Value6', 'Value6'],
    datasets: [
      {
        data: data || [1,1,1,1,1,1],
        backgroundColor: [
          'rgba(10, 128, 191, 1)',
          'rgba(10, 191, 57, 1)',
          'rgba(103, 0, 180)',
          'rgba(197, 197, 197)',
          'rgba(32, 28, 111, 1)',
          'rgba(223, 223, 0, 1)', 
          'rgba(193, 53, 8, 1)', 
        ],
        hoverOffset: 6,
        borderWidth: 1,
      },
    ],
  }

  return(
    <div className='chart-circular-container-main dashboard-item'>
      <span className='chart-header'>{title}</span>
      <div className='chart-circular-container'>
        <Doughnut data={dataDefault}  options={options}/>
      </div>
    </div>
  )
}