import React, { useState } from 'react';
import { Form, InputGroup, Button, ListGroup, Spinner } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa'; // Librería para íconos
import { buscarConsecutivo, getDataList } from '../../api/common';
import { Alertassw } from '../Alertas/Alertassw';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(query)
  // Función para buscar en la base de datos
  const searchDatabase = async () => {
    if (query.trim() === '') {
      setResults([]);
      return; // Evitar búsqueda vacía
      }

    setLoading(true);
    try {
      const response = await buscarConsecutivo('EqInOt', {consecutivo:query});
      console.log(response)
      if(response.data.data){
        setResults(response.data.data); // Asume que la respuesta es un array de resultados
      }else{
        Alertassw('error','Informacion no encontrada','Verifica e intenta de nuevo');
      }
    } catch (error) {
      console.error('Error en la búsqueda:', error);
    } finally {
      setLoading(false);
    }
  };

  // Manejador de envío del formulario (cuando se presiona Enter)
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evitar que el formulario se envíe
      searchDatabase(); // Ejecutar la búsqueda
    }
  };

  return (
    <div className="search-bar mb-3 mt-3 mr-4">
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Buscar..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress} // Detectar cuando se presiona Enter
          aria-label="Buscar"
        />
        <Button variant="outline-secondary" onClick={searchDatabase}>
          <FaSearch /> {/* Ícono de lupa */}
        </Button>
        {loading && (
          <InputGroup.Text>
            <Spinner animation="border" size="sm" />
          </InputGroup.Text>
        )}
      </InputGroup>
      
      {results.length > 0 && (
        <ListGroup className="results-dropdown mt-1">
          {results.map((result, index) => (
            <ListGroup.Item action key={index}>
              {result.equiposDetalles.consecutivo } {/* Muestra la propiedad que corresponda */}
              {' '}
              {result.equiposDetalles?.equipo?.item?.nombre } 
              {' ('}
              {result?.equiposDetalles?.equipo?.cliente?.nombre}
              {' -> '}
              {result.numeroOrden}
              {')'}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default SearchBar;



/*  Body agregate

const response = await getDataList(`EqInOt`,{conditions:{consecutivo:'MPR720'}}

[{
  $match: {
    otType: "Parent"                     // Filtra las órdenes de trabajo que tengan otType igual a "PARENT"
  }
},
{
  $lookup: {
    from: "equipmentinots",               // La colección con la que haces la unión
    localField: "equipos",                // El campo en 'ot' que contiene los ObjectId de equipinot
    foreignField: "_id",                  // El campo en 'equipinot' que es el ObjectId
    as: "equiposDetalles"                 // El nombre del campo donde quieres poner los datos combinados
  }
},
{
  $unwind: "$equiposDetalles"             // Descompones el array 'equiposDetalles' para trabajar con un solo elemento
},
{
  $match: {
    "equiposDetalles.consecutivo": "MPR720" // Filtras por el consecutivo que buscas
  }
},
{
  $group: {
    _id: "$_id",                           // Agrupas por el ID de la OT
    otType: { $first: "$otType" },   // Retienes el nombre de la OT
    numeroOrden: { $first: "$numeroOrden" },   // Retienes el nombre de la OT
    cliente:{ $first: "$cliente"},
    equiposDetalles: { $push: "$equiposDetalles" } // Vuelves a agrupar los equipos que coinciden
  }
}
]  */
