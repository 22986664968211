import { useNavigate, Link } from 'react-router-dom'
import { deleteById } from '../../api/common';
import Modal from "../commons/Modal";

export function DetailEvent ({event, isOpen, setIsOpen, setEvents, events }) {
    const {title, end, start, createdBy, descripcion, responsable, zonaCliente, numeroEquipos,type, otNumeroOrden} = event;
    
    const navigate = useNavigate();

    const handleDetalle = (otNumeroOrden) => {
        navigate(`/Seguimiento/${otNumeroOrden}?type=Ot`, { replace: true })
    }

    const handleEliminar = async() => {
        await deleteById('calendario', event.id);
        const newEvents = events.filter( el => el.id !== event.id );
        setEvents(newEvents);
        setIsOpen(false);
    }

    console.log(type);
    return(
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="detail-event-calendar">
                <h3 className='calendar-detail-title'>{title.toUpperCase()}</h3>

                {createdBy && <div className='calendar-detail-list-container'><h6>Creado por:</h6><h6>{createdBy}</h6></div>}

                {type === 'ot' ? 
                                <>
                                <div className='calendar-detail-list-container'><h6>Responsable: </h6> <h6>{responsable}</h6></div>
                                <div className='calendar-detail-list-container'><h6>Fecha Inicio: </h6> <h6>{new Date(start).toLocaleString()}</h6></div>
                                <div className='calendar-detail-list-container'><h6>Fecha Cierre: </h6> <h6>{new Date(end).toLocaleString()}</h6></div>
                                <div className='calendar-detail-list-container'><h6>Ciudad: </h6><h6>{zonaCliente}</h6></div>
                                <div className='calendar-detail-list-container'><h6>Numero Equipos: </h6><h6>{numeroEquipos}</h6></div>
                                </>
                                : 
                                <div className='calendar-detail-list-container'><h6>Fecha:</h6><h6>{new Date(start).toLocaleString()}</h6></div>
                }
                {descripcion.length > 0 &&  <h5 className='calendar-detail-descripcion'>{descripcion}</h5>}
                <div className='button-controller'>
                    {
                        type === 'ot'   ? 
                                        <button className='button-primary' onClick={() => handleDetalle(otNumeroOrden)}>Ver Detalle</button>
                                        :
                                        <>
                                        <button  className='button-primary'onClick={() => handleEliminar()}>Eliminar</button>
                                        </>
                    }
                </div>
            </div>
        </Modal>
    )
}