module.exports = {
    ADMIN:'Admin',
    CLIENT:'Cliente',
    METROLOGIST:'Metrologo',
    COMERCIAL_ADVISOR:'Asesor Comercial',
    ATTENDEE:'Asistente Administrativo',
    MANAGER:'Gerente',
    PRACTICING:'Practicante',
    LABORATY_DIRECTOR:'Directora de laboratorio',
    QUALITY_CO0RDINATOR:'Coordinadora de calidad'
}