import React, { useState } from 'react';
import { actualizarDatos, crearDatos } from '../../api/common';
import { Alertassw } from '../Alertas/Alertassw';

export const UploadSign=({ot, cambiarestado})=> {
    const {recibido }=ot
    const [base64String, setBase64String] = useState('');
    const [base64Stringview, setBase64Stringview] = useState('');
    console.log(ot)
    console.log(recibido)

    if(!recibido.persona){
        Alertassw('error','Esta hoja de trabajo no esta recibida', 'No puedes subir firma en esta sección')
        cambiarestado(false)
    }

  function convertir(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function() {
      const base64String = reader.result;
      setBase64Stringview(base64String.split(',')[1])
      setBase64String(base64String);
    }

    reader.readAsDataURL(file);
  }

  const settinsign=()=>{
    async function modificarign(){
        try {

        const result = await crearDatos("sign", {
            user: ot._id,
            sign: base64String,
            });
        
            console.log(result.data.data)

        const signedDocument = await actualizarDatos("Ot",{recibido:{
            firma: result.data.data._id,
            persona: ot?.recibido?.persona || "No registrado",
            cargo: ot?.recibido?.cargo,
            fecha: ot?.fechaRealEntrega
            }},ot._id);

        console.log(signedDocument)

        Alertassw('success','Firma guardada correctamente','Refresca la pagina para visualizar el cambio')
        cambiarestado(false);
            
        } catch (error) {
            
        }

    }

    modificarign()
  }

  return (
    <div>
      <input type="file" onChange={convertir} accept="image/*" />
      <br />
      {base64String? <img src={`data:image/png;base64,${base64Stringview}`} alt="Vista previa de la imagen" />:null}
      <br />
      {/*<textarea value={//base64String} cols="30" rows="10" readOnly />*/}
      {base64String? <button className='btn-btn-secundary' onClick={settinsign}>Guardar firma</button>:null}
    </div>
  );
}

