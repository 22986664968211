import React, { useEffect, useState } from 'react'
import moment from 'moment'
moment.locale('es')

export const  Changes=({changesot})=> {
    //console.log(changesot)
    const[changesview, setChangesview]=useState([]) //almaceno los cambios a mostrar
    useEffect(()=>{
        for(var i=0; i<changesot?.changes?.length; i++){
            const result = changesot?.changes.filter((chang)=> chang.type === 'Create-Ot' || (chang.type === 'fields' && chang.changes.length>2)  || chang.type === 'Delete_Equipment' || chang.type==='equipment updated')
            setChangesview(result)
        }
    },[changesot])
    const types =({
        'Create-Ot':'Creación OT',
        'fields':'Cambios generales',
        'Delete_Equipment':'Cancelación de equipo',
        'equipment updated':'Actualización de equipo'
    })
    console.log(changesview) 
    return(
        <>
            {changesview.map(change=>(
                <div key={change.date} className='bodychange'>
                    <div className='changetitle'>
                        <h5>{types[change.type]}</h5>
                        <div className='titlechange'>
                            <h6 className='itemtitle'>{change?.user?.name}</h6>
                            <h6 className='itemtitle'>{moment(change?.date).format('LLL') }</h6>
                        </div>
                    </div>
                    <div className='contenchange'>
                        {change.type==='equipment updated'?
                        <>
                         {change?.changes.map(cambio=>(
                             <div className='individual'>
                                <div className='cambioCard'>
                                    <div className='titlefield'>{cambio.field+':'}</div> 
                                    {cambio.field==='observacion'?
                                    (<div >{'Motivo: '+cambio.nextValue}</div>)
                                    :
                                    <>
                                    <div >{'Actual: '+cambio.nextValue}</div>
                                    <div >{'Anterio: '+cambio.prevValue}</div>
                                    </>
                                    }
                                </div>
                             </div>

                         ))}
                        </>:null}

                        {change.type==='Create-Ot'? null:
                        <>
                            {change?.changes?.length>1?(
                                change?.changes?.map(cambio=>(
                                    <>
                                    {cambio.prevValue!== cambio.nextValue && (cambio.field==='responsable' || cambio.field==='estado' || cambio.field==='fechaEntrega')? 
                                    <>
                                    <div className='individual'>
                                        <div className='cambioCard'>   
                                        {cambio.field==='fechaEntrega'?(
                                            <>
                                                <div className='titlefield'>{cambio.field+':'}</div> 
                                                <div >{'Actual: '+moment(cambio.nextValue).format('L') }</div>
                                                <div >{'Anterio: '+ (cambio.prevValue? moment(cambio.prevValue).format('L'): 'No definido')}</div>
                                            </>):
                                            <>
                                                {cambio.field!=='fechaEntrega' ?(
                                                <>
                                                    <div className='titlefield'>{cambio.field+':'}</div> 
                                                    <div >{'Actual: '+cambio.nextValue}</div>
                                                    <div >{'Anterio: '+cambio.prevValue}</div>
                                                </>): null}
                                            </>}
                                        </div>
                                    </div>
                                    </>
                                    :null}
                                    </>
                                ))
                                    ):<div></div>}
                            </>}

                        {change?.equipment?(
                            <div className='canceleq'>
                                <div className='cambioCard'>
                                    <h6 className='bold'>Equipo: </h6>
                                    <h6>{change?.equipment[0]?.name +' - '+change?.equipment[0]?.consecutive}</h6>
                                </div>
                                <div className='cambioCard'>
                                    <h6 className='bold'>Motivo: </h6>
                                    <h6>{change?.comment}</h6>
                                </div>
                            </div>):null}
                        </div>
                    </div>
            ))}
        </>
    )
}
