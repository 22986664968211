




const TabThree = ({userSelected,handleInputChange}) => {
    return(
        <>
        <div className="form_group">
          <div className="form_group_label">Contacto Recepción Factura</div>
          <input
            type="text"
            name="contactoFactura"
            defaultValue={userSelected?.contactoFactura}
            onChange={handleInputChange}
          />
        </div>

        <div className="form_group">
          <div className="form_group_label">Fecha Cierre Contable</div>
          <input
            type="date"
            name="fechaCierreContable"
            defaultValue={userSelected?.fechaCierreContable}
            onChange={handleInputChange}
          />
        </div>

        
        <div className="form_group">
          <div className="form_group_label">Correo de Facturación</div>
          <input
            type="text"
            name="correoFacturación"
            defaultValue={userSelected?.correoFacturación}
            onChange={handleInputChange}
          />
        </div>

    </>

        );
}


export default TabThree;