import React, { useState } from 'react';
import moment from 'moment';
import { Modales } from '../Modales';
import { ViewActividadDetalle } from './ViewActividadDetalle';

export const ActividadesTotal = ({actividades, refresh, setRefresh}) => {
  const [modal, setModal] = useState(false);
  const [actividadView, setActividadView] = useState();
  const [tipoView, setTipoView] = useState();
  const [intercambiador, setintercambiador]=useState(false)
  const [mesActual, setMesActual] = useState(moment()); // Mes actual
  const [verPorMes, setVerPorMes] = useState(true); // Estado para controlar si se muestra por mes o por año
  const fechaInicioInicioMes = mesActual.startOf('month') //moment().month(mesActual - 1).startOf('month');
  const diasMes = mesActual.daysInMonth() //new Date (moment().month(mesActual - 1).endOf('month')).getDate();

  const cambiarVista = () => {
    setVerPorMes(!verPorMes); // Cambiar entre vista por mes o por año
  };
  const avanzarMes = () => {
    if(verPorMes){
      setMesActual(mesActual.add(1, 'month'));
    }else{
      setMesActual(mesActual.add(1, 'year'));
    }
      setintercambiador(!intercambiador);
  };

  const retrocederMes = () => {

    if(verPorMes){
      setMesActual(mesActual.add(-1, 'month'));
    }else{
      setMesActual(mesActual.add(-1, 'year'));
    }
      setintercambiador(!intercambiador);
  };

  const handleFechaInicial=(event)=>{
    setActividadView(event)
    setTipoView(1)
    setModal(true)
  }
  const handleNextActividad=(event)=>{
    setActividadView(event)
    setTipoView(2)
    setModal(true)
  }

  const generarColumnas = (data) => {
    let dia = moment(fechaInicioInicioMes)
    if (verPorMes) {
      return Array.from({ length: diasMes }, (_, i) => {
          let actividadTrue=[];
          let fechaVencimientoCoincide=false;
          let nexfechascoincide=false;
          let fechaInicioCoincide=false;
          const esFechaCalibracion = data.map(ActividadItem => {
              let fechaVencimientoCoincide1=false;
              let nexfechascoincide1=false;
              const fechaInicioCoincide1 = dia.format('YYYY-MM-DD') === moment(ActividadItem.FechaInicio).format('YYYY-MM-DD');
              if(ActividadItem.estado==='Abierta'){
                fechaVencimientoCoincide1 = dia.format('YYYY-MM-DD') === moment(ActividadItem.FechaVencimiento).format('YYYY-MM-DD');
                nexfechascoincide1= ActividadItem?.nextFechas?.some(fecha => {
                const fechaInicio=  dia.format('YYYY-MM-DD') ===  moment(fecha).format('YYYY-MM-DD')
                return fechaInicio
              })}

          //const fechaNextFechasCoincide = ActividadItem.nextFechas && ActividadItem.nextFechas.includes(fechaActual);
          if ( fechaInicioCoincide1) {
            actividadTrue.push(ActividadItem)
            fechaInicioCoincide=true;
            return 1; // Ambas fechas coinciden
          } else if (fechaVencimientoCoincide1) {
            actividadTrue.push(ActividadItem)
            fechaVencimientoCoincide=true;
            return 2; // Solo la fecha de inicio coincide
          } else if (nexfechascoincide1) {
            nexfechascoincide=true;
            return 3; // Solo la fecha de vencimiento coincide
          } else {
            return 0; // Ninguna fecha coincide
          }
        });
        // Aplicar clase de estilo condicional si es fecha de calibración
        const claseEstilo = fechaInicioCoincide? 'ActiOriginal' : 
        fechaVencimientoCoincide? 'ActiVenc':
        nexfechascoincide? 'ActiNex':'';
        dia.add(1,'days')
        return <td className={claseEstilo} key={i} 
          onClick={
            fechaInicioCoincide? ()=>handleFechaInicial(actividadTrue):
            fechaVencimientoCoincide? ()=>handleNextActividad(actividadTrue):null
           }
        >{actividadTrue.length>=2? '+'+actividadTrue.length:''}</td>;
      });
    }
    if (!verPorMes){
      dia = dia.startOf('year')
      return Array.from({ length: 12 }, (_, i) => {
        // Verificar si el día coincide con alguna fecha de calibración
        //console.log(dia.format('YYYY-MM'))
        let actividadTrue=[];
        let fechaVencimientoCoincide=false;
        let nexfechascoincide=false;
        let fechaInicioCoincide=false;

         const esFechaCalibracion = data.map(ActividadItem => {
          let fechaVencimientoCoincide1=false;
          let nexfechascoincide1=false;
            const fechaInicioCoincide1 = dia.format('YYYY-MM') === moment(ActividadItem?.FechaInicio).format('YYYY-MM');
            if(ActividadItem.estado==='Abierta'){
               fechaVencimientoCoincide1 = dia.format('YYYY-MM') === moment(ActividadItem.FechaVencimiento).format('YYYY-MM');
               nexfechascoincide1= ActividadItem?.nextFechas?.some(fecha => {
              const fechaInicio=  dia.format('YYYY-MM') ===  moment(fecha).format('YYYY-MM')
              return fechaInicio
            })}

        //const fechaNextFechasCoincide = ActividadItem.nextFechas && ActividadItem.nextFechas.includes(fechaActual);
        if ( fechaInicioCoincide1) {
          actividadTrue.push(ActividadItem)
          fechaInicioCoincide=true;
          return 1; // Ambas fechas coinciden
        } else if (fechaVencimientoCoincide1) {
          actividadTrue.push(ActividadItem)
          fechaVencimientoCoincide=true;
          return 2; // Solo la fecha de inicio coincide
        } else if (nexfechascoincide1) {
          nexfechascoincide=true;
          return 3; // Solo la fecha de vencimiento coincide
        } else {
          return 0; // Ninguna fecha coincide
        }
        });
        // Aplicar clase de estilo condicional si es fecha de calibración
        const claseEstilo = fechaInicioCoincide? 'ActiOriginal' : 
        fechaVencimientoCoincide? 'ActiVenc':
        nexfechascoincide? 'ActiNex':'';
        dia.add(1,'month')
        return <td key={i} className={claseEstilo}
          onClick={
            fechaInicioCoincide? ()=>handleFechaInicial(actividadTrue):
            fechaVencimientoCoincide? ()=>handleNextActividad(actividadTrue):null
           }
        >{actividadTrue.length>=2? '+'+actividadTrue.length:''}</td>;
      });
    }
  };

  const nombreMes = mesActual.format('MMMM').charAt(0).toUpperCase() + mesActual.format('MMMM').slice(1); // Nombre del mes actual con primera letra en mayúscula
  const añomes=mesActual.year()
  return (
    <div>
      <Modales
        estado={modal}
        cambiarestado={setModal}
        titulo={actividadView?  tipoView===2? actividadView[0]?.tipoActividad+' '+'en espera':actividadView[0]?.tipoActividad:''}
      >
        {actividadView? <ViewActividadDetalle actividades={actividadView} tipo={tipoView}setModal={setModal} setRefresh={setRefresh} refresh={refresh}/>:null}
      </Modales>
      <div className="d-flex justify-content-between mb-3">
        <button className="btn btn-primary" onClick={retrocederMes}>&lt;</button>
        <h3>{verPorMes ? `${nombreMes} ${añomes}` : añomes}</h3> {/* Mostrar el nombre del mes y el año */}
        <button className="btn btn-primary" onClick={ avanzarMes }>&gt;</button>
      </div>
      <button className="btn btn-primary mb-3" onClick={cambiarVista}>{verPorMes ? 'Ver por año' : 'Ver por mes'}</button>
      <table className="Table_Acti">
        <thead className='table-secondary'>
          <tr style={{ backgroundColor: '#CCCCCC' }}> {/* Establecer el color de fondo gris */}
            {verPorMes && <th style={{ background: '#05a1b6', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} colSpan={diasMes + 3} className="text-center">Cronograma de actividades</th>} {/* Mostrar el nombre del mes y el año */}
            {!verPorMes && <th style={{ background: '#05a1b6', borderBottom: '1px solid #ddd', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }} colSpan={15} className="text-center">Cronograma de actividades</th>} {/* Mostrar solo el año */}
          </tr>
          <tr className='Activ_items'>
            <th >Equipo</th>
            <th >Actividad</th>
            <th>Fr</th>
            {/* Generar cabeceras de columna para cada día del mes */}
            {verPorMes && Array.from({ length: diasMes }, (_, i) => <th key={i}>{i + 1}</th>)}
            {/* Generar cabeceras de columna para cada mes del año */}
            {!verPorMes && Array.from({ length: 12 }, (_, i) => <th key={i}>{moment().month(i).format('MMM')}</th>)}
          </tr>
        </thead>
        <tbody>
          {/* Generar filas de la tabla */}
          {actividades && actividades.map((actividad, index) => (
            <React.Fragment key={index}>
              <>
              <tr>
                <td className='Activ_items' rowSpan={actividad.cantidadActividades+1}>{actividad.equipo.item}</td>
              </tr>
                {/* Obtener las claves de las actividades y renderizar una fila para cada una */}
                {Object.entries(actividad.Actividades).map(([actividadKey, actividad], i) => (
                  <tr key={i}>
                    {/* Renderizar el nombre de la actividad */}
                    <td className='Activ_lis'>{actividadKey}</td>
                    <td className='Activ_lis'>{actividad[0].frecuencia}</td>
                    {/* Generar celdas de actividad para cada día del mes */}
                    {generarColumnas(actividad)}
                    </tr>
                ))}
              </>
          </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

