import { useEffect,useState } from "react";
import { getDataObtenerUno } from "../../api/common";
import { paginate } from "../../api/paginate";
import Spinner from "../commons/Spinner";
import moment from 'moment'

moment.locale('es');

const CalibrationHistory = ({eq}) =>{
    const [history, setHistory ] = useState()
  
    useEffect(()=>{
        async function getCalibrationHistory(){
            const calibDB = await getDataObtenerUno('Changes',{object_id:eq,typeChange:'CalibrationHistory'})
            setHistory(calibDB.data.data)
            ////console.log(history)
        }
        //console.log(eq)
    if(eq) getCalibrationHistory()
    },[eq])

    return(
        <div className="center-table">
           {history?.changes?.length>0?(
                <>
                <ul className='four-cols cabeceraitem '>
                    <li > Consecutivo  </li>
                    <li > Numero Orden </li>
                    <li > Fecha Finalizacion </li>
                    <li > Responsable </li>
                </ul>
                {history.changes.map(({consecutivo,numeroOrden,fechaFinalizacion,responsable})=>(
                    <ul key={consecutivo} className="four-cols sombra-button">
                        <li >{consecutivo}</li>
                        <li>{numeroOrden}</li>
                        <li>{moment(fechaFinalizacion).format('L')}</li>
                        <li>{responsable.name}</li>
                    </ul>
                    ))}
                </>):
           (!history || history?.changes?.length===0) ?(
                <div> No hay Calibraciones Previas</div>
            ):
            (<Spinner />)
            }
        </div>)
}

export default CalibrationHistory;