import React, { useEffect, useState, useContext } from "react";
import { crearDatos, postDataClientes } from "../../api/common";
import { paginate } from "../../api/paginate";
import UserContext from "../../context/User/UserContext";
import Spinner from "../commons/Spinner";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../commons/Pagination";
import { getClientRoles } from "../users/settings";
import { useTheme } from "../../hooks/useTheme";
import DetailUser from "../commons/DetailUser";

const ListClients = ({ onModal = (modal) => modal, modal,roles }) => {
  const navigate = useNavigate();
  const [userstotal, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const { setUserSelected } = useContext(UserContext);
  const { size } = useTheme()
  const [search, setSearch]=useState("")

  /*const changePage = (page) => {
    setUsers({ ...users, results: [] });
    setPage(page);
  };*/

  const client = {
    "role.nombre":"Cliente",
    "type":"foreign",
     "populate":[]
}

  roles = getClientRoles(roles,true);

  const openSettings = (e) => {
    const display = e.target.children[0].style.display;
    e.target.children[0].style.display = display === "none" ? "block" : "none";
  };

  useEffect(() => {
    async function getUsers() {
      const clientesDB = await postDataClientes(client);
      setUsers(clientesDB.data.data);
    }

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, page]);

  const searcher=(e)=>{
    setSearch(e.target.value)
  }

  const  users = !search ? userstotal: userstotal.filter((client)=>client.nombre.toLowerCase().includes(search.toLowerCase())) 

  const editUser = (data) => {
    onModal(true);
    setUserSelected(data);
  };

  return (
    <>
    <input
      type="text"
      placeholder="Ingresa el nombre de un cliente"
      className="form-control"
      onChange={searcher}
      value={search}
    />
    <br/>
      {users && (
        <table className={size.width>800?"my_table":"w-100"}>
        {size.width>800&&<thead>
            <tr className="cabeceraitem">
              <th>Razon Social</th>
              <th>Responsable</th>
              <th>Correo</th>
              <th>Teléfono</th>
              <th>Rol</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>}
          <tbody>
            {users?.length === 0?'No hay Clientes creados':
              users?.length > 0 ? (
              users.map((user) => (
                < >{size.width>800?(
                <tr key={user._id}>
                  <th
                  className="under_line"
                  onClick={() => navigate(`/perfil?correo=${user.correo}`)}
                  >{user.nombre || ""}
                  </th>
                  <th>{user.contacto || "No hay contacto"}</th>
                  <th>{user.correo || ""}</th>
                  <th> 
                  <i class="bi bi-telephone-fill mr-5"></i>
                  <span>{user.telefono || "No registrado"}</span></th>
                  <th>
                  <div  className="color-box">
                  {user.role?.nombre || ""}
                  </div>
                
                  </th>

                  <th>
                    <div
                     className={`   
                            ${
                              user.status?.toUpperCase() === "ACTIVO"
                                ? " active-user"
                                : " inactive-user"
                            }
                            `}>{user.status?.toUpperCase() || ""}</div>
                  </th>
                  <th  style={{ cursor: "pointer" }}>
                    <div  onClick={openSettings}>
                      . . .
                      <div className="fixed-box">
                        <div
                          onClick={() => {
                            editUser(user);
                          }}
                        >
                          {" "}
                          Editar
                        </div>
                        <div>Eliminar</div>
                      </div>
                    </div>
                  </th>
                </tr>
              ):<DetailUser user={user} editUser={editUser}  />}</>))
            ) : (
              <Spinner />
            )}
          </tbody>
        </table>
      )}
     { /*<Pagination
        prev={users.prev}
        next={users.next}
        total_pages={users.total_pages}
        changePage={changePage}
        page={page}
      />*/}
    </>
  );
};
export default ListClients;
