import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth';
import Form_1EqPatron from './Form_1EqPatron';
import Form_2EqPatron from './Form_2EqPatron';
import Form_3EqPatron from './Form_3EqPatron';
import { Modales } from '../Modales';
import Form_4EqPatron from './Form_4EqPatron';
import { actualizarDatos, crearDatos, getDataList } from '../../api/common';
import { Modalcarga } from '../Modales/Modalcarga';
import Spinner from '../commons/Spinner';
import { Alertassw } from '../Alertas/Alertassw';
import PatronesList from './PatronesList';
import { ActividadesPatron } from './ActividadesPatron';
import CargaArchivo from './CargaArchivo';


const EqPatrones = () => {
    const[pageForm, setPageForm]=useState(0)
    const[estadoModal, setEstadoModal]=useState(false)
    const[eqBody, setEqBody]=useState()
    const[titulo, setTitulo]=useState()
    const[estadoIn, setEstadoIn]=useState(false)
    const[patronesList, setPatronesList]=useState()
    const[refresh, setRefresh]=useState(false)
    const[patronData, setPatronData]=useState();

    const[dataActividad,setDataActividad]=useState({
        equipo:patronData?._id,
        estado:'Abierta'
      })

    useEffect(()=>{
        setEstadoIn(false) 
        if(estadoModal===false){
            setPageForm(0)
        }
    },[estadoModal])

    useEffect(()=>{
        async function obtenerPatrones(){
            const getPatron = await getDataList ('Patrones')
            setPatronesList(getPatron.data.data)
        }
        obtenerPatrones()
    },[refresh])
    const add=(data)=>{
        data._id? setEqBody(data):setEqBody()
        console.log('editar equipos')
        setEstadoModal(true)
        setPageForm(1)
    }
    const add2=()=>{
        setEstadoModal(true)
        setTitulo('Cargar Listado de Equipos')
        setPageForm(6)
    }

    const crearEqPatron=()=>{
        async function crearPt(){
            console.log(eqBody)
            setEstadoIn(true)
            try {
                const result = eqBody._id? await actualizarDatos('Patrones',eqBody) :await crearDatos('Patrones',eqBody)
                setEqBody()
                setEstadoIn(false)
                setEstadoModal(false)
                setRefresh(!refresh)
                eqBody._id?
                Alertassw('success','Ey Excelente!','Equipo patrón editado correctamente'):
                Alertassw('success','Ey Excelente!','Equipo patrón creado correctamente')
            } catch (error) {
                setEstadoIn(false)
                Alertassw('error','Oops!','Hubo un error creando el equipo patron, verifica la infromación e intentalo de nuevo')
                console.log(error)
            }

        }
        crearPt()
    }
  return (
    <div>
        <div className="cabecera">
            <a className="titulo">Equipos patrones</a>
        </div>
        <Modalcarga
            estadoIn={estadoIn}
        >
            <Spinner/>
        </Modalcarga>
        {pageForm===0? 
        <div className="contenedor">
        <button className='btn-btn-primary' onClick={add}>Agregar Equipo Patron</button>
        <button className='btn-btn-secundary' onClick={add2}>Agregar Listado</button>
        </div>
        
        :null}
        <Modales
            estado={estadoModal}
            cambiarestado={setEstadoModal}
            titulo={titulo}
        >
            {pageForm===1? <Form_1EqPatron  crearEqPatron={crearEqPatron} setPageForm={setPageForm} setEqBody={setEqBody} eqBody={eqBody} setTitulo={setTitulo}/>: null}
            {pageForm===2? <Form_2EqPatron dataActividad={dataActividad} setDataActividad={setDataActividad} setEstadoModal={setEstadoModal} patronData={patronData} setTitulo={setTitulo} accion={1} setRefresh={setRefresh} refresh={refresh}/>: null}
            {pageForm===3? <Form_3EqPatron setPageForm={setPageForm} setEqBody={setEqBody} eqBody={eqBody} setTitulo={setTitulo}/>: null}
            {pageForm===4? <Form_4EqPatron  setPageForm={setPageForm} setEqBody={setEqBody} eqBody={eqBody} setTitulo={setTitulo}/>: null}
            {pageForm===5? <ActividadesPatron setPageForm={setPageForm} patronData={patronData} />: null}
            {pageForm===6? <CargaArchivo  setEstadoModal={setEstadoModal}  setEstadoIn={setEstadoIn} setRefresh={setRefresh} refresh={refresh}/>: null}
        </Modales>
        <PatronesList patrones={patronesList} setPageForm={setPageForm} setPatronData={setPatronData} setEstadoModal={setEstadoModal} setTitulo={setTitulo} add={add}/>
        

    </div>
  )
}

export default EqPatrones
