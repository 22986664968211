import React, { useEffect, useState } from 'react'
import { Alertassw } from '../Alertas/Alertassw'
import { Modales } from '../Modales'
import { EqInlab } from './EqInlab'

var counter=0

export const Selecteditems=({equipos, infoActa,  setModal, setVentana, crearActa, Validacion})=> {
    const[modalitem, setModalitem]=useState(false)
    const[itemIn, setItemIn]=useState({})

    const checkequipo=(e)=>{
        const i=e.target.name;
        console.log(i)
        if(e.target.checked===true ){
            equipos[i].tipoCheck='ok'
            if (equipos[i].tipoEntrega==='Certificado'){
            equipos[i].tipoEntrega='Certificado y Equipo'
            }else{
            counter++
            equipos[i].tipoEntrega='Equipo'}
        }else {
            if(equipos[i].tipoEntrega==='Certificado y Equipo' || equipos[i].tipoEntrega==='Certificado'){
                equipos[i].tipoEntrega='Certificado'
            }else{
                equipos[i].tipoEntrega='NA'
                equipos[i].tipoCheck=''
                counter--
            }
        }
    }
    console.log(equipos)

    const checkcertificado=(e)=>{
        const i=e.target.name;
        console.log(i)
        console.log(equipos[i])
       if(e.target.checked===true ){
             equipos[i].tipoCheck='ok'
            if (equipos[i].tipoEntrega==='Equipo'){
            equipos[i].tipoEntrega='Certificado y Equipo'
            }else{
                equipos[i].tipoEntrega='Certificado'
                counter++
            }
        }else {
            if(equipos[i].tipoEntrega==='Certificado y Equipo' || equipos[i].tipoEntrega==='Equipo' ){
                equipos[i].tipoEntrega='Equipo'
            }else{
                equipos[i].tipoEntrega='NA'
                equipos[i].tipoCheck=''
                counter--
            }
        }
    }
    const chargeEq=()=>{
        const ult=equipos.filter((eqfilt=> !(eqfilt?.estado==='Entregado' || eqfilt?.tipoEntrega==='NA' )&& eqfilt.tipoCheck==='ok') )
        infoActa.Equipos=ult
        console.log(infoActa)
       if(ult.length>0){ 
            infoActa.tipo='Parcial'
            const noActos=infoActa.Equipos.filter(noActa=> noActa.tipoEntrega==='Certificado' && noActa.equipo.equipo.movimiento==='Ingresado')
            if(noActos.length>0){
                Alertassw('error','Por favor selecciona certificado y equipo o solo equipo')
            }else{
               crearActa()
            }
        }else{ Alertassw('error','No hay items seleccionados')}
    }
  return (
    <div>
    <Modales
      estado={modalitem}
      cambiarestado={setModalitem}
      titulo='Equipos Ingresados'
      >
        <EqInlab setVentana={setVentana} itemIn={itemIn} setModalitem={setModalitem} crearActa={crearActa}  setModal={setModal}/>
    </Modales>
    <div className='bodyacta'>
        <div className='selectActa sombra-button cabeceraitem'>
            <div>Ítem</div>
            <div>Marca</div>
            <div>Serie</div>
            <div>Estado</div>
            <div>Consecutivo</div>
            {infoActa.entrega!=='Certificado'?(<div>Equipo</div>):<div></div>}
            {infoActa.entrega!=='Equipo'?(<div>Certificado</div>):<div></div>}
        </div>
        {equipos?.map((eq,index)=>(
            <>
            {eq?.equipo?.estado==='Aprobado' || eq?.equipo?.equipo?.movimiento==='Ingresado'?
            <div key={eq?.equipo?._id} className='selectActa sombra-button '>
                <div>{eq?.equipo?.equipo?.item.nombre}</div>
                <div>{eq?.equipo?.equipo?.marca}</div>
                <div>{eq?.equipo?.equipo?.serie}</div>
                <li>{eq?.equipo?.estado}</li>
                <div>{eq.equipo?.consecutivo}</div>
                {!(infoActa?.entrega==='Certificado' || (eq?.equipo?.equipo?.movimiento!=='Ingresado' ))?(<input className='inputchek' name={`${index}`}  type={'checkbox'} onChange={checkequipo}></input>):<div></div>}
                {infoActa.entrega==='Certificado y Equipo' && eq?.equipo?.estado==='Aprobado'? <input className='inputchek' name={`${index}`} type={'checkbox'} onChange={checkcertificado}></input>: null}
                {infoActa.entrega==='Certificado'?
                 eq.equipo.equipo.movimiento==='Ingresado'?<i class="bi bi-pin-map-fill"/>
                 :
                 <input className='inputchek' name={`${index}`} type={'checkbox'} onChange={checkcertificado}></input>
                 :
                 null
                }
            </div>:null
            }
            </>
        ))}
    </div >
    <hr></hr>
    {equipos.length>0?
        <div className='actaButton'>
            <button className='btn-btn-secundary w-100' onClick={chargeEq}>Crear Acta Parcial</button>
            <button className='btn-btn-primary w-100'  onClick={Validacion}>Crear Acta Total</button>
        </div>:null
        }
    </div>
  )
}
