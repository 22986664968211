import React, { useState } from 'react'
import {RViewerTrigger, RViewer} from 'react-viewerjs';

export const ViewImage=({eqinfo})=> {

  const [imagenActual, setImagenActual]=useState(0)
  const [zoom, setZoom]=useState(false)
  const cantidad=eqinfo?.fotos?.length

  console.log(cantidad)

  const nextImage=()=>{
    setZoom(false)
    setImagenActual(imagenActual===cantidad - 1 ? 0: imagenActual+1)
  }

  const preImage=()=>{
    setZoom(false)
    setImagenActual(imagenActual=== 0 ? cantidad - 1: imagenActual-1)
  }

  return (
    <div className='contentimg'>
        <div className='containerimg'>
        {zoom===true? null:<i class="bi bi-arrow-left-square cursor-p w30" onClick={preImage}></i>}
            {eqinfo?.fotos?.map((imagen, index)=>{
                return(
                  <div className={imagenActual===index? 'slide active':'slide'}>
                  {imagenActual===index?(
                    <img src={imagen} onClick={()=>setZoom(!zoom)} className={zoom===true? 'styleimg zoom cursor-zout ':'styleimg cursor-zin'}/>):null}
                  </div>
                )
            })}
          {zoom===true? null:<i class="bi bi-arrow-right-square cursor-p w30" onClick={nextImage}></i>}
        </div>
        <div>{imagenActual+1+'/'+cantidad}</div>
    </div>
  )
}
