import React, { useEffect, useState } from 'react'

const Form_4EqPatron = ({crearEqPatron, setPageForm, setEqBody, eqBody, setTitulo}) => {
  const [dataMI, setDataMI]=useState()

  //console.log(eqBody)

  useEffect(()=>{
   if(eqBody?.medIntermedias){
      console.log('agregando informacion a mediciones intermedias')
      setDataMI(eqBody?.medIntermedias[0])}
    setTitulo('Información de Mediciones Intermedias')
  },[])

  useEffect(()=>{
    if(dataMI)
    setEqBody({
      ...eqBody,
      medIntermedias:[
        dataMI
      ]})
   },[dataMI])

  const handleSubmit=async(e)=>{
    e.preventDefault();
    setPageForm(3)
}
  const handlePerMi=(event)=>{
    setEqBody({
        ...eqBody,
        [event.target.name]:event.target.value.toLowerCase()
    })
   }
  
   const handledata=(event)=>{
    setDataMI({
      ...dataMI,
      [event.target.name]:event.target.value.toLowerCase()
  })
}
  return (
    <div>
      <div>
        <form  className='form_container' onSubmit={handleSubmit}>
        <div className='form_group '>
                <label htmlFor={'perMi'} className='form_group_label'>PERIODO DE MEDICIONES INTERMEDIAS EN MESES</label>
                <input name='perMi' className='titulo w-100' type={'number'} onChange={handlePerMi} defaultValue={eqBody?.perMi} required={eqBody?.medIntermedias? true: false}  />
            </div>
            <div className='form_group '>
                <label htmlFor={'fecha'} className='form_group_label'>FECHA DE  MEDICIÓN:</label>
                <input name={'fecha'} className='titulo w-100' type={'date' } onChange={handledata}value={eqBody?.medIntermedias && eqBody?.medIntermedias[0]?.fecha? eqBody?.medIntermedias[0]?.fecha:'' }  />
            </div>
            <div className='form_group ' >
                <label htmlFor={'evidencia'} className='form_group_label'>EVIDENCIA</label>
                <input name={'evidencia'} className='titulo w-100' onChange={handledata}defaultValue={eqBody?.medIntermedias && eqBody?.medIntermedias[0]?.evidencia? eqBody?.medIntermedias[0]?.evidencia:'' }  />
            </div>
            <div className='form_group ' >
                <label htmlFor={'responsable'} className='form_group_label'>RESPONSABLE</label>
                <input name={'responsable'} className='titulo w-100' onChange={handledata}defaultValue={eqBody?.medIntermedias && eqBody?.medIntermedias[0]?.responsable? eqBody?.medIntermedias[0]?.responsable:''  }  />
            </div>

            <div className='w-100'>
            <button className='btn-btn-tertiary ' onClick={()=>setPageForm(3)}>Anterior</button>
            <button className='btn-btn-secundary' onClick={crearEqPatron} >Guardar</button>
            </div>
        </form>
    </div>
      
    </div>
  )
}

export default Form_4EqPatron