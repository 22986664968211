import { Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,ArcElement, PointElement,
    LineElement, Filler  } from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "chartjs-plugin-datalabels";


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,LinearScale,BarElement,Title, ChartDataLabels, PointElement,
    LineElement, Filler  );


export function BarChar({title="Grafico", data=[]}) {
    const dataDefault = {
        labels: data[0] || ['Value 1', 'Value 2', 'Value 3'],
        datasets: [
            {
                backgroundColor: 'rgba(32, 28, 111, 1)',
                borderColor: "rgb(255, 99, 132)",
                data: data[1] || [2, 6, 4],
                fill: {
                    target: "origin", // 3. Set the fill options
                    above: "rgba(255, 0, 0, 0.3)",
                    below: '#000000'
                }
            },
        ],
    };

    const options = {
        responsive: true, 
        maintainAspectRatio: true,
        aspectRatio: 2,
        plugins: {
            legend:{
                display: false
            },
            datalabels: {
                display: true,
                color: 'black',
                backgroundColor: 'white',
                borderRadius: '100',
                padding: '3',
                textAlign: 'center',
                anchor: 'end',
                borderWidth: '1',
                borderColor: 'black',
                textStrokeWidth: 'center'
            }
        }
    }

    return(
        <div className='chart-barra-container-main dashboard-item'>
            <span className='chart-header'>{title}</span>
            <div className='chart-barra-container'>
                <Bar data={dataDefault} options={options} />
            </div>
        </div>
    )
}