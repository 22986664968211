import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { EditMg } from '../Magnitud/EditMg'
import { crearDatos, getDataList, postDataMagnitud } from '../../api/common'
import { Modales } from '../Modales'

export const Areas =() => {
    //creo el estado para almacenar las areas guardadas
    const [areas, setAreas]=useState([])
    const [estadomodal, setEstadomodal] = useState(false);
    const [infoArea, setInfoArea]=useState()
    const [refresh, setRefresh]=useState(false)

    const [Area, setArea]=useState({
        nombre:'',
        descripcion:'',
    })
    console.log(Area)
    //evento para capturar la informacion de los inputs del formulario de area
    const dataarea = (event) =>{
        setArea({
            ...Area,
         [event.target.name]:event.target.value
        })
    }
    // para elimiar de la lista un objeto 
    const del = (index)=>{
        /*
        //console.log(index)
        areas.splice(index,1)
        setAreas([
          ...areas
        ])
        //console.log(areas)*/
      }

    //Evento para almacenar las Areas
    const saveArea = (e)=>{
        e.preventDefault()
        if (Area.nombre ){
        async function postArea(){
            const areasDB = await crearDatos('Areas',Area);
            console.log(areasDB)
            setAreas([
                ...areas,
                areasDB.data.data
            ]);
           
        }
        postArea()
    }}
    // Funcion para traer todas las areas almacenadas
    useEffect(() => {        
        async function getArea(){
            const areasDB = await getDataList('areas');
            ////console.log(areasDB.data.data)
            setAreas(areasDB.data.data);
            //console.log(areas)
        }
        getArea()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[refresh])

    const editArea=(data)=>{
        /*console.log(data)
        setEstadomodal(true)
        setInfoArea(data)*/
    }

  return (
    <div>
        <Modales
            estado={estadomodal} 
            cambiarestado={setEstadomodal}
            validation={true}
            titulo={`Editar Magnitud : ${infoArea?.nombre}`}
        >
        </Modales>
        <div className='cabecera'>
        <label className='titulo'>Areas de Trabajo </label>
      </div>
      <div >
            <form className='conten_mg flex wrap'>
                <input className='datauseri' onChange={dataarea} type={'string'} name='nombre' placeholder='Area' required='true'/>
                <textarea className='datauseri' onChange={dataarea} type={'string'} name='descripcion' placeholder='descripcion' required='true'/>
               <div className="botonrol"> <button className='btn-btn-secundary  submit' onClick={saveArea} type='submit'>Crear</button> </div>
            </form>
      </div>
      <div className='conten_mg flex wrap space-around ' >
          {areas && areas.map((mg , index)=>(
                    <div className='card  mb-5 mt-5' key={index}>
                        <label className='title'>{mg.nombre}</label>
                        <div className='contenido'>
                            <label className='contenido_i' htmlFor='consecutivo'>Descripcion:</label>
                            <label className='contenido_i' name='consecutivo'>{mg.descripcion}</label>
                        </div>
                        <div className='botones'> 
                            <i className="bi bi-pencil-square togEdit cursor-p" onClick={()=>editArea(mg)}></i>
                            <i onClick={()=>{del(index)}} className="bi bi-trash togDel"></i>
                        </div>
                 </div>
          ))}
      </div>
    </div>
  )
}