import React, { useState } from 'react'

const EditEqListado = ({equipoInfo, setEquipos, equipos, setEstadomodalEd}) => {
     const equipo=equipoInfo[0]
     const index=equipoInfo[1].index
     const[infoedit, setInfoedit]=useState()


     const handledata = (event) => {
        const nuevosObjetos = JSON.parse(JSON.stringify(equipos));
        if (index >= 0 && index < nuevosObjetos.length) {

            nuevosObjetos[index][event.target.name] = event.target.value;

          // Actualiza el estado con la nueva copia
         setEquipos(nuevosObjetos);
        }
      }

      const cerrarCambios=(e)=>{
        e.preventDefault();
        setEstadomodalEd(false);
       }

  return (
    <div>
        <form className='form_container' onSubmit={cerrarCambios}>
            <div className='form_group'>
                <label className='form_group_label' htmlFor='marca'>Marca:</label>
                <input onChange={handledata} name='marca' className='titulo' type={'string'}  defaultValue={equipo?.marca} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='modelo'>Modelo: </label>
                <input onChange={handledata} name='modelo' className='titulo' type={'string'}  defaultValue={equipo?.modelo} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='serie'>Serie:</label>
                <input onChange={handledata} name='serie' className='titulo' type={'string'}  defaultValue={equipo?.serie} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='inventario'>Inventario:</label>
                <input onChange={handledata} name='inventario' className='titulo' type={'string'}  defaultValue={equipo?.inventario} />
            </div>
            <div className='form_group '>             
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='unicacion'>Ubicación:</label>
                <input onChange={handledata} name='ubicacion' className='titulo' type={'string'}  defaultValue={equipo?.ubicacion} />
            </div>
            <div className='form_group '>
                <label className='form_group_label' htmlFor='resolucion'>Resolución:</label>
                <input onChange={handledata} name='resolucion' className='titulo' type={'string'}  defaultValue={equipo?.resolucion} />
            </div>
            <div className='form_group'>
                <label className='form_group_label' htmlFor='puntos'>Puntos a calibrar:</label>
                <textarea  onChange={handledata} name='puntos' className='titulo' type={'string'}  defaultValue={equipo?.puntos} ></textarea>
            </div>

            <div className='form_group'> 
            <button type='submit'>Guardar cambios</button>
            </div>
        </form>
      
    </div>
  )
}

export default EditEqListado
