import React, { useContext, useEffect, useState } from "react";
import { useForm } from "../../hooks/useForm";
import { formData, getClientRoles } from "./settings";
import UserContext from "../../context/User/UserContext";
import { actualizarDatos, getDataList } from "../../api/common";
import { adminCreate } from "../../api/user";
import { Modalcarga } from "../Modales/Modalcarga";
import Spinner from "../commons/Spinner";
import { useAuth } from "../../hooks/useAuth";

export const RegisterUserForm = ({ onModal = (modal) => modal }) => {
  const { userSelected } = useContext(UserContext);

  const [values, handleInputChange] = useForm({});
  const [estadomodalIn, setEstadomodalIn] = useState(false);
  const [roles,setRoles] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const { userData } = useAuth();

  //console.log(userSelected.role)

  const handleSubmit = async (e) => {
    setEstadomodalIn(true);
    e.preventDefault();
    try{
    if (userSelected?._id) {
      //console.log(values);
      const user = await actualizarDatos("usuarios", values, userSelected._id);
      //console.log(user);
      onModal(false);
      setEstadomodalIn(false);
    } else {
      const user = await adminCreate(values);
      //console.log(user);
      onModal(false);
      setEstadomodalIn(false);
    }
  }catch(error){
    setEstadomodalIn(false);
    setErrorMessage(error.response?.data?.detail || "Error Registando Usuario");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    
  }
  };

  useEffect(()=>{
    async function getRoles(){
      const dbRoles = await getDataList('Roles',{})  
      setRoles(getClientRoles(dbRoles.data.data,false,"object"))
    }

    getRoles()


  },[])

  return (
    <>
      <form className="form_container" onSubmit={handleSubmit}>
        <h3>{userSelected?._id ? "Editar" : "Registrar"} Usuario</h3>
        {errorMessage&&<div className="error">{errorMessage}</div>}
        <div className="form_group">
          <div className="form_group_label">Nombre</div>
          <input
            type="text"
            name="nombre"
            defaultValue={userSelected?.nombre}
            onChange={handleInputChange}
          />
        </div>
        <div className="form_group">
          <div className="form_group_label">Apellido</div>
          <input
            type="text"
            name="apellido"
            defaultValue={userSelected?.apellido}
            onChange={handleInputChange}
          />
        </div>
        <div className="form_group">
          <div className="form_group_label">Cedula</div>
          <input
            type="text"
            name="identificacion"
            defaultValue={userSelected?.identificacion}
            onChange={handleInputChange}
          />
        </div>

        <div className="form_group">
          <div className="form_group_label">Correo</div>
          <input
            type="text"
            name="correo"
            defaultValue={userSelected?.correo}
            onChange={handleInputChange}
          />
        </div>

        <div className="form_group">
          <div className="form_group_label">Género</div>
          <select
            defaultValue={userSelected?.genero}
            onChange={handleInputChange}
            name="genero"
          >
            <option value="M">Masculino</option>
            <option value="F">Femenino</option>
          </select>
        </div>

        {userData.role.nombre === "Admin" &&<div className="form_group">
          <div className="form_group_label">Rol</div>
          <select
            //defaultValue={userSelected?.role?._id}
            onChange={handleInputChange}
            name="role"
          >        
          {roles&&  roles.map((rol)=>(
            <option 
            selected={rol._id === userSelected?.role?._id}
            value={rol._id}
            >{rol.nombre}</option>
          ))}
          </select>
        </div>}

        <div className="form_group">
          <div className="form_group_label">Telefono</div>
          <input
            type="text"
            name="telefono"
            defaultValue={userSelected?.telefono}
            onChange={handleInputChange}
          />
        </div>

        <div className="form_group mt-5">
          <button type="submit">
            {" "}
            {userSelected?._id ? "Editar" : "Guardar"}
          </button>
          :
        </div>
      </form>

      <Modalcarga estadoIn={estadomodalIn} cambiarestadoIn={setEstadomodalIn}>
        <Spinner />
      </Modalcarga>
    </>
  );
};
