import { 
    Route,
    Routes,
} from "react-router-dom";
import React from "react";
import { Users } from "../views/Users";
import { Ot } from "../views/Ot";
import { CrearOt } from "../views/CrearOt";
import { Magnitud } from "../views/Magnitud";
import { Items } from "../views/Items";
import { Otselect } from "../views/Otselect";
import { Ingresos } from "../views/Ingresos";
import { SeguimientoControler } from "../components/Seguimiento/Seguimiento_controler";
import { ViewIngreso } from "../components/Ingresos/ViewIngreso";
import { Profile } from "../components/Profile/Profile";
import ListEquipments from "../components/Equipo/ListEquipments";
import ProfileEquipments from "../components/Equipo/ProfileEquipments";
import { Settinglistingreso } from "../components/Ingresos/Settinglistingreso";
import { Rolescreate } from "../views/Rolescreate";
import { Clientes} from '../views/Clientes';
import { Cotizacion } from "../components/Cotizacion/Cotizacion";
import { Calendario } from "../views/Calendario";
import { Dashboard } from "../components/dashboard/Dashboard";
import Information from "../views/Information";
import TracingEquipment from "../components/Equipo/TracingEquipment";
import { OtsonClient } from "../components/VisitasClient/OtsonClient";
import EqInOtList from "../components/EqInOt/EqInOtList";
import { ActasList } from "../components/Pdfs/ActasList";
import { ActualizarDate } from "../components/Ingresos/ActualizaDate";
import SheetsWork from "../components/Soportes/SheetsWork";
import EqPatrones from "../components/EqPatron/EqPatrones";
import { Areas } from "../components/Areas/AreasRoles";
import { SettingNot } from "../components/SettingNot/SettingNot";
import { ConsulActividades } from "../components/EqPatron/ConsulActividades";
import PerfilPatron from "../components/EqPatron/PerfilPatron";





const ContentRouter = () =>{
    return(
        <Routes>
            <Route exact path="/Ot/:id" element={<Otselect/>} /> 
            <Route exact path="/Visitas" element={<OtsonClient/>} /> 
            <Route exact path="/Seguimiento/:id" element={<SeguimientoControler/>} /> 
            <Route exact path="/users" element={<Users />} /> 
            <Route exact path="/Otlista/:id" element={<Ot />} />
            <Route exact path="/Cotizacion" element={<Cotizacion/>} />
            <Route exact path="/crearot" element={<CrearOt/>} />
            <Route exact path="/magnitud" element={<Magnitud/>} />
            <Route exact path="/items" element={<Items/>} />
            <Route exact path="/Ingresos/crear" element={<Ingresos/>} />
            <Route exact path="/Ingresos/listar" element={<Settinglistingreso/>} />
            <Route exact path="/Ingresos/:id" element={<ViewIngreso/>} />
            <Route exact path="/Ingresos" element={<Ingresos/>} />
            <Route exact path="/perfil" element={<Profile/>} />
            <Route exact path="/equipos" element={<ListEquipments />} />
            <Route exact path="/Roles" element={<Rolescreate />} />
            <Route exact path="/perfilEq" element={<ProfileEquipments />} />
            <Route exact path="/clientes" element={<Clientes />} />
            <Route exact path="/calendario" element={<Calendario />} />
            <Route path="/info" element={<Information/>} />
            <Route path="/seguimientoEquipo/:id" element={<TracingEquipment/>} />
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="*" element={<Dashboard />} />
            <Route exact path="/Actas" element={<ActasList />} />
            <Route exact path="/Ingresos/Actualizar" element={<ActualizarDate />} />
            <Route exact path="/eqInOt" element={<EqInOtList/>} />
            <Route exact path="/SheetWork" element={<SheetsWork/>} />
            <Route exact path="/Inventario" element={<EqPatrones/>} />
            <Route exact path="/Areas" element={<Areas/>} />
            <Route exact path="/Setting" element={<SettingNot/>} />
            <Route exact path="/PerfilPatron/:id" element={<PerfilPatron/>} />
            <Route exact path="/Actividades" element={<ConsulActividades/>} />
            
        </Routes>
        );

}

export default ContentRouter;