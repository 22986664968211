import React from 'react';
import { Bar } from 'react-chartjs-2';

export const ChartComponent = ({ data }) => {
// Preparar los datos para el gráfico
const datasets = [];
const responsables = []; // Almacenar todos los responsables
const colors = [
  'rgba(54, 162, 235, 0.6)',
  'rgba(255, 99, 132, 0.6)',
  'rgba(255, 206, 86, 0.6)',
  'rgba(75, 192, 192, 0.6)',
  'rgba(153, 102, 255, 0.6)',
  'rgba(255, 159, 64, 0.6)',
  // Agrega más colores si es necesario
];

// Crear un conjunto de datos para cada responsable
data.forEach(item => {
  const itemResponsables = Object.keys(item.responsables);

  itemResponsables.forEach(responsable => {
    if (responsable.trim() !== '') {
      const responsableIndex = responsables.indexOf(responsable);

      if (responsableIndex === -1) {
        responsables.push(responsable);
        const newData = Array(data.length).fill(null); // Inicializar con null
        newData[data.indexOf(item)] = item.responsables[responsable]; // Colocar la cantidad correspondiente
        datasets.push({
          label: responsable,
          data: newData,
          backgroundColor: colors[datasets.length % colors.length],
          borderColor: colors[datasets.length % colors.length].replace('0.6', '1'),
          borderWidth: 1,
        });
      } else {
        datasets[responsableIndex].data[data.indexOf(item)] = item.responsables[responsable];
      }
    }
  });
});

// Configuración del gráfico
const chartData = {
  labels: data.map(item => item.nombre), // Usar los nombres de los items como etiquetas
  datasets: datasets,
};

const options = {
  indexAxis: 'x', // Cambiar la orientación a vertical
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0,
      },
    },
  },
};

return (
  <div style={{ width: '100%', margin: '0 auto'}}> {/* Ancho del 90% de la pantalla y centrado */}
    <h5 className='text-center'>Distribución de items para todos los responsables</h5>
    <div style={{ width: '100%' }}>
        <Bar data={chartData} options={options} />
    </div>
  </div>
);
};




